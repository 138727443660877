import { Component, OnInit } from '@angular/core';
import {DialogBase, DialogManager} from '../../common/dialogs/dialog-manager.service';
import {
  WorksheetLabelsEditorDialogSession,
  WorksheetLabelsEditorDialogSessionFactory
} from './worksheet-labels-editor-session';
import {
  WorksheetLabelsEditorDialogData,
  WorksheetLabelsEditorResult
} from './worksheet-labels-editor-dialog.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cs-worksheet-labels-editor-dialog',
  templateUrl: './worksheet-labels-editor-dialog.component.html',
  styleUrls: ['./worksheet-labels-editor-dialog.component.scss']
})
export class WorksheetLabelsEditorDialogComponent implements OnInit {
  public isVisible: boolean = false;

  public dialog: DialogBase<WorksheetLabelsEditorResult>;
  public session: WorksheetLabelsEditorDialogSession;
  public sessionSubscription: Subscription;

  constructor(
    private sessionFactory: WorksheetLabelsEditorDialogSessionFactory,
    private dialogManager: DialogManager) {
  }

  public ngOnInit(): any {
    this.dialogManager.secondaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public accept(result: WorksheetLabelsEditorResult) {
    if(this.dialog) {
      this.dialog.resolve(result);
    }
  }

  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getSecondary();

    if(this.sessionSubscription){
      this.sessionSubscription.unsubscribe();
      this.sessionSubscription = undefined;
    }

    if(currentDialog && currentDialog instanceof WorksheetLabelsEditorDialogData){
      this.isVisible = true;
      this.dialog = <WorksheetLabelsEditorDialogData>currentDialog;
      this.session = this.sessionFactory.create(<WorksheetLabelsEditorDialogData>currentDialog);
      this.sessionSubscription = this.session.accepted.subscribe((result: WorksheetLabelsEditorResult) => this.accept(result));
      this.session.load();
    } else {
      this.isVisible = false;
      this.session = undefined;
      this.dialog = undefined;
    }
  }
}
