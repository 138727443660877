import { Component } from '@angular/core';
import {WorksheetDock} from '../worksheet-dock/worksheet-dock.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

@Component({
  templateUrl: './request-undock-worksheet.page.html',
  styleUrls: ['./request-undock-worksheet.page.scss']
})
export class RequestUndockWorksheetPage {
  public errorMessage: string;

  constructor(
    private readonly dock: WorksheetDock,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public requestUndock() {
    try {
      this.dock.requestUndock();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}
