import { Injectable } from '@angular/core';
import { $each } from './polyfills';
import { AllButtonCategories } from '../json-editor-node-extensions/editor-node-extensions';
import { EditorNodeExtensionsFactory } from '../json-editor-node-extensions/editor-node-extensions-factory';
import { JsonEditorCustomization } from './json-editor-customization';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';

export declare let JSONEditor: any;

@Injectable()
export class MultipleEditor extends JsonEditorCustomization {

  constructor(
    private readonly editorNodeExtensionsFactory: EditorNodeExtensionsFactory) {
    super();
  }

  public apply(): void {
    const service = this;

    JSONEditor.defaults.editors.multiple = JSONEditor.defaults.editors.multiple.extend({
      getDisplayText(arr: any[]) {
        let self = <any>this;
        let expanded: any[] = [];
        $each(arr, function(i, el) {
          if (el.$ref) {
            expanded.push(self.jsoneditor.expandRefs(el));
          } else {
            expanded.push(el);
          }
        });

        let disp = self._super(expanded);

        // The base implementation will append ' N' to the display names
        // if it finds duplicates. However it considers the same element having
        // identical title and description as a duplicate, and ends up appending
        // ' 1' even if then only appears once in the list. We therefore work around
        // this by removing the ' 1'. If there are genuine duplicates then the others
        // will still end with ' 2', ' 3', etc.
        $each(disp, function(i, name: string) {
          if (name && name.endsWith(' 1')) {
            disp[i as any] = name.substr(0, name.length - 2);
          }
        });

        return disp;
      },
      updateSwitcherVisibility() {
        let switcher = this.switcher as HTMLSelectElement;
        let itemCount = this.display_text.length;
        if (itemCount === 1
          || (itemCount === 2 && (this.display_text[0] === 'Encrypted' || this.display_text[1] === 'Encrypted'))
          || switcher.value === 'Encrypted') {
          switcher.classList.add('select-hidden');
        } else {
          switcher.classList.remove('select-hidden');
        }
      },
      build() {
        this._super();
        const canopyOptions = getCanopyJsonEditorOptions(this);

        let self = this;

        if (this.container.children.length < 3) {
          return;
        }

        const header = document.createElement('div');
        this.container.insertBefore(header, this.container.children[0]);
        header.appendChild(this.container.children[1]);
        header.appendChild(this.container.children[1]);

        this.extensions = service.editorNodeExtensionsFactory.create(canopyOptions.simVersion, canopyOptions.configType, this, canopyOptions.configSubTreeRepository);
        let buttonsResult = this.extensions.addButtonsContainer(header, AllButtonCategories);

        if (buttonsResult) {
          if (buttonsResult.buttons.encrypt) {
            header.className += ' hover-button-group-container';
          }

          let newSwitcher = this.theme.getSwitcher(this.display_text);
          header.insertBefore(newSwitcher, this.switcher);
          header.removeChild(this.switcher);
          this.switcher = newSwitcher;
          this.switcher_options = this.theme.getSwitcherOptions(this.switcher);
          this.switcher.addEventListener('change', function(e: Event) {
            e.preventDefault();
            e.stopPropagation();

            let value = (e.target as any).value;

            if (value === 'Encrypted') {
              buttonsResult.buttons.encrypt.click();
            } else {
              self.switchEditor(self.display_text.indexOf(value));
              self.onChange(true);
            }

            self.updateSwitcherVisibility();
          });
        }
      },
      onChange(bubble: boolean) {
        this._super(bubble);
        this.updateSwitcherVisibility();
      },
      setValue(value: any, initial: any) {
        // Determine type by getting the first one that validates
        let self = this;

        const tryMatchValue = (val: any) => {
          let found = false;
          $each(this.validators, function(i, validator) {

            // Quick test for objects failing encrypted test, to save running the validator.
            if (validator.schema.title === 'Encrypted' && (!val || val.name !== 'encrypted')) {
              return true;
            }

            if (!validator.validate(val).length) {
              self.type = i;
              self.switcher.value = self.display_text[i];
              found = true;
              return false;
            }
            return true;
          });
          return found;
        };

        let foundMatch = tryMatchValue(value);

        if (!initial && !foundMatch) {
          // Try putting the value in an array
          const valueInArray = [value];
          if (tryMatchValue(valueInArray)) {
            value = valueInArray;
          }
        }

        this.switchEditor(this.type);

        this.editors[this.type].setValue(value, initial);

        this.refreshValue();
        self.onChange();

        if (this.extensions) {
          this.extensions.update();
        }
      }
    });
  }
}
