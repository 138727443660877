import {DocumentSubType, StudyReference, StudyStub} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {GetSimVersion} from '../common/get-sim-version.service';
import {StudyInput} from './study-input';
import {studyResultToStudyInput} from './study-input-utilities';

@Injectable()
export class LoadConfigFromStudyInput {
  constructor(
    private readonly getSimVersion: GetSimVersion,
    private readonly studyStub: StudyStub){
  }

  public async execute(configType: DocumentSubType, studyReference: StudyReference, simVersion?: string): Promise<StudyInput | undefined>{
    simVersion = simVersion || this.getSimVersion.currentSimVersion;

    const studyResult = await this.studyStub.getStudy(
      studyReference.tenantId,
      studyReference.targetId,
      simVersion);

    return studyResultToStudyInput(configType, studyResult);
  }
}
