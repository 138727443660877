import {ColumnType} from './column-type';
import {DocumentSubType, SimType} from '../../generated/api-stubs';

export class ColumnViewModel {

  public readonly trackingId: string;

  constructor(
    public readonly columnType: ColumnType,
    public readonly id: DocumentSubType | SimType | undefined,
    public readonly name: string) {

    this.trackingId = `${id}-${name}`;
  }
}
