import {RowViewModel} from './row-view-model';
import {MenuItemsDefinition} from '../context-menu/context-menu-types';
import {ClipboardContent} from './worksheet-clipboard.service';
import {IWorksheetWrappedContextMenuBuilder} from './worksheet-wrapped-context-menu-builder';
import {CommandResult, WorksheetContext} from './worksheet-commands/worksheet-command';

export abstract class RowItemViewModel {
  private contextMenuBuilder: IWorksheetWrappedContextMenuBuilder;

  protected constructor(
    public readonly row: RowViewModel) {
  }

  public async pasteRowsAsync(content: ClipboardContent, forceDuplication: boolean, removeExisting: boolean) {
    await this.row.pasteRowsAsync(content, forceDuplication, removeExisting);
  }

  public abstract get isSelected(): boolean;

  public abstract setItemsMatching(): void;
  public abstract clearItemsMatching(): void;

  public setContextMenuBuilder(builder: IWorksheetWrappedContextMenuBuilder) {
    if(builder){
      this.contextMenuBuilder = builder;
    }
  }

  public removeContextMenuBuilder(builder: IWorksheetWrappedContextMenuBuilder) {
    if(this.contextMenuBuilder === builder){
      this.contextMenuBuilder = undefined;
    }
  }
  public generateContextMenu(worksheetContext: WorksheetContext): MenuItemsDefinition<CommandResult> {
    if(this.contextMenuBuilder) {
      return this.contextMenuBuilder.generateWrappedContextMenu(worksheetContext);
    }

    return new MenuItemsDefinition([], undefined);
  }
}
