import { Injectable } from '@angular/core';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SelectEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.select = JSONEditor.defaults.editors.select.extend({
      getAutocompleteMap() {
        const canopyOptions = getCanopyJsonEditorOptions(this);

        if (!canopyOptions.autoCompleteMaps) {
          return undefined;
        }

        return canopyOptions.autoCompleteMaps.find(v => this.path.endsWith(v.pathSuffix));
      },
      build() {
        let self = this;
        this._super();
        this.theme.addHelpLink(this.control, this.schema, this);

        let map = this.getAutocompleteMap();
        if (map) {
          if (map.changedAction) {
            this.input.addEventListener('change', function(e: Event) {
              map.changedAction(self.value, self.parent);
            });
          }
        }
      },
      setValue(value: any, initial: any, from_template: boolean) {
        this._super(value, initial, from_template);
        let map = this.getAutocompleteMap();
        if (map && map.changedAction) {
          map.changedAction(value, this.parent);
        }
      }
    });
  }
}
