import { Injectable } from '@angular/core';
import { stringToBoolean } from './string-to-boolean';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SplitBooleansEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitBooleans = JSONEditor.defaults.editors.splitStrings.extend({
      getValue() {
        return (<any>this)._super().map(function(str: string) {
 return stringToBoolean(str); 
});
      }
    });
  }
}
