import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {CompareConfigToStudyInput} from '../compare-config-to-study-input';
import {ConfigViewModel} from '../config-view-model';

@Injectable()
export class CompareToStudyInputCommand extends WorksheetCommand {

  constructor(
    private readonly compareConfigToStudyInput: CompareConfigToStudyInput) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    return context.isConfig()
      && context.isSingleRow
      && context.target.isPopulated
      && context.target.isStudyPopulated;
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    await this.compareConfigToStudyInput.execute(
      context.target.configType,
      context.target.reference,
      context.target.row.study.reference);

    return CommandResult.NoUpdate;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Compare to Study Input`,
      `compare-to-study-input-button`,
      () => this.execute(context)));
  }
}
