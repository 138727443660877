import * as d3octree from 'd3-octree';
import * as d3 from '../../d3-bundle';
import { OrderedUniqueIndices } from '../../ordered-unique-indices';
import { Utilities } from '../../utilities';
import { Vector2, Vector3 } from 'three';
import { TrackCoordinate } from '../3d/track-coordinate';
import { DisplayableError } from '../../displayable-error';
import { TrackData } from './track-data';
import { TrackPath } from './track-path';
import { isNumber } from '../../is-number';
import { isUndefined } from '../../is-defined';

interface IntermediateTrackData {
  xLeft?: number[];
  yLeft?: number[];
  zLeft?: number[];
  xRight?: number[];
  yRight?: number[];
  zRight?: number[];
  xCentreLine?: number[];
  yCentreLine?: number[];
  zCentreLine?: number[];
  xCar?: number[];
  yCar?: number[];
  zCar?: number[];
  sLap?: number[];
}

export const CreateZeroVector3 = () => new Vector3(0, 0, 0);
const MINIMUM_CROSS_PRODUCT_ANGLE = Math.PI / 8;
const MINIMUM_EDGE_DISTANCE_TO_REFERENCE = 0.1;

export const ALTERNATE_SOURCE_FOR_DEBUGGING = false; // DO NOT COMMIT WITH THIS SET TO TRUE.
export enum TrackSource {
  auto,
  outline,
  centreLine,
  racingLine,
}

enum ReferenceLocation {
  OuterNext,
  OuterPrevious,
  InnerNext,
  InnerPrevious,
}

export class ExtractTrackData {

  public execute(track: any, source: TrackSource = TrackSource.auto) {

    let outline: IntermediateTrackData | undefined = this.extractFromOutline(track);
    let hasOutline = !isUndefined(outline);
    let data: IntermediateTrackData = outline || {};

    let outlineLeftCoordinates = this.getValidatedTrackCoordinates('left', data.xLeft, data.yLeft, data.zLeft);
    let outlineRightCoordinates = this.getValidatedTrackCoordinates('right', data.xRight, data.yRight, data.zRight);

    if (source !== TrackSource.outline) {
      switch (source) {
        case TrackSource.centreLine:
          this.applyFromCentreLine(track, data);
          break;

        case TrackSource.racingLine:
          this.applyFromRacingLine(track, data);
          break;

        default:
          if (!this.applyFromCentreLine(track, data) && !hasOutline) {

            // Simulation outputs have centreLine definitions, so we won't reach here.
            // Configs should only render their racing line if the outline is missing.
            // https://app.slack.com/team/UBCCYAV8A
            this.applyFromRacingLine(track, data);
          }
          break;
      }
    }

    //// Uncomment if you want to use edge as missing racing line.
    /*
    if(!data.xCar || !data.xCar.length){
      data.xCar = data.xRight;
      data.yCar = data.yRight;
      data.zCar = data.zRight;
    }
    */

    let isPeriodic = this.isTrackPeriodic(track);

    let leftCoordinates = this.getValidatedTrackCoordinates('inner', data.xLeft, data.yLeft, data.zLeft);
    let rightCoordinates = this.getValidatedTrackCoordinates('outer', data.xRight, data.yRight, data.zRight);
    let centreLineCoordinates = this.getValidatedTrackCoordinates('centreLine', data.xCentreLine, data.yCentreLine, data.zCentreLine);
    let carCoordinates = this.getValidatedTrackCoordinates('car', data.xCar, data.yCar, data.zCar);

    // Decide which is the inner and which is the outer edge of the track.
    let isFigureOfEight = false;
    let outlineOuterCoordinates = outlineRightCoordinates;
    let outlineInnerCoordinates = outlineLeftCoordinates;
    let outerCoordinates = rightCoordinates;
    let innerCoordinates = leftCoordinates;
    if (d3.minStrict(leftCoordinates.map(e => e.x)) < d3.minStrict(rightCoordinates.map(e => e.x))) {
      if (d3.maxStrict(leftCoordinates.map(e => e.x)) < d3.maxStrict(rightCoordinates.map(e => e.x))) {
        isFigureOfEight = true;
      }
      outlineOuterCoordinates = outlineLeftCoordinates;
      outlineInnerCoordinates = outlineRightCoordinates;
      outerCoordinates = leftCoordinates;
      innerCoordinates = rightCoordinates;
    } else if (d3.maxStrict(leftCoordinates.map(e => e.x)) > d3.maxStrict(rightCoordinates.map(e => e.x))) {
      isFigureOfEight = true;
    }

    // Clean up car coordinates, just in case of NaNs or repeats.
    let carIndicesToRemove = new OrderedUniqueIndices();
    for (let i = 0; i < carCoordinates.length - 1; i++) {
      if (isNaN(carCoordinates[i].x) || isNaN(carCoordinates[i].y)
        || (carCoordinates[i].x === carCoordinates[i + 1].x && carCoordinates[i].y === carCoordinates[i + 1].y)) {
        carIndicesToRemove.add(i);
      }
    }
    Utilities.strip(carIndicesToRemove, carCoordinates);

    if (isPeriodic) {
      this.wrapCoordinates(outlineInnerCoordinates);
      this.wrapCoordinates(outlineOuterCoordinates);
      this.wrapCoordinates(innerCoordinates);
      this.wrapCoordinates(outerCoordinates);
      this.wrapCoordinates(centreLineCoordinates);
      this.wrapCoordinates(carCoordinates);
    }

    if (carCoordinates.every(v => v.z === 0)) {
      this.applyTrackElevation(outlineInnerCoordinates, outlineOuterCoordinates, innerCoordinates, outerCoordinates, centreLineCoordinates, carCoordinates);
    }

    // Compute distance along the racing line from car positions.
    let sLapCoordinates = carCoordinates.length ? carCoordinates : outerCoordinates;
    let sLap = this.computeDistanceVector(sLapCoordinates);

    let sLapCentreLine: number[] = [];
    if (centreLineCoordinates.length) {
      sLapCentreLine = this.computeDistanceVector(centreLineCoordinates);
    }

    let carNormals = this.findCarNormals(innerCoordinates, outerCoordinates, carCoordinates);

    let sLapVisibleUntil: number | undefined = track.racingLine && track.racingLine.sLap ? track.racingLine.sLap[track.racingLine.sLap.length - 1] : undefined;
    if (sLapVisibleUntil) {
      let visibleUntilIndex = sLap.findIndex(v => v > sLapVisibleUntil);
      if (visibleUntilIndex !== -1) {
        carCoordinates = carCoordinates.slice(0, visibleUntilIndex);
        carNormals = carNormals.slice(0, visibleUntilIndex);
      }
    }

    return new TrackData(
      new TrackPath(innerCoordinates),
      new TrackPath(outerCoordinates),
      new TrackPath(centreLineCoordinates),
      new TrackPath(carCoordinates),
      new TrackPath(carNormals),
      sLap,
      sLapCentreLine,
      isFigureOfEight,
      this.getStartFinishOffset(track));
  }

  private getStartFinishOffset(track: any): number {
    let result: number = track.startFinishOffset ? +track.startFinishOffset : 0;
    return isNaN(result) ? 0 : result;
  }

  private computeDistanceVector(coordinates: ReadonlyArray<TrackCoordinate>) {
    let result = [0];
    for (let i = 0; i < coordinates.length - 1; i++) {
      let sLapDiff = Math.sqrt(
        Math.pow(coordinates[i + 1].x - coordinates[i].x, 2) +
        Math.pow(coordinates[i + 1].y - coordinates[i].y, 2) +
        Math.pow(coordinates[i + 1].z - coordinates[i].z, 2));
      result.push(result[i] + sLapDiff);
    }
    return result;
  }

  private findCarNormals(innerCoordinates: ReadonlyArray<TrackCoordinate>, outerCoordinates: ReadonlyArray<TrackCoordinate>, carCoordinates: ReadonlyArray<TrackCoordinate>): TrackCoordinate[] {
    if (!carCoordinates.length) {
      return [];
    }

    let normals = new Array(carCoordinates.length).fill(new TrackCoordinate(0, 0, 1));

    let innerOctree = this.createOctree(innerCoordinates);
    let outerOctree = this.createOctree(outerCoordinates);

    let calculateEvery = 10;
    for (let carIndex = 0; carIndex < carCoordinates.length; carIndex += calculateEvery) {
      let carCoordinate = carCoordinates[carIndex];
      // let innerNearest = this.findNearestCoordinate(carCoordinate, innerCoordinates, innerIndex, false);
      // let outerNearest = this.findNearestCoordinate(carCoordinate, outerCoordinates, outerIndex, false);
      let innerNearest = innerOctree.find(carCoordinate.x, carCoordinate.y, carCoordinate.z);
      let outerNearest = outerOctree.find(carCoordinate.x, carCoordinate.y, carCoordinate.z);

      if (!innerNearest || !outerNearest) {
        continue;
      }

      let innerDistance = this.getDistance3D(innerNearest.coordinate, carCoordinate);
      let outerDistance = this.getDistance3D(outerNearest.coordinate, carCoordinate);

      let innerNearestResult = new NearestCoordinateResult(innerNearest.coordinate, innerNearest.index, innerDistance);
      let outerNearestResult = new NearestCoordinateResult(outerNearest.coordinate, outerNearest.index, outerDistance);

      //let cross = this.calculateNormalUsingCarCoordinate(carIndex, carCoordinates, innerNearest, outerNearest);
      let cross = this.calculateNormalUsingTrackCoordinates(innerNearestResult, innerCoordinates, outerNearestResult, outerCoordinates);

      if (cross.equals(CreateZeroVector3())) {
        continue;
      }

      cross.normalize();
      if (cross.z < 0.5) {
        //console.log(cross.z);
      }

      let normal = new TrackCoordinate(cross.x, cross.y, cross.z);
      for (let subIndex = 0; subIndex < calculateEvery && carIndex + subIndex < carCoordinates.length; ++subIndex) {
        normals[carIndex + subIndex] = normal;
      }

      if (carIndex >= calculateEvery) {
        for (let subIndex = 1; subIndex < calculateEvery; ++subIndex) {
          let aWeight = subIndex / calculateEvery;
          let bWeight = 1 - aWeight;
          let a = normals[carIndex];
          let b = normals[carIndex - calculateEvery];
          normals[carIndex - calculateEvery + subIndex] =
            new TrackCoordinate(
              a.x * aWeight + b.x * bWeight,
              a.y * aWeight + b.y * bWeight,
              a.z * aWeight + b.z * bWeight);
        }
      }
    }

    return normals;
  }

  private getAverageVector(list: ReadonlyArray<TrackCoordinate>, startIndexInclusive: number, endIndexInclusive: number): Vector3 {

    let minIndex = Math.min(startIndexInclusive, endIndexInclusive);
    let maxIndex = Math.max(startIndexInclusive, endIndexInclusive);

    let result = CreateZeroVector3();
    let count = 0;
    for (let i = minIndex; i <= maxIndex; ++i) {
      if (i >= 0 && i < list.length) {
        result.add(list[i].asVector3());
        ++count;
      }
    }

    if (count === 0) {
      return CreateZeroVector3();
    }

    result.divideScalar(count);
    return result;
  }

  private getReferenceVector(
    innerNearest: NearestCoordinateResult,
    innerCoordinates: ReadonlyArray<TrackCoordinate>,
    outerNearest: NearestCoordinateResult,
    outerCoordinates: ReadonlyArray<TrackCoordinate>,
    location: ReferenceLocation): Vector3 {

    const numberOfVectorsToAverage: number = 4;

    switch (location) {
      case ReferenceLocation.OuterNext:
        return this.getAverageVector(
          outerCoordinates,
          outerNearest.index + 1,
          outerNearest.index + numberOfVectorsToAverage);

      case ReferenceLocation.OuterPrevious:
        return this.getAverageVector(
          outerCoordinates,
          outerNearest.index - 1,
          outerNearest.index - numberOfVectorsToAverage);

      case ReferenceLocation.InnerNext:
        return this.getAverageVector(
          innerCoordinates,
          innerNearest.index + 1,
          innerNearest.index + numberOfVectorsToAverage);

      case ReferenceLocation.InnerPrevious:
        return this.getAverageVector(
          innerCoordinates,
          innerNearest.index - 1,
          innerNearest.index - numberOfVectorsToAverage);
    }

    return CreateZeroVector3();
  }

  public calculateNormalUsingTrackCoordinates(
    innerNearest: NearestCoordinateResult,
    innerCoordinates: ReadonlyArray<TrackCoordinate>,
    outerNearest: NearestCoordinateResult,
    outerCoordinates: ReadonlyArray<TrackCoordinate>): Vector3 {

    let edge1 = new Vector3(innerNearest.coordinate.x, innerNearest.coordinate.y, innerNearest.coordinate.z);
    let edge2 = new Vector3(outerNearest.coordinate.x, outerNearest.coordinate.y, outerNearest.coordinate.z);

    if (edge1.equals(edge2)) {
      return CreateZeroVector3();
    }

    let referenceLocations = [
      ReferenceLocation.OuterNext,
      ReferenceLocation.OuterPrevious,
      ReferenceLocation.InnerNext,
      ReferenceLocation.InnerPrevious,
    ];

    let validNormals: Vector3[] = [];
    for (let location of referenceLocations) {
      let cross = CreateZeroVector3();

      let reference = this.getReferenceVector(innerNearest, innerCoordinates, outerNearest, outerCoordinates, location);

      let v1 = new Vector3().subVectors(edge1, reference);
      let v2 = new Vector3().subVectors(edge2, reference);

      let angle = Math.abs(v1.angleTo(v2));
      if (angle < MINIMUM_CROSS_PRODUCT_ANGLE
        || Math.abs(angle - Math.PI) < MINIMUM_CROSS_PRODUCT_ANGLE
        || edge1.distanceTo(reference) < MINIMUM_EDGE_DISTANCE_TO_REFERENCE
        || edge2.distanceTo(reference) < MINIMUM_EDGE_DISTANCE_TO_REFERENCE) {
        continue;
      } else {
        cross = new Vector3().crossVectors(v1, v2);
        if (cross.z < 0) {  // In track coordinates we are z +ve downwards, so we wan the negative z normal.
          cross = new Vector3().crossVectors(v2, v1);
        }
      }

      if (!cross.equals(CreateZeroVector3())) {
        cross.normalize();
        validNormals.push(cross);
      }
    }

    if (validNormals.length === 0) {
      return CreateZeroVector3();
    }

    let result = CreateZeroVector3();
    for (let normal of validNormals) {
      result.add(normal);
    }

    return result;
  }

  public calculateNormalUsingCarCoordinate(
    carIndex: number,
    carCoordinates: ReadonlyArray<TrackCoordinate>,
    innerNearest: NearestCoordinateResult,
    outerNearest: NearestCoordinateResult): Vector3 {

    let edge1 = new Vector3(innerNearest.coordinate.x, innerNearest.coordinate.y, innerNearest.coordinate.z);
    let edge2 = new Vector3(outerNearest.coordinate.x, outerNearest.coordinate.y, outerNearest.coordinate.z);

    if (edge1.equals(edge2)) {
      return CreateZeroVector3();
    }

    let cross = CreateZeroVector3();
    let carReferenceIndex = carIndex;
    while (cross.equals(CreateZeroVector3()) && carReferenceIndex < carCoordinates.length) {
      let carReference = carCoordinates[carReferenceIndex];
      let car = new Vector3(carReference.x, carReference.y, carReference.z);

      let v1 = new Vector3().subVectors(edge1, car);
      let v2 = new Vector3().subVectors(edge2, car);

      let angle = Math.abs(v1.angleTo(v2));
      if (angle < MINIMUM_CROSS_PRODUCT_ANGLE
        || Math.abs(angle - Math.PI) < MINIMUM_CROSS_PRODUCT_ANGLE
        || edge1.distanceTo(car) < MINIMUM_EDGE_DISTANCE_TO_REFERENCE
        || edge2.distanceTo(car) < MINIMUM_EDGE_DISTANCE_TO_REFERENCE) {
        cross = CreateZeroVector3();
      } else {
        cross = new Vector3().crossVectors(v1, v2);
        if (cross.z < 0) {  // In track coordinates we are z +ve downwards, so we wan the negative z normal.
          cross = new Vector3().crossVectors(v2, v1);
        }
      }

      ++carReferenceIndex;
    }

    return cross;
  }

  private applyTrackElevation(
    sourceInnerCoordinates: ReadonlyArray<TrackCoordinate>,
    sourceOuterCoordinates: ReadonlyArray<TrackCoordinate>,
    innerCoordinates: TrackCoordinate[],
    outerCoordinates: TrackCoordinate[],
    centreLineCoordinates: TrackCoordinate[],
    carCoordinates: TrackCoordinate[]): void {

    if (!sourceInnerCoordinates.length
      || !sourceOuterCoordinates.length
      || (sourceInnerCoordinates.every(v => v.z === 0) && sourceOuterCoordinates.every(v => v.z === 0))) {
      return;
    }

    this.applyTrackElevationToPath(sourceInnerCoordinates, sourceOuterCoordinates, innerCoordinates);
    this.applyTrackElevationToPath(sourceInnerCoordinates, sourceOuterCoordinates, outerCoordinates);
    this.applyTrackElevationToPath(sourceInnerCoordinates, sourceOuterCoordinates, centreLineCoordinates);
    this.applyTrackElevationToPath(sourceInnerCoordinates, sourceOuterCoordinates, carCoordinates);
  }

  private applyTrackElevationToPath(
    sourceInnerCoordinates: ReadonlyArray<TrackCoordinate>,
    sourceOuterCoordinates: ReadonlyArray<TrackCoordinate>,
    path: TrackCoordinate[]): void {

    let innerQuadTree = this.createQuadTree(sourceInnerCoordinates);
    let outerQuadTree = this.createQuadTree(sourceOuterCoordinates);

    let calculateEvery = 10;
    for (let pathIndex = 0; pathIndex < path.length; pathIndex += calculateEvery) {
      let pathCoordinate = path[pathIndex];
      // let innerNearest = this.findNearestCoordinate(pathCoordinate, sourceInnerCoordinates, innerIndex, true);
      //let outerNearest = this.findNearestCoordinate(pathCoordinate, sourceOuterCoordinates, outerIndex, true);
      let innerNearest = innerQuadTree.find(pathCoordinate.x, pathCoordinate.y);
      let outerNearest = outerQuadTree.find(pathCoordinate.x, pathCoordinate.y);

      if (!innerNearest || !outerNearest) {
        continue;
      }

      let innerDistance = this.getDistance2D(innerNearest.coordinate, pathCoordinate);
      let outerDistance = this.getDistance2D(outerNearest.coordinate, pathCoordinate);

      let z = innerNearest.coordinate.z;
      let totalDistance = innerDistance + outerDistance;
      if (totalDistance) {
        z = innerNearest.coordinate.z * (1 - (innerDistance / totalDistance))
          + outerNearest.coordinate.z * (1 - (outerDistance / totalDistance));
      }

      for (let subIndex = 0; subIndex < calculateEvery && pathIndex + subIndex < path.length; ++subIndex) {
        if (z !== pathCoordinate.z) {
          let subIndexCoordinate = path[pathIndex + subIndex];
          path[pathIndex + subIndex] = new TrackCoordinate(subIndexCoordinate.x, subIndexCoordinate.y, z);
        }
      }

      if (pathIndex >= calculateEvery) {
        for (let subIndex = 1; subIndex < calculateEvery; ++subIndex) {
          let aWeight = subIndex / calculateEvery;
          let bWeight = 1 - aWeight;
          let a = path[pathIndex];
          let b = path[pathIndex - calculateEvery];
          let c = path[pathIndex - calculateEvery + subIndex];
          path[pathIndex - calculateEvery + subIndex] =
            new TrackCoordinate(
              c.x,
              c.y,
              a.z * aWeight + b.z * bWeight);
        }
      }
    }
  }

  private createQuadTree(coordinates: ReadonlyArray<TrackCoordinate>) {
    return d3.quadtree<QuadTreeItem>()
      .x(v => v.coordinate.x)
      .y(v => v.coordinate.y)
      .addAll(coordinates
        .map((v, i) => ({
          coordinate: v,
          index: i
        }))
        .filter(v => isNumber(v.coordinate.x) && isNumber(v.coordinate.y)));
  }

  private createOctree(coordinates: ReadonlyArray<TrackCoordinate>) {
    return d3octree.octree()
      .x((v: QuadTreeItem) => v.coordinate.x)
      .y((v: QuadTreeItem) => v.coordinate.y)
      .z((v: QuadTreeItem) => v.coordinate.z)
      .addAll(coordinates
        .map((v, i) => ({
          coordinate: v,
          index: i
        }))
        .filter(v => isNumber(v.coordinate.x) && isNumber(v.coordinate.y) && isNumber(v.coordinate.z)));
  }

  private getDistance2D(a: TrackCoordinate, b: TrackCoordinate) {
    return Math.pow(a.x - b.x, 2)
      + Math.pow(a.y - b.y, 2);
  }

  private getDistance3D(a: TrackCoordinate, b: TrackCoordinate) {
    return Math.pow(a.x - b.x, 2)
      + Math.pow(a.y - b.y, 2)
      + Math.pow(a.z - b.z, 2);
  }

  //   private findNearestCoordinate(
  //     target: TrackCoordinate,
  //     path: ReadonlyArray<TrackCoordinate>,
  //     startIndex: number,
  //     search2dOnly: boolean): NearestCoordinateResult | undefined {
  //
  //     let result: NearestCoordinateResult | undefined = undefined;
  //     let resultDistance: number = 0;
  //     for(let index=/*startIndex*/ 0; index < path.length; ++index){
  //       let c = path[index];
  //       let d
  //         = Math.pow(c.x - target.x, 2)
  //         + Math.pow(c.y - target.y, 2)
  //         + (search2dOnly ? 0 : Math.pow(c.z - target.z, 2));
  //
  // /*
  //       // NOTE: This optimization causes issues when the track width adjustments are applied to the centre line
  //       // section. See track TrackWidthAffectsElevation.json for details.
  //       if(startIndex){
  //         if(result && d > resultDistance){
  //           break;
  //         }
  //
  //         result = new NearestCoordinateResult(c, index, d);
  //         resultDistance = d;
  //       }
  //       else {
  //
  //  */
  //         if(!result || d < resultDistance){
  //           result = new NearestCoordinateResult(c, index, d);
  //           resultDistance = d;
  //         }
  //       //}
  //     }
  //
  //     return result;
  //   }

  private extractFromOutline(track: any): IntermediateTrackData | undefined {
    if (track.trackOutline && Object.entries(track.trackOutline).length >= 0) { // longest tracks don't store the centreline because it's too long!
      return {
        xLeft: track.trackOutline.xTrackEdgeLeft,
        yLeft: track.trackOutline.yTrackEdgeLeft,
        zLeft: track.trackOutline.zTrackEdgeLeft,
        xRight: track.trackOutline.xTrackEdgeRight,
        yRight: track.trackOutline.yTrackEdgeRight,
        zRight: track.trackOutline.zTrackEdgeRight,
        xCar: undefined,
        yCar: undefined,
        zCar: undefined,
        sLap: undefined,
      };
    }

    return undefined;
  }

  private applyFromCentreLine(track: any, data: IntermediateTrackData): boolean {
    if (!track.centreLine || Object.keys(track.centreLine).length === 0) {
      return false;
    }

    if (!track.centreLine.xHalfWidth) {
      throw new DisplayableError('Centre line xHalfWidth is missing.');
    }
    if (!track.centreLine.xCentreLine) {
      throw new DisplayableError('Centre line xCentreLine is missing.');
    }
    if (!track.centreLine.yCentreLine) {
      throw new DisplayableError('Centre line yCentreLine is missing.');
    }

    let xCentreLine: number[] = track.centreLine.xCentreLine;
    let yCentreLine: number[] = track.centreLine.yCentreLine;

    let aYaw: number[] = [];
    if (track.centreLine.aYawCentreLine && track.centreLine.aYawCentreLine.length) {
      aYaw = track.centreLine.aYawCentreLine;
    } else {
      for (let i = 0; i < xCentreLine.length - 1; i++) {
        aYaw.push(Math.atan2(yCentreLine[i + 1] - yCentreLine[i], xCentreLine[i + 1] - xCentreLine[i]));
      }
      aYaw.push(aYaw[aYaw.length - 1]);
    }

    let xTrackOffset = (track.centreLine.xHalfWidth as number[]).map((xHalf, i) => -xHalf * Math.sin(aYaw[i]));
    let yTrackOffset = (track.centreLine.xHalfWidth as number[]).map((xHalf, i) => xHalf * Math.cos(aYaw[i]));
    let xLeft = xCentreLine.map((e, i) => e + xTrackOffset[i]);
    let yLeft = yCentreLine.map((e, i) => e + yTrackOffset[i]);
    let xRight = xCentreLine.map((e, i) => e - xTrackOffset[i]);
    let yRight = yCentreLine.map((e, i) => e - yTrackOffset[i]);

    let xCar: number[];
    let yCar: number[];
    if (track.centreLine.rTrackCentreOffset) {
      let xCarOffset = xTrackOffset.map((e, i) => e * track.centreLine.rTrackCentreOffset[i]);
      let yCarOffset = yTrackOffset.map((e, i) => e * track.centreLine.rTrackCentreOffset[i]);
      xCar = xCentreLine.map((e, i) => e + xCarOffset[i]);
      yCar = yCentreLine.map((e, i) => e + yCarOffset[i]);
    } else {
      xCar = xRight;
      yCar = yRight;
    }

    let zLeft = new Array(xLeft.length).fill(0);
    let zRight = new Array(xRight.length).fill(0);
    let zCar = new Array(xCar.length).fill(0);
    let zCentreLine = new Array(xCentreLine.length).fill(0);

    data.xLeft = xLeft;
    data.yLeft = yLeft;
    data.zLeft = zLeft;
    data.xRight = xRight;
    data.yRight = yRight;
    data.zRight = zRight;
    data.xCar = xCar;
    data.yCar = yCar;
    data.zCar = zCar;
    data.xCentreLine = xCentreLine;
    data.yCentreLine = yCentreLine;
    data.zCentreLine = zCentreLine;
    return true;
  }

  private applyFromRacingLine(track: any, data: IntermediateTrackData): boolean {
    if (!track.racingLine) {
      return false;
    }

    let hasData = (v: ReadonlyArray<number>) => !!(v && v.length);

    let hasRacingLineDefinedByCurvature
      = hasData(track.racingLine.sLap)
      && hasData(track.racingLine.cLap);

    let hasRacingLineDefinedByXY
      = hasData(track.racingLine.xRacingLine)
      && hasData(track.racingLine.yRacingLine);

    let hasRacingLineDefinedBygLat
      = hasData(track.racingLine.gLat)
      && hasData(track.racingLine.vCar)
      && (hasData(track.racingLine.sLap) || hasData(track.racingLine.tLap));

    let hasRacingLine
      = hasRacingLineDefinedByCurvature
      || hasRacingLineDefinedBygLat
      || hasRacingLineDefinedByXY;

    if (!hasRacingLine) {
      return false;
    }

    // let isPeriodic = this.isTrackPeriodic(track);
    let trackLapType = this.getTrackLapType(track);

    if (trackLapType === TrackLapType.notSpecified) {
      // trackLapType = isPeriodic ? TrackLapType.closedInfer : TrackLapType.open;
      trackLapType = TrackLapType.open;
    }

    let extractRacingLineResult: ExtractRacingLineResult | undefined;

    if (hasRacingLineDefinedByXY) {
      extractRacingLineResult = this.extractRacingLineFromXY(
        track.racingLine.xRacingLine,
        track.racingLine.yRacingLine);
    } else if (hasRacingLineDefinedBygLat) {
      extractRacingLineResult = this.extractRacingLineFromgLat(
        track.racingLine.gLat,
        track.racingLine.vCar,
        track.racingLine.tLap,
        track.racingLine.sLap,
        trackLapType);
    } else {
      extractRacingLineResult = this.extractRacingLineFromCurvature(
        track.racingLine.sLap,
        track.racingLine.cLap,
        trackLapType);
    }

    let xCar = extractRacingLineResult.xCar;
    let yCar = extractRacingLineResult.yCar;
    let sLap = extractRacingLineResult.sLap;

    let zCar = track.racingLine.zTrack;
    if (!zCar || !zCar.length) {
      let incline = track.racingLine.aTrackIncline;
      if (incline && incline.length) {
        zCar = [0];
        for (let i = 1; i < sLap.length; i++) {
          let ds = sLap[i] - sLap[i - 1];
          zCar.push(zCar[i - 1] + ds * Math.tan(incline[i - 1]));
        }
      }
    } else if (sLap.length !== zCar.length) {
      throw new DisplayableError('Racing line sLap and zTrack differ in length.');
    }

    if (!zCar) {
      zCar = new Array(xCar.length).fill(0);
    }

    data.sLap = [...sLap];
    data.xCar = [...xCar];
    data.yCar = [...yCar];
    data.zCar = zCar;

    let camber = track.racingLine.aTrackCamber;
    if (camber && camber.length) {

      if (sLap.length !== camber.length) {
        throw new DisplayableError('Racing line sLap and aTrackCamber differ in length.');
      }

      if (!data.xLeft || !data.xLeft.length) {
        data.xLeft = [...xCar];
        data.yLeft = [...yCar];
        data.zLeft = [...zCar];
        data.xRight = [...xCar];
        data.yRight = [...yCar];
        data.zRight = [...zCar];

        const trackHalfWidth = 0.5;
        for (let i = 0; i < sLap.length; i++) {
          let heightChange = trackHalfWidth * Math.tan(camber[i]);
          data.zLeft[i] += heightChange;
          data.zRight[i] -= heightChange;

          // This isn't totally accurate, as we don't account for track rotation when calculating X and Y.
          let aOffset = 0;
          let bOffset = -1;
          if (i === 0) {
            aOffset = 1;
            bOffset = 0;
          }
          let diff = new Vector2(data.xCar[i + aOffset] - data.xCar[i + bOffset], data.yCar[i + aOffset] - data.yCar[i + bOffset]);
          diff.normalize();
          data.xLeft[i] -= diff.y;
          data.yLeft[i] += diff.x;
          data.xRight[i] += diff.y;
          data.yRight[i] -= diff.x;
        }
      }
    }

    return true;
  }

  private extractRacingLineFromXY(xCar: ReadonlyArray<number>, yCar: ReadonlyArray<number>): ExtractRacingLineResult {
    if (xCar.length !== yCar.length) {
      throw new DisplayableError('Racing line X and Y coordinates must be the same length.');
    }

    let coordinates = xCar.map((v, i) => new TrackCoordinate(v, yCar[i], 0));
    let sLap = this.computeDistanceVector(coordinates);

    return new ExtractRacingLineResult(xCar, yCar, sLap);
  }

  private extractRacingLineFromgLat(
    gLat: ReadonlyArray<number>,
    vCar: ReadonlyArray<number>,
    tLap: ReadonlyArray<number> | undefined,
    sLap: ReadonlyArray<number> | undefined,
    trackLapType: TrackLapType) {

    if (gLat.length !== vCar.length) {
      throw new DisplayableError('Racing line gLat and vCar vectors do not have consistent lengths.');
    }


    if (!sLap || !sLap.length) {
      if (!tLap) {
        throw new DisplayableError('Racing line must have either sLap or tLap.');
      }

      if (tLap.length !== vCar.length) {
        throw new DisplayableError('Racing line tLap and vCar vectors do not have consistent lengths.');
      }

      if (tLap[0] !== 0) {
        throw new DisplayableError('Racing line tLap must start at tLap = 0.');
      }

      let sLapConverted: number[] = [];
      sLapConverted.push(0);
      for (let i = 0; i < (tLap.length - 1); i++) {
        sLapConverted.push(0.5 * (vCar[i + 1] + vCar[i]) * (tLap[i + 1] - tLap[i]) + sLapConverted[i]);
      }
      sLap = sLapConverted;
    }

    let gLatOffset = 0;

    if (trackLapType !== TrackLapType.open) {
      /* Populate cLap from gLat and vCar */
      let dx = 1e-6;

      /* Loop through a few gLat adjusts to ensure we go through 2*pi radians */
      for (let j = 0; j < 3; j++) {
        let cLapBase: number[] = Array(vCar.length).fill(0);
        let cLapPerturbed: number[] = Array(vCar.length).fill(0);

        for (let i = 0; i < vCar.length; i++) {
          cLapBase[i] = ((gLat[i] + gLatOffset) / (vCar[i] * vCar[i]));
          cLapPerturbed[i] = ((gLat[i] + gLatOffset + dx) / (vCar[i] * vCar[i]));
        }

        let aYaw = this.inferTotalaYawFromRacingLine(trackLapType, sLap, cLapBase);
        let aYawPerturbed = this.trapz(sLap, cLapPerturbed);

        gLatOffset -= aYaw.totalMinusExpected / ((aYawPerturbed - aYaw.total) / dx);
      }
    }

    // let cLap: number[] = [];
    // for (let i = 0; i < vCar.length; i++) {
    //   cLap.push(gLat[i] / (vCar[i] * vCar[i]));
    // }

    /* Generate cLap with the gLatOffset we've got */
    //gLatOffset = 0;
    let cLap: number[] = Array(vCar.length).fill(0);
    for (let i = 0; i < vCar.length; i++) {
      cLap[i] = ((gLat[i] + gLatOffset) / (vCar[i] * vCar[i]));
    }

    return this.computeTrackXYFromCurvature(sLap, cLap, trackLapType !== TrackLapType.open);
  }

  private extractRacingLineFromCurvature(
    sLap: ReadonlyArray<number>,
    cLap: ReadonlyArray<number>,
    trackLapType: TrackLapType): ExtractRacingLineResult {

    if (sLap.length !== cLap.length) {
      throw new DisplayableError(`Racing line sLap and cLap differ in length (${sLap.length} vs ${cLap.length}).`);
    }

    // let aYawTrack: number[] = Array(sLap.length-1).fill(0);
    // let pi = 3.141592;
    // aYawTrack[0] = 0;
    // for (let i = 1; i < sLap.length-1; i++) {
    //   aYawTrack[i] = aYawTrack[i-1] + (sLap[i+1] - sLap[i-1]) / 2 * cLap[i];
    // }

    // if(isPeriodic) {
    //   // scale to exactly match finish angle with start angle
    //   let rScaleTheta = 1;
    //   let thetaOffset = 0;
    //   let thetaTotal = aYawTrack[aYawTrack.length-1];
    //   if (Math.abs(thetaTotal) > pi) {
    //     // loop track
    //     rScaleTheta = Math.abs(2 * pi / thetaTotal);
    //     thetaOffset = 0;
    //   } else {
    //     // figure of eight track
    //     rScaleTheta = 1;
    //     thetaOffset = -thetaTotal;
    //   }
    //   for (let i = 0; i < aYawTrack.length; i++) {
    //     aYawTrack[i] *= rScaleTheta;
    //     aYawTrack[i] += thetaOffset * i / aYawTrack.length;
    //     aYawTrack[i] -= pi/2;
    //   }
    // }

    // // convert theta to x-y
    // let xCar: number[] = [];
    // let yCar: number[] = [];
    // xCar.push(0);
    // yCar.push(0);
    // for (let i = 1; i < sLap.length; i++) {
    //   let ds = sLap[i] - sLap[i-1];
    //   xCar.push(xCar[i-1] + ds * Math.sin(aYawTrack[i-1]));
    //   yCar.push(yCar[i-1] - ds * Math.cos(aYawTrack[i-1]));
    // }

    // if(isPeriodic) {
    //   // match up start and finish points (in x-y)
    //   let dxsf = xCar[xCar.length - 1] - xCar[0];
    //   let dysf = yCar[xCar.length - 1] - yCar[0];
    //   for (let i = 0; i < xCar.length; i++) {
    //     xCar[i] -= dxsf * i / (xCar.length - 1);
    //     yCar[i] -= dysf * i / (xCar.length - 1);
    //   }
    // }

    let aYaw = this.inferTotalaYawFromRacingLine(trackLapType, sLap, cLap);
    // double aYawTotal = trapz(RacingLine.sLap, RacingLine.cLap);
    // double aYawExpected = InferTotalaYawFromRacingLine(RacingLine.sLap, RacingLine.cLap);
    // double aYawAdjust = aYawExpected - aYawTotal;

    /* Apply the changes to cLap to get aYaw just right */
    if (!aYaw.isExpectedYaw) {
      let sLapStart = sLap[0];
      let sLapEnd = sLap[sLap.length - 1];
      let sLapTotal = sLapEnd - sLapStart;

      let cLapAdjusted = [...cLap];

      let daYaw_dsLap = aYaw.expectedMinusTotal / sLapTotal;
      for (let i = 0; i < sLap.length; i++) {
        cLapAdjusted[i] += daYaw_dsLap;
      }

      cLap = cLapAdjusted;
    }

    return this.computeTrackXYFromCurvature(sLap, cLap, trackLapType !== TrackLapType.open);
  }

  private computeTrackXYFromCurvature(
    sLap: ReadonlyArray<number>,
    cLap: ReadonlyArray<number>,
    correctToZeroDrift: boolean): ExtractRacingLineResult {

    if (sLap.length !== cLap.length) {
      throw new DisplayableError(`Racing line sLap and cLap differ in length (${sLap.length} vs ${cLap.length}).`);
    }

    let aYawTrack: number[] = Array(sLap.length - 1).fill(0);
    aYawTrack[0] = 0;
    for (let i = 1; i < sLap.length - 1; i++) {
      aYawTrack[i] = aYawTrack[i - 1] + (sLap[i + 1] - sLap[i - 1]) / 2 * cLap[i];
    }

    // convert theta to x-y
    let xCar: number[] = [];
    let yCar: number[] = [];
    xCar.push(0);
    yCar.push(0);
    for (let i = 1; i < sLap.length; i++) {
      let ds = sLap[i] - sLap[i - 1];
      xCar.push(xCar[i - 1] + ds * Math.sin(aYawTrack[i - 1]));
      yCar.push(yCar[i - 1] - ds * Math.cos(aYawTrack[i - 1]));
    }

    if (correctToZeroDrift) {
      // match up start and finish points (in x-y)
      let dxsf = xCar[xCar.length - 1] - xCar[0];
      let dysf = yCar[xCar.length - 1] - yCar[0];
      let sLapEnd = sLap[sLap.length - 1];
      for (let i = 0; i < xCar.length; i++) {
        xCar[i] -= dxsf * (sLap[i] / sLapEnd);
        yCar[i] -= dysf * (sLap[i] / sLapEnd);

        // This was the previous code, but using sLap matches what canopy-sims does and seems more correct.
        // xCar[i] -= dxsf * i / (xCar.length - 1);
        // yCar[i] -= dysf * i / (xCar.length - 1);
      }

    }

    return new ExtractRacingLineResult(xCar, yCar, sLap);
  }

  private getValidatedTrackCoordinates(name: string, x: number[] | undefined, y: number[] | undefined, z: number[] | undefined): TrackCoordinate[] {
    if (!x || !y) {
      return [];
    }

    if (!z || !z.length) {
      z = new Array(x.length).fill(0);
    }

    const zDefined = z;

    if (x.length !== y.length
      || x.length !== z.length) {
      throw new DisplayableError(`Track ${name} vector lengths do not match (${x.length},${y.length},${z.length}).`);
    }

    return x.map((v, i) => new TrackCoordinate(x[i], y[i], zDefined[i]));
  }

  private isTrackPeriodic(track: any) {
    return !track.trackPeriodicity || track.trackPeriodicity.bTrackPeriodic;
  }

  private getTrackLapType(track: any): TrackLapType {
    if (track.racingLine && track.racingLine.lapType) {
      switch (track.racingLine.lapType) {
        case 'Open, ends not joined': return TrackLapType.open;
        case 'Single conventional lap': return TrackLapType.closedConventionalLap;
        case 'Single figure of 8': return TrackLapType.closedFigureOfEight;
        case 'Automatically infer': return TrackLapType.closedInfer;
      }
    }

    return TrackLapType.notSpecified;
  }

  // Trapezoidal numerical integration.
  // Taken from canopy-sims/Track.cpp
  private trapz(x: ReadonlyArray<number>, y: ReadonlyArray<number>): number {
    let z: number = 0;

    for (let i = 0; i < x.length - 1; i++) {
      let dx = x[i + 1] - x[i];
      z += dx * (y[i + 1] + y[i]) / 2;
    }

    return z;
  }

  // Taken from canopy-sims/Track.cpp
  private sign(value: number): number {
    if (value < 0) {
      return -1;
    }

    return 1;
  }

  // Taken from canopy-sims/Track.cpp
  private inferTotalaYawFromRacingLine(trackLapType: TrackLapType, sLap: ReadonlyArray<number>, cLap: ReadonlyArray<number>): InferredYaw {
    const aYawTotal = this.trapz(sLap, cLap);

    let aYawExpected = aYawTotal;

    switch (trackLapType) {
      case TrackLapType.closedInfer:
        /* Correct the yaw to the nearest multiple of 2*pi */
        aYawExpected = 2.0 * Math.PI * Math.round(aYawTotal / (2 * Math.PI));
        break;

      case TrackLapType.closedConventionalLap:
        /* Correct the yaw to + or - 2 *pi */
        aYawExpected = (2 * Math.PI * this.sign(aYawTotal));
        break;

      case TrackLapType.closedFigureOfEight:
        /* Correct the yaw to zero */
        aYawExpected = 0;
        break;
    }

    return new InferredYaw(aYawTotal, aYawExpected);
  }


  private wrapCoordinates(input: TrackCoordinate[]): void {
    if (input.length) {
      input.push(input[0]);
    }
  }
}

enum TrackLapType {
  notSpecified,
  open,
  closedConventionalLap,
  closedFigureOfEight,
  closedInfer
}

class InferredYaw {
  constructor(
    public readonly total: number,
    public readonly expected: number) {
    this.expectedMinusTotal = expected - total;
    this.totalMinusExpected = total - expected;
    this.isExpectedYaw = (expected === total);
  }

  public readonly expectedMinusTotal: number;
  public readonly totalMinusExpected: number;
  public readonly isExpectedYaw: boolean;
}

class NearestCoordinateResult {
  constructor(
    public readonly coordinate: TrackCoordinate,
    public readonly index: number,
    public readonly distance: number) { }
}

class ExtractRacingLineResult {
  constructor(
    public readonly xCar: ReadonlyArray<number>,
    public readonly yCar: ReadonlyArray<number>,
    public readonly sLap: ReadonlyArray<number>) {
  }
}

interface QuadTreeItem {
  coordinate: TrackCoordinate;
  index: number;
}
