import { ChartSettings } from '../chart-settings';
import { Margin } from '../margin';

export class TrackViewer3dSettings extends ChartSettings {
  public static build(sourceCount: number): TrackViewer3dSettings {
    return new TrackViewer3dSettings(sourceCount);
  }

  constructor(sourceCount: number) {
    super(sourceCount);
    this.svgPadding = new Margin(5, 5, 18, 5);
  }

  public get spaceBetweenPlots(): number {
    return 6;
  }
}
