import { Injectable, NgZone } from '@angular/core';
import { OverrideChildUnits } from '../get-autocomplete-maps.service';
import { UnitsManager } from '../../../../units/units-manager.service';
import { JsonEditorCustomization } from './json-editor-customization';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import awesomplete from 'awesomplete';

export declare let JSONEditor: any;

@Injectable()
export class StringEditor extends JsonEditorCustomization {

  constructor(
    private readonly zone: NgZone,
    private readonly unitsManager: UnitsManager) {
    super();
  }

  public apply(): void {
    const service = this;

    JSONEditor.defaults.editors.string = JSONEditor.defaults.editors.string.extend({
      getAutocompleteMap() {
        const canopyOptions = getCanopyJsonEditorOptions(this);

        if (!canopyOptions.autoCompleteMaps) {
          return undefined;
        }

        return canopyOptions.autoCompleteMaps.find(v => this.path.endsWith(v.pathSuffix));
      },
      getOverrideChildUnits(): OverrideChildUnits | undefined {
        let parent = this.parent;
        while (parent) {
          if (parent.overrideChildUnits) {
            return parent.overrideChildUnits;
          }

          parent = parent.parent;
        }
        return undefined;
      },
      preBuild() {
        this._super();
        const canopyOptions = getCanopyJsonEditorOptions(this);

        this.getDefaultUnitsAndChannelName = () => {
          let defaultUnits = this.schema.units;
          let channelName = this.schema.unitsChannelName || this.title;

          if (defaultUnits) {
            let overrideChildUnits = this.getOverrideChildUnits();
            if (overrideChildUnits) {
              defaultUnits = overrideChildUnits.units || defaultUnits;
              channelName = overrideChildUnits.channelName || channelName;
            }
          }

          return {
            units: defaultUnits,
            channelName
          };
        };
        this.getUnitsAndChannelName = () => {
          let defaultUnitsAndChannel = this.getDefaultUnitsAndChannelName();
          let defaultUnits = defaultUnitsAndChannel.units;
          let channelName = defaultUnitsAndChannel.channelName;

          let units = service.unitsManager.getUnitsSynchronous(channelName, defaultUnits);

          return {
            units,
            channelName
          };
        };
        this.getDefaultUnits = () => this.getDefaultUnitsAndChannelName().units;
        this.getUnits = () => this.getUnitsAndChannelName().units;
        this.initializeUnitChangedSubscription = () => {
          canopyOptions.internalUnitsChanged.subscribe(() => {
            service.zone.runOutsideAngular(() => {
              let previousUnits = this.valueUnits;
              let newUnits = this.getUnits();

              // We always update if overrideChildUnits is set because we may have changed the OverrideChildUnitsMode.
              let overrideChildUnits = this.getOverrideChildUnits();

              if (previousUnits !== newUnits || overrideChildUnits) {
                let value = this.getValue();
                this.setValue(value);
              }
            });
          });
        };
        this.sanitizeArray = (value: any) => {
          if (value && !Array.isArray(value)) {
            if (typeof value === 'object') {
              value = [];
            } else {
              value = [value];
            }
          }
          return value || [];
        };
        this.sanitizeArray2D = (value: any) => {
          if (value) {
            if (!Array.isArray(value)) {
              if (typeof value === 'object') {
                return [[]];
              }
              return [[value]];
            }

            for (let i = 0; i < value.length; ++i) {
              value[i] = this.sanitizeArray(value[i]);
            }

            return value;
          }

          return [[]];
        };
        this.replaceNullWithNaN = (value: any) => {
          if (!Array.isArray(value)) {
            return;
          }

          for (let i = 0; i < value.length; ++i) {
            if (value[i] === null || value[i] === undefined) {
              value[i] = NaN;
            }
          }
        };
        this.replaceNullWithNaN2D = (value: any) => {
          if (!Array.isArray(value)) {
            return;
          }

          for (let row of value) {
            this.replaceNullWithNaN(row);
          }
        };
      },
      build() {
        let self = this;
        this.title = this.getTitle();

        if (!this.options.compact) {
 this.header = this.label = this.theme.getFormInputLabel(this.title);
}
        if (this.schema.description) {
 this.description = this.theme.getFormInputDescription(this.schema.description);
}

        this.format = this.schema.format;
        if (!this.format && this.schema.media && this.schema.media.type) {
          this.format = this.schema.media.type.replace(/(^(application|text)\/(x-)?(script\.)?)|(-source$)/g, '');
        }
        if (!this.format && this.options.default_format) {
          this.format = this.options.default_format;
        }
        if (this.options.format) {
          this.format = this.options.format;
        }

        if (this.format) {
          // Specific format
          (<any>this)._super();
          return;
        } else {
          // Normal text input
          this.input_type = 'text';
          this.input = this.theme.getFormInputField(this.input_type);
        }

        // minLength, maxLength, and pattern
        if (typeof this.schema.maxLength !== 'undefined') {
 this.input.setAttribute('maxlength', this.schema.maxLength);
}
        if (typeof this.schema.pattern !== 'undefined') {
 this.input.setAttribute('pattern', this.schema.pattern);
} else if (typeof this.schema.minLength !== 'undefined') {
 this.input.setAttribute('pattern', '.{' + this.schema.minLength + ',}');
}

        if (this.options.compact) {
          (<any>this).container.className += ' compact';
        } else {
          if (this.options.input_width) {
 this.input.style.width = this.options.input_width;
}
        }

        if (this.schema.readOnly || this.schema.readonly || this.schema.template) {
          this.always_disabled = true;
          this.input.disabled = true;
        }

        this.input.id = this.path.replace(/\./g, '-');

        this.input
          .addEventListener('change', function(this: typeof JSONEditor.defaults.editors.string, e: Event) {
            e.preventDefault();
            e.stopPropagation();

            // Don't allow changing if this field is a template
            if (self.schema.template) {
              this.value = self.value;
              return;
            }

            let val = this.value;

            // sanitize value
            let sanitized = self.sanitize(val);
            if (val !== sanitized) {
              this.value = sanitized;
            }

            self.is_dirty = true;

            self.refreshValue();
            self.onChange(true);
          });

        if (this.format) {
 this.input.setAttribute('data-schemaformat', this.format);
}

        let formElement = this.input;
        let map = this.getAutocompleteMap();
        if (map) {
          formElement = document.createElement('div');
          formElement.appendChild(this.input);

          let a = new awesomplete(this.input, map.options);
          if (map.popupOnSelect) {
            this.input.addEventListener('awesomplete-selectcomplete', function(e: Event) {
              self.refreshValue();
              self.onChange(true);
              a.open();
              a.evaluate();
            });
            this.input.addEventListener('focus', function(e: Event) {
              a.open();
              a.evaluate();
            });
          }

          if (map.changedAction) {
            this.input.addEventListener('change', function(e: Event) {
              map.changedAction(self.value, self.parent);
            });
            //this.input.addEventListener('keyup',function(e) {
            //  map.changedAction(e.target.value, self.parent);
            //});
            this.input.addEventListener('awesomplete-selectcomplete', function(e: Event) {
              map.changedAction(self.value, self.parent);
            });
          }

          if (map.userInformation.length) {
            let oldDescription = this.description;

            this.description = document.createElement('div');
            for (let userInformation of map.userInformation) {
              this.description.appendChild(this.theme.getFormInputDescription(userInformation));
            }

            if (oldDescription) {
              this.description.appendChild(oldDescription);
            }
          }
        }

        this.control = this.theme.getFormControl(this.label, formElement, this.description, this.schema, this.title, this);
        (<any>this).container.appendChild(this.control);

        // Any special formatting that needs to happen after the input is added to the dom
        window.requestAnimationFrame(function() {
          // Skip in case the input is only a temporary editor,
          // otherwise, in the case of an ace_editor creation,
          // it will generate an error trying to append it to the missing parentNode
          if (self.input.parentNode) {
 self.afterInputReady();
}
          if (self.adjust_height) {
 self.adjust_height(self.input);
}
        });

        // Compile and store the template
        if (this.schema.template) {
          this.template = (<any>this).jsoneditor.compileTemplate(this.schema.template, (<any>this).template_engine);
          (<any>this).refreshValue();
        } else {
          (<any>this).refreshValue();
        }
      },
      setValue(value: any, initial: any, from_template: boolean) {
        this._super(value, initial, from_template);
        let map = this.getAutocompleteMap();
        if (map && map.changedAction) {
          map.changedAction(value, this.parent);
        }
      }
    });
  }
}
