import {Component, Input} from '@angular/core';
import {ResolvedLabel} from '../../../generated/api-stubs';

@Component({
  selector: 'cs-worksheet-labels',
  templateUrl: './worksheet-labels.component.html',
  styleUrls: ['./worksheet-labels.component.scss']
})
export class WorksheetLabelsComponent {

  @Input() public labels: ReadonlyArray<ResolvedLabel>;

  constructor() { }

  public get hasLabels(): boolean {
    return !!(this.labels && this.labels.length);
  }

  public get isNumericValue(): boolean {
    let value = this.labels[0].numericValue;
    return value !== null && value !== undefined;
  }

  public get isStringValue(): boolean {
    let value = this.labels[0].stringValue;
    return value !== null && value !== undefined;
  }

  public get isNumericStatistics(): boolean {
    let value = this.labels[0].numericStatistics;
    return value !== null && value !== undefined;
  }
}
