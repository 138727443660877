import {RowViewModel} from './row-view-model';
import {RowItemViewModel} from './row-item-view-model';

export class RowMetadataViewModel extends RowItemViewModel {
  constructor(
    parent: RowViewModel){
    super(parent);
  }

  public get name(): string {
    return this.row.name;
  }

  public set name(value: string) {
    this.row.name = value;
  }

  public get isSelected() {
    return this.row.configs.every(v => v.isSelected)
      && this.row.study.isSelected;
  }

  public setItemsMatching() {
    // Do nothing.
  }

  public clearItemsMatching() {
    // Do nothing.
  }
}
