import { LinePlotViewer } from './line-plot-viewer';
import { SiteHooks } from '../../site-hooks';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { LineViewerGetInterpolatedChannelValueAtDomainValue } from './data-renderer';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { IMultiPlotLayout } from '../data-pipeline/types/i-multi-plot-layout';
import { SharedState } from '../shared-state';
import { Subscription } from 'rxjs';

import { DimensionCoordinatesSlider } from './dimension-coordinates-slider';
import {
  EDIT_TYPE_ROWS,
  upgradeMultiPlotViewerLayout
} from '../multi-plot-viewer-base/multi-plot-viewer-base';
import { IEditChannelsType } from '../../navigation-station/navigation-station-viewer';
import { DataPipeline } from '../data-pipeline/data-pipeline';

export const LINE_MULTI_PLOT_VIEWER_TYPE = 'lineMultiPlotViewer';

export class DimensionLinePlotViewer extends LinePlotViewer {

  private slider?: DimensionCoordinatesSlider;
  private dimensionSubscriptions?: Subscription;

  constructor(
    private readonly dimensionIndex: number,
    private readonly xDomainNames: ReadonlyArray<string>,
    private readonly showDimensionSlider: boolean,
    primaryDomainName: string,
    layout: IMultiPlotLayout,
    channelNameStyle: ChannelNameStyle,
    dataPipeline: DataPipeline,
    sharedState: SharedState,
    siteHooks: SiteHooks,
    interpolator: LineViewerGetInterpolatedChannelValueAtDomainValue) {
    super(
      primaryDomainName,
      layout,
      channelNameStyle,
      dataPipeline,
      sharedState,
      siteHooks,
      interpolator);
  }

  public static createDimensionLinePlotViewer(
    dimensionIndex: number,
    xDomainNames: ReadonlyArray<string>,
    showDimensionSlider: boolean,
    primaryDomainName: string,
    layout: IMultiPlotLayout,
    channelNameStyle: ChannelNameStyle,
    sharedState: SharedState,
    siteHooks: SiteHooks): DimensionLinePlotViewer {

    let interpolator = new LineViewerGetInterpolatedChannelValueAtDomainValue();
    return new DimensionLinePlotViewer(
      dimensionIndex,
      xDomainNames,
      showDimensionSlider,
      primaryDomainName,
      layout,
      channelNameStyle,
      DataPipeline.create(primaryDomainName, siteHooks, sharedState.sourceLoaderSet, channelNameStyle, interpolator),
      sharedState,
      siteHooks,
      interpolator);
  }

  protected async loadFromLayout(inputLayout?: IMultiPlotLayout) {
    if (inputLayout) {
      // We only allow the dimension columns, so always overwrite.
      upgradeMultiPlotViewerLayout(inputLayout);
      inputLayout.columns = [
        {
          channels: this.xDomainNames.map(v => ({ name: v })),
          relativeSize: 1
        }
      ];
    }

    return super.loadFromLayout(inputLayout);
  }

  protected onBuilt() {
    super.onBuilt();

    if (!this.svg) {
      return;
    }

    if (this.showDimensionSlider) {
      this.slider = new DimensionCoordinatesSlider(this.sharedState, this.dimensionIndex, this.xDomainNames, this.svg);
      this.dimensionSubscriptions = this.slider.sourcesChanged.subscribe(() => this.handleSourcesChanged());
      this.slider.build();
    }
  }

  public dispose() {
    super.dispose();

    if (this.slider) {
      this.slider.dispose();
    }

    if (this.dimensionSubscriptions) {
      this.dimensionSubscriptions.unsubscribe();
    }
  }
  protected onDataSet() {
    super.onDataSet();

    if (!this.settings) {
      return;
    }

    if (this.slider) {
      this.slider.setData(this.layout as IPopulatedMultiPlotLayout, this.settings);
    }
  }

  protected onRendered() {
    super.onRendered();
    if (this.slider) {
      this.slider.render();
    }
  }

  public getEditChannelsTypes(): IEditChannelsType[] {
    return [
      {
        name: 'Edit Rows',
        icon: 'navicon',
        id: EDIT_TYPE_ROWS
      }
    ];
  }
}
