import { Injectable } from '@angular/core';
import { RowMetadataViewModel } from '../../row-metadata-view-model';
import { ICommandContext } from '../worksheet-command';
import { Dayjs } from '../../../common/dayjs.service';
import { ConfigViewModel } from '../../config-view-model';


@Injectable()
export class UpdateRowNames {

  constructor(
    private readonly dayjs: Dayjs) {
  }

  public execute(context: ICommandContext<RowMetadataViewModel | ConfigViewModel>) {
    if (context.isRowMetadata()) {
      const nowString = this.dayjs.toDateTimeUniversal(undefined);
      for (let row of context.selectedRows) {
        if (!row.name) {
          row.name = 'Staged ' + nowString;
        }
      }
    }
  }
}
