import { ChartSettings } from '../chart-settings';
import { Margin } from '../margin';

export class ParallelCoordinatesViewerSettings extends ChartSettings {
  public static build(sourceCount: number) {
    return new ParallelCoordinatesViewerSettings(sourceCount);
  }

  constructor(sourceCount: number) {
    super(sourceCount);

    this.svgPadding = new Margin(43, 10, 10, 10);
    this.chartMargin = new Margin(
      this.chartMargin.top,
      this.chartMargin.right,
      16, // This creates room for the flip icons.
      this.chartMargin.left);

    // NOTE: The chart margin is currently only used indirectly (as it changes the chart height).
  }

  public get spaceBetweenPlots(): number {
    return 0;
  }
}
