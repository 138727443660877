import {Component, Input} from '@angular/core';
import {RowViewModel} from '../row-view-model';
import {ColumnType} from '../column-type';
import {ResolvedColumn} from '../resolved-column';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[cs-worksheet-row]',
  templateUrl: './worksheet-row.component.html',
  styleUrls: ['./worksheet-row.component.scss']
})
export class WorksheetRowComponent {

  @Input() public readonly row: RowViewModel;
  @Input() public readonly rowIndex: number;
  @Input() public readonly isWorksheetInDock: boolean;

  public ColumnType = ColumnType;

  constructor() { }

  public columnTrackById(index: number, item: ResolvedColumn) {
    return item.definition.trackingId;
  }

}
