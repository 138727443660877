import { Injectable } from '@angular/core';
import { init, replayIntegration } from '@sentry/angular';
import { SENTRY_CLIENT_DSN } from '../generated/api-base-url';

@Injectable()
export class SentryService {
  public static initialize() {
    const replayIntegrationOptions = {
      maskAllText: true,
      blockAllMedia: true,
    };

    init({
      dsn: SENTRY_CLIENT_DSN,

      // This sets the sample rate to be 0.5%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.005,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      // This enables automatic instrumentation (highly recommended),
      // but is not necessary for purely manual usage
      // If you only want to use custom instrumentation:
      // * Remove the `BrowserTracing` integration
      // * add `Sentry.addTracingExtensions()` above your Sentry.init() call
      integrations: [
        replayIntegration(replayIntegrationOptions as any),
      ],
    });
  }
}
