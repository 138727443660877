import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {StudyViewModel} from '../study-view-model';
import {
  ButtonMenuItem,
  MenuItem,
  SubMenuItem
} from '../../context-menu/context-menu-types';
import {StudyType} from '../../../generated/api-stubs';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class SetStudyTypeCommand extends WorksheetCommand {

  constructor(
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<StudyViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isStudy()
      && context.worksheet.canWrite(userData.sub)
      && !context.target.isPopulated
      && !context.target.isSubmitting;
  }

  public async execute(context: CommandContext, studyType: StudyType): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    for(let row of context.selectedRows){
      row.study.unpopulated.studyType = studyType;
    }

    return CommandResult.GenerateColumnsOnly;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    let studyTypes: MenuItem<CommandResult>[] = [];
    for(let studyTypeDefinition of context.target.underlyingData.studyTypesList){
      studyTypes.push(new ButtonMenuItem<CommandResult>(
        studyTypeDefinition.name,
        `${studyTypeDefinition.studyType}-button`,
        () => this.execute(context, studyTypeDefinition.studyType)));
    }

    if(studyTypes.length){
      result.push(new SubMenuItem<CommandResult>(
        'Study Type',
        'study-type-menu',
        studyTypes,
        true));
    }
  }
}
