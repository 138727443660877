import {Component, ElementRef} from '@angular/core';

@Component({
  selector: 'cs-worksheet-context-menu-button',
  templateUrl: './worksheet-context-menu-button.component.html',
  styleUrls: ['./worksheet-context-menu-button.component.scss']
})
export class WorksheetContextMenuButtonComponent {

  constructor(private readonly elementRef: ElementRef) { }

  public onButtonClick(event: MouseEvent){
    event.preventDefault();
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent(
        'contextmenu',
        event));
  }
}
