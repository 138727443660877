import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {IGetUnits, UnitsInformation} from '../compare-config-structures/get-units';
import {ENCRYPTED_HASH_PREFIX, EXPECTED_HASH_LENGTH, LARGE_ARRAY_HASH_PREFIX} from '../../../../common/canopy-json-diff-patch.service';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[cs-compare-config-structures-value-renderer]',
  templateUrl: './compare-config-structures-value-renderer.component.html',
  styleUrls: ['./compare-config-structures-value-renderer.component.scss'],
})
export class CompareConfigStructuresValueRendererComponent implements OnInit {

  @Input() public value: any;
  @Input() public parentName: string;
  @Input() public parentPath: string;
  @Input() public getUnits: IGetUnits;

  public ValueType = ValueType;

  @HostBinding('class.value-container') public readonly valueContainer: boolean = true;

  public valueType: ValueType = ValueType.unknown;
  @HostBinding('class.complex-value-container') public isComplexValue: boolean = false;
  public unitsInformation: UnitsInformation;

  constructor() { }

  ngOnInit() {
    this.unitsInformation = this.getUnits.getUnits(this.parentName);

    let valueType = typeof this.value;
    if(valueType === 'object'){
      this.isComplexValue = true;
      if(Array.isArray(this.value)){
        this.valueType = ValueType.array;
      } else {
        this.valueType = ValueType.object;
      }
    } else if(valueType === 'string'){
      if(this.value.startsWith(LARGE_ARRAY_HASH_PREFIX)){
        this.valueType = ValueType.arrayHash;
      }else if(this.value.startsWith(ENCRYPTED_HASH_PREFIX)){
        this.valueType = ValueType.encrypted;
      }
    } else{
      this.valueType = ValueType.primitive;
    }
  }

  public get keys(): string[] {
    return Object.keys(this.value);
  }

  public getNextPath(propertyName: string){
    if(this.parentPath){
      return this.parentPath + '.' + propertyName;
    }

    return propertyName;
  }
  public get encryptedValue(): string{
    return '🔒 ' + this.value.substring(ENCRYPTED_HASH_PREFIX.length + EXPECTED_HASH_LENGTH);
  }
}

enum ValueType {
  unknown,
  object,
  array,
  arrayHash,
  encrypted,
  primitive,
}
