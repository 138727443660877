import {Component, ElementRef, HostBinding, Input} from '@angular/core';
import {ConfigViewModel} from '../config-view-model';
import {StudyInputNecessity} from '../study-view-model';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {
  WorksheetItemComponentBase
} from '../worksheet-item-component-base';
import {GetSimVersion} from '../../common/get-sim-version.service';
import {DocumentSubType, StudyDocument} from '../../../generated/api-stubs';
import {HashValidity} from '../hash-validity';
import {CellElementToViewModelLookup} from '../cell-element-to-view-model-lookup';
import {ConfigContextMenuBuilder} from '../worksheet-commands/config-context-menu-builder';
import { AuthenticationService } from '../../identity/state/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { RowItemUrlService } from '../worksheet-commands/row-item-url.service';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[cs-worksheet-config-item]',
  templateUrl: './worksheet-config-item.component.html',
  styleUrls: ['./worksheet-config-item.component.scss']
})
export class WorksheetConfigItemComponent extends WorksheetItemComponentBase {

  @Input() public viewModel: ConfigViewModel;

  public StudyInputNecessity = StudyInputNecessity;
  public DocumentSubType = DocumentSubType;
  public HashValidity = HashValidity;

  constructor(
    authenticationService: AuthenticationService,
    cellElementToViewModelLookup: CellElementToViewModelLookup,
    elementRef: ElementRef,
    contextMenuBuilder: ConfigContextMenuBuilder,
    private readonly getSimVersion: GetSimVersion,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    rowItemUrl: RowItemUrlService,
    route: ActivatedRoute) {
    super(authenticationService, cellElementToViewModelLookup, elementRef, contextMenuBuilder, getFriendlyErrorAndLog, rowItemUrl, route);
  }

  @HostBinding('class.matches-selected') public get matchesSelected(): boolean {
    return this.viewModel.matchesSelectedConfig;
  }

  @HostBinding('class.is-selected') public get isSelected(): boolean {
    return this.viewModel.isSelected;
  }

  public get referenceCountString(): string {
    let referenceMetadata = this.viewModel.referenceMetadata;
    if(!referenceMetadata){
      return '';
    }

    if(this.viewModel.configType === DocumentSubType.telemetry){
      return this.getDualReferenceCountString(referenceMetadata.configCount, referenceMetadata.studyCount);
    }

    return this.getReferenceCountString(referenceMetadata.configCount);
  }

  public get currentSimVersion(): string {
    return this.getSimVersion.currentSimVersion;
  }

  public get studyInputNecessity(): StudyInputNecessity {
    return this.viewModel.studyInputNecessity;
  }

  public get isConfigReadOnly(): boolean {
    return this.viewModel.isReadOnly(this.userData.sub);
  }

  public get hasJobIndex(): boolean {
    if(!this.viewModel.isTelemetry){
      return false;
    }

    const reference = this.viewModel.reference;
    return !!(reference && reference.tenant && reference.tenant.jobIndex);
  }

  public get jobIndex(): number {
    if(this.hasJobIndex) {
      return this.viewModel.reference.tenant.jobIndex;
    }

    return 0;
  }

  public get study(): StudyDocument{
    return this.viewModel.populated.populatedStudy.resolvedReference?.data?.studyDocument;
  }
}
