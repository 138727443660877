import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SplitIntegersEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitIntegers = JSONEditor.defaults.editors.splitNumbers.extend({
      getValue() {
        return this._super().map(function(v: number) {
 return Math.trunc(v); 
});
      }
    });
  }
}
