import { Component } from '@angular/core';
import {
  ConfigType,
  WorksheetConfigType
} from '../../simulations/configs/config-types';
import {ListFilterData, WorksheetStub} from '../../../generated/api-stubs';
import {FilterUtilities} from '../../simulations/list-filtering/filter-utilities';
import {InferableRouteParams} from '../../common/inferable-route-params.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigSummary} from '../../simulations/configs/view-configs/view-configs.component';
import {PromptDialog} from '../../common/dialogs/prompt-dialog.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';
import { AuthenticationService, UserData } from '../../identity/state/authentication.service';

@Component({
  templateUrl: './view-worksheets.page.html',
  styleUrls: ['./view-worksheets.page.scss']
})
export class ViewWorksheetsPage {
  public configType: ConfigType = WorksheetConfigType;
  public tenantId: string;
  public userId: string;

  public userData: UserData;
  public initialFilter: ListFilterData;
  public errorMessage: string;

  constructor(
    filterUtilities: FilterUtilities,
    inferableRouteParams: InferableRouteParams,
    route: ActivatedRoute,
    private readonly promptDialog: PromptDialog,
    private readonly loadingDialog: LoadingDialog,
    private readonly worksheetStub: WorksheetStub,
    private readonly getFriendlyErrorMessageAndLog: GetFriendlyErrorAndLog,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService) {

    this.tenantId = inferableRouteParams.getTenantId(route);
    this.userId = inferableRouteParams.getUserId(route);

    this.initialFilter = filterUtilities.getInitialFilter();

    this.userData = this.authenticationService.userDataSnapshot;
  }

  public filterChanged(newFilter: ListFilterData){
  }

  public navigationUrlTree(config: ConfigSummary) {
    return ['/worksheets', config.tenantId, config.documentId];
  }

  public async createWorksheet() {
    try {
      const name = await this.promptDialog.show<string>('Enter a name for the new worksheet', 'Worksheet Name');
      if(!name){
        return;
      }

      await this.loadingDialog.show(async (d) => {
        d.setStatus('Creating...');
        const createResult = await this.worksheetStub.postWorksheet(
          this.tenantId,
          {
            name
          });
        await this.router.navigate(['/worksheets', createResult.worksheet.tenantId, createResult.worksheet.worksheetId]);
      });
    } catch(error){
      this.errorMessage = this.getFriendlyErrorMessageAndLog.execute(error);
    }
  }
}
