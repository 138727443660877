import { Units } from '../../../../visualizations/units';
import { Injectable } from '@angular/core';
import { humanizeNumber } from '../humanize-number';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SplitNumbers2DEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitNumbers2D = JSONEditor.defaults.editors.splitStrings2D.extend({
      postBuild() {
        this._super();
        this.initializeUnitChangedSubscription();
      },
      getValue() {
        if (this.initialValue && this.value === this.initialValue.display) {
          return this.initialValue.raw;
        }

        let defaultUnits = this.getDefaultUnits();
        return this._super().map((line: string[]) => Units.convertValuesBetweenUnits(line.map(str => +str), this.valueUnits, defaultUnits));
      },
      setValue(value: any, initial: any, from_template: boolean) {
        let defaultUnits = this.getDefaultUnits();
        this.valueUnits = this.getUnits();
        let isConversionRequired = Units.isConversionRequired(defaultUnits, this.valueUnits);

        value = this.sanitizeArray2D(value);
        this.replaceNullWithNaN2D(value);

        let displayValues = value.map((line: any[]) => line.slice());

        if (isConversionRequired) {
          for (let i = 0; i < displayValues.length; ++i) {
            let line = displayValues[i];
            displayValues[i] = Units.convertValuesBetweenUnits(line.map((v: string) => +v), defaultUnits, this.valueUnits)
              .map(humanizeNumber);
          }
        }

        this._super(displayValues, initial, from_template);

        let displayValue = this.input.value;

        this.initialValue = {
          raw: value,
          display: displayValue
        };
      }
    });
  }
}
