import { Injectable } from '@angular/core';
import { splitStringContainingQuotes } from '../split-string-containing-quotes';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SplitStrings2DEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitStrings2D = JSONEditor.defaults.editors.string.extend({
      build() {
        let self = this;
        this.title = this.getTitle();
        if (!this.options.compact) {
          this.header = this.label = this.theme.getFormInputLabel(this.title);
        }
        if (this.schema.description) {
          this.description = this.theme.getFormInputDescription(this.schema.description);
        }

        this.input_type = 'textarea';
        this.input = this.theme.getTextareaInput();

        if (this.options.compact) {
          (<any>this).container.className += ' compact';
        } else {
          if (this.options.input_width) {
            this.input.style.width = this.options.input_width;
          }
        }

        if (this.schema.readOnly || this.schema.readonly || this.schema.template) {
          this.always_disabled = true;
          this.input.disabled = true;
        }

        this.input
          .addEventListener('change', function(this: typeof JSONEditor.defaults.editors.splitStrings2D, e: Event) {
            e.preventDefault();
            e.stopPropagation();

            // Don't allow changing if this field is a template
            if (self.schema.template) {
              this.value = self.value;
              return;
            }

            let val = this.value;

            // sanitize value
            let sanitized = self.sanitize(val);
            if (val !== sanitized) {
              this.value = sanitized;
            }

            self.is_dirty = true;

            self.refreshValue();
            self.onChange(true);
          });

        this.control = this.theme.getFormControl(this.label, this.input, this.description, this.schema, this.title, this);
        (<any>this).container.appendChild(this.control);

        // Any special formatting that needs to happen after the input is added to the dom
        window.requestAnimationFrame(function() {
          // Skip in case the input is only a temporary editor,
          // otherwise, in the case of an ace_editor creation,
          // it will generate an error trying to append it to the missing parentNode
          if (self.input.parentNode) {
            self.afterInputReady();
          }
        });

        // Compile and store the template
        if (this.schema.template) {
          this.template = (<any>this).jsoneditor.compileTemplate(this.schema.template, (<any>this).template_engine);
          (<any>this).refreshValue();
        } else {
          (<any>this).refreshValue();
        }
      },
      getValue() {
        let split_by_outer = /\s*?\n\s*/;
        // let split_by_inner=/[\s,]+/;
        let lines = this.value ? this.value.trim().split(split_by_outer) : [];
        let result = [];
        for (let line of lines) {
          let splitLine = line ? splitStringContainingQuotes(line.trim()) : [];
          result.push(splitLine);
        }
        return result;
      },
      setValue(value: any, initial: any, from_template: boolean) {
        if (!value) {
          return (<any>this)._super('', initial, from_template);
        }

        if (value && !Array.isArray(value)) {
          if (typeof value === 'object') {
            value = [];
          } else {
            value = [[value]];
          }
        }

        let join_with_outer = '\n';
        let join_with_inner = ', ';
        let lines = [];
        for (let set of value) {
          lines.push(set.join(join_with_inner));
        }
        let result = lines.join(join_with_outer);

        return (<any>this)._super(result, initial, from_template);
      }
    });
  }
}
