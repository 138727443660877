import {RowViewModel} from '../row-view-model';
import {ReplaceResult} from '../worksheet-item-component-base';
import {Injectable} from '@angular/core';
import {ConfirmationDialog} from '../../common/dialogs/confirmation-dialog.service';
import {RowItemViewModel} from '../row-item-view-model';
import {ConfigViewModel} from '../config-view-model';

export const replaceThisReferenceMessage = 'Replace this reference';
export const getReplaceAllReferencesMessage = (count: number) => 'Replace ' + count + ' references';

@Injectable()
export class ConfirmReplaceAllReferences {

  constructor(
    private readonly confirmationDialog: ConfirmationDialog) {
  }

  // When a config has multiple cells referencing it, and only one is selected,
  // this method confirms if all references should be replaced or just the selected one.
  public async execute(target: RowItemViewModel, rows: ReadonlyArray<RowViewModel>): Promise<ReplaceResult> {
    if(!this.isConfig(target)) {
      return ReplaceResult.replaceThisReference;
    }

    if(rows.length !== 1){
      return ReplaceResult.replaceThisReference;
    }

    let referenceMetadata = target.referenceMetadata;
    if(!referenceMetadata || referenceMetadata.configCount < 2){
      return ReplaceResult.replaceThisReference;
    }

    const replaceAllReferences = getReplaceAllReferencesMessage(referenceMetadata.configCount);
    let response = await this.confirmationDialog.show(
      'Would you like to replace all ' + referenceMetadata.configCount + ' references?',
      'Replace Reference',
      [replaceAllReferences, replaceThisReferenceMessage],
      'Cancel');

    if(!response){
      return ReplaceResult.cancel;
    }

    return response === replaceAllReferences
      ? ReplaceResult.replaceAllReferences
      : ReplaceResult.replaceThisReference;
  }

  public isConfig(target: RowItemViewModel): target is ConfigViewModel {
    return target instanceof ConfigViewModel;
  }
}
