import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {ListFilterGroup} from '../../../generated/api-stubs';

@Injectable()
export class StudyLoaderDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async loadStudy(
    tenantId: string,
    userId: string,
    initialQuery?: ListFilterGroup): Promise<StudyLoaderResult> {

    let result = await this.dialogManager.showSecondaryDialog(
      new StudyLoaderDialogData(
        tenantId,
        userId,
        initialQuery));

    return result;
  }
}

export class StudyLoaderDialogData extends DialogBase<StudyLoaderResult> implements IDialog {
  constructor(
    public readonly tenantId: string,
    public readonly userId: string,
    public readonly initialQuery: ListFilterGroup) {
    super();
  }
}

export interface StudyLoaderResult {
  userId: string;
  studyId: string;
  name: string;
}
