import {Injectable} from '@angular/core';
import {IWorksheetContextMenuBuilder} from '../worksheet-context-menu-builder';
import {MenuItem} from '../../context-menu/context-menu-types';
import {RowItemViewModel} from '../row-item-view-model';
import {CommandContext, CommandResult, WorksheetContext} from './worksheet-command';

@Injectable()
export class SimulationContextMenuBuilder implements IWorksheetContextMenuBuilder {
  constructor(){
  }

  generateContextMenu(target: RowItemViewModel, worksheetContext: WorksheetContext): ReadonlyArray<MenuItem<CommandResult>> {
    let result: MenuItem<CommandResult>[] = [];

    const context = new CommandContext(target, worksheetContext);
    if(!context.isSimulation()){
      return result;
    }

    return result;
  }
}
