import {ConfigViewModel} from './config-view-model';
import {ConfigReference, DocumentSubType, WorksheetConfig} from '../../generated/api-stubs';
import {WorksheetUnderlyingData} from './worksheet-underlying-data';
import {RowViewModel} from './row-view-model';

// Note: We don't concern ourselves with removing config view models from this set,
// as if the config isn't displayed anymore it simply won't be shown, and we may re-use it later.
export class ConfigSet {
  private readonly _list: ConfigViewModel[] = [];
  private readonly _map: ConfigMap = {};

  public get list(): ReadonlyArray<ConfigViewModel> {
    return this._list;
  }

  public get(configType: DocumentSubType): ConfigViewModel | undefined {
    return this._map[configType];
  }

  public set(prototype: IConfigPrototype): ConfigViewModel {
    let existing = this._map[prototype.configType];
    if(existing) {
      existing.setConfig(prototype.reference);
      return existing;
    }

    let config = prototype.createViewModel();
    this._list.push(config);
    this._map[prototype.configType] = config;
    return config;
  }

  public replace(configPrototypes: ReadonlyArray<IConfigPrototype>) {
    let updatedConfigTypes = new Set<DocumentSubType>();
    for(let prototype of configPrototypes){
      updatedConfigTypes.add(prototype.configType);
      let existing = this._map[prototype.configType];
      if(existing){
        existing.setConfig(prototype.reference);
      } else{
        existing = prototype.createViewModel();
        this._list.push(existing);
        this._map[prototype.configType] = existing;
      }
    }

    for(let config of this._list){
      if(!updatedConfigTypes.has(config.configType)){
        config.setConfig(undefined);
      }
    }
  }
}

type ConfigMap  = { [configType in DocumentSubType]?: ConfigViewModel | undefined };

export class ConfigPrototype implements IConfigPrototype {
  constructor(
    private readonly config: WorksheetConfig,
    private readonly row: RowViewModel,
    private readonly underlyingData: WorksheetUnderlyingData){
  }

  public get configType(): DocumentSubType {
    return this.config.configType;
  }

  public get reference(): ConfigReference {
    return this.config.reference;
  }

  public get createViewModel(): () => ConfigViewModel {
    return () => new ConfigViewModel(this.row, this.configType, this.config, this.underlyingData);
  }
}

export class AbstractConfigPrototype implements IConfigPrototype {
  constructor(
    public readonly configType: DocumentSubType,
    public readonly reference: ConfigReference,
    public readonly createViewModel: () => ConfigViewModel){
  }
}

export class UnpopulatedConfigPrototype extends AbstractConfigPrototype {
  constructor(
    configType: DocumentSubType,
    row: RowViewModel,
    underlyingData: WorksheetUnderlyingData){
    super(configType, undefined, () => new ConfigViewModel(row, configType, undefined, underlyingData));
  }
}

export class IConfigPrototype {
  constructor(
    public readonly configType: DocumentSubType,
    public readonly reference: ConfigReference,
    public readonly createViewModel: () => ConfigViewModel){
  }
}
