import {ClipboardContent} from './worksheet-clipboard.service';
import {CanopyJson} from '../common/canopy-json.service';
import {ConfigReference, DefaultConfigId, DocumentSubType, WorksheetConfig, WorksheetStub} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {GetSimVersion} from '../common/get-sim-version.service';

@Injectable()
export class DuplicateClipboardContentIfRequired {

  constructor(
    private readonly getSimVersion: GetSimVersion,
    private readonly json: CanopyJson,
    private readonly worksheetStub: WorksheetStub) {
  }

  public async execute(targetTenantId: string, targetWorksheetId: string, content: ClipboardContent, forceDuplication: boolean): Promise<ClipboardContent> {

    if(!content || !content.rows || !content.rows.length){
      return content;
    }

    // console.log('Force Duplication:' + forceDuplication);
    if(forceDuplication === false){
      if(content.sourceWorksheetId === targetWorksheetId && content.sourceTenantId === targetTenantId) {
        return content;
      }
    }

    const sourceConfigIdSet = new Set<string>();
    this.forEachTenantConfig(content, config => {
      sourceConfigIdSet.add(config.reference.tenant.targetId);
    });

    const sourceDefaultConfigIdSet = new Map<string, DefaultConfigId>();
    if(forceDuplication){
      this.forEachDefaultConfig(content, config => {
        const defaultConfigId: DefaultConfigId = {
          configType: config.configType,
          name: config.reference.default.name,
        };
        sourceDefaultConfigIdSet.set(
          this.defaultConfigIdToString(defaultConfigId),
          defaultConfigId);
      });
    }

    if(sourceConfigIdSet.size === 0 && sourceDefaultConfigIdSet.size === 0) {
      return content;
    }

    const sourceConfigIds: string[] = Array.from(sourceConfigIdSet);
    const sourceDefaultConfigIds: DefaultConfigId[] = Array.from(sourceDefaultConfigIdSet.values());
    const duplicationResult = await this.worksheetStub.postDuplicateConfigs(
      targetTenantId,
      targetWorksheetId,
      {
        sourceTenantId: content.sourceTenantId,
        sourceWorksheetId: content.sourceWorksheetId,
        sourceConfigIds,
        sourceDefaultConfigIds,
      },
      this.getSimVersion.currentSimVersion);

    if(duplicationResult.targetConfigIds.length !== sourceConfigIds.length){
      throw new Error('Unexpected number of target config IDs returned from duplication request.');
    }

    const map: {[input: string]: string} = {};
    for(let i=0; i < sourceConfigIds.length; ++i){
      map[sourceConfigIds[i]] = duplicationResult.targetConfigIds[i];
    }

    content = this.json.clone(content);
    this.forEachTenantConfig(content, config => {
      config.reference.tenant.tenantId = targetTenantId;
      config.reference.tenant.targetId = map[config.reference.tenant.targetId];
    });

    if(forceDuplication) {
      const defaultMap: {[input: string]: string} = {};
      for(let i=0; i < sourceDefaultConfigIds.length; ++i){
        defaultMap[this.defaultConfigIdToString(sourceDefaultConfigIds[i])] = duplicationResult.targetDefaultConfigIds[i];
      }

      this.forEachDefaultConfig(content, config => {
        const defaultConfigId: DefaultConfigId = {
          configType: config.configType,
          name: config.reference.default.name,
        };
        delete config.reference.default;
        config.reference.tenant = {
          tenantId: targetTenantId,
          targetId: defaultMap[this.defaultConfigIdToString(defaultConfigId)],
        };
      });
    }

    return content;
  }

  private defaultConfigIdToString(id: DefaultConfigId): string {
    return id.configType + '/' + id.name;
  }

  private forEachTenantConfig(content: ClipboardContent, action: (config: WorksheetConfig) => void){
    this.forEachConfig(content, r => !!r.tenant, action);
  }

  private forEachDefaultConfig(content: ClipboardContent, action: (config: WorksheetConfig) => void){
    this.forEachConfig(content, r => !!r.default, action);
  }

  private forEachConfig(content: ClipboardContent, check: (reference: ConfigReference) => boolean, action: (config: WorksheetConfig) => void){
    for(let row of content.rows){
      if(!row.configs){
        continue;
      }

      for(let config of row.configs){
        if(config.reference && config.configType !== DocumentSubType.telemetry && check(config.reference)){
          action(config);
        }
      }
    }
  }
}
