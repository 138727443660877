import {DocumentCustomPropertyData, WorksheetOutline} from '../../generated/api-stubs';

export class WorksheetUpdateRequest {
  constructor(
    public readonly generateColumns: boolean,
    public readonly name: string,
    public readonly outline: WorksheetOutline,
    public readonly properties: DocumentCustomPropertyData[] | undefined,
    public readonly notes: string | undefined) {

  }
}
