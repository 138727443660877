import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {ConfigViewModel} from '../config-view-model';
import {ReplaceResult} from '../worksheet-item-component-base';
import {SetConfigOrAllReferences} from './set-config-or-all-references.service';
import {ConfirmReplaceAllReferences} from './confirm-replace-all-references.service';
import {GoToJob} from '../../simulations/jobs/go-to-job.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class SetTelemetryJobIndexCommand extends WorksheetCommand {

  constructor(
    private readonly setConfigOrAllReferences: SetConfigOrAllReferences,
    private readonly confirmReplaceAllReferences: ConfirmReplaceAllReferences,
    private readonly goToJob: GoToJob,
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isConfig()
      && context.worksheet.canWrite(userData.sub)
      && context.isSingleRow
      && context.target.isResolved
      && context.target.isTelemetry;
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    const rows = context.selectedRows;
    const replaceResult = await this.confirmReplaceAllReferences.execute(context.target, rows);
    if(replaceResult === ReplaceResult.cancel){
      return CommandResult.NoUpdate;
    }

    const jobIndex = await this.goToJob.loadAndPromptForJobIndex(
      context.target.reference.tenant.tenantId,
      context.target.reference.tenant.targetId);

    if(jobIndex === -1) {
      return CommandResult.NoUpdate;
    }

    await this.setConfigOrAllReferences.execute(
      context.target,
      rows,
      {
        tenant: {
          ...context.target.reference.tenant,
          jobIndex,
        }
      },
      replaceResult);

    return CommandResult.UpdateOnly;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Set Job Index`,
      `set-telemetry-job-index-button`,
      () => this.execute(context),
      'list-ol'));
  }
}
