import {
  ConfigStub,
  DocumentSubType,
  GetStudyQueryResult,
  NewStudyDataSource,
  PostStudyResult,
  StudyReference,
  StudyStub,
  WorksheetConfig,
  WorksheetRow
} from '../../generated/api-stubs';
import {ClipboardContent} from './worksheet-clipboard.service';
import {GetSimVersion} from '../common/get-sim-version.service';
import {Injectable} from '@angular/core';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {RecreateTelemetryStudyFromInputData} from './recreate-telemetry-study-from-input-data';
import {LoadStudy} from './load-study';
import { AuthenticationService, UserData } from '../identity/state/authentication.service';

@Injectable()
export class CreateWorksheetRowFromStudy {

  constructor(
    private readonly loadingDialog: LoadingDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly studyStub: StudyStub,
    private readonly configStub: ConfigStub,
    private readonly getSimVersion: GetSimVersion,
    private readonly loadStudyService: LoadStudy,
    private readonly recreateTelemetryStudyFromInputData: RecreateTelemetryStudyFromInputData){
  }

  public async execute(
    tenantId: string,
    studyId: string,
    targetWorksheetId: string,
    options: CreateWorksheetRowFromStudyOptions): Promise<WorksheetContent> {

    if(!options){
      options = CreateWorksheetRowFromStudyOptions.default();
    }

    const rows: WorksheetRow[] = [];

    const userData = this.authenticationService.userDataSnapshot;
    const simVersion = this.getSimVersion.currentSimVersion;

    const studyIndex = 0;
    const studyResult = await this.loadStudy(tenantId, studyId, simVersion, studyIndex);
    await this.appendRowsFromStudy(studyResult, targetWorksheetId, options, simVersion, userData, rows, studyIndex);

    return new WorksheetContent(
      new ClipboardContent(
        userData.tenant,
        targetWorksheetId,
        rows
      ));
  }

  private async loadStudyIfNotForbidden(
    tenantId: string,
    studyId: string,
    simVersion: string,
    studyIndex: number): Promise<GetStudyQueryResult> {
    return await this.loadingDialog.showUntilFinished(
      this.loadStudyService.tryLoadIfNotForbidden(tenantId, studyId, simVersion),
      `Loading ${this.getProgressStudyName(studyIndex)}...`);
  }

  private async loadStudy(
    tenantId: string,
    studyId: string,
    simVersion: string,
    studyIndex: number): Promise<GetStudyQueryResult> {
    return await this.loadingDialog.showUntilFinished(
      this.loadStudyService.load(tenantId, studyId, simVersion),
      `Loading ${this.getProgressStudyName(studyIndex)}...`);
  }

  private async appendRowsFromStudy(
    studyResult: GetStudyQueryResult,
    targetWorksheetId: string,
    options: CreateWorksheetRowFromStudyOptions,
    simVersion: string,
    userData: UserData,
    rows: WorksheetRow[],
    studyIndex: number): Promise<StudyReference> {

    const study = studyResult.study;
    const tenantId = study.tenantId;
    const studyId = study.documentId;

    const simConfig = { ... study.data.definition.simConfig };
    const exploration = study.data.definition.exploration;
    if (exploration) {
      // We'll stick this here so it's extracted automatically in the loop below.
      simConfig[DocumentSubType.exploration] = exploration;
    }

    let studyData = {
      simTypes: studyResult.simTypes,
      simConfig: <any>{},
      exploration: undefined as any
    };
    let studyDataSources: NewStudyDataSource[] = [];

    const worksheetConfigs: WorksheetConfig[] = [];
    for (let key of Object.keys(simConfig)) {
      const configType = key as DocumentSubType;
      let configName = study.name;
      let configData = simConfig[key];
      const configSource = study.data.sources.find(v => v.configType === configType);
      if (configSource && configSource.name) {
        configName = configSource.name;
      }

      const configResult = await this.processConfig(
        studyResult,
        options,
        userData,
        configName,
        configType,
        configData,
        simVersion,
        tenantId,
        targetWorksheetId,
        rows,
        studyIndex);

      const configId = configResult.configId;

      if(configResult.worksheetConfig){
        worksheetConfigs.push(configResult.worksheetConfig);
      }

      if (configType === DocumentSubType.exploration) {
        studyData.exploration = configResult.studyConfig;
      } else {
        studyData.simConfig[key] = configResult.studyConfig;
      }

      studyDataSources.push({
        configType,
        userId: configId ? userData.sub : undefined,
        configId,
        name: configName,
        isEdited: false,
      });
    }

    let studyReference: StudyReference = {
      tenantId,
      targetId: studyId,
    };

    if (options.alwaysCreateNewStudies || tenantId !== userData.tenant) {
      const newStudyId = <PostStudyResult>await this.loadingDialog.showUntilFinished(
        this.studyStub.postStudy(
          userData.tenant,
          {
            name: study.name,
            studyType: study.data.studyType,
            simVersion,
            sources: studyDataSources,
            study: studyData,
            isTransient: false,
          }),
        `Creating ${this.getProgressStudyName(studyIndex)}...`);

      studyReference = {
        tenantId: userData.tenant,
        targetId: newStudyId.studyId,
      };
    }

    const row: WorksheetRow = {
      name: undefined,
      configs: worksheetConfigs,
      study: {
        reference: studyReference
      }
    };

    rows.push(row);
    return studyReference;
  }

  private processConfig(
    studyResult: GetStudyQueryResult,
    options: CreateWorksheetRowFromStudyOptions,
    userData: UserData,
    configName: string,
    configType: DocumentSubType,
    configData: any,
    simVersion: string,
    tenantId: string,
    targetWorksheetId: string,
    rows: WorksheetRow[],
    studyIndex: number): Promise<StudyConfigAndWorksheetConfig> {

    if (configType === DocumentSubType.telemetry) {
      return this.processTelemetryConfig(
        studyResult,
        options,
        userData,
        configName,
        configType,
        configData,
        simVersion,
        tenantId,
        targetWorksheetId,
        rows,
        studyIndex);
    }

    return this.processNonTelemetryConfig(
      userData,
      configName,
      configType,
      configData,
      simVersion,
      targetWorksheetId,
      studyIndex);
  }

  private async processTelemetryConfig(
    studyResult: GetStudyQueryResult,
    options: CreateWorksheetRowFromStudyOptions,
    userData: UserData,
    configName: string,
    configType: DocumentSubType,
    configData: any,
    simVersion: string,
    tenantId: string,
    targetWorksheetId: string,
    rows: WorksheetRow[],
    studyIndex: number): Promise<StudyConfigAndWorksheetConfig> {

    if(!configData.source){
      // If we have telemetry configs with data then we need to add it as a study input
      // but not save the config or put a config in the worksheet as you can't save telemetry data
      // on the platform.
      return new StudyConfigAndWorksheetConfig(undefined, configData, undefined);
    }

    // If we have telemetry configs with a source then we need to append the source study.
    const originalTelemetryTenantId = configData.source.tenantId || tenantId;
    const originalTelemetryStudyId = configData.source.studyId;
    const originalTelemetryJobIndex = configData.source.jobIndex || 0;

    let telemetryTenantId = originalTelemetryTenantId;
    let telemetryStudyId = originalTelemetryStudyId;
    let telemetryJobIndex = originalTelemetryJobIndex;

    let shouldCreateNewStudy =
      originalTelemetryTenantId !== userData.tenant
      || options.alwaysCreateNewStudies;

    let shouldRecreateFromData = shouldCreateNewStudy && options.alwaysRecreateTelemetryStudiesFromData;

    let telemetryStudyResult: GetStudyQueryResult | undefined;
    if(shouldCreateNewStudy && !shouldRecreateFromData){
      telemetryStudyResult = await this.loadStudyIfNotForbidden(
        originalTelemetryTenantId, originalTelemetryStudyId, simVersion, studyIndex + 1);

      if(!telemetryStudyResult){
        shouldRecreateFromData = true;
      }
    }

    let telemetryStudyReference: StudyReference;
    if(shouldRecreateFromData){
      telemetryStudyReference = await this.recreateTelemetryStudyFromInputData.execute(
        configName,
        studyResult,
        telemetryJobIndex);

      telemetryTenantId = telemetryStudyReference.tenantId;
      telemetryStudyId = telemetryStudyReference.targetId;
      telemetryJobIndex = 0;

      await this.loadingDialog.showUntilFinished(
        this.loadStudyService.loadCompleted(
          telemetryStudyReference.tenantId,
          telemetryStudyReference.targetId,
          simVersion),
        'Waiting for telemetry study to complete...');
    } else if (shouldCreateNewStudy) {
      // Only add a new row for the telemetry study if we're re-running it in its original form.
      // This is because even if the original was telemetry (rather than another simulation) the
      // user will need to be able to see when it completes and re-run the dependent study.
      telemetryStudyReference = await this.appendRowsFromStudy(
        telemetryStudyResult,
        targetWorksheetId,
        options,
        simVersion,
        userData,
        rows,
        studyIndex + 1);

      telemetryTenantId = telemetryStudyReference.tenantId;
      telemetryStudyId = telemetryStudyReference.targetId;
    }

    configData = {
      source: {
        tenantId: telemetryTenantId,
        studyId: telemetryStudyId,
        jobIndex: telemetryJobIndex,
      }
    };

    const worksheetConfig = {
      configType,
      inheritReference: false,
      reference: {
        tenant: {
          tenantId: telemetryTenantId,
          targetId: telemetryStudyId,
          jobIndex: telemetryJobIndex,
        }
      }
    };

    return new StudyConfigAndWorksheetConfig(undefined, configData, worksheetConfig);
  }

  private async processNonTelemetryConfig(
    userData: UserData,
    configName: string,
    configType: DocumentSubType,
    configData: any,
    simVersion: string,
    targetWorksheetId: string,
    studyIndex: number): Promise<StudyConfigAndWorksheetConfig> {

    const configId = await this.loadingDialog.showUntilFinished(
      this.configStub.postConfig(
        userData.tenant,
        {
          name: configName,
          configType,
          config: configData,
          simVersion,
          parentWorksheetId: targetWorksheetId,
        }),
      `Creating ${configType} from ${this.getProgressStudyName(studyIndex)}...`);

    const worksheetConfig = {
      configType,
      inheritReference: false,
      reference: {
        tenant: {
          tenantId: userData.tenant,
          targetId: configId,
        }
      }
    };

    return new StudyConfigAndWorksheetConfig(configId, configData, worksheetConfig);
  }

  private getProgressStudyName(studyIndex: number){
    return 'study' + (studyIndex ? ' ' + (studyIndex + 1) : '');
  }
}

class StudyConfigAndWorksheetConfig {
  constructor(
    public readonly configId: string,
    public readonly studyConfig: any,
    public readonly worksheetConfig: WorksheetConfig){
  }
}

export class WorksheetContent {
  constructor(
    public readonly clipboardContent: ClipboardContent){
  }
}

export class CreateWorksheetRowFromStudyOptions {
  constructor(
    public readonly alwaysCreateNewStudies: boolean,
    public readonly alwaysRecreateTelemetryStudiesFromData: boolean){
  }

  public static default(): CreateWorksheetRowFromStudyOptions {
    return new CreateWorksheetRowFromStudyOptions(
      false,
      true);
  }

  public static fromEvent(event: MouseEvent): CreateWorksheetRowFromStudyOptions {
    const defaultValues = CreateWorksheetRowFromStudyOptions.default();
    return new CreateWorksheetRowFromStudyOptions(
      CreateWorksheetRowFromStudyOptions.invertIf(defaultValues.alwaysCreateNewStudies, !!(event.ctrlKey || event.metaKey)),
      CreateWorksheetRowFromStudyOptions.invertIf(defaultValues.alwaysRecreateTelemetryStudiesFromData, !!(event.altKey)));
  }

  private static invertIf(defaultValue: boolean, shouldInvert: boolean){
    return shouldInvert ? !defaultValue : defaultValue;
  }
}
