import { Injectable } from '@angular/core';
import { AllButtonCategories } from '../json-editor-node-extensions/editor-node-extensions';
import { EditorNodeExtensionsFactory } from '../json-editor-node-extensions/editor-node-extensions-factory';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class ObjectEditor extends JsonEditorCustomization {

  constructor(
    private readonly editorNodeExtensionsFactory: EditorNodeExtensionsFactory) {
    super();
  }

  public apply(): void {
    const service = this;

    JSONEditor.defaults.editors.object = JSONEditor.defaults.editors.object.extend({
      addPropertyCheckbox(key: string) {
        let checkbox = (<any>this)._super(key);
        checkbox.id = this.path.replace(/\./g, '-') + '-' + key + '-show';
        return checkbox;
      },
      refreshAddProperties() {
        let self = <any>this;
        self._super();
        let hide = false;

        if (!self.schema.additionalProperties) {
          hide = true;
          for (let i in self.addproperty_checkboxes) {
            if (!self.addproperty_checkboxes.hasOwnProperty(i)) {
 continue; 
}
            if (!self.addproperty_checkboxes[i].disabled) {
              hide = false;
              break;
            }
          }
        }

        if (hide) {
          self.addproperty_controls.style.display = 'none';
        }
      },
      showAddProperty() {
        let self = <any>this;
        self._super();

        self.clickFunction = function() {
          self.hideAddProperty();
        };
        window.document.body.addEventListener('click', self.clickFunction);
      },
      hideAddProperty() {
        let self = <any>this;
        self._super();

        window.document.body.removeEventListener('click', self.clickFunction);
      },
      preBuild() {
        // We add explicit property ordering otherwise
        // some of the properties get jumbled up.
        let self = <any>this;
        if (this.schema.properties) {
          let propertyNumber = 1;
          for (let key of Object.keys(this.schema.properties)) {
            let schema = this.schema.properties[key];
            if (!schema.propertyOrder) {
              schema.propertyOrder = propertyNumber;
            }

            ++propertyNumber;
          }
        }

        self._super();
      },
      init(options: any) {
        this._super(options);

        // This is so that options in $ref schemas are used.
        // Note, there are some places this is used in AbstractEditor.init which we
        // miss by doing this after calling super(), however to do it before we would have
        // to expand the schema ourselves, before super() would then expand it again. Or we
        // would have to replace the entire AbstractEditor.init.
        this.options = {
          ...this.options,
          ...(this.schema.options || {})
        };
      },
      build() {
        let self = <any>this;
        self._super();
        const canopyOptions = getCanopyJsonEditorOptions(this);

        this.theme.addHelpLink(this.container, this.schema, this);

        if (this.addproperty_holder) {
          // This prevents the property dialog being hidden when a checkbox is
          // clicked due to the event propagating to the body element.
          this.addproperty_holder.addEventListener('click', function(e: Event) {
            e.stopPropagation();
          });
        }

        if (this.title) {
          let subTreeControls = this.theme.getHeaderButtonHolder();
          subTreeControls.className += ' hover-button-group';
          this.title.appendChild(subTreeControls);

          this.extensions = service.editorNodeExtensionsFactory.create(canopyOptions.simVersion, canopyOptions.configType, this, canopyOptions.configSubTreeRepository);
          this.extensions.addButtons(subTreeControls, AllButtonCategories);
        }
      },
      setValue(...args: any[]) {
        let self = <any>this;
        self._super(...args);
        if (this.extensions) {
          this.extensions.update();
        }
      }
    });
  }
}
