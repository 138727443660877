import { CoordinateTriplet } from '../3d/coordinate-triplet';

export class RawCarData {
  constructor(private readonly car: any) {
  }

  public get frontSuspension(): RawCarSuspension {
    return new RawCarSuspension(this.car && this.car.suspension && this.car.suspension.front);
  }

  public get rearSuspension(): RawCarSuspension {
    return new RawCarSuspension(this.car && this.car.suspension && this.car.suspension.rear);
  }

  public get chassis(): RawSuspensionCoordinatesMap {
    return (this.car && this.car.chassis) || {};
  }
}

export class RawCarSuspension {
  constructor(private readonly suspension: any) {
    if (!this.suspension) {
      this.suspension = {};
    }
  }

  public get suspensionOffset(): CoordinateTriplet {
    return this.suspension.rSuspensionDatumOffset || [0, 0, 0];
  }

  public get externalPickUpPoints(): RawSuspensionCoordinatesMap {
    return (this.suspension.external && this.suspension.external.pickUpPts) || {};
  }

  public get externalPickUpPointsL(): RawSuspensionCoordinatesMap {
    return (this.suspension.external && this.suspension.external.pickUpPtsL) || {};
  }

  public get internalPickUpPoints(): RawSuspensionCoordinatesMap {
    return (this.suspension.internal && this.suspension.internal.pickUpPts) || {};
  }

  public get internalPickUpPointsL(): RawSuspensionCoordinatesMap {
    return (this.suspension.internal && this.suspension.internal.pickUpPtsL) || {};
  }


  public get wheel(): RawWheelData {
    return new RawWheelData(this.suspension.rWheelDesign, this.suspension.rWheelSetup);
  }

}

export interface RawSuspensionCoordinatesMap {
  readonly [name: string]: CoordinateTriplet;
}

export class RawWheelData {
  constructor(
    public readonly rWheelDesign: number,
    public readonly rWheelSetup: number) {
  }
}
