import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {
  DocumentSubType,
  GetWorksheetQueryResult,
  WorksheetOutline,
  WorksheetRow,
  WorksheetStub
} from '../../../generated/api-stubs';
import {IWorksheetParent, WorksheetViewModel, WorksheetViewModelFactory} from '../worksheet-view-model';
import {DisplayableError} from '../../common/errors/errors';
import {WorksheetUnderlyingDataFactory} from '../worksheet-underlying-data';
import {WorksheetUpdateRequest} from '../worksheet-update-request';
import {DockedWorksheet, WorksheetDock} from '../worksheet-dock/worksheet-dock.service';
import {Router} from '@angular/router';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';
import {Subscription} from 'rxjs';
import {cssSanitize} from '../../common/css-sanitize';
import {DocumentUpdatedEventService, UpdatedDocument} from '../document-updated-event.service';
import {
  WorksheetLabelsEditorDialog, WorksheetLabelsEditorResult,
} from '../worksheet-labels-editor-dialog/worksheet-labels-editor-dialog.service';
import {CanopyJson} from '../../common/canopy-json.service';
import {ActiveWorksheets} from '../active-worksheets.service';
import {EditDocumentMetadataDialog} from '../../simulations/edit-document-metadata-dialog/edit-document-metadata-dialog.service';
import {DocumentMetadata} from '../../simulations/edit-document-metadata/edit-document-metadata.component';
import {GetSimVersion} from '../../common/get-sim-version.service';
import {UnitsManager} from '../../units/units-manager.service';
import { AuthenticationService, UserData } from '../../identity/state/authentication.service';

@Component({
  selector: 'cs-edit-worksheet',
  templateUrl: './edit-worksheet.component.html',
  styleUrls: ['./edit-worksheet.component.scss']
})
export class EditWorksheetComponent implements OnInit, OnDestroy, IWorksheetParent {

  @Input() public readonly tenantId: string;
  @Input() public readonly worksheetId: string;
  @Input() public readonly isInstanceInDock: boolean = false;

  public errorMessage: string;

  public worksheetViewModel: WorksheetViewModel;

  private updateTask: Promise<any>;
  private pendingUpdate: WorksheetUpdateRequest;

  private subscriptions: Subscription = new Subscription();

  private readonly userData: UserData;

  constructor(
    private readonly worksheetStub: WorksheetStub,
    private readonly worksheetUnderlyingDataFactory: WorksheetUnderlyingDataFactory,
    private readonly worksheetViewModelFactory: WorksheetViewModelFactory,
    private readonly dock: WorksheetDock,
    private readonly router: Router,
    private readonly loadingDialog: LoadingDialog,
    private readonly configUpdatedEventService: DocumentUpdatedEventService,
    private readonly authenticationService: AuthenticationService,
    private readonly worksheetLabelsEditorDialog: WorksheetLabelsEditorDialog,
    private readonly json: CanopyJson,
    private readonly activeWorksheets: ActiveWorksheets,
    private readonly editDocumentMetadataDialog: EditDocumentMetadataDialog,
    private readonly getSimVersion: GetSimVersion,
    private readonly unitsManager: UnitsManager,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {

    this.userData = this.authenticationService.userDataSnapshot;
  }

  public get containerElementId(): string {
    if(this.worksheetViewModel){
      return cssSanitize(this.worksheetViewModel.name) + '-worksheet';
    }

    return cssSanitize(this.worksheetId) + '-worksheet';
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    // This could be undefined if the worksheet was not loaded due to it being already open in the dock.
    if(this.worksheetViewModel){
      this.activeWorksheets.remove(this.worksheetViewModel);
    }
  }

  public get isLoading(): boolean {
    return (!this.worksheetViewModel && !this.errorMessage) || !!this.updateTask;
  }

  public get isOwner(): boolean {
    return !!this.worksheetViewModel && this.userData.sub === this.worksheetViewModel.user.userId;
  }

  public get isWorksheetInDock(): boolean {
    return this.dock.worksheet
      && this.dock.worksheet.tenantId === this.tenantId
      && this.dock.worksheet.worksheetId === this.worksheetId;
  }

  public async load() {
    try {
      this.errorMessage = undefined;

      // We're going to display a lot of units, so ensure they are loaded
      // in advance for efficiency.
      await this.unitsManager.ensureInitialized();

      if(!this.worksheetId){
        throw new DisplayableError('Worksheet ID not provided.');
      }

      if(!this.isInstanceInDock && this.isWorksheetInDock){
        this.router.navigate(['/worksheets', 'docked']);
        return;
      }

      const transferringViewModel = this.dock.popTransferringViewModel();
      if(transferringViewModel
        && transferringViewModel.tenant.tenantId === this.tenantId
        && transferringViewModel.worksheetId === this.worksheetId){
        this.worksheetViewModel = transferringViewModel;
        this.worksheetViewModel.updateParent(this);
      } else{
        await this.loadFromServer();
      }

      this.subscriptions.add(this.configUpdatedEventService.documentUpdated.subscribe((e) => this.handleConfigChangedEvent(e)));
      this.subscriptions.add(this.getSimVersion.changed.subscribe(() => this.handleSimVersionChangedEvent()));

      if (this.isInstanceInDock) {
        this.subscriptions.add(this.dock.undockRequested.subscribe(() => this.undockWorksheet(true)));
      }

      this.activeWorksheets.add(this.worksheetViewModel);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async editLabels() {
    try {
      this.errorMessage = undefined;
      const outline = this.worksheetViewModel.getOutline();
      const labelDefinitions = this.json.clone(outline.labelDefinitions);
      const result = await this.worksheetLabelsEditorDialog.show(this.isOwner, labelDefinitions);
      await this.processEditLabelsResult(result);
    } catch(error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async editMetadata() {
    try {
      this.errorMessage = undefined;
      const result = await this.editDocumentMetadataDialog.show(
        this.tenantId,
        DocumentSubType.worksheet,
        new DocumentMetadata(this.worksheetViewModel.name, this.worksheetViewModel.properties, this.worksheetViewModel.notes),
        false);

      if(result && result.documentMetadata){
        this.worksheetViewModel.name = result.documentMetadata.name;
        this.worksheetViewModel.properties = [...result.documentMetadata.customProperties];
        this.worksheetViewModel.notes = result.documentMetadata.notes;
        await this.update(false, false);
      }
    } catch(error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get isCurrentTenant(): boolean {
    return this.userData.tenant === this.tenantId;
  }

  public get hasProperties(): boolean {
    return !!(this.worksheetViewModel.properties && this.worksheetViewModel.properties.length);
  }

  public get hasNotes(): boolean {
    return !!this.worksheetViewModel.notes;
  }

  private async processEditLabelsResult(result: WorksheetLabelsEditorResult){
    if(result && result.refreshRequired){
      if(result.newWorksheetLabels){
        this.worksheetViewModel.worksheetLabelDefinitions = result.newWorksheetLabels;
      }
      if(this.isOwner){
        await this.update(true, false);
      } else{
        this.reload();
      }
    }
  }

  private async handleSimVersionChangedEvent(){
    if(this.isOwner){
      await this.update(true, false);
    } else{
      this.reload();
    }
  }

  public async loadFromServer() {
    const worksheetResult = await this.worksheetStub.getWorksheet(this.tenantId, this.worksheetId);
    await this.loadFrom(worksheetResult, true);
  }

  public waitForUpdate() {
    if(this.updateTask){
      return this.updateTask;
    }

    return Promise.resolve();
  }

  public async reload() {
    if(this.isOwner) {
      // If we own the worksheet we should use update() to reload the worksheet.
      return;
    }

    try {
      this.errorMessage = undefined;
      const task = this.worksheetStub.getWorksheet(this.tenantId, this.worksheetId);
      this.updateTask = task;
      const worksheetResult = await task;
      this.worksheetViewModel = undefined;
      await this.loadFrom(worksheetResult, true);
    } catch(error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    } finally {
      this.updateTask = undefined;
    }
  }

  public async update(generateColumns: boolean, updateHistory: boolean) {
    try {
      await this.updateInner(generateColumns, updateHistory);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async undo() {
    try {
      const newRows = this.worksheetViewModel.history.undo();
      await this.setFromHistory(newRows);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async redo() {
    try {
      const newRows = this.worksheetViewModel.history.redo();
      await this.setFromHistory(newRows);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get undoBufferSizeString(): string {
    return this.getBufferSizeString(this.worksheetViewModel.history.undoBufferSize);
  }

  public get redoBufferSizeString(): string {
    return this.getBufferSizeString(this.worksheetViewModel.history.redoBufferSize);
  }

  private getBufferSizeString(size: number): string {
    return size ? '(' + size + ')' : '';
  }

  private async setFromHistory(newRows: ReadonlyArray<WorksheetRow>) {
    if(!newRows){
      return;
    }

    const newOutline: WorksheetOutline = {
      rows: [...newRows],
      labelDefinitions: this.worksheetViewModel.worksheetLabelDefinitions
    };

    this.worksheetViewModel.applyRows(newRows);

    // Generate columns is true as we could restore a study which adds simulation columns.
    this.worksheetViewModel.generateColumns();
    await this.updateInner(true, false, newOutline);
  }

  private async updateInner(generateColumns: boolean, updateHistory: boolean, newOutline?: WorksheetOutline) {
    this.errorMessage = undefined;
    if(!this.isOwner) {
      await this.reload();
      return;
      // throw new DisplayableError('You are not the owner of this worksheet.');
    }

    if(this.pendingUpdate && this.pendingUpdate.generateColumns){
      generateColumns = true;
    }

    if(newOutline){
      updateHistory = false;
    } else {
      newOutline = this.worksheetViewModel.getOutline();
    }

    if(updateHistory){
      this.worksheetViewModel.history.add(newOutline.rows);
    }

    this.pendingUpdate = new WorksheetUpdateRequest(
      generateColumns,
      this.worksheetViewModel.name,
      newOutline,
      this.worksheetViewModel.properties,
      this.worksheetViewModel.notes);

    if(!this.updateTask){
      try {
        this.updateTask = this.performUpdateLoop();
        await this.updateTask;
      } finally{
        this.updateTask = undefined;
      }
    }
  }

  private async performUpdateLoop(){
    while(this.pendingUpdate){
      try {
        const nextUpdate = this.pendingUpdate;
        this.pendingUpdate = undefined;
        await this.updateFromRequest(nextUpdate);
      } catch(error){
        this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      }
    }
  }

  public async updateFromRequest(request: WorksheetUpdateRequest) {
    const worksheetResult = await this.worksheetStub.putWorksheet(
      this.tenantId,
      this.worksheetId,
      {
        name: request.name,
        properties: request.properties,
        notes: request.notes,
        outline: request.outline
      });
    await this.loadFrom(worksheetResult, request.generateColumns);
  }

  public async dockWorksheet() {
    try {
      await this.waitForUpdateWithSpinner();
    } catch(error){
      return;
    }

    this.dock.setTransferringViewModel(this.worksheetViewModel);
    await this.dock.setWorksheet(new DockedWorksheet(this.tenantId, this.worksheetId));
    this.router.navigate(['/worksheets']);
  }

  public async undockWorksheet(navigateToWorksheet: boolean) {
    try {
      await this.waitForUpdateWithSpinner();
    } catch(error){
      return;
    }

    this.dock.setTransferringViewModel(this.worksheetViewModel);
    await this.dock.setWorksheet(undefined);

    if(navigateToWorksheet) {
      this.router.navigate(['/worksheets', this.tenantId, this.worksheetId]);
    }
  }

  private async handleConfigChangedEvent(event: UpdatedDocument) {
    try {
      if(!this.worksheetViewModel){
        return;
      }

      if(this.worksheetViewModel.rows.some(
        row =>
          (
            row.study.populated
            && row.study.populated.reference
            && row.study.populated.reference.tenantId === event.tenantId
            && row.study.populated.reference.targetId === event.documentId
          )
          ||
          row.configs.some(
            config => config.populated
            && config.populated.reference
            && config.populated.reference.tenant
            && config.populated.reference.tenant.tenantId === event.tenantId
            && config.populated.reference.tenant.targetId === event.documentId))){
        await this.update(false, false);
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private waitForUpdateWithSpinner(): Promise<void> {
    return this.loadingDialog.show((v) => {
      v.setStatus('Waiting for worksheet sync...');
      return this.waitForUpdate();
    });
  }

  private async loadFrom(worksheetResult: GetWorksheetQueryResult, generateColumns: boolean){
    const underlyingData = await this.worksheetUnderlyingDataFactory.create(worksheetResult);
    const defaultStudyType = underlyingData.studyTypesList[0];

    if(this.worksheetViewModel){
      this.worksheetViewModel.update(underlyingData);
    } else{
      this.worksheetViewModel = this.worksheetViewModelFactory.create(this, underlyingData, defaultStudyType.studyType);
      this.worksheetViewModel.history.add(underlyingData.worksheetResult.worksheet.outline.rows);
    }

    if (generateColumns) {
      this.worksheetViewModel.generateColumns();
    }
  }
}
