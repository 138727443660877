import { Injectable } from '@angular/core';
import { LoadingDialog } from '../../../common/dialogs/loading-dialog.service';
import { ConfigReference, ConfigStub } from '../../../../generated/api-stubs';
import { fromReadonly } from '../../../common/from-readonly';
import { StudyInput } from '../../study-input';
import { AuthenticationService } from '../../../identity/state/authentication.service';


@Injectable()
export class ImportStandardConfigFromStagingArea {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly loadingDialog: LoadingDialog,
    private readonly configStub: ConfigStub) {
  }

  public async execute(studyInput: StudyInput): Promise<ConfigReference | undefined> {
    const userData = this.authenticationService.userDataSnapshot;

    const configId = await this.loadingDialog.showUntilFinished(
      this.configStub.postConfig(
        userData.tenant,
        {
          name: studyInput.name,
          configType: studyInput.configType,
          config: studyInput.data,
          properties: fromReadonly(studyInput.properties),
          notes: studyInput.notes,
          simVersion: studyInput.simVersion
        }),
      `Saving "${studyInput.name}"...`);

    return {
      tenant: {
        tenantId: userData.tenant,
        targetId: configId,
      }
    };
  }
}
