// Draft-06 compatibility

interface KeyedObject {
  [key: string]: KeyedObject | string | number | boolean;
}

const $schema = '$schema';
const draft04Url = 'http://json-schema.org/draft-04/schema';
const exclusiveMinimum = 'exclusiveMinimum';
const exclusiveMaximum = 'exclusiveMaximum';
const minimum = 'minimum';
const maximum = 'maximum';

const schemaTransformer = (node: KeyedObject) => {
  if (Object.getOwnPropertyNames(node).includes($schema)) {
    node[$schema] = draft04Url;
  }
};

const exclusiveMinimumTransformer = (node: KeyedObject) => {
  if (Object.getOwnPropertyNames(node).includes(exclusiveMinimum)) {
    node[minimum] = node[exclusiveMinimum];
    node[exclusiveMinimum] = true;
  }
};

const exclusiveMaximumTransformer = (node: KeyedObject) => {
  if (Object.getOwnPropertyNames(node).includes(exclusiveMaximum)) {
    node[maximum] = node[exclusiveMaximum];
    node[exclusiveMaximum] = true;
  }
};

const transformers: Array<(node: KeyedObject) => void> = [
  schemaTransformer,
  exclusiveMaximumTransformer,
  exclusiveMinimumTransformer
];

export const backwardsCompatibility = (node: { [key: string]: any }, draft: number = 4): void => {
  if (!node) {
    return;
  }

  // todo: could be unnecessary...
  let currentDraft = draft;
  if (Object(node).hasOwnProperty($schema)) {
    currentDraft = getSchemaVersion(node[$schema]);
  }

  if (currentDraft === 6) {
    for (let tranformer of transformers) {
      tranformer(node);
    }
  }

  Object.keys(node).forEach(key => {
    if (typeof node[key] === 'object') {
      backwardsCompatibility(node[key], currentDraft);
    }
  });
};

const getSchemaVersion = (schema: string) => schema.includes('/draft-06/schema') ? 6 : 4;
