import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class FontAwesomeIconLibrary extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.iconlibs.fontawesome4 = JSONEditor.AbstractIconLib.extend({
      mapping: {
        collapse: 'caret-down',
        expand: 'caret-right',
        delete: 'times',
        edit: 'pencil',
        add: 'plus',
        cancel: 'ban',
        load: 'cloud-download',
        save: 'cloud-upload',
        saveas: 'cloud-upload plus',
        import: 'upload',
        importtir: 'upload',
        tirtocanopy: 'arrow-right',
        export: 'download',
        encrypt: 'lock',
        encryptwithmetadata: 'lock filter',
        decrypt: 'unlock',
        moveup: 'arrow-up',
        movedown: 'arrow-down'
      },
      icon_prefix: 'fa fa-',
      getIcon(key: string) {
        let iconClass = this.mapping[key];

        if (!iconClass) {
          return null;
        }

        let iconClasses = iconClass.split(' ');

        if (iconClasses.length > 1) {
          let span = document.createElement('span');

          for (let iconClassItem of iconClasses) {
            let i = document.createElement('i');
            i.className = this.icon_prefix + iconClassItem;
            span.appendChild(i);
          }

          return span;
        }

        let i = document.createElement('i');
        i.className = this.icon_prefix + iconClass;
        return i;
      }
    });
  }
}
