import {GetStudyQueryResult, StudyStub} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {StudyNotifier} from '../notifications/study-notifier.service';
import {defer} from '../common/promise-defer';
import {isStudyComplete} from '../common/is-study-complete';

@Injectable()
export class LoadStudy {
  constructor(
    private readonly studyStub: StudyStub,
    private readonly studyNotifier: StudyNotifier){

  }

  public async tryLoadIfNotForbidden(
    tenantId: string,
    studyId: string,
    simVersion: string): Promise<GetStudyQueryResult> {
    try {
      return await this.load(tenantId, studyId, simVersion);
    } catch(error){
      if (error.isUnauthorizedError
        || error.isFromApi && error.response.status === 404) {
        return undefined;
      } else {
        throw error;
      }
    }
  }

  public async load(
    tenantId: string,
    studyId: string,
    simVersion: string): Promise<GetStudyQueryResult> {
    return await this.studyStub.getStudy(tenantId, studyId, simVersion);
  }

  public async loadMetadata(
    tenantId: string,
    studyId: string): Promise<GetStudyQueryResult> {
    return await this.studyStub.getStudyMetadata(tenantId, studyId);
  }

  public async loadCompleted(
    tenantId: string,
    studyId: string,
    simVersion: string): Promise<GetStudyQueryResult>{

    let deferred = defer();
    let subscription = this.studyNotifier.studyCompleted.subscribe(event => {
      if(event.studyId === studyId){
        deferred.resolve();
      }
    });

    try{
      let studyResult = await this.loadMetadata(tenantId, studyId);
      const isComplete = isStudyComplete(studyResult.study.data);
      if(!isComplete){
        await deferred.promise;
      }
    } finally{
      subscription.unsubscribe();
    }

    const studyResult = await this.load(tenantId, studyId, simVersion);
    if(!isStudyComplete(studyResult.study.data)){
      console.warn('Study was not complete.');
    }

    return studyResult;
  }
}
