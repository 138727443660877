import { Injectable } from '@angular/core';
import { splitStringContainingQuotes } from '../split-string-containing-quotes';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SplitStringsEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitStrings = JSONEditor.defaults.editors.string.extend({
      getValue() {
        //let split_by=/[\s,]+/;
        return this.value ? splitStringContainingQuotes(this.value.trim()).filter((v: string) => v !== '') : [];
      },
      setValue(value: any, initial: any, from_template: boolean) {
        value = this.sanitizeArray(value);

        if (value) {
          value = value.map((v: any) => {
            if (typeof v === 'string') {

              if (v.indexOf(' ') !== -1 || v.indexOf(',') !== -1) {
                return '"' + v + '"';
              }

              return v;
            }

            return v;
          });
        } else {
          value = '';
        }
        let join_with = ', ';
        value = value ? value.join(join_with) : '';

        return (<any>this)._super(value, initial, from_template);
      }
    });
  }
}
