import {Injectable} from '@angular/core';
import {RowViewModel} from '../row-view-model';
import {ConfigReference} from '../../../generated/api-stubs';
import {ReplaceResult} from '../worksheet-item-component-base';
import {ConfirmReplaceAllReferences} from './confirm-replace-all-references.service';
import {ConfigViewModel} from '../config-view-model';

@Injectable()
export class SetConfigOrAllReferences {

  constructor(
    private readonly confirmReplaceAllReferences: ConfirmReplaceAllReferences) {
  }

  public async execute(target: ConfigViewModel, rows: ReadonlyArray<RowViewModel>, reference: ConfigReference, replaceResult: ReplaceResult | undefined){

    // NOTE: We usually pass the replace result in, because we prompt at the beginning of the operation.
    // Prompting at the end would often result in needing to clean up any duplicated configs if the user chooses
    // to cancel, and in other cases we still prompt at the beginning for consistency.
    if(replaceResult === undefined){
      replaceResult = await this.confirmReplaceAllReferences.execute(target, rows);
    }

    switch(replaceResult){
      case ReplaceResult.replaceThisReference:
        for(let row of rows) {
          row.getOrCreateConfig(target.configType).setConfig(reference);
        }
        break;

      case ReplaceResult.replaceAllReferences:
        // Note: This only occurs if the user has selected a single cell, so we don't need to iterate rows.
        await target.replaceAllConfigs(reference);
        break;
    }
  }
}
