@if (!isLoaded && !errorMessage) {
  <cs-loader></cs-loader>
}
<cs-error-message [message]="errorMessage"></cs-error-message>
@if (isLoaded) {
  <ul class="nav nav-tabs">
    @for (tab of tabs; track tab) {
      <li class="nav-item">
        <a class="nav-link"
          [id]="tab.type-tab"
        [ngClass]="{ active: tab.isVisible }" href="javascript:void(0)" (click)="setActiveTab(tab)">{{tab.name}}</a>
      </li>
    }
  </ul>
  @if (compareStructure.isRendered) {
    <cs-compare-config-structures #compareConfigStructuresComponent
      [ngStyle]="{ display: compareStructure.isVisible ? 'block' : 'none' }"
      [configs]="configs"
      [configType]="configType"
    [simVersion]="simVersion"></cs-compare-config-structures>
  }
  @if (compareVisualizations.isRendered) {
    <cs-compare-config-visualizations
      [ngStyle]="{ display: compareVisualizations.isVisible ? 'block' : 'none' }"
      [configs]="configs"
      [configType]="configTypeDefinition"
      [units]="simVersionDocumentsResult.units"
    [simVersion]="simVersion"></cs-compare-config-visualizations>
  }
  @if (compareLegacy.isRendered) {
    <cs-compare-config-deprecated
      [ngStyle]="{ display: compareLegacy.isVisible ? 'block' : 'none' }"
      [firstTitle]="configs[1]?.shortName || 'first'"
      [first]="configs[1]?.materialized.data"
      [secondTitle]="configs[0]?.shortName || 'second'"
      [second]="configs[0]?.materialized.data"
    [elementId]="'dialog-diff'"></cs-compare-config-deprecated>
  }
}
