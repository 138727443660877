import {Injectable} from '@angular/core';
import {IWorksheetContextMenuBuilder} from '../worksheet-context-menu-builder';
import {MenuItem, SeparatorMenuItem} from '../../context-menu/context-menu-types';
import {RowItemViewModel} from '../row-item-view-model';
import {ViewConfigOrStudyCommand} from './view-config-or-study-command';
import {CommandContext, CommandResult, WorksheetContext} from './worksheet-command';
import {TakeOwnershipCommand} from './take-ownership-command';
import {LoadConfigCommand} from './load-config-command';
import {ImportTelemetryCommand} from './import-telemetry-command';
import {SetTelemetryJobIndexCommand} from './set-telemetry-job-index-command';
import {ClearSelectedCommand} from './clear-selected-command';
import {DuplicateInPlaceCommand} from './duplicate-in-place-command';
import {CutCommand} from './cut-command';
import {CopyCommand} from './copy-command';
import {PasteCommand} from './paste-command';
import {PasteDuplicateCommand} from './paste-duplicate-command';
import {CompareToStudyInputCommand} from './compare-to-study-input-command';
import {StageSnapshotCommand} from './stage-snapshot-command';
import {CompareToStagedCommand} from './compare-to-staged-command';
import {CompareSelectedCommand} from './compare-selected-command';
import {CompareToSomethingCommand} from './compare-to-something-command';
import { ImportStagedCommand } from './import-staged/import-staged-command';
import { DownloadConfigCommandService } from './download-config-command';
import { CompareResultsCommand } from './compare-results-command';
import { ImportConfigFromFileCommand } from './import-config-from-file-command';

@Injectable()
export class ConfigContextMenuBuilder implements IWorksheetContextMenuBuilder {
  constructor(
    private readonly viewConfigOrStudyCommand: ViewConfigOrStudyCommand,
    private readonly takeOwnershipCommand: TakeOwnershipCommand,
    private readonly loadConfigCommand: LoadConfigCommand,
    private readonly importTelemetryCommand: ImportTelemetryCommand,
    private readonly setTelemetryJobIndexCommand: SetTelemetryJobIndexCommand,
    private readonly clearSelectedCommand: ClearSelectedCommand,
    private readonly duplicateInPlaceCommand: DuplicateInPlaceCommand,
    private readonly cutCommand: CutCommand,
    private readonly copyCommand: CopyCommand,
    private readonly pasteCommand: PasteCommand,
    private readonly pasteDuplicateCommand: PasteDuplicateCommand,
    private readonly compareToStudyInputCommand: CompareToStudyInputCommand,
    private readonly stageSnapshotCommand: StageSnapshotCommand,
    private readonly compareToStagedCommand: CompareToStagedCommand,
    private readonly compareSelectedCommand: CompareSelectedCommand,
    private readonly compareToSomethingCommand: CompareToSomethingCommand,
    private readonly importStagingAreaCommand: ImportStagedCommand,
    private readonly downloadConfigCommand: DownloadConfigCommandService,
    private readonly compareResultsCommand: CompareResultsCommand,
    private readonly importConfigFromFileCommand: ImportConfigFromFileCommand){
  }

  generateContextMenu(target: RowItemViewModel, worksheetContext: WorksheetContext): ReadonlyArray<MenuItem<CommandResult>> {
    let result: MenuItem<CommandResult>[] = [];

    const context = new CommandContext(target, worksheetContext);
    if(!context.isConfig()){
      return result;
    }

    this.viewConfigOrStudyCommand.createMenuItems(context, result);
    this.takeOwnershipCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.loadConfigCommand.createMenuItems(context, result);
    this.importStagingAreaCommand.createMenuItems(context, result);
    this.importConfigFromFileCommand.createMenuItems(context, result);
    this.importTelemetryCommand.createMenuItems(context, result);
    this.setTelemetryJobIndexCommand.createMenuItems(context, result);
    this.clearSelectedCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.duplicateInPlaceCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.compareSelectedCommand.createMenuItems(context, result);
    this.compareToStudyInputCommand.createMenuItems(context, result);
    this.stageSnapshotCommand.createMenuItems(context, result);
    this.compareToStagedCommand.createMenuItems(context, result);
    this.compareToSomethingCommand.createMenuItems(context, result);
    this.compareResultsCommand.createMenuItems(context, result);
    this.downloadConfigCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.cutCommand.createMenuItems(context, result);
    this.copyCommand.createMenuItems(context, result);
    this.pasteCommand.createMenuItems(context, result);
    this.pasteDuplicateCommand.createMenuItems(context, result);

    return result;
  }
}
