import {Component, Input, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {PromptDialog} from '../../common/dialogs/prompt-dialog.service';
import {CONFIG_LABEL_SOURCE, SCALAR_RESULTS_LABEL_SOURCE} from '../worksheet-constants';
import {DocumentSubType} from '../../../generated/api-stubs';
import {cssSanitize} from '../../common/css-sanitize';
import {GetStudyAutocompleteOptions} from '../../simulations/configs/json-config-editor/get-study-autocomplete-options.service';

export class CombinedWorksheetLabelDefinitionsSet {
  constructor(
    public readonly name: string,
    public readonly items: CombinedWorksheetLabelDefinitionSet[]){
  }
}

export class CombinedWorksheetLabelDefinitions {
  public readonly list: ReadonlyArray<CombinedWorksheetLabelDefinitionsSet>;

  constructor(
    public readonly configLabelDefinitions: CombinedWorksheetLabelDefinitionSet[],
    public readonly simulationLabelDefinitions: CombinedWorksheetLabelDefinitionSet[]) {

    this.list = [
      new CombinedWorksheetLabelDefinitionsSet('Configs', this.configLabelDefinitions),
      new CombinedWorksheetLabelDefinitionsSet('Simulations', this.simulationLabelDefinitions),
    ];
  }
}

export class CombinedWorksheetLabelDefinitionSet {
  constructor(
    public readonly key: string,
    public readonly displayName: string,
    public readonly labelSetType: LabelSetType,
    public readonly labels: CombinedWorksheetLabelDefinition[]){
  }
}

export class CombinedWorksheetLabelDefinition {
  constructor(
    public readonly source: string,
    public readonly name: string,
    public inWorksheetLabels: boolean = false,
    public inUserLabels: boolean = false,
    public inTenantLabels: boolean = false){
  }
}

export enum LabelSetType {
  config,
  simulation,
}

@Component({
  selector: 'cs-worksheet-labels-editor',
  templateUrl: './worksheet-labels-editor.component.html',
  styleUrls: ['./worksheet-labels-editor.component.scss']
})
export class WorksheetLabelsEditorComponent implements OnInit {

  @Input() public readonly canEditWorksheetLabels: boolean;
  @Input() public readonly labels: CombinedWorksheetLabelDefinitions;

  public errorMessage: string;

  public activeSet: CombinedWorksheetLabelDefinitionsSet;

  constructor(
    private readonly promptDialog: PromptDialog,
    private readonly getStudyAutocompleteOptions: GetStudyAutocompleteOptions,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) { }

  ngOnInit() {
    try {
      if(this.labels.list.length){
        this.activeSet = this.labels.list[0];
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public setActiveSet(set: CombinedWorksheetLabelDefinitionsSet){
    this.activeSet = set;
  }

  public deleteLabel(item: CombinedWorksheetLabelDefinitionSet, definition: CombinedWorksheetLabelDefinition){
    try {
      let index = item.labels.findIndex(v => v.name === definition.name && v.source === definition.source);
      if(index !== -1){
        item.labels.splice(index, 1);
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public toggleLocation(definition: CombinedWorksheetLabelDefinition, toggle: keyof CombinedWorksheetLabelDefinition){
    try {
      (definition as any)[toggle] = !definition[toggle];
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async addLabel(item: CombinedWorksheetLabelDefinitionSet) {
    try {
      let newLabelDefinition: CombinedWorksheetLabelDefinition;

      if(item.labelSetType === LabelSetType.config){
        // Get autocomplete.
        let configType = item.key as DocumentSubType;
        await this.getStudyAutocompleteOptions.initialize();
        let autocomplete = await this.getStudyAutocompleteOptions.executeSynchronous(false, false, configType);

        let newLabel = await this.promptDialog.show<string>(
          'Enter a config path (stage a config to enable auto-complete):',
          'Add New Label',
          undefined,
          undefined,
          undefined,
          undefined,
          autocomplete.options);

        if(newLabel){
          newLabel = newLabel.trim();
          newLabelDefinition = new CombinedWorksheetLabelDefinition(
            CONFIG_LABEL_SOURCE,
            newLabel,
            false,
            true,
            false);
        }
      } else{
        let newLabel = await this.promptDialog.show<string>(
          'Enter a scalar result name:',
          'Add New Label');

        if(newLabel){
          newLabel = newLabel.trim();
          newLabelDefinition = new CombinedWorksheetLabelDefinition(
            SCALAR_RESULTS_LABEL_SOURCE,
            newLabel,
            false,
            true,
            false);
        }
      }

      if(newLabelDefinition
        && !item.labels.some(v => v.name === newLabelDefinition.name && v.source === newLabelDefinition.source)){
        item.labels.push(newLabelDefinition);
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public cssSanitize(value: string): string{
    return cssSanitize(value);
  }
}
