import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { ErrorMessageService } from '../../common/errors/services/error-message/error-message.service';
import { UserChangedMessage } from '../state/identity.actions';
import { CheckAuthentication, Login, Logout } from './authentication.actions';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';

export interface AuthenticationStateModel {
  idToken: string;
  isAuthenticated: boolean;
  authToken: string;
  userData: any; // UserData
}

const STATE_TOKEN = new StateToken<AuthenticationStateModel>('authentication');

@State({
  name: STATE_TOKEN
})
@Injectable()
export class AuthenticationState {
  private bc: BroadcastChannel;

  constructor(
    private _errorMessageService: ErrorMessageService,
    private _oidcSecurityService: OidcSecurityService) {
      this.bc = new BroadcastChannel('authentication_state');
      this.bc.onmessage = async m => {
        if(m.data === 'logout'){
          await this._oidcSecurityService.logoffAndRevokeTokens().toPromise();
        }
      }
    }


  @Action(UserChangedMessage)
  public userChanged(ctx: StateContext<AuthenticationStateModel>): void {
    ctx.setState({
      idToken: undefined,
      isAuthenticated: false,
      authToken: undefined,
      userData: undefined,
    });
  }

  @Action(CheckAuthentication)
  public async checkAuthentication(ctx: StateContext<AuthenticationStateModel>): Promise<void> {
    this._errorMessageService.clearErrorMessage();
    try {
      let AuthenticationResult: LoginResponse;
      if(await this._oidcSecurityService.getRefreshToken().toPromise()){
        AuthenticationResult = await this._oidcSecurityService.checkAuthIncludingServer().toPromise();
      }else{
        AuthenticationResult = await this._oidcSecurityService.checkAuth().toPromise();
      }
      ctx.patchState({
        idToken: AuthenticationResult.idToken,
        isAuthenticated: AuthenticationResult.isAuthenticated,
        authToken: AuthenticationResult.accessToken,
        userData: AuthenticationResult.userData,
      });
    } catch (err) {
      this._errorMessageService.setErrorMessage(err);
    }
  }

  @Action(Login)
  public login(ctx: StateContext<AuthenticationStateModel>): void {
    let queryParams = new URLSearchParams(window.location.search);
    let username = queryParams.get('username') ?? '';
    let tenant = queryParams.get('tenant') ?? '';
    let signInMessage = queryParams.get('signInMessage') ?? '';
    this._oidcSecurityService.authorize(undefined, { customParams:
      {
        login_hint: username,
        tenant_hint: tenant,
        sign_in_message: signInMessage
      }
    });
  }

  @Action(Logout)
  public async logout(ctx: StateContext<AuthenticationStateModel>): Promise<void> {
    await this._oidcSecurityService.logoffAndRevokeTokens().toPromise();
    this.bc.postMessage('logout');
  }
}
