import {Injectable} from '@angular/core';
import {IWorksheetContextMenuBuilder} from '../worksheet-context-menu-builder';
import {MenuItem, SeparatorMenuItem} from '../../context-menu/context-menu-types';
import {RowItemViewModel} from '../row-item-view-model';
import {CommandContext, CommandResult, WorksheetContext} from './worksheet-command';
import {ClearSelectedCommand} from './clear-selected-command';
import {CutCommand} from './cut-command';
import {CopyCommand} from './copy-command';
import {PasteCommand} from './paste-command';
import {PasteDuplicateCommand} from './paste-duplicate-command';
import {RenameRowCommand} from './rename-row-command';
import {CopyRowDownCommand} from './copy-row-down-command';
import {InsertRowCommand} from './insert-row-command';
import {AppendRowCommand} from './append-row-command';
import {RemoveRowCommand} from './remove-row-command';
import { ImportStagedCommand } from './import-staged/import-staged-command';

@Injectable()
export class RowMetadataContextMenuBuilder implements IWorksheetContextMenuBuilder {
  constructor(
    private readonly renameRowCommand: RenameRowCommand,
    private readonly copyRowDownCommand: CopyRowDownCommand,
    private readonly insertRowCommand: InsertRowCommand,
    private readonly appendRowCommand: AppendRowCommand,
    private readonly clearSelectedCommand: ClearSelectedCommand,
    private readonly removeRowCommand: RemoveRowCommand,
    private readonly cutCommand: CutCommand,
    private readonly copyCommand: CopyCommand,
    private readonly pasteCommand: PasteCommand,
    private readonly pasteDuplicateCommand: PasteDuplicateCommand,
    private readonly importStagingAreaCommand: ImportStagedCommand){
  }

  generateContextMenu(target: RowItemViewModel, worksheetContext: WorksheetContext): ReadonlyArray<MenuItem<CommandResult>> {
    let result: MenuItem<CommandResult>[] = [];

    const context = new CommandContext(target, worksheetContext);
    if(!context.isRowMetadata()){
      return result;
    }

    this.renameRowCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.copyRowDownCommand.createMenuItems(context, result);
    this.importStagingAreaCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.insertRowCommand.createMenuItems(context, result);
    this.appendRowCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.clearSelectedCommand.createMenuItems(context, result);
    this.removeRowCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.cutCommand.createMenuItems(context, result);
    this.copyCommand.createMenuItems(context, result);
    this.pasteCommand.createMenuItems(context, result);
    this.pasteDuplicateCommand.createMenuItems(context, result);

    return result;
  }
}
