import * as d3 from '../../d3-bundle';
import {
  MultiPlotDataRendererBase,
  MultiPlotDataRendererInnerBase
} from '../multi-plot-viewer-base/multi-plot-data-renderer-base';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedPlotSourceChannel } from '../data-pipeline/types/processed-plot-source-channel';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';

export class DataRenderer extends MultiPlotDataRendererBase {

  public static create() {
    return new DataRenderer(new DataRendererInner('scatter-plot', new GetInterpolatedChannelValueAtDomainValue()));
  }

  private constructor(inner: DataRendererInner) {
    super(inner);
  }
}

export class DataRendererInner extends MultiPlotDataRendererInnerBase {

  protected drawChannelData(context: CanvasRenderingContext2D, plot: ProcessedPlot, channel: ProcessedPlotSourceChannel) {
    let sourceIndex = channel.sourceIndex;
    let yChannel = channel.yChannel;
    let xCoordinates = channel.xCoordinates;
    let yCoordinates = channel.yCoordinates;

    context.fillStyle = this.definedSettings.getChannelColor ? this.definedSettings.getChannelColor(yChannel.channelIndex, sourceIndex) : 'green';

    let pointSize = DataRendererInner.getScatterPlotPointSize(xCoordinates.length);

    for (let dataIndex = 0; dataIndex < xCoordinates.length; ++dataIndex) {
      context.beginPath();
      context.arc(xCoordinates[dataIndex], yCoordinates[dataIndex], pointSize, 0, 2 * Math.PI);
      context.fill();
    }
  }

  protected drawPlotFeatures(context: CanvasRenderingContext2D, plot: ProcessedPlot) {

  }

  static getScatterPlotPointSize(dataLength: number) {
    const maxPointSize = 1;
    const minPointSize = 1;

    const minPointSizeAtDataSize = 200;
    const maxPointSizeAtDataSize = 50;

    return d3.maxStrict([minPointSize, d3.minStrict([maxPointSize,
      maxPointSize - ((maxPointSize - minPointSize) * (dataLength - maxPointSizeAtDataSize) / (minPointSizeAtDataSize - maxPointSizeAtDataSize))])]);
  }
}
