import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {ConfigViewModel} from '../config-view-model';
import {StageConfigReference} from '../stage-config-reference';

@Injectable()
export class StageSnapshotCommand extends WorksheetCommand {

  constructor(
    private readonly stageConfigReference: StageConfigReference) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    return context.isConfig()
      && context.isSingleRow
      && context.target.isPopulated;
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    await this.stageConfigReference.execute(
      context.target.configType,
      context.target.reference,
      context.target.resolvedReference.data.name);

    return CommandResult.NoUpdate;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Stage Snapshot`,
      `stage-snapshot-button`,
      () => this.execute(context),
      'arrow-circle-up'));
  }
}
