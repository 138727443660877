
@switch (valueType) {
  @case (ValueType.primitive) {
    {{value | units:unitsInformation.targetUnits:unitsInformation.sourceUnits:-1}}
  }
  @case (ValueType.arrayHash) {
    Large Array
  }
  @case (ValueType.encrypted) {
    {{encryptedValue}}
  }
  @case (ValueType.object) {
    <div class="complex-value object-value">
      @for (key of keys; track key) {
        <div class="object-value-property">
          <span class="property-name"><span class="parameter-path">{{child.parentPath}}</span>{{key}}:</span>
          <span cs-compare-config-structures-value-renderer
            #child
            [value]="value[key]"
            [parentPath]="getNextPath(key)"
            [parentName]="key"
          [getUnits]="getUnits"></span>
        </div>
      }
    </div>
  }
  @case (ValueType.array) {
    <div class="complex-value array-value">
      @for (key of keys; track key) {
        <div class="array-value-property">
          <span class="array-index"><span class="parameter-path">{{child.parentPath}}</span>{{key}}:</span>
          <span cs-compare-config-structures-value-renderer
            #child
            [value]="value[key]"
            [parentPath]="getNextPath(key)"
            [parentName]="parentName"
          [getUnits]="getUnits"></span>
        </div>
      }
    </div>
  }
}
