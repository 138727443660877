import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {ConfigViewModel} from '../config-view-model';
import {CompareConfigToStaged} from '../compare-config-to-staged';

@Injectable()
export class CompareToStagedCommand extends WorksheetCommand {

  constructor(
    private readonly compareConfigToStaged: CompareConfigToStaged) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    return context.isConfig()
      && context.isSingleRow
      && context.target.isPopulated;
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    await this.compareConfigToStaged.execute(
      context.target.configType,
      context.target.reference);

    return CommandResult.NoUpdate;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Compare to Staged`,
      `compare-to-staged-button`,
      () => this.execute(context)));
  }
}
