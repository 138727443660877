import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, WorksheetCommand} from './worksheet-command';
import {ButtonMenuItem, KeyboardAction, MenuItem,} from '../../context-menu/context-menu-types';
import {CLEAR_ICON} from '../worksheet-item-component-base';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class ClearSelectedCommand extends WorksheetCommand {

  constructor(
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  public canExecute(context: CommandContext): boolean {
    const userData = this.authenticationService.userDataSnapshot;
    return context.worksheet.canWrite(userData.sub)
      && (
        context.isRowMetadata()
        || (context.isConfigOrStudy() && context.target.isPopulated)
      );
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    context.worksheet.clearSelected();

    return CommandResult.UpdateAndGenerateColumns;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Clear`,
      `clear-button`,
      () => this.execute(context),
      CLEAR_ICON,
      KeyboardAction.delete));
  }
}
