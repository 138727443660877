import { ISlider, sliderBottom } from '../components/slider';
import { BaseType } from 'd3-selection';
import * as d3 from '../../d3-bundle';
import { Subscription, Subject, Observable } from 'rxjs';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedMultiPlotChannel } from '../data-pipeline/types/processed-multi-plot-channel';
import { SharedState } from '../shared-state';
import { ISize } from '../size';
import { IMargin } from '../margin';
import { Timer } from '../../timer';
import { PlotClippingRenderer } from '../multi-plot-viewer-base/plot-clipping-renderer';
import { throttleTime } from 'rxjs/operators';
import { ExplorationChannelMetadata } from '../channel-data-loaders/exploration-channel-metadata';

interface IChartSettings {
  readonly uniqueId: string;
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly spaceBetweenPlots: number;
}

export class DimensionCoordinatesSlider {
  private slider?: ISlider;
  private dimensionSubscriptions?: Subscription;
  private layout?: IPopulatedMultiPlotLayout;
  private settings?: IChartSettings;
  private sourcesChangedEvent: Subject<void> = new Subject<void>();
  private areSourcesChanging: boolean = false;

  constructor(
    private readonly sharedState: SharedState,
    private readonly dimensionIndex: number,
    private readonly xDomainNames: ReadonlyArray<string>,
    private readonly svg: d3.Selection<SVGElement, any, BaseType, any>) {
  }

  public get sourcesChanged(): Observable<void> {
    return this.sourcesChangedEvent;
  }

  public dispose() {
    if (this.dimensionSubscriptions) {
      this.dimensionSubscriptions.unsubscribe();
    }
  }

  public build() {
    const dimensions = this.sharedState.definedDimensions;

    this.slider = sliderBottom()
      .on('moved', (event: any) => this.onSliderMoved(event));

    this.dimensionSubscriptions = dimensions.rCoordinatesNews.subscribe(
      () => this.onrCoordinatesNews());
    this.dimensionSubscriptions.add(dimensions.rCoordinatesNews.pipe(throttleTime(200)).subscribe(
      () => this.onrCoordinatesNewsThrottled()));
  }

  private onrCoordinatesNews() {
    this.render();
  }

  private async onrCoordinatesNewsThrottled() {
    if (this.areSourcesChanging) {
      return;
    }
    this.areSourcesChanging = true;
    try {
      await Timer.yield();
      this.sourcesChangedEvent.next();
    } finally {
      this.areSourcesChanging = false;
    }
  }

  public setData(layout: IPopulatedMultiPlotLayout, settings: IChartSettings) {
    this.layout = layout;
    this.settings = settings;
  }

  public render() {
    if (!this.settings || !this.slider) {
      return;
    }
    const settings = this.settings;

    let validPlot = this.updateSlider();

    let containerUpdate = this.svg.selectAll<SVGGElement, any>('.dimension-sliders').data([null]);
    let containerEnter = containerUpdate.enter().append('g').attr('class', 'dimension-sliders');
    let container = containerEnter.merge(containerUpdate);

    let data: ProcessedPlot[] = validPlot ? [validPlot] : [];

    let gUpdate = container.selectAll<SVGGElement, ProcessedPlot>('.slider-group').data(data);
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g')
      .attr('class', 'slider-group')
      .attr('clip-path', (d: ProcessedPlot) => `url(#${PlotClippingRenderer.getPlotXAxisClipPathId(settings.uniqueId, d.plotIndex)})`);
    let g = gEnter.merge(gUpdate);

    let sliderContainerUpdate = g.select<SVGGElement>('.slider-container');
    let sliderContainerEnter = gEnter.append<SVGGElement>('g')
      .attr('class', 'slider-container');
    let sliderContainer = sliderContainerEnter.merge(sliderContainerUpdate);

    sliderContainer.attr('transform', (plot: ProcessedPlot) => {
      let scale = plot.column.processed.scale;
      let sourcesVisibility = this.sharedState.sourceLoaderSet.sources.map(v => v.isVisible);
      let visibleMinimum = plot.column.processed.getVisibleMinimum(sourcesVisibility);

      return 'translate('
        + (plot.absoluteRenderArea.x + scale(visibleMinimum))
        + ','
        + (plot.absoluteRenderArea.y + plot.absoluteRenderArea.height)
        + ')';
    });

    sliderContainer.call(this.slider);
  }

  private updateSlider(): ProcessedPlot | undefined {
    if (!this.slider) {
      return undefined;
    }

    let plot = this.getXDomainPlot();
    if (!plot) {
      return undefined;
    }

    let visibleChannel = this.getVisibleChannel(plot);
    if (!visibleChannel) {
      return undefined;
    }

    let scale = plot.column.processed.scale;
    const dimensions = this.sharedState.definedDimensions;
    let rCoordinate = dimensions.rCoordinates[this.dimensionIndex];

    let loaderMetadata = ExplorationChannelMetadata.fromProcessedMultiPlotChannel(visibleChannel);
    if (loaderMetadata) {
      rCoordinate = loaderMetadata.explorationSubSweep.mapFromIndexToDataNormalizedValue(rCoordinate);
    }

    let sourcesVisibility = this.sharedState.sourceLoaderSet.sources.map(v => v.isVisible);
    let visibleMinimum = plot.column.processed.getVisibleMinimum(sourcesVisibility);
    let visibleMaximum = plot.column.processed.getVisibleMaximum(sourcesVisibility);

    let positionMinimum = scale(visibleMinimum);
    let positionMaximum = scale(visibleMaximum);

    let trackLength = positionMaximum - positionMinimum;
    let currentValue = rCoordinate * (visibleMaximum - visibleMinimum) + visibleMinimum;
    let currentPosition = scale(currentValue) - positionMinimum;

    this.slider
      .trackLength(trackLength)
      .position(currentPosition);

    return plot;
  }

  protected onSliderMoved(currentEvent: any) {
    let plot = this.getXDomainPlot();
    if (!plot) {
      return;
    }

    let visibleChannel = this.getVisibleChannel(plot);
    if (!visibleChannel) {
      return;
    }

    let scale = plot.column.processed.scale;
    let sourcesVisibility = this.sharedState.sourceLoaderSet.sources.map(v => v.isVisible);
    let visibleMinimum = plot.column.processed.getVisibleMinimum(sourcesVisibility);
    let visibleMaximum = plot.column.processed.getVisibleMaximum(sourcesVisibility);
    let range = visibleMaximum - visibleMinimum;
    let positionMinimum = scale(visibleMinimum);
    let value = scale.invert(positionMinimum + currentEvent.position);

    let rCoordinate = (value - visibleMinimum) / range;
    let loaderMetadata = ExplorationChannelMetadata.fromProcessedMultiPlotChannel(visibleChannel);
    if (loaderMetadata) {
      rCoordinate = loaderMetadata.explorationSubSweep.mapFromDataToIndexNormalizedValue(rCoordinate);
    }

    const dimensions = this.sharedState.definedDimensions;
    dimensions.rCoordinatesSet(this.dimensionIndex, rCoordinate);
  }

  private getXDomainPlot(): ProcessedPlot | undefined {
    if (!this.layout) {
      return undefined;
    }

    let plots = this.layout.processed.plots.filter(plot => {
      let visibleChannel = this.getVisibleChannel(plot);
      if (!visibleChannel) {
        return false;
      }
      const visibleChannelName = visibleChannel.name;
      return !!this.xDomainNames.find(v => v === visibleChannelName);
    });

    return plots.length ? plots[plots.length - 1] : undefined;
  }

  private getVisibleChannel(plot: ProcessedPlot): ProcessedMultiPlotChannel | undefined {
    return plot.column.processed.channels.find(v => v.isVisible);
  }
}
