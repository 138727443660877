import { TrackCoordinate } from '../3d/track-coordinate';
import { Vector3 } from 'three';
import { CreateZeroVector3 } from './extract-track-data';

export class TrackPath {
  public readonly vertices: Float32Array;

  public get minHeight(): number {
    return this._minHeight;
  }
  public get maxHeight(): number {
    return this._maxHeight;
  }
  public get elevationOffset(): number {
    return this._elevationOffset;
  }
  public get elevationScale(): number {
    return this._elevationScale;
  }

  private _minHeight: number = 0;
  private _maxHeight: number = 0;
  private _elevationOffset: number = 0;
  private _elevationScale: number = 1;

  constructor(
    public readonly trackCoordinates: ReadonlyArray<TrackCoordinate>) {
    this.vertices = this.trackCoordinates
      .reduce((p: Float32Array, c: TrackCoordinate, i: number) => {
        let targetIndex = i * 3;
        p[targetIndex] = c.worldX;
        p[targetIndex + 1] = c.worldY;
        p[targetIndex + 2] = c.worldZ;
        return p;
      },
        new Float32Array(this.trackCoordinates.length * 3));

    if (trackCoordinates.length) {
      this._minHeight = this._maxHeight = trackCoordinates[0].worldY;
      for (let coordinate of trackCoordinates) {
        let worldY = coordinate.worldY;
        if (worldY > this._maxHeight) {
          this._maxHeight = worldY;
        }
        if (worldY < this._minHeight) {
          this._minHeight = worldY;
        }
      }
    }
  }

  public cloneRange(startIndex: number, count: number) {
    let trackCoordinates = new Array<TrackCoordinate>(count);
    let sourceIndex = startIndex;
    for (let i = 0; i < count && sourceIndex < this.trackCoordinates.length; ++i, ++sourceIndex) {
      trackCoordinates[i] = this.trackCoordinates[sourceIndex];
    }

    let result = new TrackPath(trackCoordinates);
    result.adjustWorldElevationCoordinates(this._elevationScale, this._elevationOffset);
    return result;
  }

  public getWorldCoordinate(index: number): Vector3 {
    let coordinate = this.trackCoordinates[index];
    if (!coordinate) {
      return CreateZeroVector3();
    }

    let worldCoordinate = coordinate.worldVector;
    worldCoordinate.setY((worldCoordinate.y + this._elevationOffset) * this._elevationScale);
    return worldCoordinate;
  }

  public adjustWorldElevationCoordinates(scale: number, offset: number) {
    this.resetWorldElevationCoordinates();

    this._elevationScale = scale;
    this._elevationOffset = offset;

    this._minHeight = (this._minHeight + offset) * scale;
    this._maxHeight = (this._maxHeight + offset) * scale;
    for (let i = 1; i < this.vertices.length; i += 3) {
      this.vertices[i] = (this.vertices[i] + offset) * scale;
    }
  }

  public resetWorldElevationCoordinates() {
    if (this._elevationScale === 1 && this._elevationOffset === 0) {
      return;
    }

    this._minHeight = this._minHeight / this._elevationScale - this._elevationOffset;
    this._maxHeight = this._maxHeight / this._elevationScale - this._elevationOffset;
    for (let i = 1; i < this.vertices.length; i += 3) {
      this.vertices[i] = this.vertices[i] / this._elevationScale - this._elevationOffset;
    }

    this._elevationScale = 1;
    this._elevationOffset = 0;
  }
}
