import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {Injectable} from '@angular/core';
import {ButtonMenuItem, MenuItem} from '../../context-menu/context-menu-types';
import {DocumentSubType} from '../../../generated/api-stubs';
import {PromptAndSetOwnership} from '../../ownership/prompt-and-set-ownership';
import {ConfigViewModel} from '../config-view-model';
import {StudyViewModel} from '../study-view-model';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class TakeOwnershipCommand extends WorksheetCommand {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly promptAndSetOwnership: PromptAndSetOwnership) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> | ICommandContext<StudyViewModel> {
    const userData = this.authenticationService.userDataSnapshot;

    if(context.isConfigOrStudy()
      && context.isSingleRow
      && context.target.isReadOnly(userData.sub)){

      if(context.isConfig()){
        const reference = context.target.reference;
        if(reference && reference.tenant){
          if(context.target.configType === DocumentSubType.telemetry){
            if(context.target.populated && context.target.populated.populatedStudy.isComplete) {
              return true;
            }
          } else{
            return true;
          }
        }
      } else {
        if(context.target.isComplete) {
          return true;
        }
      }
    }

    return false;
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)){
      return CommandResult.NoUpdate;
    }

    const userData = this.authenticationService.userDataSnapshot;
    let success: boolean = false;
    if(context.isConfig()) {
      success = await this.promptAndSetOwnership.execute(
        context.target.reference.tenant.tenantId,
        context.target.reference.tenant.targetId,
        userData.sub);
    } else {
      success = await this.promptAndSetOwnership.execute(
        context.target.reference.tenantId,
        context.target.reference.targetId,
        userData.sub);
    }

    return success ? CommandResult.UpdateOnly : CommandResult.NoUpdate;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]) {
    if(!this.canExecute(context)){
      return;
    }

    if(context.isConfig()){
      result.push(new ButtonMenuItem<CommandResult>(
        `Take Ownership of ${context.target.unpopulated.configTypeName}`,
        `take-ownership-button`,
        () => this.execute(context),
        'user'));
    } else if(context.isStudy()) {
      result.push(new ButtonMenuItem<CommandResult>(
        `Take Ownership of Study`,
        `take-ownership-button`,
        () => this.execute(context),
        'user'));
    }
  }
}
