@if (dialog) {
  <div class="modal dialog-container dialog-inner-scroll dialog-almost-full-width" id="compare-config-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Compare</h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <div class="scroll-area">
            <cs-compare-configs #compareConfigsComponent
              [configType]="dialog.configType"
              [saveOutputConfigHandler]="saveOutputConfigHandler"
            [configs]="configs"></cs-compare-configs>
            <!--
            <cs-compare-config-deprecated
              *ngIf="!dialog.configType"
              [firstTitle]="dialog.firstTitle"
              [first]="dialog.first"
              [secondTitle]="dialog.secondTitle"
              [second]="dialog.second"
            [elementId]="'dialog-diff'"></cs-compare-config-deprecated>
            -->
          </div>
        </div>
        <div class="modal-footer">
          @if (dialog.configType && compareConfigsComponent.isSafeToSaveOutput) {
            <div>
              @if (canSave) {
                <button class="btn btn-smart btn-secondary" id="save-output-button" (click)="save()">{{dialog.saveOutputButtonText}}</button>
              }
              <button class="btn btn-smart btn-secondary" id="save-output-as-button" (click)="saveAs()">Save Output As</button>
              <button class="btn btn-smart btn-secondary" id="stage-output-button" (click)="stage()">Stage Output</button>
            </div>
          }
          <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Close</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->
