import {Component, OnInit} from '@angular/core';
import {InferableRouteParams} from '../../common/inferable-route-params.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Timer} from '../../common/timer.service';

export const WORKSHEET_ID_URL_PARAMETER_KEY: string = 'worksheetId';

@Component({
  templateUrl: './edit-worksheet.page.html',
  styleUrls: ['./edit-worksheet.page.scss']
})
export class EditWorksheetPage implements OnInit {

  public tenantId: string;
  public worksheetId: string;

  public hideRoute: boolean = false;
  public hasChildRoute: boolean = false;

  public worksheetUrl: ReadonlyArray<string>;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    inferableRouteParams: InferableRouteParams,
    private readonly timer: Timer) {
    this.tenantId = inferableRouteParams.getTenantId(route);
    this.worksheetId = route.snapshot.params[WORKSHEET_ID_URL_PARAMETER_KEY];

    this.worksheetUrl = this.route.snapshot.url.map(v => v.path);
    this.hasChildRoute = route.children.length > 0;
    this.router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {
        this.hideRoute = true;
        await this.timer.yield();
        this.hideRoute = false;

        this.hasChildRoute = route.children.length > 0;
      }
    });
  }

  public async ngOnInit(){
    this.hideRoute = true;
    await this.timer.yield();
    this.hideRoute = false;
  }

  public dismissChildRoute(){
    this.router.navigate([...this.worksheetUrl]);
  }
}
