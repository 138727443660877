import * as d3 from '../../d3-bundle';
import { LinePlotViewerSettings } from './line-plot-viewer-settings';
import { DataRenderer, LineViewerGetInterpolatedChannelValueAtDomainValue } from './data-renderer';
import { NavigationStationViewer } from '../../navigation-station/navigation-station-viewer';
import { SiteHooks } from '../../site-hooks';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { MultiPlotViewerBase } from '../multi-plot-viewer-base/multi-plot-viewer-base';
import { IMultiPlotLayout } from '../data-pipeline/types/i-multi-plot-layout';
import { MultiPlotViewerSettings } from '../multi-plot-viewer-base/multi-plot-viewer-settings';
import { DomainSnapBehaviour, MultiPlotDataRendererBase } from '../multi-plot-viewer-base/multi-plot-data-renderer-base';
import { SharedState } from '../shared-state';
import { ZoomType } from '../multi-plot-viewer-base/zoom-renderer';
import { DataPipeline } from '../data-pipeline/data-pipeline';

export const POINT_MULTI_PLOT_VIEWER_TYPE = 'pointMultiPlotViewer';

export class LinePlotViewer extends MultiPlotViewerBase implements NavigationStationViewer {

  private readonly overrideLineCurve?: d3.CurveFactory;

  constructor(
    primaryDomainName: string,
    layout: IMultiPlotLayout,
    channelNameStyle: ChannelNameStyle,
    dataPipeline: DataPipeline,
    sharedState: SharedState,
    siteHooks: SiteHooks,
    private interpolator: LineViewerGetInterpolatedChannelValueAtDomainValue) {
    super(
      primaryDomainName,
      layout,
      channelNameStyle,
      dataPipeline,
      sharedState,
      siteHooks);

    let overrideLineCurveType = (<any>this.layout).overrideLineCurve;
    if (overrideLineCurveType) {
      this.overrideLineCurve = (<any>d3)[overrideLineCurveType];
    }
  }

  public static createLinePlotViewer(
    primaryDomainName: string,
    layout: IMultiPlotLayout,
    channelNameStyle: ChannelNameStyle,
    sharedState: SharedState,
    siteHooks: SiteHooks): LinePlotViewer {

    let interpolator = new LineViewerGetInterpolatedChannelValueAtDomainValue();
    return new LinePlotViewer(
      primaryDomainName,
      layout,
      channelNameStyle,
      DataPipeline.create(primaryDomainName, siteHooks, sharedState.sourceLoaderSet, channelNameStyle, interpolator),
      sharedState,
      siteHooks,
      interpolator);
  }

  protected getCssClass(): string {
    return 'line-plot-viewer';
  }

  createSettings(sourceCount: number): MultiPlotViewerSettings {
    return LinePlotViewerSettings.build(sourceCount);
  }

  createDataRenderer(): MultiPlotDataRendererBase {
    let dataRenderer = DataRenderer.create(this.interpolator);

    if (!this.overrideLineCurve || this.overrideLineCurve === d3.curveLinear) {
      dataRenderer.domainSnapBehaviour(DomainSnapBehaviour.linearInterpolation);
    } else {
      dataRenderer.curve(this.overrideLineCurve);
      dataRenderer.domainSnapBehaviour(DomainSnapBehaviour.nearest);
    }

    return dataRenderer;
  }

  protected onBuilt() {
    super.onBuilt();

    if (this.zoomRenderer) {
      this.zoomRenderer.zoomType(ZoomType.x);
    }
  }
}
