import {Injectable} from '@angular/core';
import {ConfigReference, DocumentSubType, WorksheetConfig, WorksheetRow, WorksheetStudy} from '../../../generated/api-stubs';
import {ReplaceResult} from '../worksheet-item-component-base';
import {ClipboardContent} from '../worksheet-clipboard.service';
import {ConfirmReplaceAllReferences} from './confirm-replace-all-references.service';
import {CommandContext} from './worksheet-command';

@Injectable()
export class PasteContent {

  constructor(
    private readonly confirmReplaceAllReferences: ConfirmReplaceAllReferences){
  }

  public async execute(context: CommandContext, content: ClipboardContent, forceDuplication: boolean) {


    const selectedRows = context.selectedRows;
    if(selectedRows.length === 0){
      return;
    }
    let isSingleTarget = selectedRows.length === 1;

    let isSingleSource: boolean = false;
    if (context.isStudy()) {
      // SCENARIO: If we are pasting on a study cell, and all copied cells are telemetry configs, move them across.
      if(content.rows.every(v => (!v.study || !v.study.reference) && v.configs && v.configs.length === 1 && v.configs[0].configType === DocumentSubType.telemetry)) {
        content = content.withRows(
          content.rows.map(v => ({
            name: undefined,
            configs: undefined,
            study: this.getStudyFromClipboardRow(v),
          })));
      }

      // SCENARIO: A single study is being pasted.
      isSingleSource = content.rows.length === 1
        && (!content.rows[0].configs || content.rows[0].configs.length === 0)
        && !!content.rows[0].study && !!content.rows[0].study.reference;
    } else if(context.isConfig()){
      if(context.target.isTelemetry) {
        // SCENARIO: If we are pasting on a telemetry cell, and all copied cells are studies, move them across.
        if(content.rows.every(v => (!v.configs || v.configs.length === 0) && !!v.study && !!v.study.reference)) {
          content = content.withRows(
            content.rows.map(v => ({
              name: undefined,
              configs: this.getTelemetryConfigFromClipboardRow(v),
              study: undefined,
            })));
        }
      }

      // SCENARIO: A single config is being pasted.
      isSingleSource = !!(content.rows.length === 1
        && content.rows[0].configs
        && content.rows[0].configs.length === 1
        && (!content.rows[0].study || !content.rows[0].study.reference));
    } else if(context.isRowMetadata()) {
      // SCENARIO: A single row is being pasted.
      isSingleSource = content.rows.length === 1;
    }

    let replaceAllReferencesSource: ConfigReference;
    if(context.isConfig()) {
      if(isSingleSource && isSingleTarget){
        // SCENARIO: Copy single source on to a single target whose existing reference is shared by other cells.
        let replaceResult = await this.confirmReplaceAllReferences.execute(context.target, selectedRows);
        if(replaceResult === ReplaceResult.cancel){
          return;
        } else if(replaceResult === ReplaceResult.replaceAllReferences){
          replaceAllReferencesSource = context.target.reference;
        }
      }
    }

    const removeExisting = context.isRowMetadata();

    if(isSingleSource) {
      for(let row of selectedRows){
        await row.pasteRowsAsync(content, forceDuplication, removeExisting);
      }
    } else {
      await selectedRows[0].pasteRowsAsync(content, forceDuplication, removeExisting);
    }

    if(replaceAllReferencesSource && context.isConfig() && context.target.isPopulated) {
      await context.target.replaceSpecificConfigs(replaceAllReferencesSource, context.target.reference);
    }
  }

  private getStudyFromClipboardRow(row: WorksheetRow): WorksheetStudy {
    if(row.study && row.study.reference){
      return row.study;
    }

    let telemetryConfig = row.configs.find(c => c.configType === DocumentSubType.telemetry);
    if(telemetryConfig && telemetryConfig.reference && telemetryConfig.reference.tenant) {
      return {
        reference: telemetryConfig.reference.tenant
      };
    }

    return undefined;
  }

  private getTelemetryConfigFromClipboardRow(row: WorksheetRow): WorksheetConfig[] {
    let telemetryConfig = row.configs.find(c => c.configType === DocumentSubType.telemetry);
    if(telemetryConfig && telemetryConfig.reference) {
      return [telemetryConfig];
    }

    if(row.study && row.study.reference) {
      return [{
        configType: DocumentSubType.telemetry,
        reference: {
          tenant: row.study.reference,
        },
        inheritReference: false,
      }];
    }

    return undefined;
  }
}
