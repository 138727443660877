import { Injectable } from '@angular/core';
import { CommandContext, CommandResult, ICommandContext, WorksheetCommand } from './worksheet-command';
import { MenuItem, ButtonMenuItem, SubMenuItem } from '../../context-menu/context-menu-types';
import { StudyViewModel } from '../study-view-model';
import { DownloadStudy } from '../../simulations/studies/download-study.service';

@Injectable()
export class DownloadResultsCommand extends WorksheetCommand {

  constructor(private readonly downloadStudy: DownloadStudy) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<StudyViewModel> {
    return context.isStudy()
      && context.isSingleRow
      && context.target.isComplete;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    let downloadTypes: MenuItem<CommandResult>[] = [];
    let study = context.target.row.study;
    let studyData = study.resolvedReference.data;
    for(let downloadType of this.downloadStudy.downloadStudyTypes(study.reference.tenantId, study.reference.targetId, studyData.name, studyData.studyDocument.jobCount, studyData.simTypes)){
      downloadTypes.push(new ButtonMenuItem<CommandResult>(
        downloadType.displayName,
        '',
        async () => {
          await downloadType.execute();
          return CommandResult.NoUpdate;
        },
        'download'));
    }

    result.push(new SubMenuItem<CommandResult>(
      'Download Results',
      '',
      downloadTypes,
      true));
  }
}
