import * as d3octree from 'd3-octree';
import * as d3 from '../../d3-bundle';
import { DomainNewsEvent, SharedState } from '../shared-state';
import {
  Box3,
  BufferAttribute,
  BufferGeometry,
  Color,
  ConeGeometry,
  DoubleSide,
  Float32BufferAttribute,
  Group,
  Line,
  Line3,
  LineBasicMaterial,
  LineBasicMaterialParameters,
  LineDashedMaterial,
  Mesh,
  MeshLambertMaterial,
  MeshLambertMaterialParameters,
  MeshPhongMaterial,
  Object3D,
  Plane,
  Raycaster,
  SphereGeometry,
  Uint16BufferAttribute,
  Vector2,
  Vector3
} from 'three';
import SpriteText from 'three-spritetext';

import { ConfigRendererBase, RenderedConfig } from '../3d/config-renderer-base';
import { System } from '../3d/system';
import { ALTERNATE_SOURCE_FOR_DEBUGGING, ExtractTrackData, TrackSource } from './extract-track-data';
import { TrackCoordinate } from '../3d/track-coordinate';
import { TrackPath } from './track-path';
import { TrackData } from './track-data';
import { SearchDirection, Utilities } from '../../utilities';

import { SourceData } from '../data-pipeline/types/source-data';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { BLUE, GREEN, SLAPCENTRELINE_DOMAIN_NAME } from '../../constants';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';
import { Units } from '../../units';
import { modulo } from '../../modulo';
import { isNumber } from '../../is-number';

const Z_VISIBILITY_ADJUSTMENT = 0.01;

class ProcessableDomainEvent {
  constructor(
    public readonly event?: DomainNewsEvent) {
  }

  public processed: boolean = false;
}

export class TrackRenderer extends ConfigRendererBase implements System {

  private getInterpolatedChannelValueAtDomainValue: GetInterpolatedChannelValueAtDomainValue;
  private sLapEvent: ProcessableDomainEvent = new ProcessableDomainEvent(undefined);
  private trackDataByIndex: { [index: number]: TrackRenderData } = {};
  private groundPlane = new Plane(new Vector3(0, 1, 0), 0);
  private sourceData?: ReadonlyArray<SourceData>;
  private layout?: IPopulatedMultiPlotLayout;

  constructor(
    sharedState: SharedState,
    private readonly extractTrackData: ExtractTrackData) {
    super(sharedState);

    this.getInterpolatedChannelValueAtDomainValue = new GetInterpolatedChannelValueAtDomainValue();
  }

  public performBuild() {
    this.subscriptions.add(this.sharedState.sLapCursorNews.subscribe(v => {
      this.sLapEvent = new ProcessableDomainEvent(v);
    }));
  }

  public setLayout(layout: IPopulatedMultiPlotLayout): this {
    this.layout = layout;
    return this;
  }

  public setChannelData(sourceData: ReadonlyArray<SourceData>) {
    this.sourceData = sourceData;
    this.updateAll = true;
  }

  private getEventValues(event: DomainNewsEvent | undefined) {
    if (event) {
      return [...event.sourceValues];
    } else {
      return new Array(this.configs.length).fill(0);
    }
  }

  public performUpdate(mouseRaycaster: Raycaster | undefined) {
    if (mouseRaycaster) {
      let broadcast = false;

      let sLapValues: number[] = this.getEventValues(this.sLapEvent.event);
      let sLapCentreLineValues: number[] = this.getEventValues(
        this.sLapEvent.event && this.sLapEvent.event.secondary
          ? this.sLapEvent.event.secondary.event
          : undefined);

      let zeroPlaneIntersection = new Vector3();
      mouseRaycaster.ray.intersectPlane(this.groundPlane, zeroPlaneIntersection);
      for (let sourceIndex = 0; sourceIndex < this.configs.length; ++sourceIndex) {
        let config = this.configs[sourceIndex];
        if (!config) {
          continue;
        }

        let trackData = this.trackDataByIndex[sourceIndex];
        if (!trackData) {
          continue;
        }

        let renderedConfig = this.renderedConfigs[config.id];
        if (!renderedConfig) {
          continue;
        }

        let point: Vector3;
        let intersects = mouseRaycaster.intersectObjects(trackData.intersectGroup.children, true);
        if (intersects.length) {
          let intersection = intersects[0];
          point = intersection.point;
        } else {
          point = zeroPlaneIntersection;
        }

        if (!point) {
          continue;
        }

        let minimumIndex = trackData.findNearest(point);
        if (minimumIndex !== -1) {
          // We always want to send sLap to support QSL which doesn't have an sRunCentreLine channel.
          sLapValues[sourceIndex] = trackData.trackData.sLap[minimumIndex];
          if (trackData.trackData.sLapCentreLine.length) {
            sLapCentreLineValues[sourceIndex] = trackData.trackData.sLapCentreLine[minimumIndex];
          } else {
            sLapCentreLineValues[sourceIndex] = NaN;
          }
          // if(trackData.trackData.sLapCentreLine.length){
          //   sLapValues[sourceIndex] = NaN;
          //   sLapCentreLineValues[sourceIndex] = trackData.trackData.sLapCentreLine[minimumIndex];
          // }
          // else{
          //   sLapValues[sourceIndex] = trackData.trackData.sLap[minimumIndex];
          //   sLapCentreLineValues[sourceIndex] = NaN;
          // }
          broadcast = true;
        }
      }

      if (broadcast) {
        this.sharedState.sLapCursorSetAll(sLapValues, sLapCentreLineValues);
      }
    }

    if (this.configs) {
      if (!this.sLapEvent.processed) {
        this.sLapEvent.processed = true;
        for (let sourceIndex = 0; sourceIndex < this.configs.length; ++sourceIndex) {
          let config = this.configs[sourceIndex];
          if (config && config.data) {
            let trackRenderData = this.trackDataByIndex[sourceIndex];
            if (trackRenderData) {

              let sLapData = trackRenderData.trackData.sLap;
              let event = this.sLapEvent.event;
              let sLapValue = event ? event.getSourceValue(sourceIndex) : 0;

              if (trackRenderData.trackData.sLapCentreLine.length
                && event && event.secondary && event.secondary.event
                && event.secondary.domainName === SLAPCENTRELINE_DOMAIN_NAME) {

                let newValue = event.secondary.event.getSourceValue(sourceIndex);
                if (!isNaN(newValue)) {
                  sLapValue = newValue;
                  sLapData = trackRenderData.trackData.sLapCentreLine;
                  event = event.secondary.event;
                }
              }

              let sLapMax = sLapData[sLapData.length - 1];
              sLapValue = modulo(sLapValue, sLapMax);
              let dataIndex = Utilities.findIndexInMonotonicallyIncreasingData(sLapData, sLapValue, SearchDirection.Forwards);
              if (dataIndex < 0 || dataIndex >= sLapData.length) {
                dataIndex = 0;
              }
              let coordinate = trackRenderData.trackData.carPath.getWorldCoordinate(dataIndex);
              let normal = trackRenderData.trackData.carNormals.getWorldCoordinate(dataIndex);
              let cursor = trackRenderData.cursor;

              cursor.position.copy(coordinate);

              let axis = new Vector3(0, 1, 0);
              cursor.quaternion.setFromUnitVectors(axis, normal);
            }
          }
        }
      }
    }
  }

  // private getClosestIndex(point: Vector3, coordinates: ReadonlyArray<TrackCoordinate>) {
  //   let minimumDistance = -1;
  //   let minimumIndex = -1;
  //   let reference = new Vector3(); // We don't want to create a new vector for each coordinate when doing this.
  //   let index = 0;
  //   for(let coordinate of coordinates){
  //     reference.set(coordinate.worldX, coordinate.worldY, coordinate.worldZ);
  //     let distance = reference.distanceTo(point);
  //     if(minimumIndex === -1 || distance < minimumDistance){
  //       minimumIndex = index;
  //       minimumDistance = distance;
  //     }
  //
  //     ++index;
  //   }
  //
  //   return minimumIndex;
  // }

  private getCoordinateForDistance(referenceDistance: number, distanceVector: ReadonlyArray<number>, coordinates: TrackPath): Vector3 {

    if (!coordinates.trackCoordinates.length) {
      return new Vector3(0, 0, 0);
    }

    if (!distanceVector.length) {
      return coordinates.getWorldCoordinate(0);
    }

    referenceDistance = modulo(referenceDistance, distanceVector[distanceVector.length - 1]);

    for (let i = 0; i < distanceVector.length; ++i) {
      const distance = distanceVector[i];
      if (distance === referenceDistance) {
        return coordinates.getWorldCoordinate(i);
      }

      if (distance > referenceDistance) {
        if (i === 0) {
          return coordinates.getWorldCoordinate(0);
        }

        const beforeDistance = distanceVector[i - 1];
        const beforeCoordinate = coordinates.getWorldCoordinate(i - 1);
        const afterDistance = distanceVector[i];
        const afterCoordinate = coordinates.getWorldCoordinate(i);

        const ratio = (referenceDistance - beforeDistance) / (afterDistance - beforeDistance);

        const worldLine = new Line3(beforeCoordinate, afterCoordinate);
        const result = new Vector3();
        worldLine.at(ratio, result);
        return result;
      }
    }

    return coordinates.getWorldCoordinate(0);
  }

  protected renderConfig(sourceIndex: number, data: any): RenderedConfig {
    let trackData = ALTERNATE_SOURCE_FOR_DEBUGGING
      ? this.extractTrackData.execute(data, <TrackSource>(modulo(sourceIndex, 4)))
      : this.extractTrackData.execute(data);

    let group = new Group();
    let intersectGroup = new Group();
    group.add(intersectGroup);

    let colorString = this.settings.getChannelColor(0, sourceIndex);
    let color = new Color(colorString);

    let roadColor = new Color(0xaaaaaa);
    let roadEdgeColor = new Color(0x777777);
    this.drawTrack(intersectGroup, trackData.innerEdge, trackData.outerEdge, roadColor, roadEdgeColor);
    this.drawRacingLine(sourceIndex, group, trackData.carPath, trackData.carNormals, color, trackData.sLap);
    this.drawZerothTrackOutlineVertexLine(group, trackData);
    let radius = this.drawStartFinishLine(group, trackData, roadEdgeColor.clone().offsetHSL(0, 0, -0.1));
    this.drawStartFinishOffsets(group, trackData, sourceIndex, color, radius);

    let cursorGroup = this.drawCursor(group, color.clone().offsetHSL(0, 0, -0.1), radius);

    this.trackDataByIndex[sourceIndex] = new TrackRenderData(trackData, cursorGroup, intersectGroup);
    return new RenderedConfig(sourceIndex, group);
  }

  private drawCursor(group: Group, color: Color, radius: number): Group {
    let cursorGroup = new Group();
    group.add(cursorGroup);
    let geometry = new SphereGeometry(radius, 16, 16, 0, Math.PI, 0, Math.PI);
    let material = new MeshPhongMaterial({ color, side: DoubleSide, flatShading: false, specular: 0x444444 });
    let marker = new Mesh(geometry, material);
    marker.rotateX(-Math.PI / 2);
    cursorGroup.add(marker);
    return cursorGroup;
  }

  private drawZerothTrackOutlineVertexLine(group: Group, trackData: TrackData) {
    if (!trackData.innerEdge.trackCoordinates.length || !trackData.outerEdge.trackCoordinates.length) {
      return;
    }

    let innerPosition = trackData.innerEdge.getWorldCoordinate(0);
    let outerPosition = trackData.outerEdge.getWorldCoordinate(0);
    let dashSize = innerPosition.distanceTo(outerPosition) / 9;

    let materialDashed = new LineDashedMaterial({ color: 0xFF0000, dashSize, gapSize: dashSize });

    let startReferenceLineGeometry = new BufferGeometry();
    startReferenceLineGeometry.setAttribute('position', new BufferAttribute(
      new Float32Array([
        innerPosition.x, innerPosition.y, innerPosition.z,
        outerPosition.x, outerPosition.y, outerPosition.z
      ]), 3));
    startReferenceLineGeometry.getAttribute('position').needsUpdate = true;
    let startReferenceLine = new Line(startReferenceLineGeometry, materialDashed);
    startReferenceLine.computeLineDistances();
    group.add(startReferenceLine);
  }

  private drawStartFinishOffsets(group: Group, trackData: TrackData, sourceIndex: number, color: Color, carRadius: number) {


    const startFinishOffset = trackData.startFinishOffset;
    if (startFinishOffset === 0) {
      return;
    }

    // If has centre line, draw centre line s/f offset and label.
    if (trackData.centreLine.trackCoordinates.length) {
      const coordinate = this.getCoordinateForDistance(startFinishOffset, trackData.sLapCentreLine, trackData.centreLine);
      this.drawLabel(group, coordinate, 'Start/Finish Offset (Centre Line)', sourceIndex, 0, color, carRadius);
    }

    // If has racing line, draw racing line s/f offset and label.
    if (trackData.carPath.trackCoordinates.length) {
      const coordinate = this.getCoordinateForDistance(startFinishOffset, trackData.sLap, trackData.carPath);
      this.drawLabel(group, coordinate, 'Start/Finish Offset (Racing Line)', sourceIndex, 1, color, carRadius);
    }
  }

  private drawLabel(group: Group, coordinate: Vector3, label: string, sourceIndex: number, labelIndex: number, color: Color, carRadius: number) {

    // let materialDashed = new LineDashedMaterial({color: color, dashSize: carRadius, gapSize: carRadius });
    let material = new LineBasicMaterial({ color });

    let a = coordinate.clone();
    let b = new Vector3(
      carRadius * 5,
      (carRadius * 2 * (sourceIndex + 1)) + (labelIndex * carRadius),
      sourceIndex * carRadius * -3).add(a);
    let c = new Vector3(
      carRadius * 5,
      0,
      0).add(b);
    let startReferenceLineGeometry = new BufferGeometry();
    startReferenceLineGeometry.setAttribute('position', new BufferAttribute(
      new Float32Array([
        a.x, a.y, a.z,
        c.x, c.y, c.z
      ]), 3));
    startReferenceLineGeometry.getAttribute('position').needsUpdate = true;
    let startReferenceLine = new Line(startReferenceLineGeometry, material);
    startReferenceLine.computeLineDistances();
    group.add(startReferenceLine);

    let sprite =  new SpriteText(label, carRadius * 0.75, '0x000000');
    sprite.fontFace = 'Arial, Helvetica, sans-serif';
    sprite.center.copy(new Vector2(0, 0.5));
    sprite.position.copy(c);
    group.add(sprite);
  }

  private drawStartFinishLine(group: Group, trackData: TrackData, color: Color): number {

    let carPath = trackData.carPath;
    if (!carPath.trackCoordinates.length) {
      carPath = trackData.innerEdge;
    }

    if (!carPath.trackCoordinates.length) {
      return 1;
    }

    let firstCoordinate = carPath.getWorldCoordinate(0);
    let nextCoordinate = carPath.getWorldCoordinate(d3.minStrict([carPath.trackCoordinates.length - 1, 10]));

    let radius: number;
    let position: Vector3;
    if (trackData.innerEdge.trackCoordinates.length && trackData.outerEdge.trackCoordinates.length) {
      let innerPosition = trackData.innerEdge.getWorldCoordinate(0);
      let outerPosition = trackData.outerEdge.getWorldCoordinate(0);
      position = new Vector3(
        (innerPosition.x + outerPosition.x) / 2,
        (innerPosition.y + outerPosition.y) / 2,
        (innerPosition.z + outerPosition.z) / 2);
      radius = innerPosition.distanceTo(outerPosition) / 2;
    } else {
      position = firstCoordinate.clone();
      radius = 5;
    }

    radius = Math.max(radius, 5);

    let height = radius * 3;

    let geometry = new ConeGeometry(radius, height, 16);
    let material = new MeshPhongMaterial({ color, flatShading: false, transparent: true, opacity: 0.6, specular: 0x444444 });
    let marker = new Mesh(geometry, material);

    let requiredOffset = new Box3().setFromObject(marker).min.y * -1;

    marker.position.copy(position);

    if (nextCoordinate.equals(firstCoordinate)) {
      nextCoordinate = firstCoordinate.clone().setX(firstCoordinate.x - 1);
    }

    let direction = nextCoordinate.sub(firstCoordinate).normalize();
    let axis = new Vector3(0, 1, 0);
    marker.quaternion.setFromUnitVectors(axis, direction);

    marker.position.add(direction.clone().multiplyScalar(requiredOffset));

    group.add(marker);

    return radius;
  }

  private drawRacingLine(sourceIndex: number, group: Group, path: TrackPath, normals: TrackPath, color: Color, sLap: ReadonlyArray<number>) {
    if (!path.trackCoordinates.length) {
      return;
    }

    let racingLineGroup = new Group();
    group.add(racingLineGroup);
    racingLineGroup.position.setY(Z_VISIBILITY_ADJUSTMENT); // Lift the racing line off the track slightly.

    const ribbonSize = 3;

    let selectColor: ((index: number) => Readonly<[number, number, number]>) | undefined;
    let selectHeight: (index: number) => number = () => ribbonSize;
    if (this.sourceData && this.layout) {
      let sourceDataItem = this.sourceData[sourceIndex];
      if (sourceDataItem) {
        let sLapData = sourceDataItem.channels.get(this.layout.processed.primaryDomain.name);
        if (sLapData) {
          let colorChannel = this.layout.processed.colorChannel;
          if (colorChannel) {
            let colorData = colorChannel.sources[sourceIndex];
            if (colorData) {
              let colorScale = d3.scaleLinear<string>()
                .domain([colorChannel.minimum, colorChannel.maximum])
                .range([BLUE, GREEN])
                .interpolate(d3.interpolateHcl);

              selectColor = index => {
                let sLapValue = sLap[index];
                sLapValue = Units.convertValueFromSi(sLapValue, sLapData.units);
                let value = this.getInterpolatedChannelValueAtDomainValue.execute(colorData.data, sLapValue, sLapData.data, sLapData.monotonicStatus);
                let c = new Color(colorScale(value));
                return [c.r, c.g, c.b];
              };
            }
          }

          let heightChannel = this.layout.processed.sizeChannel;
          if (heightChannel) {
            let heightData = heightChannel.sources[sourceIndex];
            if (heightData) {
              let minimumHeight = heightChannel.minimum;
              let maximumHeight = heightChannel.maximum;
              selectHeight = index => {
                let sLapValue = sLap[index];
                sLapValue = Units.convertValueFromSi(sLapValue, sLapData.units);
                let value = this.getInterpolatedChannelValueAtDomainValue.execute(heightData.data, sLapValue, sLapData.data, sLapData.monotonicStatus);
                return ribbonSize + 6 * ribbonSize * ((value - minimumHeight) / (maximumHeight - minimumHeight));
              };
            }
          }
        }
      }
    }

    let topPath = new TrackPath(path.trackCoordinates.map(
      (v, i) => {
        let height = selectHeight(i);
        return new TrackCoordinate(
          v.x + height * normals.trackCoordinates[i].x,
          v.y + height * normals.trackCoordinates[i].y,
          v.z + height * normals.trackCoordinates[i].z / path.elevationScale);
      }));
    topPath.adjustWorldElevationCoordinates(path.elevationScale, path.elevationOffset);

    this.drawStrip(racingLineGroup, path, topPath, color, new Color(color).offsetHSL(0, 0, -0.1), false, selectColor, true);
  }

  private drawTrack(group: Group, inner: TrackPath, outer: TrackPath, roadColor: Color, edgeColor: Color) {
    this.drawStrip(group, inner, outer, roadColor, edgeColor);

    if (inner.trackCoordinates.length === outer.trackCoordinates.length) {
      let centerLine = new TrackPath(inner.trackCoordinates.map((v, i) => new TrackCoordinate(
        (v.x + outer.trackCoordinates[i].x) / 2,
        (v.y + outer.trackCoordinates[i].y) / 2,
        ((v.z + outer.trackCoordinates[i].z) / 2) - 10 * Z_VISIBILITY_ADJUSTMENT)));
      centerLine.adjustWorldElevationCoordinates(inner.elevationScale, inner.elevationOffset);

      let centerLineAtZero = new TrackPath(centerLine.trackCoordinates.map(v => new TrackCoordinate(v.x, v.y, 0 - 10 * Z_VISIBILITY_ADJUSTMENT)));

      this.drawStrip(group, centerLine, centerLineAtZero, new Color(roadColor).offsetHSL(0, 0, 0.15), roadColor, true);
    }
  }

  private drawPerimeterLine(group: Group, path: TrackPath, color: Color) {
    if (!path.trackCoordinates.length) {
      return;
    }

    let geometry = new BufferGeometry();
    let vertices = path.vertices;
    geometry.setAttribute('position', new BufferAttribute(vertices, 3));
    let materialParameters: LineBasicMaterialParameters = {
      color,
    };
    let material = new LineBasicMaterial(materialParameters);
    let line = new Line(geometry, material);
    group.add(line);
  }

  private drawStrip(group: Group, inner: TrackPath, outer: TrackPath, stripColor: Color, edgeColor: Color, noInnerLine: boolean = false,
    getColor: ((index: number) => Readonly<[number, number, number]>) | undefined = undefined,
    isTransparent: boolean = false) {
    if (inner.trackCoordinates.length === outer.trackCoordinates.length
      && !!inner.trackCoordinates.length) {

      let sideVertexCount = inner.trackCoordinates.length;
      let sideCoordinateCount = sideVertexCount * 3;
      let totalVertexCount = sideVertexCount * 2;
      let totalCoordinateCount = totalVertexCount * 3;

      const maxVertices = 65536;
      const maxVerticesPerSide = maxVertices / 2;
      if (sideVertexCount > maxVerticesPerSide) {
        let remainingSideVertices = inner.trackCoordinates.length;
        let startIndex = 0;
        while (remainingSideVertices > 0) {
          let endIndexExclusive = startIndex + maxVerticesPerSide;
          if (endIndexExclusive > inner.trackCoordinates.length) {
            endIndexExclusive = inner.trackCoordinates.length;
          }
          let subInner = new TrackPath(inner.trackCoordinates.slice(startIndex, startIndex + maxVerticesPerSide));
          let subOuter = new TrackPath(outer.trackCoordinates.slice(startIndex, startIndex + maxVerticesPerSide));

          this.drawStrip(group, subInner, subOuter, stripColor, edgeColor, noInnerLine, getColor, isTransparent);
          remainingSideVertices -= maxVerticesPerSide;
          startIndex = endIndexExclusive;
        }

        return;
      }

      let allVertices = new Float32Array(totalCoordinateCount);
      allVertices.set(inner.vertices, 0);
      allVertices.set(outer.vertices, sideCoordinateCount);

      let vertexColors: Float32Array | undefined;
      if (getColor) {
        vertexColors = new Float32Array(totalCoordinateCount);
        const defaultColor = [stripColor.r, stripColor.g, stripColor.b] as Readonly<[number, number, number]>;
        for (let colorIndex = 0, innerIndex = 0, outerIndex = sideCoordinateCount; colorIndex < sideVertexCount; ++colorIndex, innerIndex += 3, outerIndex += 3) {
          let color = getColor(colorIndex) || defaultColor;
          vertexColors[innerIndex] = color[0];
          vertexColors[innerIndex + 1] = color[1];
          vertexColors[innerIndex + 2] = color[2];
          vertexColors[outerIndex] = color[0];
          vertexColors[outerIndex + 1] = color[1];
          vertexColors[outerIndex + 2] = color[2];
        }
      }

      let faceCount = totalVertexCount - 2;
      let indexCount = faceCount * 3;
      let indexes = new Uint16Array(indexCount);
      for (let sideIndex = 0, targetIndex = 0; sideIndex < sideVertexCount; ++sideIndex) {
        if (sideIndex !== 0) {
          indexes[targetIndex++] = sideIndex;
          indexes[targetIndex++] = sideIndex + sideVertexCount;
          indexes[targetIndex++] = sideIndex - 1;
        }

        if (sideIndex !== sideVertexCount - 1) {
          indexes[targetIndex++] = sideIndex;
          indexes[targetIndex++] = sideIndex + sideVertexCount + 1;
          indexes[targetIndex++] = sideIndex + sideVertexCount;
        }
      }

      let geometry = new BufferGeometry();
      geometry.setAttribute('position', new Float32BufferAttribute(allVertices, 3));
      geometry.setIndex(new Uint16BufferAttribute(indexes, 1));

      if (vertexColors) {
        geometry.setAttribute('color', new Float32BufferAttribute(vertexColors, 3));
      }

      geometry.computeVertexNormals();

      let materialParameters: MeshLambertMaterialParameters = {
        side: DoubleSide,
        wireframe: false,
      };

      if (isTransparent) {
        materialParameters.transparent = true;
        materialParameters.opacity = 0.6;
      }

      if (getColor) {
        materialParameters.vertexColors = true;
      } else {
        materialParameters.color = stripColor;
      }

      let material = new MeshLambertMaterial(materialParameters);
      let mesh = new Mesh(geometry, material);
      group.add(mesh);
    }

    if (edgeColor) {
      let perimeterLineGroup = new Group();
      group.add(perimeterLineGroup);
      perimeterLineGroup.position.setY(Z_VISIBILITY_ADJUSTMENT); // Lift the racing line off the track slightly.

      if (!noInnerLine) {
        this.drawPerimeterLine(perimeterLineGroup, inner, edgeColor);
      }

      this.drawPerimeterLine(perimeterLineGroup, outer, edgeColor);
    }
  }
}

class TrackRenderData {

  private _worldTrackCoordinatesOctree: any;

  constructor(
    public readonly trackData: TrackData,
    public readonly cursor: Object3D,
    public readonly intersectGroup: Group) {

    this._worldTrackCoordinatesOctree = d3octree.octree()
      .x((v: QuadTreeItem) => v.coordinate.worldX)
      .y((v: QuadTreeItem) => v.coordinate.worldY)
      .z((v: QuadTreeItem) => v.coordinate.worldZ)
      .addAll(trackData.carPath.trackCoordinates
        .map((v, i) => ({
          coordinate: v,
          index: i
        }))
        .filter(v => isNumber(v.coordinate.x) && isNumber(v.coordinate.y) && isNumber(v.coordinate.z)));
  }

  public findNearest(point: Vector3) {
    let result = this._worldTrackCoordinatesOctree.find(point.x, point.y, point.z);
    if (result) {
      return result.index;
    }

    return -1;
  }
}

interface QuadTreeItem {
  coordinate: TrackCoordinate;
  index: number;
}
