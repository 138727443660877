import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class HiddenEnumEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.hiddenEnum = JSONEditor.defaults.editors.string.extend({
      build() {
        let self = <any>this;
        if (!this.options.compact) {
          this.header = this.label = this.theme.getFormInputLabel(this.getTitle());
        }
        if (this.schema.description) {
          this.description = this.theme.getFormInputDescription(this.schema.description);
        }
        this.format = this.schema.format;
        this.input_type = 'hidden';
        this.input = this.theme.getFormInputField(this.input_type);

        this.control = this.theme.getFormControl(this.label, this.input, this.description);
        self.container.appendChild(this.control);
        self.refreshValue();
      },
      setValue(val: any, initial: any, from_template: boolean) {
        // Only set the value if we are setting the initial value.
        // Hidden enums can only have one value, so a branches value should never
        // be changed after it has been created. If it looks like it should change,
        // then the wrong branch must have been selected.
        // This allows us to safely use keep_oneof_values.
        if (!this.value) {
          this._super(val, initial, from_template);
        }
      }
    });
  }
}
