import { ExtractSuspensionDataFromCar } from './extract-suspension-data-from-car';
import {
  Color,
  BufferGeometry,
  Group,
  Line,
  LineBasicMaterial,
  LineDashedMaterial,
  Material,
  Mesh,
  MeshPhongMaterial,
  Raycaster,
  SphereGeometry, Object3D, BufferAttribute
} from 'three';
import { System } from '../3d/system';
import { SharedState } from '../shared-state';
import { SelectedComponentsRenderer } from './selected-components-renderer';
import { firstDistinct } from '../../first-distinct';
import { ConfigRendererBase, RenderedConfig } from '../3d/config-renderer-base';
import { SuspensionAreaData, SuspensionAreas, SuspensionMember, SuspensionMemberType } from './car-types';


export class RenderedSuspensionMember {
  constructor(
    public readonly suspensionArea: SuspensionAreaData,
    public readonly suspensionMember: SuspensionMember,
    public readonly colorString: string) {
  }
}

export class SuspensionRenderer extends ConfigRendererBase implements System {
  private selectedComponentsRenderer?: SelectedComponentsRenderer;
  constructor(
    sharedState: SharedState,
    private readonly extractSuspensionDataFromCar: ExtractSuspensionDataFromCar) {
    super(sharedState);
  }

  private highlightedGroups: ReadonlyArray<Object3D> | undefined;

  public performBuild() {
    this.selectedComponentsRenderer = new SelectedComponentsRenderer(this.settings);
  }

  public performUpdate(mouseRaycaster: Raycaster | undefined) {
    if (!this.selectedComponentsRenderer) {
      return;
    }

    if (mouseRaycaster) {
      let intersectionGroups = this.getIntersectionGroups(mouseRaycaster);

      if (this.highlightedGroups && !this.areIntersectionGroupsEqual(this.highlightedGroups, intersectionGroups)) {
        for (let highlightedGroup of this.highlightedGroups) {
          let member: RenderedSuspensionMember = highlightedGroup.userData.model;
          if (member) {
            this.setMemberColor(highlightedGroup, undefined);
          }
        }
        this.highlightedGroups = undefined;
      }

      if (intersectionGroups && !this.areIntersectionGroupsEqual(intersectionGroups, this.highlightedGroups)) {
        this.highlightedGroups = intersectionGroups;
        for (let intersectionGroup of intersectionGroups) {
          let member: RenderedSuspensionMember = intersectionGroup.userData.model;
          if (member) {
            this.setMemberColor(intersectionGroup, (c: Color) => c.multiplyScalar(0.75));
          }
        }

        this.selectedComponentsRenderer.selectedComponents(this.highlightedGroups).render(this.svg);
      }
    }
  }

  private areIntersectionGroupsEqual(a: ReadonlyArray<Object3D> | undefined, b: ReadonlyArray<Object3D> | undefined): boolean {
    if (!a && !b) {
      return true;
    }

    if (!a || !b) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    return a.every(v => b.indexOf(v) !== -1) && b.every(v => a.indexOf(v) !== -1);
  }

  private getIntersectionGroups(mouseRaycaster: Raycaster | undefined): ReadonlyArray<Object3D> {
    if (mouseRaycaster) {
      let intersects = mouseRaycaster.intersectObjects(this.group.children, true);
      intersects = intersects.filter(v => {
        if (v.object.userData.ignoreIntersection) {
          return false;
        }

        let visible = true;
        v.object.traverseAncestors(a => visible = visible && a.visible);
        return visible;
      });
      if (intersects && intersects.length) {
        return firstDistinct(intersects.map(v => v.object.parent), v => v);
      }
    }

    return [];
  }

  private setMemberColor(group: Object3D, desiredColor: ((c: Color) => Color) | undefined) {
    group.traverse(v => {
      if (v.userData.originalColor) {
        let material: Material = (v as any).material;
        if (material) {
          let color: Color = (material as any).color;
          if (color) {

            if (desiredColor) {
              color.set(desiredColor(new Color(v.userData.originalColor)));
            } else {
              color.set(v.userData.originalColor);
            }
          }
        }
      }
    });
  }

  protected renderConfig(sourceIndex: number, data: any): RenderedConfig {
    let suspensionData = this.extractSuspensionDataFromCar.execute(data);

    let group = new Group();
    for (let areaName of SuspensionAreas) {
      let area = suspensionData.getArea(areaName);
      this.addSuspensionAreaToScene(group, sourceIndex, area);
    }

    return new RenderedConfig(sourceIndex, group);
  }

  private addSuspensionAreaToScene(areaGroup: Group, sourceIndex: number, suspensionArea: SuspensionAreaData) {
    let colorIndex = 0;
    for (let member of suspensionArea.list) {

      let colorString = this.settings.getChannelColor(colorIndex, sourceIndex);
      ++colorIndex;

      if (!member.coordinates || !member.coordinates.length) {
        continue;
      }

      let group = new Group();
      areaGroup.add(group);
      let color = new Color(colorString);
      group.userData.model = new RenderedSuspensionMember(suspensionArea, member, colorString);

      switch (member.type) {
        case SuspensionMemberType.line:
          this.addSuspensionMemberAsLine(color, member, group);
          break;

        case SuspensionMemberType.circle:
          this.addSuspensionMemberAsCircle(color, member, group);
          break;
      }
    }
  }

  private addSuspensionMemberAsCircle(color: Color, member: SuspensionMember, group: Group) {
    if (!member.coordinates || member.coordinates.length !== 2) {
      return;
    }

    const center = member.coordinates[0].worldVector;
    const edge = member.coordinates[1].worldVector;

    const radius = center.distanceTo(edge);
    const segmentCount = 32;
    const geometry = new BufferGeometry();
    const material = new LineBasicMaterial({ color, linewidth: 1 });

    let array = new Float32Array((segmentCount + 1) * 3);
    for (let i = 0; i <= segmentCount; i++) {
      const theta = (i / segmentCount) * Math.PI * 2;
      array[i * 3] = center.x + Math.cos(theta) * radius;
      array[i * 3 + 1] = center.y + Math.sin(theta) * radius;
      array[i * 3 + 2] = center.z;
    }
    geometry.setAttribute('position', new BufferAttribute(array, 3));
    geometry.getAttribute('position').needsUpdate = true;
    group.add(new Line(geometry, material));
  }

  private addSuspensionMemberAsLine(color: Color, member: SuspensionMember, group: Group) {
    if (!member.coordinates) {
      return;
    }

    let referenceLineColor = 0xdddddd;
    let startMaterial = new MeshPhongMaterial({ color, flatShading: false, specular: 0x444444 });
    let endMaterial = new MeshPhongMaterial({
      color,
      flatShading: false,
      specular: 0x444444,
      wireframe: true,
      wireframeLinewidth: 0.1
    });
    let lineMaterial = new LineBasicMaterial({ color, linewidth: 1 });
    let materialDashed = new LineDashedMaterial({ color: referenceLineColor, dashSize: 0.02, gapSize: 0.02 });

    for (let i = 0; i < member.coordinates.length; ++i) {
      let current = member.coordinates[i];

      if (i === 0) {
        let startSphereGeometry = new SphereGeometry(0.012, 16, 16);
        let startSphere = new Mesh(startSphereGeometry, startMaterial);
        startSphere.position.copy(current.worldVector);
        startSphere.userData.originalColor = color;
        group.add(startSphere);

        let startReferenceLineGeometry = new BufferGeometry();
        startReferenceLineGeometry.setAttribute('position', new BufferAttribute(
          new Float32Array([
            current.worldVector.x, current.worldVector.y, current.worldVector.z,
            current.worldVector.x, 0, current.worldVector.z
          ]), 3));
        startReferenceLineGeometry.getAttribute('position').needsUpdate = true;
        let startReferenceLine = new Line(startReferenceLineGeometry, materialDashed);
        startReferenceLine.computeLineDistances();
        startReferenceLine.userData.originalColor = referenceLineColor;
        group.add(startReferenceLine);
      } else {
        let previous = member.coordinates[i - 1];

        let lineGeometry = new BufferGeometry();
        lineGeometry.setAttribute('position', new BufferAttribute(
          new Float32Array([
            previous.worldVector.x, previous.worldVector.y, previous.worldVector.z,
            current.worldVector.x, current.worldVector.y, current.worldVector.z
          ]), 3));
        lineGeometry.getAttribute('position').needsUpdate = true;
        let line = new Line(lineGeometry, lineMaterial);
        line.userData.originalColor = color;
        group.add(line);

        let endReferenceLineGeometry = new BufferGeometry();
        endReferenceLineGeometry.setAttribute('position', new BufferAttribute(
          new Float32Array([
            current.worldVector.x, current.worldVector.y, current.worldVector.z,
            current.worldVector.x, 0, current.worldVector.z
          ]), 3));
        endReferenceLineGeometry.getAttribute('position').needsUpdate = true;
        let endReferenceLine = new Line(endReferenceLineGeometry, materialDashed);
        endReferenceLine.computeLineDistances();
        endReferenceLine.userData.originalColor = referenceLineColor;
        group.add(endReferenceLine);

        let endSphereGeometry = new SphereGeometry(0.012, 10, 10);
        let endSphere = new Mesh(endSphereGeometry, endMaterial);
        endSphere.position.copy(current.worldVector);
        endSphere.userData.originalColor = color;
        group.add(endSphere);
      }
    }
  }
}
