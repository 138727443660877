import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {StudyViewModel} from '../study-view-model';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {StageStudy} from '../../simulations/studies/stage-study.service';
import { ConfigViewModel } from '../config-view-model';

@Injectable()
export class CompareResultsCommand extends WorksheetCommand {

  constructor(
    private readonly stageStudy: StageStudy) {
    super();
  }

  //Don't check target since assumed selected
  public canExecute(context: CommandContext): context is ICommandContext<StudyViewModel | ConfigViewModel> {
    return context.selectedRows.filter(r => r.study.isSelected).every(r => r.study.isComplete) &&
    context.selectedRows.every(r => r.configs.filter(c=>c.isSelected).every(c=> c.isTelemetry && c.isResolved));
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    for(let row of context.selectedRows){
      const study = row.study;
      if (study.isSelected && study.isComplete) {
        this.stageStudy.toResultsStagingArea(
          study.reference.tenantId,
          study.reference.targetId,
          study.resolvedReference.data.name,
          study.resolvedReference.data.studyDocument.jobCount);

      }

      for(let config of row.configs){
        if(config.isSelected && config.isTelemetry){
          this.stageStudy.toResultsStagingArea(
            config.reference.tenant.tenantId,
            config.reference.tenant.targetId,
            config.resolvedReference.data.name,
            1);
        }
      }
    }

    return CommandResult.NoUpdate;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Compare Results`,
      `compare-results-button`,
      () => this.execute(context),
      'area-chart'));
  }
}
