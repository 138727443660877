import { ElementRef, HostListener, Input, OnDestroy, OnInit, Directive, OnChanges } from '@angular/core';
import {RowItemViewModel} from './row-item-view-model';
import {CellElementToViewModelLookup} from './cell-element-to-view-model-lookup';
import {IWorksheetContextMenuBuilder} from './worksheet-context-menu-builder';
import {IMenuItemWrapper, MenuItemsDefinition} from '../context-menu/context-menu-types';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {IWorksheetWrappedContextMenuBuilder} from './worksheet-wrapped-context-menu-builder';
import {CommandResult, UpdateType, WorksheetContext} from './worksheet-commands/worksheet-command';
import { AuthenticationService, UserData } from '../identity/state/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { RowItemUrlService } from './worksheet-commands/row-item-url.service';

export const CLEAR_ICON = 'square-o';
export const DUPLICATE_ICON = 'clone';
export const LOAD_ICON = 'cloud-download';

@Directive()
export abstract class WorksheetItemComponentBase implements OnInit, OnDestroy, OnChanges, IMenuItemWrapper<CommandResult>, IWorksheetWrappedContextMenuBuilder {
  @Input() public readonly isWorksheetInDock: boolean;

  public errorMessage: string;

  public canWrite: boolean;

  public userData: UserData;

  public url: string;

  protected constructor(
    protected readonly authenticationService: AuthenticationService,
    private readonly cellElementToViewModelLookup: CellElementToViewModelLookup,
    private readonly elementRef: ElementRef,
    private readonly contextMenuBuilder: IWorksheetContextMenuBuilder,
    protected readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    protected readonly rowItemUrl: RowItemUrlService,
    protected readonly route: ActivatedRoute) {
  }

  ngOnInit() {
    this.userData = this.authenticationService.userDataSnapshot;
    this.canWrite = this.viewModel.row.worksheet.canWrite(this.userData.sub);
    this.cellElementToViewModelLookup.register(this.elementRef.nativeElement, this.viewModel);
    this.viewModel.setContextMenuBuilder(this);
  }

  ngOnChanges(){
    this.url = this.rowItemUrl.generate(this.viewModel, new WorksheetContext(this.isWorksheetInDock, this.route)).toString();
  }

  ngOnDestroy(){
    this.cellElementToViewModelLookup.unregister(this.elementRef.nativeElement);
    this.viewModel.removeContextMenuBuilder(this);
  }

  public generateWrappedContextMenu(worksheetContext: WorksheetContext): MenuItemsDefinition<CommandResult> {
    return new MenuItemsDefinition<CommandResult>(
      this.contextMenuBuilder.generateContextMenu(this.viewModel, worksheetContext),
      this);
  }

  public async executeMenuItemAction(event: MouseEvent, action: (event: MouseEvent) => Promise<CommandResult>): Promise<CommandResult> {
    try {
      this.resetErrorMessage();
      const result = await action(event);

      if(result){
        if(result.generateColumnsImmediately){
          this.viewModel.row.worksheet.generateColumns();
        }

        if(result.refreshWorksheet === UpdateType.update
          || result.refreshWorksheet === UpdateType.updateAndGenerateColumns) {
          this.viewModel.row.worksheet.requestUpdate(
            result.refreshWorksheet === UpdateType.updateAndGenerateColumns);
          this.url = this.rowItemUrl.generate(this.viewModel, new WorksheetContext(this.isWorksheetInDock, this.route)).toString();
        }
      }

      return result;
    } catch(error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      return undefined;
    }
  }

  public getReferenceCountString(referenceCount: number): string {
    return referenceCount > 1 ? '' + referenceCount : '';
  }

  public getDualReferenceCountString(firstReferenceCount: number, secondReferenceCount: number): string {
    if((firstReferenceCount + secondReferenceCount) <= 1){
      return '';
    }

    if(firstReferenceCount === 0){
      return '' + secondReferenceCount;
    }

    if(secondReferenceCount === 0){
      return '' + firstReferenceCount;
    }

    return '' + firstReferenceCount + '+' + secondReferenceCount;
  }

  public get isTestUser(): boolean{
    return this.authenticationService.isTestUser;
  }

  public resetErrorMessage() {
    this.errorMessage = undefined;
  }

  @HostListener('mouseenter', [])
  public handleMouseEnter() {
    this.viewModel.setItemsMatching();
  }

  @HostListener('mouseleave', [])
  public handleMouseLeave() {
    this.viewModel.clearItemsMatching();
  }

  public onClick(e: MouseEvent): boolean{
    if(e.button !==1){
      e.preventDefault();
      return false;
    }
    return true;
  }

  protected abstract get viewModel(): RowItemViewModel;
}

export enum ReplaceResult {
  cancel,
  replaceThisReference,
  replaceAllReferences,
}

