import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class CheckboxEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.checkbox = JSONEditor.defaults.editors.checkbox.extend({
      build() {
        this._super();
        this.theme.addHelpLink(this.control, this.schema, this);
      }
    });
  }
}
