
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {StudyLoaderDialogData, StudyLoaderResult} from './study-loader-dialog.service';
import {Injectable, EventEmitter} from '@angular/core';
import {GetStudiesQueryResult, ListFilterGroup} from '../../../generated/api-stubs';
import {Timer} from '../../common/timer.service';
import {FilterUtilities} from '../../simulations/list-filtering/filter-utilities';
import {StudySummary} from '../../simulations/studies/view-studies/view-studies.component';

@Injectable()
export class StudyLoaderDialogSessionFactory {
  constructor(
    private filterUtilities: FilterUtilities,
    private timer: Timer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){}

  public create(data: StudyLoaderDialogData): StudyLoaderDialogSession{
    return new StudyLoaderDialogSession(
      data,
      this.filterUtilities,
      this.timer,
      this.getFriendlyErrorAndLog);
  }
}

export class StudyLoaderDialogSession {
  public errorMessage: string;
  public initialQuery: ListFilterGroup;
  public lastResult: GetStudiesQueryResult;
  public studySelected: EventEmitter<StudyLoaderResult> = new EventEmitter<StudyLoaderResult>();
  public isLoading: boolean;

  constructor(
    private dialog: StudyLoaderDialogData,
    private filterUtilities: FilterUtilities,
    private timer: Timer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public async load() {
    try {
      this.errorMessage = undefined;
      if(this.dialog.initialQuery){
        this.initialQuery = this.dialog.initialQuery;
      } else {
        this.initialQuery = this.filterUtilities.getInitialFilter().query;
      }
      await this.timer.yield();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onStudySelected(study: StudySummary) {
    try {
      this.isLoading = true;
      let result: StudyLoaderResult = {
        userId: study.userId,
        studyId: study.studyId,
        name: study.name,
      };

      this.studySelected.emit(result);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isLoading = false;
  }
}

