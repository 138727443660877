import {OptionalColumnItemViewModel} from './worksheet-types';
import {ColumnViewModel} from './column-view-model';

export class ResolvedColumn {

  constructor(
    public readonly definition: ColumnViewModel,
    public readonly value: OptionalColumnItemViewModel) {
  }
}
