import { Injectable } from '@angular/core';
import { RowMetadataViewModel } from '../../row-metadata-view-model';
import { ICommandContext } from '../worksheet-command';
import { StudyTypeLookup } from '../../../simulations/studies/study-type-lookup.service';
import { GetSimVersion } from '../../../common/get-sim-version.service';
import { DocumentSubType, StudyType } from '../../../../generated/api-stubs';
import { ConfigViewModel } from '../../config-view-model';


@Injectable()
export class GetConfigsToImport {

  constructor(
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly getSimVersion: GetSimVersion) {
  }

  public async execute(context: ICommandContext<RowMetadataViewModel | ConfigViewModel>, stagingAreaStudyType: StudyType): Promise<ReadonlyArray<DocumentSubType>> {
    if (context.isConfig()) {
      return [context.target.configType];
    }

    const simVersion = this.getSimVersion.currentSimVersion;

    const studyTypesLookup = await this.studyTypeLookup.getStudyTypeMap(simVersion);

    return studyTypesLookup[stagingAreaStudyType].inputs.map(v => v.configType);
  }
}
