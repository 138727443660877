import * as d3 from '../../d3-bundle';
import { TrackPath } from './track-path';

export const TRACK_HEIGHT_SCALE_FACTOR = 1;

export class TrackData {
  constructor(
    public readonly innerEdge: TrackPath,
    public readonly outerEdge: TrackPath,
    public readonly centreLine: TrackPath, // This is the supplied centre line if it exists, not a calculated one.
    public readonly carPath: TrackPath,
    public readonly carNormals: TrackPath,
    public readonly sLap: ReadonlyArray<number>,
    public readonly sLapCentreLine: ReadonlyArray<number>,
    public readonly isFigureOfEight: boolean,
    public readonly startFinishOffset: number) {

    let minHeight = d3.minStrict(
      [innerEdge, outerEdge, carPath]
        .filter(v => v.trackCoordinates.length)
        .map(v => v.minHeight));

    let scale = TRACK_HEIGHT_SCALE_FACTOR;
    let offset = -minHeight;

    // Drop minimum height to zero and scale up to exaggerate height in the viewer.
    this.innerEdge.adjustWorldElevationCoordinates(scale, offset);
    this.outerEdge.adjustWorldElevationCoordinates(scale, offset);
    this.centreLine.adjustWorldElevationCoordinates(scale, offset);
    this.carPath.adjustWorldElevationCoordinates(scale, offset);
  }
}
