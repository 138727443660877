import {Component, Input, OnInit} from '@angular/core';
import {DocumentSubType} from '../../../../../generated/api-stubs';
import {CanopyJsonDiffPatch, DiffResult} from '../../../../common/canopy-json-diff-patch.service';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {DisplayableError} from '../../../../common/errors/errors';
import {ConvertDiffToViewModel, Delta} from './convert-diff-to-view-model.service';
import {UnitsManager} from '../../../../units/units-manager.service';
import {GetSimVersion} from '../../../../common/get-sim-version.service';
import {SimVersionDocumentCache, UnitsMap} from '../../../sim-version-document-cache.service';
import {IGetUnits, UnitsInformation} from './get-units';
import {OutputChangeSet} from '../compare-config-structures-renderer/compare-config-structures-renderer.component';
import {CanopyJson} from '../../../../common/canopy-json.service';
import {ApplyChangesToConfig} from '../../apply-changes-to-config.service';
import {StudyInput} from '../../../../worksheets/study-input';
import {CustomPropertyUtilities} from '../../../custom-properties/custom-property-utilities';
import {defaultConfigToStudyInput} from '../../../../worksheets/study-input-utilities';
import {ConfigOrConfigLoader} from '../config-or-config-loader';

@Component({
  selector: 'cs-compare-config-structures',
  templateUrl: './compare-config-structures.component.html',
  styleUrls: ['./compare-config-structures.component.scss']
})
export class CompareConfigStructuresComponent implements OnInit, IGetUnits {
  @Input() public configs: ConfigOrConfigLoader[];
  @Input() public simVersion: string;
  @Input() public configType: DocumentSubType;

  public errorMessage: string;
  public isLoaded: boolean;
  public delta: Delta;

  public left: ConfigOrConfigLoader;
  public right: ConfigOrConfigLoader;

  public defaultUnits: UnitsMap;
  public outputChangeSet = new OutputChangeSet();

  private diff: DiffResult;

  constructor(
    private readonly canopyJsonDiffPatch: CanopyJsonDiffPatch,
    private readonly convertDiffToViewModel: ConvertDiffToViewModel,
    private readonly unitsManager: UnitsManager,
    private readonly getSimVersion: GetSimVersion,
    private readonly simVersionDocumentCache: SimVersionDocumentCache,
    private readonly json: CanopyJson,
    private readonly applyChangesToConfig: ApplyChangesToConfig,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public get leftName(): string {
    return this.left.shortName;
  }
  public get rightName(): string {
    return this.right.shortName;
  }
  public get leftFullName(): string {
    return this.left.materialized.name;
  }
  public get rightFullName(): string {
    return this.right.materialized.name;
  }

  public get leftDeleteRequested(): boolean {
    return this.left.additionalData ? !!this.left.additionalData.deleteRequested : false;
  }
  public get rightDeleteRequested(): boolean {
    return this.right.additionalData ? !!this.right.additionalData.deleteRequested : false;
  }

  public get leftParentWorksheetId(): string {
    return this.left.additionalData ? this.left.additionalData.parentWorksheetId : undefined;
  }
  public get rightParentWorksheetId(): string {
    return this.right.additionalData ? this.right.additionalData.parentWorksheetId : undefined;
  }

  ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.isLoaded = false;
      if(this.configs.length > 2) {
        throw new DisplayableError('Only comparison between two configs is currently supported (' + this.configs.length + ' config attempted).');
      }

      await this.unitsManager.refresh();
      let documentCacheResult = await this.simVersionDocumentCache.get(this.getSimVersion.currentSimVersion);
      this.defaultUnits = documentCacheResult.units;

      this.left = this.configs.length === 2
        ? this.configs[1]
        : new ConfigOrConfigLoader('nothing', defaultConfigToStudyInput(this.configType, 'nothing', {}, this.simVersion), undefined);
      this.right = this.configs[0];

      let rightData = this.studyInputToCompareData(this.right.materialized);
      let leftData = this.studyInputToCompareData(this.left.materialized);
      this.diff = this.canopyJsonDiffPatch.getDiff(leftData, rightData, {

        // Should be big enough that we don't do a text diff on a large array hash.
        // Actually we will disable this for now.
        textDiff: {
          minLength: 9999999
        },

        /* default false. if true, values in the obtained delta will be cloned
          (using jsondiffpatch.clone by default), to ensure delta keeps no references to left or right objects. this becomes useful if you're diffing and patching the same objects multiple times without serializing deltas.
          instead of true, a function can be specified here to provide a custom clone(value)
          */
        cloneDiffValues: true
      });

      if(this.diff.data){
        this.delta = this.convertDiffToViewModel.execute(this.diff);
      }else{
        this.delta = undefined;
      }
      this.isLoaded = true;
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public studyInputToCompareData(studyInput: StudyInput): CompareData{
    return {
      notes: studyInput.notes || '',
      properties: CustomPropertyUtilities.listToObject(studyInput.properties),
      data: studyInput.data
    };
  }

  public compareDataToStudyInput(studyInput: StudyInput, data: CompareData){
    return new StudyInput(
      studyInput.configType,
      studyInput.userId,
      studyInput.configId,
      studyInput.name,
      data.data,
      CustomPropertyUtilities.objectToList(data.properties),
      data.notes,
      studyInput.simVersion,
      true,
      undefined);
  }

  public getOutputConfig(): StudyInput{
    let output = this.json.clone(this.studyInputToCompareData(this.configs[0].materialized));
    let changes = this.outputChangeSet.getChanges();

    this.applyChangesToConfig.execute(output, changes);
    output = this.canopyJsonDiffPatch.restore(output, this.diff.hashMap);

    return this.compareDataToStudyInput(this.configs[0].materialized, output);
  }


  public getUnits(key: string): UnitsInformation {
    let sourceUnits = this.defaultUnits[key];
    return new UnitsInformation(
      this.unitsManager.getUnitsSynchronous(key, sourceUnits),
      sourceUnits);
  }
}

interface CompareData {
  notes: string;
  properties: { [name: string]: string};
  data: any;
}
