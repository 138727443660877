import { SVGSelection } from '../../untyped-selection';
import { ICanvasData } from '../canvas-utilities';
import { ParallelCoordinatesViewerSettings } from './parallel-coordinates-viewer-settings';
import {
  CanvasDataRendererBase,
  RenderInformation
} from '../components/canvas-data-renderer-base';
import * as d3 from '../../d3-bundle';
import {
  Dimension,
  LineMouseEvent,
  ParallelCoordinatesData,
  PointsInDimensions
} from './parallel-coordinates-types';
import { Position } from '../position';
import { Rectangle } from '../rectangle';
import { SharedState } from '../shared-state';

const BACKGROUND_CSS_CLASS = 'parallel-coordinates-canvas-background';
const FOREGROUND_CSS_CLASS = 'parallel-coordinates-canvas-foreground';

class ParallelCoordinatesRenderInformation extends RenderInformation {
  constructor(
    public readonly redrawAllData: boolean) {
    super();
  }

  public getTargetCanvasContexts(isFirstCall: boolean): ReadonlyArray<CanvasRenderingContext2D> {
    if (this.redrawAllData || isFirstCall) {
      return this.canvases.all.map(v => v.canvasContext);
    }

    return [this.canvases.getByCssClass(FOREGROUND_CSS_CLASS).canvasContext];
  }
}

export class ParallelCoordinatesDataRenderer extends CanvasDataRendererBase<ParallelCoordinatesViewerSettings, ParallelCoordinatesRenderInformation>  {
  private readonly listeners = d3.dispatch('mouseover', 'mouseout', 'click', 'dataRemovalRequested');

  private data?: ParallelCoordinatesData;

  constructor(
    private readonly sharedState: SharedState,
    settings: ParallelCoordinatesViewerSettings,
    canvasData: ICanvasData) {
    super(
      'parallel-coordinates-data-renderer',
      [BACKGROUND_CSS_CLASS, FOREGROUND_CSS_CLASS],
      settings,
      canvasData);
  }

  public setData(data: ParallelCoordinatesData) {
    this.data = data;
  }

  public render(redrawAllData: boolean) {
    super.performRender(new ParallelCoordinatesRenderInformation(redrawAllData));
  }

  protected renderContext(targetContexts: ReadonlyArray<CanvasRenderingContext2D>, renderInformation: ParallelCoordinatesRenderInformation) {
    if (!this.container || !this.svg || !this.data) {
      return;
    }

    const settings = this.definedSettings;
    this.container.attr('transform', 'translate(' + settings.svgPadding.left + ',' + settings.svgPadding.top + ')');

    this.updateMatchedPath(this.svg, -1);

    if (!this.sharedState.isFirstSourceVisible) {
      return;
    }

    let backgroundContext = renderInformation.canvases.getByCssClass(BACKGROUND_CSS_CLASS).canvasContext;
    let foregroundContext = renderInformation.canvases.getByCssClass(FOREGROUND_CSS_CLASS).canvasContext;

    let orderedDimensions = this.getOrderedDimensions();
    if (orderedDimensions.length) {
      let firstDimension = orderedDimensions[0];
      let lastDimension = orderedDimensions[orderedDimensions.length - 1];
      let yRange = d3.extentStrict(firstDimension.scale.range());
      let clip = new Rectangle(
        settings.svgPadding.left + firstDimension.renderPosition,
        settings.svgPadding.top,
        lastDimension.renderPosition - firstDimension.renderPosition,
        yRange[1] - yRange[0]);

      targetContexts.forEach(c => c.beginPath());
      targetContexts.forEach(c => c.rect(clip.x, clip.y, clip.width, clip.height));
      targetContexts.forEach(c => c.clip());

      targetContexts.forEach(c => c.translate(settings.svgPadding.left, settings.svgPadding.top));
      targetContexts.forEach(c => c.lineWidth = 0.5);
      backgroundContext.strokeStyle = '#ccc';
      backgroundContext.globalAlpha = 0.4;

      for (let d of this.data.lines) {
        let line = this.createLine(d);

        foregroundContext.strokeStyle = this.data.colorScale(d[this.data.colorDimensionIndex]);

        targetContexts.forEach(c => {
          let shouldRender = c !== foregroundContext || this.shouldShowData(d);
          if (shouldRender) {
            line.context(c);
            c.beginPath();
            line(d as number[]);
            c.stroke();
          }
        });
      }
    }
  }

  private getMatchedDataIndex(orderedDimensions: ReadonlyArray<Dimension>, mousePosition: Position) {
    const lines = this.data ? this.data.lines : [];
    const settings = this.definedSettings;
    let matchedIndex = -1;
    let matchedDistance = 1000;
    let matchedFilteredIndex = -1;
    let matchedFilteredDistance = 1000;
    for (let dataIndex = 0; dataIndex < lines.length; ++dataIndex) {
      let d = lines[dataIndex];
      let dp = d.map((v, i) => {
        let dimension = orderedDimensions[i];
        return new Position(
          settings.svgPadding.left + dimension.renderPosition,
          settings.svgPadding.top + dimension.scale(d[dimension.plot.plotIndex]));
      });
      for (let i = 0; i < d.length - 1; ++i) {
        let distance = distanceToSegment(mousePosition, dp[i], dp[i + 1]);
        if (dataIndex === 0 && i === 0) {
        }
        if (distance < 4) {
          if (this.shouldShowData(d)) {
            if (distance < matchedDistance) {
              matchedDistance = distance;
              matchedIndex = dataIndex;
            }
          } else {
            if (distance < matchedFilteredDistance) {
              matchedFilteredDistance = distance;
              matchedFilteredIndex = dataIndex;
            }
          }
        }
      }
    }

    return matchedIndex === -1 ? matchedFilteredIndex : matchedIndex;
  }

  private updateMatchedPath(svg: SVGSelection, matchedIndex: number) {
    let transitionDuration = 250;

    let matchedData = matchedIndex === -1 || !this.sharedState.isFirstSourceVisible ? [] : [matchedIndex];

    this.updateMatchedPathInner(transitionDuration, matchedData, 'canvas-selected-line');
    this.updateMatchedPathInner(transitionDuration, matchedData, 'canvas-selected-line-outline', 'rgba(0,0,0,0.5)');
  }

  private updateMatchedPathInner(transitionDuration: number, matchedData: number[], className: string, colorOverride?: string) {
    if (!this.svg || !this.data) {
      return;
    }

    const data = this.data;

    let lineUpdate = this.svg.select('g').selectAll<SVGPathElement, number>('.' + className).data(matchedData);
    let lineEnter = lineUpdate.enter()
      .append('path')
      .attr('class', className)
      .lower();

    lineEnter.style('opacity', 0);

    lineUpdate.merge(lineEnter)
      .transition().duration(50).ease(d3.easeLinear)
      .style('stroke', (d) => colorOverride
        ? colorOverride
        : this.shouldShowData(data.lines[d]) ? data.colorScale(data.lines[d][data.colorDimensionIndex]) : '#ccc')
      .style('opacity', 1)
      .attr('d', (d) => this.createLine(data.lines[d])(data.lines[d] as number[]));

    lineUpdate.exit().transition().duration(transitionDuration).style('opacity', 0).remove();
  }

  protected attachCanvasMouseMoveHandler(svg: SVGSelection) {
    let lastMouseDataIndex = -1;

    svg.on('mousemove', currentEvent => {
      let mouseEvent = <MouseEvent>currentEvent;
      let position = new Position(mouseEvent.offsetX, mouseEvent.offsetY);
      let orderedDimensions = this.getOrderedDimensions();
      let matchedIndex = this.getMatchedDataIndex(orderedDimensions, position);
      if (lastMouseDataIndex !== -1) {
        this.dispatchEvent('mouseout', lastMouseDataIndex, currentEvent);
      }

      if (lastMouseDataIndex !== matchedIndex) {
        this.updateMatchedPath(svg, matchedIndex);
      }

      if (matchedIndex !== -1) {
        svg.attr('clickable', 'true');
        this.dispatchEvent('mouseover', matchedIndex, currentEvent);
      } else {
        svg.attr('clickable', null);
      }

      lastMouseDataIndex = matchedIndex;
    })
      .on('mouseout', currentEvent => {        //undo everything on the mouseout
        let mouseEvent = <MouseEvent>currentEvent;
        if (lastMouseDataIndex !== -1) {
          this.dispatchEvent(mouseEvent.type, lastMouseDataIndex, currentEvent);
          this.updateMatchedPath(svg, -1);
          lastMouseDataIndex = -1;
        }
      })
      .on('click', currentEvent => {
        let mouseEvent = <MouseEvent>currentEvent;
        let position = new Position(mouseEvent.offsetX, mouseEvent.offsetY);
        let orderedDimensions = this.getOrderedDimensions();
        let matchedIndex = this.getMatchedDataIndex(orderedDimensions, position);
        if (matchedIndex !== -1) {
          if(currentEvent.altKey){
            this.listeners.call('dataRemovalRequested', this, matchedIndex);
            return;
          }
          this.dispatchEvent('click', matchedIndex, currentEvent);
        }
      });
  }

  public createLine(d: PointsInDimensions): d3.Line<number> {
    let orderedDimensions = this.getOrderedDimensions();
    return d3.line<number>()
      .curve(d3.curveLinear)
      .defined((d) => !isNaN(d))
      .x((ds, i) => orderedDimensions[i].renderPosition)
      .y((ds, i) => orderedDimensions[i].scale(d[orderedDimensions[i].plot.plotIndex]));
  }

  private getOrderedDimensions(): Dimension[] {
    if (!this.data) {
      return [];
    }

    let result = [...this.data.dimensionList];
    result.sort((a, b) => a.renderPosition - b.renderPosition);
    return result;
  }

  private shouldShowData(d: PointsInDimensions): boolean {
    if (!this.data) {
      return false;
    }

    for (let dimension of this.data.dimensionList) {
      let filter = dimension.channel.unprocessed.transient.filter;
      if (!filter) {
        continue;
      }

      let show = filter.minimum <= d[dimension.plot.plotIndex] && d[dimension.plot.plotIndex] <= filter.maximum;
      if (show) {
        continue;
      }

      return false;
    }

    return true;
  }

  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.listeners.on(typenames, callback);
    return this;
  }

  private dispatchEvent(type: string, lineIndex: number, currentEvent: any) {
    // Example: https://github.com/d3/d3-drag/blob/master/src/drag.js
    let event = new LineMouseEvent(type, lineIndex, currentEvent);
    this.listeners.call(type, this, event);
  }
}

// http://stackoverflow.com/a/1501725/37725
function sqr(x: number) {
  return x * x;
}
function dist2(v: Position, w: Position) {
  return sqr(v.x - w.x) + sqr(v.y - w.y);
}
function distToSegmentSquared(p: Position, v: Position, w: Position) {
  let l2 = dist2(v, w);
  if (l2 === 0) {
    return dist2(p, v);
  }
  let t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
  t = Math.max(0, Math.min(1, t));
  return dist2(p, new Position(v.x + t * (w.x - v.x), v.y + t * (w.y - v.y)));
}
function distanceToSegment(p: Position, v: Position, w: Position) {
  return Math.sqrt(distToSegmentSquared(p, v, w));
}
