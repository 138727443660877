import { Injectable } from '@angular/core';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import { JsonEditorCustomization } from './json-editor-customization';
import { SetAutocompleteSanitizer } from '../../../../common/set-autocomplete-sanitizer';
import awesomplete from 'awesomplete';

export declare let JSONEditor: any;

@Injectable()
export class SavedConfigEditor extends JsonEditorCustomization {

  constructor(
    private readonly setAutocompleteSanitizer: SetAutocompleteSanitizer) {
      super();
  }

  public apply(): void {
    const service = this;

    JSONEditor.defaults.editors.savedConfig = JSONEditor.defaults.editors.string.extend({
      /*getValue: function() {
        let split_by=/[\s,]+/;
        return this.value ? this.value.split(split_by) : [];
      },
      setValue: function(value,initial,from_template){
        value = this.sanitizeArray(value);

        let join_with=', ';
        value = value ? value.join(join_with) : '';

        return (<any>this)._super(value, initial,from_template)  ;
      }
      */
      build() {
        let self = this;
        this._super();
        const canopyOptions = getCanopyJsonEditorOptions(this);

        let lastParameterPath = '';
        let loadedValues: any[] = [];

        let options = {
          minChars: 0,
          maxItems: 100,
          autoFirst: true,
          sort: (a: string, b: string) => a.localeCompare(b)
        };

        service.setAutocompleteSanitizer.execute(options);

        let a = new awesomplete(this.input, options);

        this.input.addEventListener('awesomplete-selectcomplete', function(e: Event) {
          self.refreshValue();
          self.onChange(true);
        });

        this.input.addEventListener('focus', function(e: Event) {
          let parameterPath = self.parent.parent.parent.parent.value.parameterPath;

          let filterList = (input: string[], currentValue: string): string[] => {
            let existingValues = self.parent.value;
            return input.filter(v => v === currentValue || existingValues.indexOf(v) === -1);
          };

          if (parameterPath !== lastParameterPath) {
            a.list = ['Loading...'];

            let parameterPathWithoutArrays = parameterPath.replace(/\[[0-9+]]/g, '');
            canopyOptions.savedConfigNamesCache.get(parameterPathWithoutArrays).then(
              value => {
                lastParameterPath = parameterPath;
                loadedValues = value;
                a.list = filterList(loadedValues, self.value);
                a.open();
                a.evaluate();
              },
              () => {
                a.list = ['Error loading configs'];
                a.open();
                a.evaluate();
              }
            );
          } else {
            a.list = filterList(loadedValues, self.value);
          }

          a.open();
          a.evaluate();
        });

      }
    });
  }
}
