import { Injectable } from '@angular/core';
import { isArray } from './is-array';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SplitItems2DResolver extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.resolvers.unshift(function(schema: any) {
      if (isArray(schema)) {
        if (schema.items.type === 'array' && schema.items.items) {
          if (schema.items.items.type === 'string') {
 return 'splitStrings2D'; 
}
          if (schema.items.items.type === 'number') {
 return 'splitNumbers2D'; 
}
          if (schema.items.items.type === 'integer') {
 return 'splitIntegers2D'; 
}
          if (schema.items.items.type === 'boolean') {
 return 'splitBooleans2D'; 
}
        }
      }

      return undefined;
    });
  }
}
