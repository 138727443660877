import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem, KeyboardAction,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {ConfigViewModel} from '../config-view-model';
import {ReplaceResult} from '../worksheet-item-component-base';
import {SetConfigOrAllReferences} from './set-config-or-all-references.service';
import {ConfirmReplaceAllReferences} from './confirm-replace-all-references.service';
import {ImportTelemetryDialog} from '../../simulations/telemetry/import-telemetry-dialog/import-telemetry-dialog.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class ImportTelemetryCommand extends WorksheetCommand {

  constructor(
    private readonly setConfigOrAllReferences: SetConfigOrAllReferences,
    private readonly confirmReplaceAllReferences: ConfirmReplaceAllReferences,
    private readonly importTelemetryDialog: ImportTelemetryDialog,
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isConfig()
      && context.worksheet.canWrite(userData.sub)
      && context.target.isTelemetry;
  }

  public async execute(context: CommandContext, showDifferences: boolean): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    const userData = this.authenticationService.userDataSnapshot;
    const rows = context.selectedRows;

    const replaceResult = await this.confirmReplaceAllReferences.execute(context.target, rows);
    if(replaceResult === ReplaceResult.cancel){
      return CommandResult.NoUpdate;
    }

    const result = await this.importTelemetryDialog.show(showDifferences);

    if(!result || !result.telemetrySubmitted){
      return CommandResult.NoUpdate;
    }

    await this.setConfigOrAllReferences.execute(
      context.target,
      rows,
      {
        tenant: {
          tenantId: userData.tenant,
          targetId: result.studyId,
          jobIndex: 0,
        }
      },
      replaceResult);

    return CommandResult.UpdateOnly;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Import From File`,
      `import-telemetry-button`,
      (event) => this.execute(context, !!(event.ctrlKey || event.metaKey)),
      'upload',
      KeyboardAction.enter));
  }
}
