import {
  DocumentSubType,
  SimulationInput,
  StudyReference,
  StudyResolvedLabels,
  StudyResolvedReference,
  StudyType,
  UserInformation,
  WorksheetStudy
} from '../../generated/api-stubs';
import {WorksheetUnderlyingData} from './worksheet-underlying-data';
import {UNKNOWN_STUDY_TYPE, UNKNOWN_USER} from './worksheet-constants';
import {RowViewModel} from './row-view-model';
import {HashValidity} from './hash-validity';
import {RowItemViewModel} from './row-item-view-model';
import {isStudyComplete} from '../common/is-study-complete';
import {IReadonlyWorksheetReferenceMetadata} from './worksheet-references-metadata';

export class StudyViewModel extends RowItemViewModel {
  public populated: PopulatedStudyViewModel | undefined;
  public readonly unpopulated: UnpopulatedStudyViewModel;

  private cachedStudyInputs: CachedStudyInputs | undefined;

  public matchesSelectedConfig: boolean = false;
  public isSelected: boolean;
  public isSubmitting: boolean;

  protected constructor(
    parent: RowViewModel,
    study: WorksheetStudy,
    unpopulatedStudyType: StudyType,
    public underlyingData: WorksheetUnderlyingData) {
    super(parent);

    if (study && study.reference) {
      this.populated = new PopulatedStudyViewModel(this, study.reference);
    }

    this.unpopulated = new UnpopulatedStudyViewModel();

    this.update(underlyingData);
    if(this.populated){
      unpopulatedStudyType = this.populated.studyType;
    }
    this.unpopulated.initialize(unpopulatedStudyType);
  }

  public get isPopulated(): boolean {
    return !!this.populated;
  }

  public isReadOnly(userId: string): boolean {
    return !!(this.populated
      && this.populated.resolvedReference
      && this.populated.resolvedReference.data
      && this.populated.resolvedReference.data.userId !== userId);
  }

  public canWrite(userId: string): boolean{
    return !this.isReadOnly(userId);
  }

  public get hashValidity(): HashValidity {
    if(this.isResolved){
      if(this.row.configs.some(v => v.hashValidity === HashValidity.invalid)){
        return HashValidity.invalid;
      }

      return HashValidity.valid;
    }

    return HashValidity.none;
  }

  public update(underlyingData: WorksheetUnderlyingData) {
    this.underlyingData = underlyingData;
    if(this.populated){
      this.populated.update(this.underlyingData);
    }

    this.unpopulated.update(this.underlyingData);
  }

  public getOutline(): WorksheetStudy {
    return {
      reference: this.reference,
    };
  }

  public setStudy(reference: StudyReference) {
    if(this.populated) {
      this.populated.destroy(this.underlyingData);
    }

    if(reference){
      this.populated = new PopulatedStudyViewModel(
        this,
        reference);
      this.populated.update(this.underlyingData);
    } else {
      this.populated = undefined;
      this.matchesSelectedConfig = false;
    }

    this.row.onStudyChanged();
  }

  public get referenceMetadata(): IReadonlyWorksheetReferenceMetadata {
    return this.populated
      ? this.underlyingData.referencesMetadata.get(this.populated.reference)
      : undefined;
  }

  public get studyType(): StudyType {
    if(this.isResolved){
      return this.populated.studyType;
    }

    return this.unpopulated.studyType;
  }

  public get studyTypeName(): string {
    if(this.isResolved){
      return this.populated.studyTypeName;
    }

    return this.unpopulated.studyTypeName;
  }

  public get reference(): StudyReference | undefined {
    if(this.populated) {
      return this.populated.reference;
    }

    return undefined;
  }

  public get resolvedReference(): StudyResolvedReference | undefined {
    if(this.populated) {
      return this.populated.resolvedReference;
    }

    return undefined;
  }

  public get inputConfigTypes(): ReadonlyArray<DocumentSubType> {
    this.ensureCachedStudyInputs();
    return this.cachedStudyInputs.inputConfigTypes;
  }

  public get inputs(): ReadonlyArray<SimulationInput> {
    this.ensureCachedStudyInputs();
    return this.cachedStudyInputs.inputs;
  }

  public getStudyInputNecessity(configType: DocumentSubType): StudyInputNecessity {
    this.ensureCachedStudyInputs();
    const input = this.cachedStudyInputs.inputMap[configType];
    if(!input){
      return StudyInputNecessity.invalid;
    }

    return input.isRequired ? StudyInputNecessity.required : StudyInputNecessity.optional;
  }

  public async extractStudyInputs(){
    await this.row.extractStudyInputs();
  }

  private ensureCachedStudyInputs() {
    let studyType = this.studyType;
    let simVersion = this.underlyingData.simVersion;
    if (!this.cachedStudyInputs || this.cachedStudyInputs.studyType !== studyType || this.cachedStudyInputs.simVersion !== simVersion) {
      const definition = this.underlyingData.studyTypes[studyType];
      let inputs = definition
        ? definition.inputs
        : [];

      this.cachedStudyInputs = new CachedStudyInputs(studyType, simVersion, inputs);
    }
  }

  public static fromStudy(
    row: RowViewModel,
    study: WorksheetStudy,
    underlyingData: WorksheetUnderlyingData): StudyViewModel {

    return new StudyViewModel(row, study, undefined, underlyingData);
  }

  public static fromEmpty(
    row: RowViewModel,
    unpopulatedStudyType: StudyType,
    underlyingData: WorksheetUnderlyingData): StudyViewModel {

    return new StudyViewModel(row, undefined, unpopulatedStudyType, underlyingData);
  }

  public get isResolved(): boolean {
    return !!(this.populated && this.populated.resolvedReference && this.populated.resolvedReference.data);
  }

  public get isErrored(): boolean {
    return !!(this.populated && this.populated.resolvedReference && this.populated.resolvedReference.error);
  }

  public get isComplete(): boolean {
    return this.isPopulated && this.populated.isComplete;
  }

  public setItemsMatching() {
    this.row.worksheet.setItemsMatching(this.reference);
  }

  public clearItemsMatching() {
    this.row.worksheet.clearItemsMatching();
  }
}

export class PopulatedStudyViewModel {
  public resolvedReference: StudyResolvedReference | undefined;
  public resolvedLabels: StudyResolvedLabels | undefined;
  public owner: UserInformation | undefined;
  public ownerName: string;

  public studyType: StudyType | undefined;
  public studyTypeName: string;

  constructor(
    private readonly parent: StudyViewModel,
    public readonly reference: StudyReference) {
  }

  public destroy(underlyingData: WorksheetUnderlyingData) {
    if(this.parent) {
      underlyingData.referencesMetadata.decrementStudy(this.parent);
    }
  }

  public update(underlyingData: WorksheetUnderlyingData) {
    if(this.parent){
      underlyingData.referencesMetadata.incrementStudy(this.parent);
    }

    this.resolvedReference = underlyingData.studyResolvedReferences.get(this.reference);
    this.resolvedLabels = underlyingData.studyResolvedLabels.get(this.reference);

    this.ownerName = UNKNOWN_USER;
    this.studyTypeName = UNKNOWN_STUDY_TYPE;
    if (this.resolvedReference && this.resolvedReference.data) {
      this.owner = underlyingData.users[this.resolvedReference.data.userId];
      if (this.owner) {
        this.ownerName = this.owner.username;
      }

      this.studyType = this.resolvedReference.data.studyDocument.studyType;
      this.studyTypeName = underlyingData.getStudyTypeName(this.studyType);
    }
  }

  public get isComplete(): boolean {
    return this.resolvedReference
      && this.resolvedReference.data
      && isStudyComplete(this.resolvedReference.data.studyDocument);
  }

  public get hasFailedJobs(): boolean {
    return this.resolvedReference
      && this.resolvedReference.data
      && this.resolvedReference.data.studyDocument.succeededJobCount !== this.resolvedReference.data.studyDocument.completedJobCount;
  }
}

export class UnpopulatedStudyViewModel {

  private _studyType: StudyType;
  private _studyTypeName: string;

  private underlyingData: WorksheetUnderlyingData;

  constructor() {
  }

  public initialize(initialStudyType: StudyType) {
    this.studyType = initialStudyType;
  }

  public update(underlyingData: WorksheetUnderlyingData) {
    this.underlyingData = underlyingData;
  }

  public get studyType(): StudyType {
    return this._studyType;
  }

  public set studyType(value: StudyType) {
    this._studyType = value;
    this._studyTypeName = this.underlyingData.getStudyTypeName(value);
  }

  public get studyTypeName(): string {
    return this._studyTypeName;
  }
}

class CachedStudyInputs {
  public readonly inputMap: StudyInputMap;
  public readonly inputConfigTypes: ReadonlyArray<DocumentSubType>;

  constructor(
    public readonly studyType: StudyType,
    public readonly simVersion: string,
    public readonly inputs: ReadonlyArray<SimulationInput>) {
    this.inputConfigTypes = this.inputs.map(v => v.configType);
    this.inputMap = this.inputs.reduce((p, c) => {
 p[c.configType] = c; return p;
}, {} as StudyInputMap);
  }
}

type StudyInputMap  = { [configType in DocumentSubType]?: SimulationInput | undefined };

export enum StudyInputNecessity {
  invalid,
  optional,
  required,
}
