import {DisplayableError, InputValidationError} from '../common/errors/errors';
import {CustomProperty} from '../simulations/custom-properties/custom-properties';
import {ConfigTypeLookup, ExplorationConfigType} from '../simulations/configs/config-types';
import {DocumentSubType, PostStudyResult, StudyStub, StudyTypeDefinition} from '../../generated/api-stubs';
import {GetSimVersion} from '../common/get-sim-version.service';
import {StudySubmittedEvents} from '../simulations/studies/study-submitted-events.service';
import {StudyMetadataCache} from '../notifications/study-metadata-cache.service';
import {Injectable} from '@angular/core';
import {StudyInput} from './study-input';
import { AuthenticationService } from '../identity/state/authentication.service';

@Injectable()
export class SubmitStudy {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly getSimVersion: GetSimVersion,
    private readonly studyStub: StudyStub,
    private readonly studySubmittedEvents: StudySubmittedEvents,
    private readonly studyMetadataCache: StudyMetadataCache) {
  }

  public async execute(
    studyName: string,
    studyTypeInformation: StudyTypeDefinition,
    getInput: (configType: DocumentSubType, targetSimVersion: string) => Promise<StudyInput>): Promise<CommittedStudy> {

    const userData = this.authenticationService.userDataSnapshot;

    // This will also refresh study inputs if the sim version changes.
    let simVersion = await this.getSimVersion.execute();

    let study = {
      simTypes: studyTypeInformation.simTypes,
      simConfig: {
      },
      exploration: undefined as any
    };

    let sources = [];
    let properties: CustomProperty[] = [];
    let notes: string = '';

    for(let input of studyTypeInformation.inputs) {
      const configType = ConfigTypeLookup.get(input.configType);
      if(!configType){
        throw new DisplayableError('Unknown config type: ' + input.configType);
      }

      let inputState = await getInput(input.configType, simVersion);
      if(!inputState){
        if(input.isRequired){
          throw new InputValidationError(configType.titleName + ' is required.');
        }
      } else{
        if(input.configType === ExplorationConfigType.singularKey){
          study.exploration = inputState.data;
        } else{
          (study.simConfig as any)[input.configType] = inputState.data;
        }

        sources.push({
          configType: inputState.configType,
          userId: inputState.userId,
          configId: inputState.configId,
          name: inputState.name,
          isEdited: inputState.isEdited
        });
        this.addProperties(properties, inputState.configType, inputState);

        if(inputState.notes){
          if(notes){
            notes += '\n\n';
          }
          notes += configType.titleName + ':\n' + inputState.notes.trim();
        }
      }
    }

    let studyResult = <PostStudyResult>await this.studyStub.postStudy(
      userData.tenant,
      {
        name: studyName,
        studyType: studyTypeInformation.studyType,
        simVersion,
        sources,
        properties,
        notes,
        study,
        isTransient: false
      });

    const committedStudy = new CommittedStudy(
      studyName,
      userData.tenant,
      userData.sub,
      studyResult.studyId);

    this.studySubmittedEvents.studySubmitted.emit(studyResult);

    this.studyMetadataCache.set(userData.tenant, studyResult.studyId, studyName);

    return committedStudy;
  }

  private addProperties(properties: CustomProperty[], type: string, input: StudyInput) {
    for(let p of input.properties){
      properties.push({
        name: type + '.' + p.name,
        value: p.value
      });
    }
  }
}

export class CommittedStudy {
  constructor(
    public name: string,
    public tenantId: string,
    public userId: string,
    public studyId: string
  ){}
}
