import {ConfigReference, ConfigStub, DocumentSubType} from '../../generated/api-stubs';
import {GetSimVersion} from '../common/get-sim-version.service';
import {ConfigTypeLookup} from '../simulations/configs/config-types';
import {TelemetryConfigStudyInput} from './telemetry-config-study-input';
import {Injectable} from '@angular/core';
import {StudyInput} from './study-input';
import {ConfigLoaderUtilities} from '../simulations/configs/config-loader-utilities.service';
import {
  configResultToStudyInput,
  telemetryConfigToStudyInput
} from './study-input-utilities';

@Injectable()
export class LoadConfigFromReference {

  constructor(
    private readonly getSimVersion: GetSimVersion,
    private readonly configStub: ConfigStub,
    private readonly configLoaderUtilities: ConfigLoaderUtilities){
  }

  public async execute(configType: DocumentSubType, configReference: ConfigReference, simVersion?: string): Promise<StudyInput | undefined>{

    simVersion = simVersion || this.getSimVersion.currentSimVersion;

    if(configType === DocumentSubType.telemetry){
      if(!configReference.tenant){
        return undefined;
      }

      const configData: TelemetryConfigStudyInput = {
        source: {
          tenantId: configReference.tenant.tenantId,
          studyId: configReference.tenant.targetId,
          jobIndex: configReference.tenant.jobIndex || 0,
        }
      };

      return telemetryConfigToStudyInput('Telemetry', configData);
    }

    if(configReference.tenant){
      const configResult = await this.configStub.getConfig(
        configReference.tenant.tenantId,
        configReference.tenant.targetId,
        undefined,
        simVersion);

      return configResultToStudyInput(configResult);
    }

    if(configReference.default){
      const configTypeData = ConfigTypeLookup.get(configType);
      const configName = configReference.default.name;
      const studyInput = await this.configLoaderUtilities.loadSimVersionConfig(
        configTypeData,
        simVersion,
        configName);

      return studyInput;
    }

    return undefined;
  }
}
