import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {WorksheetClipboard} from '../worksheet-clipboard.service';
import {ConfigViewModel} from '../config-view-model';
import {PasteContent} from './paste-content.service';
import {DUPLICATE_ICON} from '../worksheet-item-component-base';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class DuplicateInPlaceCommand extends WorksheetCommand {

  constructor(
    private readonly clipboard: WorksheetClipboard,
    private readonly pasteContent: PasteContent,
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    const userData = this.authenticationService.userDataSnapshot;

    return context.isConfig()
      && context.worksheet.canWrite(userData.sub)
      && context.target.isPopulated
      && !context.target.isTelemetry;
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    const content = this.clipboard.createContent(
      context.worksheet.tenantId,
      context.worksheet.worksheetId,
      context.worksheet.getSelectedOutline());

    context.worksheet.clearSelected();

    await this.pasteContent.execute(context, content, true);

    return CommandResult.UpdateOnly;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Duplicate In Place`,
      `duplicate-in-place-button`,
      () => this.execute(context),
      DUPLICATE_ICON));
  }
}
