import {Component, Input} from '@angular/core';

@Component({
  selector: 'cs-worksheet-item-error-message',
  templateUrl: './worksheet-item-error-message.component.html',
  styleUrls: ['./worksheet-item-error-message.component.scss']
})
export class WorksheetItemErrorMessageComponent{

  @Input() public errorMessage: string;

  constructor() { }
}
