import { Injectable, NgZone } from '@angular/core';
import { SetChannelUnitsDialog } from '../../../../units/set-channel-units-dialog.service';
import { JsonEditorCustomization } from './json-editor-customization';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';

export declare let JSONEditor: any;

@Injectable()
export class CanopyTheme extends JsonEditorCustomization {

  constructor(
    private readonly zone: NgZone,
    private readonly setChannelUnitsDialog: SetChannelUnitsDialog) {
    super();
  }

  public apply(): void {
    const service = this;

    JSONEditor.defaults.themes.canopy = JSONEditor.defaults.themes.bootstrap3.extend({
      getHelpLink(wikiPage: string, wikiAnchor: string, editorNode: any) {
        const canopyOptions = getCanopyJsonEditorOptions(editorNode);

        let helpBox = document.createElement('a');
        helpBox.className += ' help-link-container';
        helpBox.setAttribute('tabindex', '-1');
        helpBox.setAttribute('target', '_blank');
        // if (service.userIdentity.isAdministrator) {
        helpBox.href = ('https://support.canopysimulations.com/hc/en-gb/articles/' + (canopyOptions.documentationPageNameToId[wikiPage] || wikiPage) + (wikiAnchor ? '#' + wikiAnchor.toLowerCase() : '').replace(/ /g, '-'));
        // }
        // else {
        //   helpBox.href = ('/wiki/' + wikiPage + (wikiAnchor ? ';location=' + wikiAnchor.toLowerCase() : '')).replace(/ /g, '-');
        // }
        let icon = document.createElement('i');
        icon.className += ' fa fa-book';
        let tooltip = document.createElement('span');
        tooltip.className += ' help-tooltip';
        tooltip.textContent = (wikiPage + (wikiAnchor ? '/' + wikiAnchor : '')).replace(/\//g, ' / ');
        helpBox.appendChild(tooltip);
        helpBox.appendChild(icon);
        return helpBox;
      },
      addHelpLink(content: any, schema: any, editorNode: any) {
        let wikiPage = schema ? schema.wikiPage : undefined;
        let wikiAnchor = schema ? schema.wikiAnchor : undefined;
        if (wikiPage) {
          let helpBox = this.getHelpLink(wikiPage, wikiAnchor, editorNode);
          helpBox.className += ' help-non-addon';
          content.appendChild(helpBox);
        }
      },
      getFormControl(label: HTMLLabelElement, input: HTMLInputElement, description: Element, schema: any, title: string, editorNode: any) {
        let units = editorNode ? editorNode.getUnits() : undefined;
        let wikiPage = schema ? schema.wikiPage : undefined;
        let wikiAnchor = schema ? schema.wikiAnchor : undefined;

        let group = document.createElement('div');

        if (input.type === 'hidden') {
          group.appendChild(input);
        } else {
          if (label && input.type === 'checkbox') {
            group.className += ' checkbox';
            label.appendChild(input);
            label.style.fontSize = '14px';
            group.style.marginTop = '0';
            group.appendChild(label);
            input.style.position = 'relative';
            input.style.cssFloat = 'left';
          } else {
            group.className += ' mb-3 form-group';

            let isInputGroup = false;
            if (label && (input.type === 'text' || input.type === 'textarea')) {
              isInputGroup = true;
            }

            if (label) {
              label.className += ' control-label';
              group.appendChild(label);

              if (!isInputGroup && units) {
                let unitsSpan = document.createElement('span');
                unitsSpan.textContent = ' (' + units + ')';
                group.appendChild(unitsSpan);
              }
            }

            let inputGroup = group;
            if (isInputGroup) {
              inputGroup = document.createElement('div');
              inputGroup.className += 'input-group input-group-sm'; // input-group-sm
              group.appendChild(inputGroup);
            }

            inputGroup.appendChild(input);

            if (isInputGroup && units) {
              let unitsText = document.createElement('span');
              unitsText.className += ' input-group-text units-addon';

              let setUnits = () => {
                let currentUnits = editorNode.getUnits();
                if (currentUnits === '()' || currentUnits === '1') {
                  currentUnits = '-';
                }
                unitsText.textContent = currentUnits;
              };
              setUnits();

              const canopyOptions = getCanopyJsonEditorOptions(editorNode);

              canopyOptions.internalUnitsChanged.subscribe(() => {
                service.zone.runOutsideAngular(() => {
                  setUnits();
                });
              });

              unitsText.addEventListener('click', async () => {
                let unitsData = editorNode.getUnitsAndChannelName();
                let result = await service.setChannelUnitsDialog.show(unitsData.channelName, unitsData.units);
                if (result) {
                  canopyOptions.internalUnitsChanged.emit(undefined);
                }
              });
              inputGroup.insertBefore(unitsText, inputGroup.firstChild);
            }

            if (wikiPage) {
              let helpBox = this.getHelpLink(wikiPage, wikiAnchor, editorNode);
              if (isInputGroup) {
                helpBox.className += ' input-group-text help-addon';
                inputGroup.appendChild(helpBox);
              } else {
                helpBox.className += ' help-non-addon';
                group.appendChild(helpBox);
              }
            }
          }

          if (description) {
            group.appendChild(description);
          }
        }

        return group;
      },
      getSelectInput(options: any){
        let el = this._super(options);
        el.className='form-select';
        return el;
      },
      getTabHolder() {
        let el = document.createElement('div');
        el.innerHTML = '<div class="json-tab-bar"></div><div class="json-tab-content"></div>';
        return el;
      },
      getTab(text: Element) {
        let el = document.createElement('a');
        el.className = 'btn btn-secondary json-tab-btn';
        el.setAttribute('href', '#');
        el.appendChild(text);
        return el;
      },
      markTabActive(tab: Element) {
        tab.className += ' active-tab-btn';
      },
      markTabInactive(tab: Element) {
        tab.className = tab.className.replace(/\s?active-tab-btn/g, '');
      }
    });
  }
}
