import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {LabelDefinitions} from '../../../generated/api-stubs';

@Injectable()
export class WorksheetLabelsEditorDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public show(
    canEditWorksheetLabels: boolean,
    worksheetLabels?: LabelDefinitions): Promise<WorksheetLabelsEditorResult> {

    return this.dialogManager.showSecondaryDialog(
      new WorksheetLabelsEditorDialogData(
        canEditWorksheetLabels,
        worksheetLabels));
  }
}

export class WorksheetLabelsEditorDialogData extends DialogBase<WorksheetLabelsEditorResult> implements IDialog {
  constructor(
    public readonly canEditWorksheetLabels: boolean,
    public readonly worksheetLabels: LabelDefinitions | undefined) {
    super();

    this.dismissData = new WorksheetLabelsEditorResult(false, undefined);
  }
}

export class WorksheetLabelsEditorResult {
  constructor(
    public readonly refreshRequired: boolean,
    public readonly newWorksheetLabels: LabelDefinitions | undefined){
  }
}
