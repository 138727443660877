import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem,
  KeyboardAction,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {WorksheetClipboard} from '../worksheet-clipboard.service';
import {PasteContent} from './paste-content.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class PasteDuplicateCommand extends WorksheetCommand {

  constructor(
    private readonly clipboard: WorksheetClipboard,
    private readonly pasteContent: PasteContent,
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  public canExecute(context: CommandContext): boolean {
    const userData = this.authenticationService.userDataSnapshot;
    let canPasteDuplicate = true;
    if(context.isStudy()){
      canPasteDuplicate = false;
    } else if(context.isConfig() && context.target.isTelemetry){
      canPasteDuplicate = false;
    }

    return canPasteDuplicate && context.worksheet.canWrite(userData.sub);
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    let content = this.clipboard.get();
    if(!content){
      return CommandResult.NoUpdate;
    }

    await this.pasteContent.execute(context, content, true);

    return CommandResult.UpdateAndGenerateColumns;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      'Paste Duplicate' + (context.isRowMetadata() ? ' Row' : ''),
      `paste-duplicate-button`,
      () => this.execute(context),
      'files-o',
      KeyboardAction.pasteDuplicate));
  }
}
