import {Injectable} from '@angular/core';
import {GetWorksheetForSupportSession} from './get-worksheet-for-support-session';
import {ConfirmationDialog} from '../common/dialogs/confirmation-dialog.service';
import {Router} from '@angular/router';
import {PromptDialog} from '../common/dialogs/prompt-dialog.service';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {CreateWorksheetFromStudy} from './create-worksheet-from-study';
import {CreateWorksheetRowFromStudyOptions} from './create-worksheet-row-from-study';

export const DO_NOT_OPEN_WORKSHEET = 'No';
export const OPEN_WORKSHEET = 'Yes';
export const OPEN_WORKSHEET_QUESTION = 'Would you like to open the worksheet?';
export const SUPPORT_SESSION_NAME_PREFIX = 'Support Session: ';
export const OPEN_EXISTING = 'Open Existing';
export const CREATE_NEW = 'Create New';
export const OPEN_EXISTING_WORKSHEET_QUESTION = 'A worksheet already exists for this support session. Would you like to open it?';

@Injectable()
export class CreateWorksheetFromStudyIfRequired {
  constructor(
    private readonly getWorksheetForSupportSession: GetWorksheetForSupportSession,
    private readonly createWorksheetFromStudy: CreateWorksheetFromStudy,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly promptDialog: PromptDialog,
    private readonly loadingDialog: LoadingDialog,
    private readonly router: Router,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public async execute(tenantId: string, studyId: string, name: string, fromSupportSession: boolean, options?: CreateWorksheetRowFromStudyOptions) {
    try{
      if(fromSupportSession){
        const existingWorksheet = await this.loadingDialog.showUntilFinished(
          this.getWorksheetForSupportSession.execute(studyId),
          'Searching for existing worksheet...');

        if(existingWorksheet) {
          const navigateToExistingWorksheetResult = await this.confirmationDialog.show(
            OPEN_EXISTING_WORKSHEET_QUESTION,
            'Existing Worksheet Found',
            [OPEN_EXISTING, CREATE_NEW]);

          if(!navigateToExistingWorksheetResult) {
            return;
          }

          if(navigateToExistingWorksheetResult === OPEN_EXISTING){
            await this.router.navigate(['/worksheets', existingWorksheet.tenantId, existingWorksheet.documentId]);
            return;
          }
        }

        name = SUPPORT_SESSION_NAME_PREFIX + name;
      }

      // Prompt for name.
      name = await this.promptDialog.show<string>(
        'What would you like to call the worksheet?',
        'Worksheet Name',
        name);

      if(!name){
        return;
      }

      // Create.
      const worksheetResult = await this.createWorksheetFromStudy.execute(tenantId, studyId, name, fromSupportSession, options);

      // Prompt to navigate to worksheet.
      const navigateToWorksheetResult = await this.confirmationDialog.show(
        OPEN_WORKSHEET_QUESTION,
        'Worksheet Created',
        [DO_NOT_OPEN_WORKSHEET, OPEN_WORKSHEET], DO_NOT_OPEN_WORKSHEET);

      if(navigateToWorksheetResult === OPEN_WORKSHEET){
        await this.router.navigate(['/worksheets', worksheetResult.tenantId, worksheetResult.worksheetId]);
        return;
      }
    } catch(error){
      const errorMessage = await this.getFriendlyErrorAndLog.execute(error);
      await this.confirmationDialog.show(errorMessage, 'Failed to create worksheet', 'OK', 'OK');
    }
  }
}
