import { SimulationViewModel } from './simulation-view-model';
import { StudyViewModel } from './study-view-model';
import { ConfigViewModel } from './config-view-model';
import { StudyReference, TenantInformation, UserInformation } from '../../generated/api-stubs';
import { RowMetadataViewModel } from './row-metadata-view-model';

export type ColumnItemViewModel = RowMetadataViewModel | ConfigViewModel | StudyViewModel | SimulationViewModel;
export type OptionalColumnItemViewModel = ColumnItemViewModel | undefined;

export interface TenantsByTenantId {
  [tenantId: string]: TenantInformation;
}

export interface UsersByUserId {
  [userId: string]: UserInformation;
}

export interface ITenantReference {
  readonly tenantId: string;
  readonly targetId: string;
  readonly jobIndex?: number;
}

export interface IDefaultReference {
  readonly name: string;
}

export interface ITenantOrDefaultReference {
  readonly tenant?: ITenantReference;
  readonly default?: IDefaultReference;
}

export type IReference = ITenantReference | ITenantOrDefaultReference;

export interface IHasReference {
  readonly reference: IReference;
}

export function referenceAsDefault(reference: IReference): IDefaultReference | undefined {
  return (reference as ITenantOrDefaultReference).default;
}

export function referenceAsTenant(reference: IReference): ITenantReference | undefined {
  if (referenceAsDefault(reference)) {
    return undefined;
  }

  return (reference as ITenantOrDefaultReference).tenant || (reference as ITenantReference);
}

export function referenceAsStudy(reference: IReference): StudyReference | undefined {
  if (referenceAsDefault(reference)) {
    return undefined;
  }

  const tenantReference = referenceAsTenant(reference);
  return {
    tenantId: tenantReference.tenantId,
    targetId: tenantReference.targetId,
  };
}

export function referenceEquals(a: IReference, b: IReference): boolean {
  if (a === null || a === undefined) {
    return b === null || b === undefined;
  }

  if (b === null || b === undefined) {
    return false;
  }

  const aDefault = (a as ITenantOrDefaultReference).default;
  if (aDefault) {
    const bDefault = (b as ITenantOrDefaultReference).default;
    return !!(bDefault && aDefault.name === bDefault.name);
  }

  const aTenant: ITenantReference = (a as ITenantOrDefaultReference).tenant || a as ITenantReference;
  const bTenant: ITenantReference = (b as ITenantOrDefaultReference).tenant || b as ITenantReference;

  // If either reference doesn't specify a job index we assume we're comparing a study to a config and we ignore
  // it. Otherwise if both exist we assume we're comparing two telemetry configs and include it.
  return !!(bTenant
    && aTenant.tenantId === bTenant.tenantId
    && aTenant.targetId === bTenant.targetId
    && (aTenant.jobIndex === undefined || bTenant.jobIndex === undefined || aTenant.jobIndex === bTenant.jobIndex));
}
