import {IReference} from './worksheet-types';
import {referenceToId} from './reference-to-id';
import {ConfigViewModel} from './config-view-model';
import {StudyViewModel} from './study-view-model';

export class WorksheetReferencesMetadata {
  private readonly map: { [referenceId: string]: WorksheetReferenceMetadata } = {};

  constructor(){
  }

  public incrementConfig(config: ConfigViewModel) {
    let metadata = this.getReferenceMetadata(config.reference);
    metadata.incrementConfig(config);
  }

  public incrementStudy(study: StudyViewModel) {
    let metadata = this.getReferenceMetadata(study.reference);
    metadata.incrementStudy(study);
  }

  public decrementConfig(config: ConfigViewModel) {
    let metadata = this.getReferenceMetadata(config.reference);
    metadata.decrementConfig(config);
  }

  public decrementStudy(study: StudyViewModel) {
    let metadata = this.getReferenceMetadata(study.reference);
    metadata.decrementStudy(study);
  }

  private getReferenceMetadata(reference: IReference) {
    const id = referenceToId(reference);
    let metadata = this.map[id];
    if (!metadata) {
      metadata = new WorksheetReferenceMetadata();
      this.map[id] = metadata;
    }
    return metadata;
  }

  public get(reference: IReference): IReadonlyWorksheetReferenceMetadata {
    return this.map[referenceToId(reference)] || WorksheetReferenceMetadata.empty;
  }
}

export interface IReadonlyWorksheetReferenceMetadata {
  readonly count: number;
  readonly configCount: number;
  readonly studyCount: number;
  readonly configs: ReadonlySet<ConfigViewModel>;
  readonly studies: ReadonlySet<StudyViewModel>;
}

export class WorksheetReferenceMetadata implements IReadonlyWorksheetReferenceMetadata {
  public static readonly empty: WorksheetReferenceMetadata = new WorksheetReferenceMetadata();

  private _configs: Set<ConfigViewModel> = new Set<ConfigViewModel>();
  private _studies: Set<StudyViewModel> = new Set<StudyViewModel>();

  constructor() {
  }

  public get count(): number {
    return this._configs.size + this._studies.size;
  }

  public get configCount(): number {
    return this._configs.size;
  }

  public get studyCount(): number {
    return this._studies.size;
  }

  public get configs(): ReadonlySet<ConfigViewModel> {
    return this._configs;
  }

  public get studies(): ReadonlySet<StudyViewModel> {
    return this._studies;
  }

  public incrementConfig(config: ConfigViewModel) {
    if(this === WorksheetReferenceMetadata.empty){
      throw new Error('Cannot increment the empty reference.');
    }

    this._configs.add(config);
  }

  public incrementStudy(study: StudyViewModel) {
    if(this === WorksheetReferenceMetadata.empty){
      throw new Error('Cannot increment the empty reference.');
    }

    this._studies.add(study);
  }

  public decrementConfig(config: ConfigViewModel) {
    if(this === WorksheetReferenceMetadata.empty){
      throw new Error('Cannot increment the empty reference.');
    }

    this._configs.delete(config);
  }

  public decrementStudy(study: StudyViewModel) {
    if(this === WorksheetReferenceMetadata.empty){
      throw new Error('Cannot increment the empty reference.');
    }

    this._studies.delete(study);
  }
}
