import { IEditChannelsType, NavigationStationViewer } from '../../navigation-station/navigation-station-viewer';
import { IEditChannelsOptions, PaneChannelLayout, SiteHooks } from '../../site-hooks';
import { SharedState } from '../shared-state';
import { ExtractSuspensionDataFromCar } from './extract-suspension-data-from-car';
import { SuspensionViewer3dSettings } from './suspension-viewer-3d-settings';

import {
  Vector3,
  Group,
  Box3,
  Raycaster
} from 'three';

import { Visualization3dBase } from '../3d/visualization-3d-base';
import { SuspensionRenderer } from './suspension-renderer';
import { OrbitControlsRenderer } from '../3d/orbit-controls-renderer';
import { SimpleLightingRenderer } from '../3d/simple-lighting-renderer';
import { FloorRenderer } from '../3d/floor-renderer';
import { loadConfigsForSources, LoadedConfig } from '../3d/load-configs-for-sources';
import { CONFIG_TYPE_CAR } from '../../constants';

export const SUSPENSION_VIEWER_TYPE = 'suspensionViewer';

export class SuspensionViewer3d extends Visualization3dBase implements NavigationStationViewer {

  private settings?: SuspensionViewer3dSettings;

  private orbitControlsRenderer?: OrbitControlsRenderer;
  private lightingRenderer?: SimpleLightingRenderer;
  private floorRenderer?: FloorRenderer;

  private readonly loadedCars: LoadedConfig[] = [];
  private suspensionRendererGroup?: Group;

  constructor(
    siteHooks: SiteHooks,
    sharedState: SharedState,
    private readonly suspensionRenderer: SuspensionRenderer
  ) {
    super(0, siteHooks, sharedState);

    this.cameraNear = 0.01;
    this.cameraFar = 100;
  }

  public static create(
    siteHooks: SiteHooks,
    sharedState: SharedState): SuspensionViewer3d {
    return new SuspensionViewer3d(
      siteHooks,
      sharedState,
      new SuspensionRenderer(sharedState, new ExtractSuspensionDataFromCar()));
  }

  public dispose() {
    super.dispose();
    this.suspensionRenderer.dispose();
  }

  public async build(elementId: string): Promise<any> {
    this.settings = SuspensionViewer3dSettings.build(this.sharedState.sourceLoaderSet.sources.length);

    await this.loadCurrentCars();

    if (!await super.build(elementId, this.settings)) {
      return;
    }

    if (!this.scene || !this.sceneParent || !this.cameraState || !this.svg || !this.element) {
      return;
    }

    this.suspensionRendererGroup = this.suspensionRenderer.build(this.settings, this.svg, this.loadedCars);
    this.scene.add(this.suspensionRendererGroup);
    let renderedGroup = this.suspensionRenderer.groupWithoutIgnoredObjects(this.suspensionRendererGroup);
    let carBoundingBox = new Box3().setFromObject(renderedGroup);
    let centerX = (carBoundingBox.max.x + carBoundingBox.min.x) / 2;
    let resetPosition = new Vector3(centerX, 1.5, -2.2);
    let resetTarget = new Vector3(centerX, 0, 0);

    this.orbitControlsRenderer = new OrbitControlsRenderer(
      this.settings,
      this.element,
      this.svg,
      this.sceneParent,
      this.scene,
      this.cameraState);
    this.orbitControlsRenderer.build(resetPosition, resetTarget);

    if (this.isRenderingManually) {
      this.orbitControlsRenderer.addEventListener('change', () => this.render3d());
    }

    this.lightingRenderer = new SimpleLightingRenderer(this.scene);
    this.lightingRenderer.build();

    this.floorRenderer = new FloorRenderer(this.scene, 10, -0.001);
    this.floorRenderer.build(this.suspensionRendererGroup);

    this.beginRender();
  }

  private async loadCurrentCars(): Promise<void> {
    let cars = await loadConfigsForSources(
      this.sharedState,
      CONFIG_TYPE_CAR,
      true);
    this.loadedCars.length = 0;
    this.loadedCars.push(...cars);
  }

  protected async performSourcesChanged() {
    await this.loadCurrentCars();
  }

  protected processLayout() {
  }

  protected performBuild2d() {
  }

  protected performSetData2d() {
  }

  protected performRender2d() {
    if (this.orbitControlsRenderer) {
      this.orbitControlsRenderer.render2d();
    }
  }

  protected performAnimate3d(mouseRaycaster: Raycaster | undefined) {

    if (this.lightingRenderer) {
      this.lightingRenderer.animate();
    }

    if (this.orbitControlsRenderer) {
      this.orbitControlsRenderer.animate();
    }

    let sceneModified = this.suspensionRenderer.update(mouseRaycaster);

    if (sceneModified) {
      this.floorRenderer.update();
    }
  }

  public getLayout(): any | undefined {
    return undefined;
  }

  public setLayout(layout: any): Promise<any> {
    return Promise.resolve();
  }

  public canImportData(): boolean {
    return false;
  }

  public canExportData(): boolean {
    return false;
  }

  public canStackDiagonals(): boolean {
    return false;
  }

  public importCsvData(name: string, fileContent: string) {
  }

  public exportCsvData(): Promise<void> {
    return Promise.resolve();
  }

  public getEditChannelsTypes(): IEditChannelsType[] {
    return [];
  }

  public getEditChannelsOptions(editType: IEditChannelsType): Promise<IEditChannelsOptions | undefined> {
    return Promise.resolve(undefined);
  }

  public setEditChannelsResult(editType: IEditChannelsType, result: PaneChannelLayout): Promise<void> {
    return Promise.resolve();
  }
}
