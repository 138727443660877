import {
  ConfigReference,
  DocumentSubType,
} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {LoadConfigFromReference} from './load-config-from-reference';
import {
  CompareConfigDialog,
} from '../simulations/configs/comparing/compare-config-dialog/compare-config-dialog.service';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {GetSimVersion} from '../common/get-sim-version.service';
import {SaveToConfigReferenceHandlerFactory} from '../simulations/configs/comparing/save-output-config-handlers/save-to-config-reference-handler';
import {ConfigLoaderDialog} from '../simulations/configs/config-loader-dialog/config-loader-dialog.service';
import {ConfigOrConfigLoader} from '../simulations/configs/comparing/config-or-config-loader';

@Injectable()
export class CompareConfigToSomething {
  constructor(
    private readonly configLoaderDialog: ConfigLoaderDialog,
    private readonly loadingDialog: LoadingDialog,
    private readonly loadConfigFromReference: LoadConfigFromReference,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly getSimVersion: GetSimVersion,
    private readonly saveToConfigReferenceHandlerFactory: SaveToConfigReferenceHandlerFactory) {
  }

  public async execute(configType: DocumentSubType, configReference: ConfigReference, referenceShortName?: string) {
    let simVersion = this.getSimVersion.currentSimVersion;

    let selectedConfig = await this.configLoaderDialog.loadConfig(
      configType,
      simVersion,
      undefined,
      undefined,
      false,
      true);

    if(!selectedConfig){
      return;
    }

    const config = await this.loadingDialog.showUntilFinished(
      this.loadConfigFromReference.execute(configType, configReference),
      'Loading config...');

    let configs: ConfigOrConfigLoader[] = [
      new ConfigOrConfigLoader(referenceShortName || 'worksheet', config, undefined),
    ];

    if(selectedConfig.configId){
      configs.push(new ConfigOrConfigLoader('selected', selectedConfig.config, undefined));
    }

    await this.compareConfigDialog.compare(
      configType,
      configs,
      this.saveToConfigReferenceHandlerFactory.create(configReference));
  }
}
