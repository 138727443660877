import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SplitIntegers2DEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitIntegers2D = JSONEditor.defaults.editors.splitNumbers2D.extend({
      getValue() {
        return (<any>this)._super().map(function(line: number[]) {
 return line.map(function(v) {
 return Math.trunc(v); 
}); 
});
      }
    });
  }
}
