import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {StudyViewModel} from '../study-view-model';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class ExtractStudyInputsCommand extends WorksheetCommand {

  constructor(
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<StudyViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isStudy()
      && context.worksheet.canWrite(userData.sub)
      && (context.target.isResolved || context.target.isErrored);
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    for(let row of context.selectedRows){
      await row.study.extractStudyInputs();
    }

    return CommandResult.UpdateOnly;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Extract Inputs`,
      `extract-inputs-button`,
      () => this.execute(context),
      'long-arrow-left'));
  }
}
