import { Units } from '../../../../visualizations/units';
import { Injectable } from '@angular/core';
import { humanizeNumber } from '../humanize-number';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SplitNumbersEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitNumbers = JSONEditor.defaults.editors.splitStrings.extend({
      postBuild() {
        this._super();
        this.initializeUnitChangedSubscription();
      },
      getValue() {
        if (this.initialValue && this.value === this.initialValue.display) {
          return this.initialValue.raw;
        }

        let defaultUnits = this.getDefaultUnits();
        return Units.convertValuesBetweenUnits(this._super().map((str: string) => +str), this.valueUnits, defaultUnits);
      },
      setValue(value: any, initial: any, from_template: boolean) {
        let defaultUnits = this.getDefaultUnits();
        this.valueUnits = this.getUnits();
        let isConversionRequired = Units.isConversionRequired(defaultUnits, this.valueUnits);

        value = this.sanitizeArray(value);
        this.replaceNullWithNaN(value);

        let displayValues = value.slice();
        if (isConversionRequired) {
          displayValues = Units.convertValuesBetweenUnits(displayValues.map((v: string) => +v), defaultUnits, this.valueUnits)
            .map(humanizeNumber);
        }

        this._super(displayValues, initial, from_template);

        let displayValue = this.input.value;

        this.initialValue = {
          raw: value,
          display: displayValue
        };
      }
    });
  }
}
