import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {StudyViewModel} from '../study-view-model';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {LOAD_ICON} from '../worksheet-item-component-base';
import {StudyLoaderDialog} from '../study-loader-dialog/study-loader-dialog.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class LoadStudyCommand extends WorksheetCommand {

  constructor(
    private readonly studyLoaderDialog: StudyLoaderDialog,
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<StudyViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isStudy()
      && context.worksheet.canWrite(userData.sub);
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    const rows = context.selectedRows;
    const userData = this.authenticationService.userDataSnapshot;
    const studyResult = await this.studyLoaderDialog.loadStudy(
      userData.tenant,
      userData.sub,
      undefined);

    if(!studyResult) {
      return CommandResult.NoUpdate;
    }

    for(let row of rows) {
      row.study.setStudy({
        tenantId: userData.tenant,
        targetId: studyResult.studyId
      });
    }

    return CommandResult.UpdateAndGenerateColumns;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Load`,
      `set-button`,
      () => this.execute(context),
      LOAD_ICON));
  }
}
