import {
  ConfigResolvedLabels,
  ConfigResolvedReference,
  DocumentSubType,
  GetWorksheetQueryResult,
  SimType,
  StudyResolvedLabels,
  StudyResolvedReference,
  StudyType, StudyTypeDefinition
} from '../../generated/api-stubs';
import {SimTypeMap, StudyTypeLookup, StudyTypeMap} from '../simulations/studies/study-type-lookup.service';
import {ConfigTypeLookup} from '../simulations/configs/config-types';
import {ResolvedByReference} from './resolved-by-reference';
import {TenantsByTenantId, UsersByUserId} from './worksheet-types';
import {DisplayableError} from '../common/errors/errors';
import {Injectable} from '@angular/core';
import {WorksheetReferencesMetadata} from './worksheet-references-metadata';
import {getFilteredStudyTypes} from '../simulations/study-staging-area/study-staging-area.component';
import {GetSimVersion} from '../common/get-sim-version.service';

@Injectable()
export class WorksheetUnderlyingDataFactory {

  constructor(
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly getSimVersion: GetSimVersion){
  }

  public async create(worksheetResult: GetWorksheetQueryResult): Promise<WorksheetUnderlyingData>{
    let simVersion = this.getSimVersion.currentSimVersion;
    const studyTypeList = getFilteredStudyTypes(await this.studyTypeLookup.getStudyTypeList(simVersion));
    const studyTypeMap = await this.studyTypeLookup.getStudyTypeMap(simVersion);
    const simTypeMap = await this.studyTypeLookup.getSimTypeMap();

    if(studyTypeList.length === 0){
      throw new DisplayableError('No study types found.');
    }

    return new WorksheetUnderlyingData(simVersion, worksheetResult, studyTypeList, studyTypeMap, simTypeMap);
  }
}

export class WorksheetUnderlyingData {
  public readonly tenants: TenantsByTenantId;
  public readonly users: UsersByUserId;

  public readonly configResolvedReferences: ResolvedByReference<ConfigResolvedReference>;
  public readonly studyResolvedReferences: ResolvedByReference<StudyResolvedReference>;
  public readonly configResolvedLabels: ResolvedByReference<ConfigResolvedLabels>;
  public readonly studyResolvedLabels: ResolvedByReference<StudyResolvedLabels>;

  public readonly referencesMetadata: WorksheetReferencesMetadata = new WorksheetReferencesMetadata();

  constructor(
    public readonly simVersion: string,
    public readonly worksheetResult: GetWorksheetQueryResult,
    public readonly studyTypesList: ReadonlyArray<StudyTypeDefinition>,
    public readonly studyTypes: StudyTypeMap,
    public readonly simTypes: SimTypeMap) {

    this.tenants = worksheetResult.userInformation.tenants
      .reduce((p, c) => {
        p[c.tenantId] = c;
        return p;
      }, {} as TenantsByTenantId);

    this.users = worksheetResult.userInformation.tenants
      .map(v => v.users)
      .reduce((p, c) => p.concat(c), [])
      .reduce((p, c) => {
        p[c.userId] = c;
        return p;
      }, {} as UsersByUserId);

    this.configResolvedReferences = worksheetResult.worksheet.resolvedReferences.configs
      .reduce((p, c) => {
        p.add(c);
        return p;
      }, new ResolvedByReference<ConfigResolvedReference>(true));

    this.studyResolvedReferences = worksheetResult.worksheet.resolvedReferences.studies
      .reduce((p, c) => {
        p.add(c);
        return p;
      }, new ResolvedByReference<StudyResolvedReference>());

    this.configResolvedLabels = worksheetResult.worksheet.resolvedLabels.configs
      .reduce((p, c) => {
        p.add(c);
        return p;
      }, new ResolvedByReference<ConfigResolvedLabels>(true));

    this.studyResolvedLabels = worksheetResult.worksheet.resolvedLabels.studies
      .reduce((p, c) => {
        p.add(c);
        return p;
      }, new ResolvedByReference<StudyResolvedLabels>());
  }

  public getConfigTypeName(configType: DocumentSubType): string {
    const resolved = ConfigTypeLookup.get(configType);
    if (resolved) {
      return resolved.titleName;
    }
    return configType;
  }

  public getConfigTypePluralKey(configType: DocumentSubType): string {
    const resolved = ConfigTypeLookup.get(configType);
    if (resolved) {
      return resolved.pluralKey;
    }
    return configType;
  }

  public getStudyTypeName(studyType: StudyType): string {
    const resolved = this.studyTypes[studyType];
    if (resolved) {
      return resolved.name;
    }
    return studyType;
  }

  public getSimTypeName(simType: SimType): string {
    const resolved = this.simTypes[simType];
    if (resolved) {
      return resolved.name;
    }
    return simType;
  }
}
