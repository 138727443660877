import {Injectable} from '@angular/core';
import {DocumentSubType, WorksheetStub} from '../../generated/api-stubs';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {CustomPropertyUtilities} from '../simulations/custom-properties/custom-property-utilities';
import {referenceEquals} from './worksheet-types';
import {GetSimVersion} from '../common/get-sim-version.service';
import {ConfirmationDialog} from '../common/dialogs/confirmation-dialog.service';
import { UserData } from '../identity/state/authentication.service';

export const CONTINUE_CONFIRMATION_DIALOG_RESPONSE = 'OK';

@Injectable()
export class CloneWorksheet {

  constructor(
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly worksheetStub: WorksheetStub,
    private readonly getSimVersion: GetSimVersion,
    private readonly loadingDialog: LoadingDialog){
  }

  // Clone all configs.
  // Copy study names to row if row doesn't already have name.
  // Remove all studies.
  public async execute(
    userData: UserData,
    name: string,
    sourceTenantId: string,
    sourceWorksheetId: string): Promise<string> {

    const confirmationResult = await this.confirmationDialog.show(
      'Configs and studies cannot be shared between worksheets. '
      + 'Cloning a worksheet will therefore duplicate all configs and remove all studies (including telemetry). '
      + 'If the row does not already have a name, the study name will be copied to the row name.',
      'Clone Worksheet',
      CONTINUE_CONFIRMATION_DIALOG_RESPONSE, 'Cancel');

    if(confirmationResult !== CONTINUE_CONFIRMATION_DIALOG_RESPONSE){
      return undefined;
    }

    const sourceWorksheetResult = await this.loadingDialog.showUntilFinished(
      this.worksheetStub.getWorksheet(
        sourceTenantId,
        sourceWorksheetId),
      'Loading worksheet...');

    const properties = CustomPropertyUtilities.objectToList(sourceWorksheetResult.worksheet.properties);
    const notes = sourceWorksheetResult.worksheet.notes;

    const worksheetResult = await this.loadingDialog.showUntilFinished(
      this.worksheetStub.postWorksheet(
        userData.tenant,
        {
          name,
        }),
      'Creating worksheet...');

    const worksheetId = worksheetResult.worksheet.worksheetId;
    const worksheetOutline = sourceWorksheetResult.worksheet.outline;
    const rows = sourceWorksheetResult.worksheet.outline.rows || [];
    const sourceConfigIdSet: Set<string> = new Set<string>();
    for(let row of rows){
      if(row.study.reference){
        if(!row.name){
          const resolvedStudy = sourceWorksheetResult.worksheet.resolvedReferences.studies.find(
            v => referenceEquals(row.study.reference, v.reference));
          if(resolvedStudy){
            row.name = resolvedStudy.data.name;
          }
        }
      }

      row.study = { reference: undefined };
      row.configs = row.configs.filter(v => v.configType !== DocumentSubType.telemetry);

      for(let config of row.configs || []){
        if(config.reference && config.reference.tenant) {
          sourceConfigIdSet.add(config.reference.tenant.targetId);
        }
      }
    }

    const sourceConfigIds = Array.from(sourceConfigIdSet);
    const duplicationResult = await this.loadingDialog.showUntilFinished(
      this.worksheetStub.postDuplicateConfigs(
        userData.tenant,
        worksheetResult.worksheet.worksheetId,
        {
          sourceTenantId,
          sourceWorksheetId,
          sourceConfigIds,
        },
        this.getSimVersion.currentSimVersion),
      'Cloning configs...');

    const map: {[input: string]: string} = {};
    for(let i=0; i < sourceConfigIds.length; ++i){
      map[sourceConfigIds[i]] = duplicationResult.targetConfigIds[i];
    }

    for(let row of rows){
      for(let config of row.configs || []){
        if(config.reference && config.reference.tenant) {
          config.reference.tenant.tenantId = userData.tenant;
          config.reference.tenant.targetId = map[config.reference.tenant.targetId];
        }
      }
    }

    worksheetOutline.rows = rows;

    await this.loadingDialog.showUntilFinished(
      this.worksheetStub.putWorksheet(
        userData.tenant,
        worksheetId,
        {
          name,
          properties,
          notes,
          outline: worksheetOutline,
        }),
      'Saving worksheet...');

    return worksheetResult.worksheet.worksheetId;
  }
}
