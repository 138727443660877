import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class IntegerEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.integer = JSONEditor.defaults.editors.number.extend({
      sanitize(value: string) {
        value = value + '';
        return value.replace(/[^0-9\-]/g, '');
      },
      getNumColumns() {
        return 2;
      }
    });
  }
}
