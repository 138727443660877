import { SVGSelection } from '../../untyped-selection';
import { Object3D } from 'three';
import { IMargin } from '../margin';
import { ISize } from '../size';
import { ILegendSettings } from '../legend-settings';
import { RenderedSuspensionMember } from './suspension-renderer';

export const CONTAINER_CLASS = 'selected-components';

interface IChartSettings {
  readonly svgSize: ISize;
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly legend: ILegendSettings;
}

export class SelectedComponentsRenderer {
  private readonly inner: SelectedComponentsRendererInner;

  constructor(settings: IChartSettings) {
    this.inner = new SelectedComponentsRendererInner(settings);
  }

  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  public selectedComponents(value: ReadonlyArray<Object3D>): this {
    this.inner.selectedComponents = value;
    return this;
  }
}

export class SelectedComponentsRendererInner {

  public selectedComponents: ReadonlyArray<Object3D> = [];

  constructor(public readonly settings: IChartSettings) {
  }

  public render(selection: SVGSelection) {

    let containerUpdate = selection.selectAll<SVGGElement, any>('.' + CONTAINER_CLASS).data([null]);
    let containerEnter = containerUpdate.enter().append('g').attr('class', CONTAINER_CLASS);
    let container = containerEnter.merge(containerUpdate);

    container.attr('transform', `translate(${this.settings.svgSize.width - this.settings.svgPadding.right - this.settings.chartMargin.right}, ${this.settings.svgPadding.top + this.settings.chartMargin.top + this.settings.chartSize.height})`);

    let gUpdate = container.selectAll<SVGGElement, RenderedSuspensionMember>('.selected-component').data(() => (this.selectedComponents || []).map(v => v.userData.model as RenderedSuspensionMember));
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g').attr('class', 'selected-component');
    let g = gEnter.merge(gUpdate);

    g.attr('transform', (v, i) => `translate(0, ${i * -15})`);

    gEnter.append('text').attr('font-size', '12px');
    g.select('text')
      .attr('fill', v => v.colorString)
      .text((v) => v.suspensionArea.name + ' - ' + v.suspensionMember.name);
  }
}
