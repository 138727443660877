export class UndoHistoryTracker<T> {
  private readonly history: T[] = [];

  private position: number = 0;

  constructor(public readonly historySize: number) {
  }

  public get canUndo(): boolean {
    return this.history.length > 0 && this.position > 0;
  }

  public get canRedo(): boolean {
    return this.history.length > 0 && this.position < this.history.length - 1;
  }

  public get undoBufferSize(): number {
    return this.position;
  }

  public get redoBufferSize(): number {
    return this.history.length - this.position - 1;
  }

  public add(item: T) {
    if(this.canRedo){
      this.history.splice(this.position + 1);
    }

    this.history.push(item);
    this.position = this.history.length - 1;

    if(this.history.length > this.historySize){
      this.history.splice(0, 1);
      --this.position;
    }
  }

  public undo(): T | undefined {
    if(!this.canUndo){
      return undefined;
    }

    return this.history[--this.position];
  }

  public redo(): T | undefined {
    if(!this.canRedo){
      return undefined;
    }

    return this.history[++this.position];
  }
}
