import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class EncryptedEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.encrypted = JSONEditor.defaults.editors.string.extend({
      getValue() {
        if (this.encryptedValue) {
          this.encryptedValue.description = this.value;
        }
        return this.encryptedValue;
      },
      setValue(value: any, initial: any, from_template: boolean) {
        if (value && value.name === 'encrypted') {
          this.encryptedValue = value;
          this._super(this.encryptedValue.description, initial, from_template);

          if (this.encryptedValue.channelWhitelist) {
            this.description.innerText = 'Encrypted component whitelisting channels ' + this.encryptedValue.channelWhitelist.join(', ') + '.';
          } else {
            this.description.innerText = 'Encrypted component.';
          }
        }
      },
      build() {
        this._super();

        this.container.setAttribute('data-encryptedpath', this.path);
      },
      getNumColumns() {
        return 2;
      },
    });

    // JSONEditor.defaults.editors.encrypted = JSONEditor.defaults.editors.string.extend({
    //   getValue: function() {
    //     return this.encryptedValue;
    //   },
    //   setValue: function(value: any) {
    //     this.encryptedValue = value;
    //     this.updateDescription();
    //     this.onChange();
    //   },
    //   getNumColumns: function() {
    //     return 2;
    //   },
    //   updateDescription: function() {
    //
    //     while(this.encryptedOuterElement.firstChild) {
    //       this.encryptedOuterElement.firstChild.remove();
    //     }
    //
    //     let encryptedDescriptionElement = document.createElement('p');
    //     this.encryptedOuterElement.appendChild(encryptedDescriptionElement);
    //
    //     let icon = document.createElement('i');
    //     icon.className = 'fa fa-lock';
    //     encryptedDescriptionElement.appendChild(icon);
    //     if(this.encryptedValue){
    //       encryptedDescriptionElement.appendChild(document.createTextNode(this.encryptedValue.description));
    //     }
    //   },
    //   build: function() {
    //     this._super();
    //
    //     this.encryptedOuterElement = document.createElement('div');
    //     this.container.appendChild(this.encryptedOuterElement);
    //     this.updateDescription();
    //   }
    // });
  }
}
