import * as d3 from '../../d3-bundle';
import { IEditChannelsType, NavigationStationViewer } from '../../navigation-station/navigation-station-viewer';
import { IEditChannelsOptions, PaneChannelLayout, SiteHooks } from '../../site-hooks';
import { SharedState } from '../shared-state';
import { TrackViewer3dSettings } from './track-viewer-3d-settings';

import {
  Vector3,
  Group,
  Box3,
  Raycaster, ArrowHelper
} from 'three';

import { Visualization3dBase } from '../3d/visualization-3d-base';
import { OrbitControlsRenderer } from '../3d/orbit-controls-renderer';
import { SimpleLightingRenderer } from '../3d/simple-lighting-renderer';
import { FloorRenderer } from '../3d/floor-renderer';
import { TrackRenderer } from './track-renderer';
import { loadConfigsForSources, LoadedConfig } from '../3d/load-configs-for-sources';
import { ExtractTrackData } from './extract-track-data';
import { degreesToRadians } from '../../degrees-to-radians';
import { CONFIG_TYPE_TRACK, SRUN_DOMAIN_NAME } from '../../constants';
import {
  ColumnLegendList,
} from '../multi-plot-viewer-base/multi-plot-viewer-base';
import { SourceLoaderUtilities } from '../channel-data-loaders/source-loader-utilities';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { MultiPlotViewerLegendRenderChannel } from '../data-pipeline/types/multi-plot-viewer-legend-render-channel';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { IMultiPlotSide } from '../data-pipeline/types/i-multi-plot-side';
import { IMultiPlotLayout } from '../data-pipeline/types/i-multi-plot-layout';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';
import { LegendRenderChannel, LegendRenderer, RenderOrientation } from '../components/legend-renderer';
import { Margin } from '../margin';
import {
  DomainEventHandler,
  LEGEND_CHANGED_EVENT
} from '../multi-plot-viewer-base/domain-event-handler';
import { DomainSnapBehaviour } from '../multi-plot-viewer-base/multi-plot-data-renderer-base';
import { DataPipeline } from '../data-pipeline/data-pipeline';

export const TRACK_VIEWER_TYPE = 'trackViewer';

export const EDIT_TYPE_CHANNELS = 'channels';

// LAYOUT:
// This is using the same layout format as the multi-plot viewer so we can share its processing.
// Single column is always sRun. First row represents "Fill", second row represents "Height".

export class TrackViewer3d extends Visualization3dBase implements NavigationStationViewer {

  private settings?: TrackViewer3dSettings;

  private orbitControlsRenderer?: OrbitControlsRenderer;
  private lightingRenderer?: SimpleLightingRenderer;
  private floorRenderer?: FloorRenderer;

  private readonly loadedTracks: LoadedConfig[] = [];
  private trackRendererGroup?: Group;

  protected rowLegendRenderer?: LegendRenderer;
  protected xLegendList?: ColumnLegendList;
  protected domainEventHandler?: DomainEventHandler;

  protected legendRenderChannels: ReadonlyArray<LegendRenderChannel> = [
    new MultiPlotViewerLegendRenderChannel(
      'Fill',
      '\uf1fc',
      'colorChannel',
      () => this.populatedLayout),
    new MultiPlotViewerLegendRenderChannel(
      'Size',
      '\uf07d',
      'sizeChannel',
      () => this.populatedLayout),
  ];

  constructor(
    private readonly primaryDomainName: string,
    private readonly channelNameStyle: ChannelNameStyle,
    private layout: IMultiPlotLayout | undefined,
    siteHooks: SiteHooks,
    sharedState: SharedState,
    private readonly trackRenderer: TrackRenderer,
    private readonly dataPipeline: DataPipeline,
  ) {
    super(0, siteHooks, sharedState, !!layout);

    this.cameraNear = 10;
    this.cameraFar = 50000;
  }

  public dispose() {
    super.dispose();
    if (this.trackRenderer) {
      this.trackRenderer.dispose();
    }

    if (this.domainEventHandler) {
      this.domainEventHandler.dispose();
    }
  }

  public static create(
    siteHooks: SiteHooks,
    sharedState: SharedState,
    primaryDomainName?: string,
    layout?: IMultiPlotLayout): TrackViewer3d {

    primaryDomainName = primaryDomainName || SRUN_DOMAIN_NAME;
    let channelNameStyle = ChannelNameStyle.Generic;
    let interpolator = new GetInterpolatedChannelValueAtDomainValue();

    return new TrackViewer3d(
      primaryDomainName,
      channelNameStyle,
      layout,
      siteHooks,
      sharedState,
      new TrackRenderer(sharedState, new ExtractTrackData()),
      DataPipeline.create(primaryDomainName, siteHooks, sharedState.sourceLoaderSet, channelNameStyle, interpolator, true));
  }

  public get populatedLayout(): IPopulatedMultiPlotLayout {
    if (!this.layout) {
      throw new Error('Layout is not defined.');
    }

    return this.layout as IPopulatedMultiPlotLayout;
  }

  public async build(elementId: string): Promise<any> {
    this.settings = TrackViewer3dSettings.build(this.sharedState.sourceLoaderSet.sources.length);

    if (this.layout) {
      this.settings.legend.renderChannelCount = this.legendRenderChannels.length;
    }

    await this.loadCurrentTracks();
    if (!await super.build(elementId, this.settings, this.layout)) {
      return;
    }

    if (!this.scene || !this.sceneParent || !this.svg || !this.cameraState || !this.element) {
      return;
    }

    this.trackRendererGroup = this.trackRenderer.build(this.settings, this.svg, this.loadedTracks);
    this.scene.add(this.trackRendererGroup);
    let renderedGroup = this.trackRenderer.groupWithoutIgnoredObjects(this.trackRendererGroup);
    let trackBoundingBox = new Box3().setFromObject(renderedGroup);
    let centerX = (trackBoundingBox.max.x + trackBoundingBox.min.x) / 2;
    let centerZ = (trackBoundingBox.max.z + trackBoundingBox.min.z) / 2;

    let extentX = Math.abs(trackBoundingBox.max.x - centerX);
    let extentZ = Math.abs(trackBoundingBox.max.z - centerZ);
    let cameraHeight = trackBoundingBox.max.y + this.calculateCameraHeight(this.settings.svgSize.width, this.settings.svgSize.height, this.cameraState.fov, extentX, extentZ);

    let resetPosition = new Vector3(centerX, cameraHeight, centerZ);
    let resetTarget = new Vector3(centerX, 0, centerZ);
    this.orbitControlsRenderer = new OrbitControlsRenderer(
      this.settings,
      this.element,
      this.svg,
      this.sceneParent,
      this.scene,
      this.cameraState);
    this.orbitControlsRenderer.build(resetPosition, resetTarget);

    if (this.isRenderingManually) {
      this.orbitControlsRenderer.addEventListener('change', () => this.render3d());
      this.subscriptions.add(this.sharedState.getDomainNews(this.primaryDomainName).observable.subscribe(() => this.render3d()));
    }

    this.lightingRenderer = new SimpleLightingRenderer(this.scene);
    this.lightingRenderer.build();

    let floorOffsetY = trackBoundingBox.min.y - 10;
    this.floorRenderer = new FloorRenderer(this.scene, 0.01, floorOffsetY);
    this.floorRenderer.build(this.trackRendererGroup);

    this.drawCompass(centerX, floorOffsetY, centerZ);

    this.beginRender();
  }

  private calculateCameraHeight(
    viewWidth: number,
    viewHeight: number,
    cameraFov: number,
    trackMaxOffsetX: number,
    trackMaxOffsetZ: number): number {

    if (viewHeight > viewWidth) {
      // Swap width and height.
      // noinspection JSSuspiciousNameCombination
      return this.calculateCameraHeight(viewHeight, viewWidth, cameraFov, trackMaxOffsetZ, trackMaxOffsetX);
    }

    let heightHalfFovRadians = degreesToRadians(cameraFov / 2);
    let viewDistance = viewHeight / Math.tan(heightHalfFovRadians);
    let widthHalfFovRadians = Math.atan(viewWidth / viewDistance);

    let distanceToFitTrackX = trackMaxOffsetX / Math.tan(widthHalfFovRadians);
    let distanceToFitTrackZ = trackMaxOffsetZ / Math.tan(heightHalfFovRadians);

    return 1.05 * d3.maxStrict([distanceToFitTrackX, distanceToFitTrackZ]);
  }

  private drawCompass(x: number, y: number, z: number) {
    if (!this.scene) {
      return;
    }

    let north = new Vector3(0, 0, -1);
    let origin = new Vector3(x, y, z);
    let length = 33;
    let color = 0xaa0000;
    let arrowHelper = new ArrowHelper(north, origin, length, color, 7, 5);
    this.scene.add(arrowHelper);
  }

  private async loadCurrentTracks(): Promise<void> {
    let tracks = await loadConfigsForSources(
      this.sharedState,
      CONFIG_TYPE_TRACK,
      true);
    this.loadedTracks.length = 0;
    this.loadedTracks.push(...tracks);
  }

  protected updateChartMargin() {
    if (!this.settings) {
      return;
    }

    if (this.layout) {
      this.dataPipeline.updatePlotSizes.execute(this.populatedLayout, this.settings);
    }

    super.updateChartMargin();

    if (this.layout) {
      let sourceCount = this.settings.sourceCount;
      let legendLabels: string[] =
        [...this.populatedLayout.rows, ...this.populatedLayout.columns].reduce<string[]>((p, c) => [...p, ...c.processed.channels.map(v => v.name)], []);

      this.settings.chartMargin = new Margin(
        this.settings.chartMargin.top,
        this.settings.legend.getRequiredHorizontalSpace(sourceCount, legendLabels),
        this.settings.chartMargin.bottom,
        this.settings.chartMargin.left);
    }
  }

  protected processLayout() {
    if (!this.layout || !this.sourceData) {
      return;
    }

    this.dataPipeline.processLayout.execute(this.layout, this.sourceData);
  }

  protected onLegendChanged() {
    if (!this.svg || !this.svgParent || !this.rowLegendRenderer) {
      return;
    }

    this.rowLegendRenderer.render(this.svg, this.svgParent);
  }

  protected async performSourcesChanged() {
    await this.loadCurrentTracks();
  }

  protected performBuild2d() {
    this.processLayout();

    if (!this.layout || !this.settings) {
      return;
    }

    this.xLegendList = new ColumnLegendList(this.settings.legend, -10);

    this.rowLegendRenderer = new LegendRenderer();
    this.rowLegendRenderer
      .chartSettings(this.settings)
      .orientation(RenderOrientation.vertical)
      .siteHooks(this.siteHooks)
      .renderChannels(this.legendRenderChannels)
      .setError((message) => this.setError(message))
      .on('changed', () => {
        this.renderAll(true);
        if (this.isRenderingManually) {
          this.render3d();
        }
      });

    this.domainEventHandler = new DomainEventHandler(this.sharedState, this.primaryDomainName);
    this.domainEventHandler
      .setXLegendList(this.xLegendList)
      .setDomainSnapBehaviour(DomainSnapBehaviour.linearInterpolation)
      .on(LEGEND_CHANGED_EVENT, () => this.onLegendChanged());
    this.domainEventHandler.build();
  }

  protected performSetData2d() {
    if (!this.xLegendList || !this.sourceData) {
      return;
    }

    if (this.rowLegendRenderer) {
      this.rowLegendRenderer.data([...this.populatedLayout.rows.map(v => v.processed), this.xLegendList]);
    }

    if (this.domainEventHandler) {
      this.domainEventHandler.setLayout(this.populatedLayout).setSourceData(this.sourceData);
    }

    this.trackRenderer.setLayout(this.populatedLayout).setChannelData(this.sourceData);
  }

  protected performRender2d() {
    if (!this.svg || !this.svgParent) {
      return;
    }

    if (this.rowLegendRenderer) {
      this.rowLegendRenderer.render(this.svg, this.svgParent);
    }

    if (this.domainEventHandler) {
      this.domainEventHandler.render();
    }

    if (this.orbitControlsRenderer) {
      this.orbitControlsRenderer.render2d();
    }
  }

  protected performAnimate3d(mouseRaycaster: Raycaster | undefined) {
    if (this.lightingRenderer) {
      this.lightingRenderer.animate();
    }

    if (this.orbitControlsRenderer) {
      this.orbitControlsRenderer.animate();
    }

    let sceneModified = this.trackRenderer.update(mouseRaycaster);

    if (sceneModified) {
      if (this.floorRenderer) {
        this.floorRenderer.update();
      }
    }
  }

  protected async loadFromLayout(inputLayout?: IMultiPlotLayout) {
    if (inputLayout) {
      this.layout = await this.dataPipeline.getValidatedLayout.execute(inputLayout);
    }

    if (this.layout) {
      this.sourceData = await this.dataPipeline.loadChannelData.execute(this.layout);
    } else {
      await super.loadFromLayout();
    }
  }

  public getLayout(): IMultiPlotLayout | undefined {
    if (!this.layout) {
      return undefined;
    }

    return this.dataPipeline.getUnprocessedLayout.execute(this.layout);
  }

  public async setLayout(layout: any): Promise<any> {

    if (layout) {
      // Ensure layout is valid for chart.
      layout.columns = [{ channels: [{ name: this.primaryDomainName }] }];
      if (layout.rows > 0) {
        layout.rows = [layout.rows[0]];
      }
    }

    await this.loadFromLayout(layout);
    await this.handleSourcesChanged();
  }

  public canImportData(): boolean {
    return false;
  }

  public canExportData(): boolean {
    return false;
  }

  public canStackDiagonals(): boolean {
    return false;
  }

  public importCsvData(name: string, fileContent: string) {
  }

  public exportCsvData(): Promise<void> {
    return Promise.resolve();
  }

  public getEditChannelsTypes(): IEditChannelsType[] {
    if (!this.layout) {
      return [];
    }

    return [
      {
        name: 'Edit Channels',
        icon: 'paint-brush',
        id: EDIT_TYPE_CHANNELS
      }
    ];
  }

  public async getEditChannelsOptions(editType: IEditChannelsType): Promise<IEditChannelsOptions> {
    let layout = this.getLayout();
    if (!layout) {
      throw new Error('Layout not present.');
    }

    let channels = await SourceLoaderUtilities.getDistinctRequestableChannels(this.sharedState.sourceLoaderSet.sources, this.channelNameStyle, this.primaryDomainName);
    let row: IMultiPlotSide | undefined;
    switch (editType.id) {
      case EDIT_TYPE_CHANNELS:
        this.padLayoutRows(layout, 1);
        row = layout.rows[0];
        break;
    }

    let panes: PaneChannelLayout = row ? row.channels.map(v => ({ channels: [v] })) : [];

    return {
      channels,
      panes,
      oneChannelPerPane: true
    };
  }

  public async setEditChannelsResult(editType: IEditChannelsType, result: PaneChannelLayout): Promise<void> {
    let layout = this.getLayout();
    if (!layout) {
      return;
    }

    let channels = result.map(v => v.channels[0]);
    let set: IMultiPlotSide = {
      channels,
      relativeSize: 1,
      processed: undefined
    };

    switch (editType.id) {
      case EDIT_TYPE_CHANNELS:
        this.padLayoutRows(layout, 1);
        layout.rows[0] = set;
        break;

      default:
        throw new Error('Unexpected edit type: ' + editType.id);
    }

    await this.setLayout(layout);
  }

  private padLayoutRows(layout: IMultiPlotLayout, requiredRows: number) {
    while (layout.rows.length < requiredRows) {
      layout.rows.push({ channels: [], relativeSize: 1 });
    }
  }
}

