import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {WorksheetLabelsEditorDialogData, WorksheetLabelsEditorResult,} from './worksheet-labels-editor-dialog.service';
import {EventEmitter, Injectable} from '@angular/core';
import {
  DocumentSubType,
  LabelDefinition,
  LabelDefinitions,
  SimType,
  TenantSettingsStub
} from '../../../generated/api-stubs';
import {UserSettings} from '../../user-state/user-settings.service';
import {StudyTypeLookup} from '../../simulations/studies/study-type-lookup.service';
import {sortBy} from '../../common/sort-by';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';
import {
  CombinedWorksheetLabelDefinition,
  CombinedWorksheetLabelDefinitions,
  CombinedWorksheetLabelDefinitionSet,
  LabelSetType
} from '../worksheet-labels-editor/worksheet-labels-editor.component';
import {CanopyJson} from '../../common/canopy-json.service';
import {ConfigTypeLookup} from '../../simulations/configs/config-types';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class WorksheetLabelsEditorDialogSessionFactory {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly tenantSettingsStub: TenantSettingsStub,
    private readonly userSettings: UserSettings,
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly loadingDialog: LoadingDialog,
    private readonly json: CanopyJson,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){}

  public create(data: WorksheetLabelsEditorDialogData): WorksheetLabelsEditorDialogSession{
    return new WorksheetLabelsEditorDialogSession(
      data,
      this.authenticationService,
      this.tenantSettingsStub,
      this.userSettings,
      this.studyTypeLookup,
      this.loadingDialog,
      this.json,
      this.getFriendlyErrorAndLog);
  }
}

export class WorksheetLabelsEditorDialogSession {
  public errorMessage: string;

  public worksheetLabels: LabelDefinitions;
  public userLabels: LabelDefinitions;
  public tenantLabels: LabelDefinitions;

  public combined: CombinedWorksheetLabelDefinitions;

  public accepted: EventEmitter<WorksheetLabelsEditorResult> = new EventEmitter<WorksheetLabelsEditorResult>();

  constructor(
    public readonly dialog: WorksheetLabelsEditorDialogData,
    private readonly authenticationService: AuthenticationService,
    private readonly tenantSettingsStub: TenantSettingsStub,
    private readonly userSettings: UserSettings,
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly loadingDialog: LoadingDialog,
    private readonly json: CanopyJson,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public async load() {
    try {
      this.errorMessage = undefined;

      const simTypeMap = await this.studyTypeLookup.getSimTypeMap();

      const userData = this.authenticationService.userDataSnapshot;
      this.worksheetLabels = this.dialog.worksheetLabels || this.getDefaultLabels();
      this.sortLabelDefinitions(this.worksheetLabels);

      const userSettings = await this.userSettings.get(userData?.tenant, userData?.sub);
      this.userLabels = userSettings.settings.labelDefinitions || this.getDefaultLabels();
      this.sortLabelDefinitions(this.userLabels);

      const tenantSettingsResult = await this.tenantSettingsStub.getTenantWorksheetLabelDefinitions(userData?.tenant);
      this.tenantLabels = tenantSettingsResult.labelDefinitions || this.getDefaultLabels();
      this.sortLabelDefinitions(this.tenantLabels);

      const combinedConfigLabelDefinitions: CombinedWorksheetLabelDefinitionSet[] = [];
      const combinedSimulationLabelDefinitions: CombinedWorksheetLabelDefinitionSet[] = [];
      for(let source of [this.worksheetLabels, this.userLabels, this.tenantLabels]){
        for(let configLabelDefinition of source.configLabelDefinitions){
          const configType = ConfigTypeLookup.get(configLabelDefinition.configType);
          this.addSourceLabelDefinitionsToSet(
            combinedConfigLabelDefinitions,
            configLabelDefinition.configType,
            configType ? configType.titleName : undefined,
            LabelSetType.config,
            configLabelDefinition.labels,
            source);
        }

        for(let simulationLabelDefinition of source.simulationLabelDefinitions){
          const simType = simTypeMap[simulationLabelDefinition.simType];
          this.addSourceLabelDefinitionsToSet(
            combinedSimulationLabelDefinitions,
            simulationLabelDefinition.simType,
            simType ? simType.name : undefined,
            LabelSetType.simulation,
            simulationLabelDefinition.labels,
            source);
        }
      }

      const configTypes = await this.studyTypeLookup.getConfigTypeList();
      for(let item of configTypes){
        if(!combinedConfigLabelDefinitions.some(v => v.key === item.singularKey)){
          combinedConfigLabelDefinitions.push(new CombinedWorksheetLabelDefinitionSet(
            item.singularKey,
            item.titleName,
            LabelSetType.config,
            [],
          ));
        }
      }

      const simTypes = await this.studyTypeLookup.getSimTypeList();
      for(let item of simTypes){
        if(!combinedSimulationLabelDefinitions.some(v => v.key === item.simType)){
          combinedSimulationLabelDefinitions.push(new CombinedWorksheetLabelDefinitionSet(
            item.simType,
            item.name,
            LabelSetType.simulation,
            [],
          ));
        }
      }

      combinedConfigLabelDefinitions.sort(sortBy(
        {name: 'labels', primer: (v: LabelDefinition[]) => v.length === 0 ? 1 : 0 },
        'displayName'));
      combinedSimulationLabelDefinitions.sort(sortBy(
        {name: 'labels', primer: (v: LabelDefinition[]) => v.length === 0 ? 1 : 0 },
        'displayName'));

      this.combined = new CombinedWorksheetLabelDefinitions(
        combinedConfigLabelDefinitions,
        combinedSimulationLabelDefinitions);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private addSourceLabelDefinitionsToSet(
    target: CombinedWorksheetLabelDefinitionSet[],
    key: string,
    displayName: string,
    labelSetType: LabelSetType,
    labels: ReadonlyArray<LabelDefinition>,
    source: LabelDefinitions) {

    let combined = target.find(v => v.key === key);
    if (!combined) {
      combined = new CombinedWorksheetLabelDefinitionSet(
        key,
        displayName || key,
        labelSetType,
        []);
      target.push(combined);
    }

    this.addSourceLabelDefinitionsToSetInner(labels, combined, source);
  }

  private addSourceLabelDefinitionsToSetInner(labels: ReadonlyArray<LabelDefinition>, combined: CombinedWorksheetLabelDefinitionSet, source: LabelDefinitions) {
    for (let label of labels) {
      let combinedLabel = combined.labels.find(v => v.name === label.name && v.source === label.source);
      if (!combinedLabel) {
        combinedLabel = new CombinedWorksheetLabelDefinition(label.source, label.name);
        combined.labels.push(combinedLabel);
      }

      combinedLabel.inWorksheetLabels = combinedLabel.inWorksheetLabels || source === this.worksheetLabels;
      combinedLabel.inUserLabels = combinedLabel.inUserLabels || source === this.userLabels;
      combinedLabel.inTenantLabels = combinedLabel.inTenantLabels || source === this.tenantLabels;
    }
  }

  private getDefaultLabels(): LabelDefinitions {
    return {
      configLabelDefinitions: [],
      simulationLabelDefinitions: [],
    };
  }

  public async save(){
    try {
      const userData = this.authenticationService.userDataSnapshot;

      const newWorksheetLabels: LabelDefinitions = this.createTargetedLabelDefinitionsFromCombined('inWorksheetLabels');
      this.sortLabelDefinitions(newWorksheetLabels);
      const haveWorksheetLabelsChanged = this.haveChanged(newWorksheetLabels, this.worksheetLabels);

      const newUserLabels: LabelDefinitions = this.createTargetedLabelDefinitionsFromCombined('inUserLabels');
      this.sortLabelDefinitions(newUserLabels);
      const haveUserLabelsChanged = this.haveChanged(newUserLabels, this.userLabels);

      const newTenantLabels: LabelDefinitions = this.createTargetedLabelDefinitionsFromCombined('inTenantLabels');
      this.sortLabelDefinitions(newTenantLabels);
      const haveTenantLabelsChanged = this.haveChanged(newTenantLabels, this.tenantLabels);

      if(haveUserLabelsChanged){
        await this.loadingDialog.showUntilFinished(
          this.userSettings.update(
            userData?.tenant,
            userData?.sub,
            s => s.settings.labelDefinitions = newUserLabels),
          'Saving user settings...');
      }

      if(haveTenantLabelsChanged) {
        await this.loadingDialog.showUntilFinished(
          this.tenantSettingsStub.putTenantWorksheetLabelDefinitions(
            userData?.tenant,
            {labelDefinitions: newTenantLabels}),
          'Saving tenant settings...');
      }

      this.accepted.emit(new WorksheetLabelsEditorResult(
        haveWorksheetLabelsChanged || haveUserLabelsChanged || haveTenantLabelsChanged,
        haveWorksheetLabelsChanged ? newWorksheetLabels : undefined));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private createTargetedLabelDefinitionsFromCombined(checkPropertyName: keyof CombinedWorksheetLabelDefinition): LabelDefinitions {
    return {
      configLabelDefinitions: this.combined.configLabelDefinitions
        .map(v => ({
          configType: v.key as DocumentSubType,
          labels: v.labels
            .filter(l => l[checkPropertyName])
            .map(l => ({
              source: l.source,
              name: l.name,
            }))
        }))
        .filter(v => v.labels.length),
      simulationLabelDefinitions: this.combined.simulationLabelDefinitions
        .map(v => ({
          simType: v.key as SimType,
          labels: v.labels
            .filter(l => l[checkPropertyName])
            .map(l => ({
              source: l.source,
              name: l.name,
            }))
        }))
        .filter(v => v.labels.length),
    };
  }

  private sortLabelDefinitions(item: LabelDefinitions){
    item.configLabelDefinitions.sort(sortBy('configType'));
    for(let set of item.configLabelDefinitions){
      if(set.labels){
        set.labels.sort(sortBy('source', 'name'));
      }
    }

    item.simulationLabelDefinitions.sort(sortBy('simType'));
    for(let set of item.configLabelDefinitions){
      if(set.labels){
        set.labels.sort(sortBy('source', 'name'));
      }
    }
  }

  private haveChanged(a: LabelDefinitions, b: LabelDefinitions): boolean {
    return !this.json.equals(a, b);
  }
}
