import {RowItemViewModel} from './row-item-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class CellElementToViewModelLookup {

  private readonly map: Map<Element, RowItemViewModel> = new Map<Element, RowItemViewModel>();

  public register(element: Element, viewModel: RowItemViewModel) {
    this.map.set(element, viewModel);
  }

  public unregister(element: Element){
    this.map.delete(element);
  }

  public get(element: Element): RowItemViewModel | undefined {
    return this.map.get(element);
  }
}
