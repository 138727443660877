import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {RowMetadataViewModel} from '../row-metadata-view-model';
import {PromptDialog} from '../../common/dialogs/prompt-dialog.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class CopyRowDownCommand extends WorksheetCommand {

  constructor(
    private readonly promptDialog: PromptDialog,
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<RowMetadataViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isRowMetadata()
      && context.worksheet.canWrite(userData.sub)
      && context.isSingleRow;
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    const count = await this.promptDialog.promptForNumber('Number of rows:','Copy Down Row', 1);
    if(!count) {
      return CommandResult.NoUpdate;
    }

    context.worksheet.copyDownRow(context.target.row, count);

    return CommandResult.UpdateAndGenerateColumns; // We need to generate columns for the new rows.
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Copy Row Down`,
      `copy-row-down-button`,
      () => this.execute(context),
      'angle-double-down'));
  }
}
