import { Injectable } from '@angular/core';
import { RowMetadataViewModel } from '../../row-metadata-view-model';
import { CommandContext, CommandResult, ICommandContext, WorksheetCommand } from '../worksheet-command';
import { ConfirmReplaceAllReferences } from '../confirm-replace-all-references.service';
import { ReplaceResult } from '../../worksheet-item-component-base';
import { ButtonMenuItem, MenuItem } from '../../../context-menu/context-menu-types';
import { StudyStagingArea } from '../../../simulations/study-staging-area/study-staging-area.service';
import { ImportConfigFromStagingArea } from './import-config-from-staging-area';
import { GetConfigsToImport } from './get-configs-to-import';
import { UpdateRowNames } from './update-row-names';
import { ConfigViewModel } from '../../config-view-model';
import { AuthenticationService } from '../../../identity/state/authentication.service';

@Injectable()
export class ImportStagedCommand extends WorksheetCommand {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly confirmReplaceAllReferences: ConfirmReplaceAllReferences,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly getConfigsToImport: GetConfigsToImport,
    private readonly importConfigFromStagingArea: ImportConfigFromStagingArea,
    private readonly updateRowNames: UpdateRowNames) {
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<RowMetadataViewModel | ConfigViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    if (!context.worksheet.canWrite(userData.sub)) {
      return false;
    }

    if (context.isRowMetadata()) {
      return true;
    }

    if (context.isConfig() && !!this.studyStagingArea.state.getInput(context.target.configType)) {
      return true;
    }

    return false;
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if (!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    const replaceResult = await this.confirmReplaceAllReferences.execute(context.target, context.selectedRows);
    if (replaceResult === ReplaceResult.cancel) {
      return CommandResult.NoUpdate;
    }

    const state = this.studyStagingArea.state;

    let configsToImport = await this.getConfigsToImport.execute(context, state.studyType);
    for (const configToImport of configsToImport) {
      await this.importConfigFromStagingArea.execute(state, configToImport, context, replaceResult);
    }

    this.updateRowNames.execute(context);

    return CommandResult.UpdateAndGenerateColumns;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if (!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Import Staged`,
      `import-staged-button`,
      () => this.execute(context),
      'arrow-circle-down'));
  }
}
