import { Injectable, Directive } from '@angular/core';
import {LocalStorage} from '../../common/local-storage.service';
import {LocalStateService} from '../local-state-service';
import {Dayjs} from '../../common/dayjs.service';
import {CustomProperty} from '../custom-properties/custom-properties';
import {
  StudyType, StudyTypeDefinition, DocumentSubType,
  ConfigStub
} from '../../../generated/api-stubs';
import {ServiceOnInit} from '../../common/service-on-init';
import {IMutableStudyInput, InputCustomProperty, StudyInput} from '../../worksheets/study-input';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

export const DEFAULT_SIM_VERSION: string = '1.0';
export const LOCAL_STORAGE_KEY = 'studyStagingArea';
export const UNKNOWN_API_KEY_ERROR_PREFIX = 'Unknown API key:';

@Directive()
@Injectable()
export class StudyStagingArea extends LocalStateService<StudyState, IMutableStudyState> implements ServiceOnInit {

  constructor(
    authenticationService: AuthenticationService,
    localStorage: LocalStorage,
    private readonly configStub: ConfigStub,
    private readonly loadingDialog: LoadingDialog,
    private readonly dayjs: Dayjs){
    super(authenticationService, localStorage);
  }

  protected fromMutable(state: IMutableStudyState): StudyState {
    return StudyState.fromMutable(state);
  }

  protected toMutable(state: StudyState): IMutableStudyState {
    return StudyState.toMutable(state);
  }

  protected uninitialized(userId: string): StudyState {
    return StudyState.uninitialized(userId);
  }

  protected getLocalStorageKey(): string {
    return LOCAL_STORAGE_KEY;
  }

  public serviceOnInit() {
    super.initialize();
  }

  public getExactInput(configType: DocumentSubType) {
    return this.state.getInput(configType);
  }

  public async getInput(configType: DocumentSubType, targetSimVersion: string){
    let input = this.state.getInput(configType);
    input = await this.getInputAsVersion(input, configType, targetSimVersion);
    return input;
  }

  public setStudyType(studyType: StudyType) {
    let mutable = StudyState.toMutable(this.state);
    mutable.studyType = studyType;
    this.update(mutable);
  }

  public setStudyInformation(studyType: StudyType, studyName: string) {
    let mutable = StudyState.toMutable(this.state);
    mutable.studyType = studyType;
    mutable.studyName = studyName;
    this.update(mutable);
  }

  public stage(
    configType: DocumentSubType,
    userId: string,
    configId: string,
    name: string,
    config: any,
    properties: CustomProperty[],
    notes: string,
    simVersion: string,
    isEdited: boolean) {
    let studyInput = new StudyInput(configType, userId, configId, name, config, InputCustomProperty.fromMutables(properties), notes, simVersion, isEdited, undefined);
    this.stageInput(studyInput);
  }

  public stageInput(
    studyInput: StudyInput) {
    let mutable = StudyState.toMutable(this.state);
    let found = false;
    let newInput = StudyInput.toMutable(studyInput);
    newInput.timestamp = this.getTimestamp();
    for(let i=0; i < mutable.inputs.length; ++i){
      if(mutable.inputs[i].configType === newInput.configType){
        found = true;
        mutable.inputs[i] = newInput;
        break;
      }
    }

    if(!found){
      mutable.inputs.push(newInput);
    }

    this.update(mutable);
  }

  public async getAsIfStaged(configType: DocumentSubType, userId: string, configId: string, name: string, config: any, properties: CustomProperty[], notes: string, simVersion: string, isEdited: boolean, targetSimVersion: string): Promise<StudyInput> {
    let input = new StudyInput(configType, userId, configId, name, config, InputCustomProperty.fromMutables(properties), notes, simVersion, isEdited, this.getTimestamp());
    input = await this.getInputAsVersion(input, configType, targetSimVersion);
    return input;
  }

  public updateIsEdited(configType: DocumentSubType, isEdited: boolean){
    let mutable = StudyState.toMutable(this.state);
    for(let i=0; i < mutable.inputs.length; ++i){
      if(mutable.inputs[i].configType === configType){
        mutable.inputs[i].isEdited = isEdited;
        break;
      }
    }

    this.update(mutable);
  }

  public remove(configType: DocumentSubType){
    let mutable = StudyState.toMutable(this.state);
    for(let i=0; i < mutable.inputs.length; ++i){
      if(mutable.inputs[i].configType === configType){
        mutable.inputs.splice(i, 1);
        break;
      }
    }

    this.update(mutable);
  }

  private async getInputAsVersion(input: StudyInput, configType: DocumentSubType, targetSimVersion: string): Promise<StudyInput> {
    if(input && targetSimVersion && input.simVersion !== targetSimVersion){
      let upgradeResult = await this.loadingDialog.showUntilFinished(
        this.configStub.upgradeConfig(
          this.userData.tenant,
          targetSimVersion,
          {
            configType,
            config: input.data,
            simVersion: input.simVersion || DEFAULT_SIM_VERSION
          }),
        'Converting config version...');

      let mutable = StudyInput.toMutable(input);
      mutable.data = upgradeResult.config;
      mutable.simVersion = upgradeResult.convertedSimVersion;
      input = StudyInput.fromMutable(mutable);
    }

    return input;
  }

  private getTimestamp(){
    return this.dayjs.currentIsoTime();
  }
}

export type StudyTypeItem = StudyTypeDefinition;

export class StudyState {

  public readonly isInitialized: boolean;

  public static uninitialized(userId: string) {
 return new StudyState(userId, StudyType.straightSim, '', undefined);
}

  constructor(
    public readonly userId: string,
    public readonly studyType: StudyType,
    public readonly studyName: string,
    public readonly inputs: ReadonlyArray<StudyInput>) {

    this.inputs = [...(inputs || [])];

    this.isInitialized = this.inputs.length > 0;
  }

  public static fromMutable(mutable: IMutableStudyState) {
    return new StudyState(
      mutable.userId,
      mutable.studyType,
      mutable.studyName,
      (mutable.inputs || []).map(StudyInput.fromMutable));
  }

  public static toMutable(value: StudyState): IMutableStudyState {
    return {
      userId: value.userId,
      studyType: value.studyType,
      studyName: value.studyName,
      inputs: value.inputs.map(StudyInput.toMutable)};
  }

  public getInput(configType: DocumentSubType){
    return this.inputs.find(v => v.configType === configType);
  }
}

export interface IMutableStudyState {
  userId: string;
  studyType: StudyType;
  studyName: string;
  inputs: IMutableStudyInput[];
}
