import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class HiddenEnumResolver extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.resolvers.unshift(function(schema: any) {
      if (schema['enum'] && schema.enum.length === 1) {
        return 'hiddenEnum';
      }

      return undefined;
    });
  }
}
