import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class DocumentUpdatedEventService {
  private eventEmitter: EventEmitter<UpdatedDocument> = new EventEmitter<UpdatedDocument>();

  public get documentUpdated(): Observable<UpdatedDocument> {
    return this.eventEmitter;
  }

  public emit(tenantId: string, documentId: string) {
    this.eventEmitter.emit(new UpdatedDocument(tenantId, documentId));
  }
}

export class UpdatedDocument {
  constructor(
    public readonly tenantId: string,
    public readonly documentId: string){
  }
}
