import {Injectable} from '@angular/core';
import {ICommandContext} from './worksheet-command';
import {StudyViewModel} from '../study-view-model';
import {ConfigStub, DocumentSubType} from '../../../generated/api-stubs';
import {TelemetryReference} from '../../simulations/studies/stage-study.service';
import {StudyInput} from '../study-input';
import {DisplayableError} from '../../common/errors/errors';
import {ConfigTypeLookup} from '../../simulations/configs/config-types';
import {ConfigLoaderUtilities} from '../../simulations/configs/config-loader-utilities.service';
import {
  configResultToStudyInput,
  telemetryConfigToStudyInput
} from '../study-input-utilities';

@Injectable()
export class LoadContextConfig {
  constructor(
    private readonly configStub: ConfigStub,
    private readonly configLoaderUtilities: ConfigLoaderUtilities) {
  }

  public async execute(context: ICommandContext<StudyViewModel>, configType: DocumentSubType, targetSimVersion: string): Promise<StudyInput | undefined> {
    const config = context.target.row.getConfig(configType);
    if(!config || !config.isResolved){
      return undefined;
    }

    if(configType === DocumentSubType.telemetry) {
      const tenantId = config.populated.reference.tenant.tenantId;
      const studyId = config.populated.reference.tenant.targetId;
      const jobIndex = config.populated.reference.tenant.jobIndex || 0;

      // TODO: If the telemetry schema changes we'll need to do change our data format here, perhaps via
      //  an upgrade call.
      const configData: TelemetryReference = {
        source: {
          tenantId,
          studyId,
          jobIndex,
        }
      };

      return telemetryConfigToStudyInput(
        config.populated.resolvedReference.data.name,
        configData);
    }

    if(config.populated.reference.tenant){
      const tenantId = config.populated.reference.tenant.tenantId;
      const configId = config.populated.reference.tenant.targetId;
      const configResult = await this.configStub.getConfig(
        tenantId,
        configId,
        undefined,
        targetSimVersion);

      if(configResult.config.subType !== configType){
        throw new DisplayableError('Unexpected config type: ' + configResult.config.subType);
      }

      return configResultToStudyInput(configResult);
    }

    let configTypeData = ConfigTypeLookup.get(configType);
    if(!configTypeData){
      throw new DisplayableError('Unknown config type: ' + configType);
    }

    const configName = config.populated.reference.default.name;
    const configResult = await this.configLoaderUtilities.loadSimVersionConfig(
      configTypeData,
      targetSimVersion,
      configName);

    return configResult;
  }
}
