import {CanopyDocument, ConfigStub, DocumentSubType, FilteredDocumentsResultType} from '../../generated/api-stubs';
import {SUPPORT_SESSION_ID_CUSTOM_PROPERTY_KEY} from './create-worksheet-from-study';
import {Injectable} from '@angular/core';
import { AuthenticationService } from '../identity/state/authentication.service';

@Injectable()
export class GetWorksheetForSupportSession {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly configStub: ConfigStub){
  }

  public async execute(supportSessionId: string): Promise<CanopyDocument | undefined> {
    const userData = this.authenticationService.userDataSnapshot;
    const configsResult = await this.configStub.getConfigs(
      userData.tenant,
      DocumentSubType.worksheet,
      {
        itemsPerPage: 1,
        filterCustomProperties: [
          {
            name: SUPPORT_SESSION_ID_CUSTOM_PROPERTY_KEY,
            value: supportSessionId,
          }
        ]
      },
      undefined,
      FilteredDocumentsResultType.queryOnly);

    if(configsResult.queryResults.documents.length){
      return configsResult.queryResults.documents[0];
    }

    return undefined;
  }
}
