import {Injectable} from '@angular/core';
import {ConfigResolvedReference, DocumentSubType} from '../../generated/api-stubs';
import {WorksheetViewModel} from './worksheet-view-model';

@Injectable()
export class DeduplicateWorksheetRow {

  public execute(worksheet: WorksheetViewModel, rowIndex: number){
    if(rowIndex < 0 || rowIndex >= worksheet.rows.length) {
      return;
    }

    const targetRow = worksheet.rows[rowIndex];
    let remainingConfigs = new Set<DocumentSubType>();
    for(let config of targetRow.configs){
      // We don't need to deduplicate telemetry as it is just a reference.
      if(config.isResolved && config.configType !== DocumentSubType.telemetry){
        remainingConfigs.add(config.configType);
      }
    }

    for (let i = rowIndex - 1; i >= 0; --i) {
      let row = worksheet.rows[i];
      let remainingConfigList = Array.from(remainingConfigs);
      for(let configType of remainingConfigList){
        let sourceConfig = row.getConfig(configType);
        if(!sourceConfig || !sourceConfig.isResolved){
          continue;
        }

        remainingConfigs.delete(configType);
        let targetConfig = targetRow.getConfig(configType);

        let sourceResolvedReference = sourceConfig.populated.resolvedReference as ConfigResolvedReference;
        let targetResolvedReference = targetConfig.populated.resolvedReference as ConfigResolvedReference;

        if(sourceResolvedReference.data.name === targetResolvedReference.data.name
          && sourceResolvedReference.data.userId === targetResolvedReference.data.userId
          && sourceResolvedReference.data.hashes.some(s => targetResolvedReference.data.hashes.some(t => s.hash === t.hash))){
          targetConfig.setConfig(sourceConfig.reference);
        }
      }
    }
  }
}
