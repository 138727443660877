import {IHasReference, IReference} from './worksheet-types';
import {referenceToId} from './reference-to-id';

export class ResolvedByReference<TResolved extends IHasReference> {
  constructor(
    public readonly includeJobIndex: boolean = false) {
  }

  private cache: { [referenceId: string]: TResolved } = {};

  public add(resolvedReference: TResolved) {
    this.cache[referenceToId(resolvedReference.reference, this.includeJobIndex)] = resolvedReference;
  }

  public get(reference: IReference): TResolved | undefined {
    return this.cache[referenceToId(reference, this.includeJobIndex)];
  }
}
