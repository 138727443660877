import { Injectable } from '@angular/core';
import { ConfigReference } from '../../../../generated/api-stubs';
import { StudyInput } from '../../study-input';


@Injectable()
export class ImportTelemetryConfigFromStagingArea {
  public execute(studyInput: StudyInput): ConfigReference | undefined {

    if (!studyInput || !studyInput.data || !studyInput.data.source) {
      return undefined;
    }

    const source = studyInput.data.source;

    return {
      tenant: {
        tenantId: source.tenantId,
        targetId: source.studyId,
        jobIndex: source.jobIndex,
      }
    };
  }
}
