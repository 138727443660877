import {WorksheetViewModel} from './worksheet-view-model';
import {Injectable} from '@angular/core';

Injectable();
@Injectable()
export class ActiveWorksheets {

  private viewModels: WorksheetViewModel[] = [];

  public get items(): ReadonlyArray<WorksheetViewModel> {
    return this.viewModels;
  }

  public add(viewModel: WorksheetViewModel){
    this.remove(viewModel);
    this.viewModels.push(viewModel);
  }

  public remove(viewModel: WorksheetViewModel){
    for(let i = this.viewModels.length - 1; i >= 0; --i){
      if(this.viewModels[i].worksheetId === viewModel.worksheetId){
        this.viewModels.splice(i, 1);
      }
    }
  }
}
