import {Injectable} from '@angular/core';
import {StudyReference} from '../../generated/api-stubs';
import {DisplayableError} from '../common/errors/errors';
import {CreateWorksheetRowFromStudy, CreateWorksheetRowFromStudyOptions} from './create-worksheet-row-from-study';
import {WorksheetViewModel} from './worksheet-view-model';
import {DeduplicateWorksheetRow} from './deduplicate-worksheet-row.service';

@Injectable()
export class AddStudyToWorksheetAndExtractInputs {
  constructor(
    private readonly createWorksheetRowFromStudy: CreateWorksheetRowFromStudy,
    private readonly deduplicateWorksheetRow: DeduplicateWorksheetRow) {
  }

  public async execute(worksheet: WorksheetViewModel, studyReference: StudyReference, deduplicate: boolean): Promise<void>{
    let rowIndex = worksheet.rows.length - 1;
    while (rowIndex >= 0) {
      const row = worksheet.rows[rowIndex];
      if(!row.isEmpty){
        break;
      }
      --rowIndex;
    }

    rowIndex = rowIndex + 1;
    const insertResult = worksheet.insertRowsAt(
      rowIndex,
      1,
      () => worksheet.createEmptyRow(worksheet.getCurrentUnderlyingData().studyTypesList[0].studyType));

    if(!insertResult){
      throw new DisplayableError('Maximum rows reached.');
    }

    const row = worksheet.rows[rowIndex];

    const rowContent = await this.createWorksheetRowFromStudy.execute(
      studyReference.tenantId,
      studyReference.targetId,
      worksheet.worksheetId,
      CreateWorksheetRowFromStudyOptions.default());

    await worksheet.waitForUpdate();

    worksheet.pasteRowsSync(row, rowContent.clipboardContent, false);

    if(deduplicate){
      this.deduplicateWorksheetRow.execute(worksheet, rowIndex);
    }

    worksheet.generateColumns();
    worksheet.requestUpdate(true);
  }
}
