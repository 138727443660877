import { Injectable } from '@angular/core';
import { RowMetadataViewModel } from '../../row-metadata-view-model';
import { ICommandContext } from '../worksheet-command';
import { ReplaceResult } from '../../worksheet-item-component-base';
import { SetConfigOrAllReferences } from '../set-config-or-all-references.service';
import { StudyState } from '../../../simulations/study-staging-area/study-staging-area.service';
import { ConfigReference, DocumentSubType } from '../../../../generated/api-stubs';
import { ImportTelemetryConfigFromStagingArea } from './import-telemetry-config-from-staging-area';
import { ImportStandardConfigFromStagingArea } from './import-standard-config-from-staging-area';
import { ConfigViewModel } from '../../config-view-model';


@Injectable()
export class ImportConfigFromStagingArea {

  constructor(
    private readonly setConfigOrAllReferences: SetConfigOrAllReferences,
    private readonly importStandardConfigFromStagingArea: ImportStandardConfigFromStagingArea,
    private readonly importTelemetryConfigFromStagingArea: ImportTelemetryConfigFromStagingArea) {
  }

  public async execute(
    state: StudyState,
    configToImport: DocumentSubType,
    context: ICommandContext<RowMetadataViewModel | ConfigViewModel>,
    replaceResult: ReplaceResult) {

    const studyInput = state.getInput(configToImport);

    if (!studyInput) {
      return;
    }

    let configReference: ConfigReference | undefined;
    if (configToImport === DocumentSubType.telemetry) {
      configReference = this.importTelemetryConfigFromStagingArea.execute(studyInput);
    } else {
      configReference = await this.importStandardConfigFromStagingArea.execute(studyInput);
    }

    if (!configReference) {
      return;
    }

    await this.setConfigOrAllReferences.execute(
      context.target.row.getOrCreateConfig(configToImport),
      context.selectedRows,
      configReference,
      replaceResult);
  }
}
