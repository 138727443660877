import {Injectable} from '@angular/core';
import {WorksheetRow} from '../../generated/api-stubs';
import {CanopyJson} from '../common/canopy-json.service';

@Injectable()
export class WorksheetClipboard {
  private cache: ClipboardContent | undefined;

  constructor(
    private readonly json: CanopyJson) {
  }

  public createContent(sourceTenantId: string, sourceWorksheetId: string, rows: ReadonlyArray<WorksheetRow>): ClipboardContent {
    return new ClipboardContent(
      sourceTenantId,
      sourceWorksheetId,
      this.json.clone(rows) || []);
  }

  public copy(sourceTenantId: string, sourceWorksheetId: string, rows: ReadonlyArray<WorksheetRow>) {
    this.cache = this.createContent(sourceTenantId, sourceWorksheetId, rows);
  }

  public get(): ClipboardContent | undefined {
    if(!this.cache) {
      return undefined;
    }

    return this.cache.withRows(this.json.clone(this.cache.rows));
  }
}

export class ClipboardContent {
  constructor(
    public readonly sourceTenantId: string,
    public readonly sourceWorksheetId: string,
    public readonly rows: ReadonlyArray<WorksheetRow>) {
    if(!rows){
      this.rows = [];
    }
  }

  public withRows(rows: ReadonlyArray<WorksheetRow>) {
    return new ClipboardContent(this.sourceTenantId, this.sourceWorksheetId, rows);
  }
}
