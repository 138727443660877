import {Component, ElementRef, Input} from '@angular/core';
import {RowMetadataViewModel} from '../row-metadata-view-model';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {WorksheetItemComponentBase} from '../worksheet-item-component-base';
import {CellElementToViewModelLookup} from '../cell-element-to-view-model-lookup';
import {RowMetadataContextMenuBuilder} from '../worksheet-commands/row-metadata-context-menu-builder';
import { AuthenticationService } from '../../identity/state/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { RowItemUrlService } from '../worksheet-commands/row-item-url.service';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[cs-worksheet-row-metadata-item]',
  templateUrl: './worksheet-row-metadata-item.component.html',
  styleUrls: ['./worksheet-row-metadata-item.component.scss']
})
export class WorksheetRowMetadataItemComponent extends WorksheetItemComponentBase {

  @Input() public readonly viewModel: RowMetadataViewModel;
  @Input() public readonly rowIndex: number;

  constructor(
    authenticationService: AuthenticationService,
    cellElementToViewModelLookup: CellElementToViewModelLookup,
    elementRef: ElementRef,
    contextMenuBuilder: RowMetadataContextMenuBuilder,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    rowItemUrl: RowItemUrlService,
    route: ActivatedRoute) {
    super(authenticationService, cellElementToViewModelLookup, elementRef, contextMenuBuilder, getFriendlyErrorAndLog, rowItemUrl, route);
  }
}
