@if (delta) {
  @switch (delta.deltaType) {
    @case (DeltaType.object) {
      <div class="object-delta object-{{cssSanitize(parentPath)}}">
        @for (property of objectDelta.properties; track property) {
          <div class="object-delta-property">
            @if (property.delta.isComplexValue) {
              <i class="fa fa-fw collapse-button"
                [ngClass]="{ 'fa-caret-right': child.isHidden, 'fa-caret-down': !child.isHidden }"
                (click)="child.isHidden = !child.isHidden">
              </i>
              }<span class="property-name" (click)="child.toggleChildren()"><span class="parameter-path">{{child.parentPath}}</span>{{property.name}}:</span>
              <cs-compare-config-structures-renderer
                #child
                class="property-{{property.name}}"
                [ngStyle]="{ display: child.isHidden ? 'none' : undefined }"
                [delta]="property.delta"
                [parentPath]="getNextPath(property.name)"
                [parentName]="property.name"
                [getUnits]="getUnits"
              [outputChangeSet]="outputChangeSet"></cs-compare-config-structures-renderer>
            </div>
          }
        </div>
      }
      @case (DeltaType.array) {
        <div class="array-delta array-{{cssSanitize(parentPath)}}">
          @for (item of arrayDelta.items; track item) {
            <div class="array-delta-index">
              @if (item.delta.isComplexValue) {
                <i class="fa fa-fw collapse-button"
                  [ngClass]="{ 'fa-caret-right': child.isHidden, 'fa-caret-down': !child.isHidden }"
                  (click)="child.isHidden = !child.isHidden">
                </i>
                }<span class="array-index" (click)="child.toggleChildren()"><span class="parameter-path">{{child.parentPath}}</span>{{item.index}}:</span>
                <cs-compare-config-structures-renderer
                  #child
                  class="index-{{item.index}}"
                  [ngStyle]="{ display: child.isHidden ? 'none' : undefined }"
                  [delta]="item.delta"
                  [parentPath]="getNextPath('' + item.index)"
                  [parentName]="parentName"
                  [getUnits]="getUnits"
                [outputChangeSet]="outputChangeSet"></cs-compare-config-structures-renderer>
              </div>
            }
          </div>
        }
        @case (DeltaType.added) {
          <span class="added"
            [ngClass]="{ 'output-value': outputChangeSet.contains(parentPath, added.newValue) }"
            cs-compare-config-structures-value-renderer
            [value]="added.newValue"
            [parentPath]="parentPath"
            [parentName]="parentName"
            [getUnits]="getUnits"
            (click)="outputChangeSet.toggle(parentPath, added.newValue)">
          </span>
        }
        @case (DeltaType.modified) {
          <span class="deleted"
            [ngClass]="{ 'output-value': outputChangeSet.contains(parentPath, modified.oldValue) }"
            cs-compare-config-structures-value-renderer
            [value]="modified.oldValue"
            [parentPath]="parentPath"
            [parentName]="parentName"
            [getUnits]="getUnits"
            (click)="outputChangeSet.toggle(parentPath, modified.oldValue, modified.newValue)">
          </span>
          <span class="added"
            [ngClass]="{ 'output-value': outputChangeSet.contains(parentPath, modified.newValue) }"
            cs-compare-config-structures-value-renderer
            [value]="modified.newValue"
            [parentPath]="parentPath"
            [parentName]="parentName"
            [getUnits]="getUnits"
            (click)="outputChangeSet.toggle(parentPath, modified.oldValue, modified.newValue)">
          </span>
        }
        @case (DeltaType.deleted) {
          <span class="deleted"
            [ngClass]="{ 'output-value': outputChangeSet.contains(parentPath, deleted.oldValue) }"
            cs-compare-config-structures-value-renderer
            [value]="deleted.oldValue"
            [parentPath]="parentPath"
            [parentName]="parentName"
            [getUnits]="getUnits"
            (click)="outputChangeSet.toggle(parentPath, deleted.oldValue)">
          </span>
        }
        @case (DeltaType.moved) {
          <span class="moved">
            property → {{moved.destinationindex}}
          </span>
        }
        @case (DeltaType.unidiff) {
          <cs-compare-config-structures-unidiff-renderer [text]="unidiff.value"></cs-compare-config-structures-unidiff-renderer>
        }
      }
    }
