import {GetStudyQueryResult, PostStudyResult, SimType, StudyReference} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {CanopyFileLoaderFactory} from '../simulations/visualizations/canopy-file-loader.service';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {ConvertTelemetryConfigToStudy} from '../simulations/configs/convert-telemetry-config-to-study.service';
import { AuthenticationService } from '../identity/state/authentication.service';

const InputSimType = 'Input' as any as SimType;

@Injectable()
export class RecreateTelemetryStudyFromInputData {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly canopyFileLoaderFactory: CanopyFileLoaderFactory,
    private readonly convertTelemetryConfigToStudy: ConvertTelemetryConfigToStudy,
    private readonly loadingDialog: LoadingDialog){
  }

  public async execute(
    studyName: string,
    studyResult: GetStudyQueryResult,
    telemetryJobIndex: number): Promise<StudyReference> {

    const fileLoader = this.canopyFileLoaderFactory.create();

    fileLoader.addStudy(
      studyResult.study.tenantId,
      studyResult.study.documentId,
      studyResult.accessInformation,
      studyResult.convertedSimVersion);

    const vectorMetadata = await this.loadingDialog.showUntilFinished(
      fileLoader.loadVectorMetadata(
        studyResult.study.documentId,
        telemetryJobIndex,
        InputSimType),
      'Loading input metadata...');

    let channels: { name: string; units: string; description: string; data: number[] }[] = [];
    for(let item of vectorMetadata){
      const data = await this.loadingDialog.showUntilFinished(
        fileLoader.loadChannelData(
          studyResult.study.documentId,
          telemetryJobIndex,
          InputSimType,
          item.name,
          item.binaryFormat),
        'Loading ' + item.name + '...');

      channels.push({
        name: item.name,
        units: item.units,
        description: item.description,
        data: Array.from(data)
      });
    }

    const telemetryStudyResult = <PostStudyResult>await this.loadingDialog.showUntilFinished(
      this.convertTelemetryConfigToStudy.execute(
        {
          channels,
        },
        studyResult.convertedSimVersion,
        studyName,
        false,
        undefined,
        undefined),
    'Creating telemetry study...');

    const userData = this.authenticationService.userDataSnapshot;

    return {
      tenantId: userData.tenant,
      targetId: telemetryStudyResult.studyId,
    };
  }
}
