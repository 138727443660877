import * as d3 from '../../d3-bundle';
import {
  MultiPlotDataRendererBase,
  MultiPlotDataRendererInnerBase
} from '../multi-plot-viewer-base/multi-plot-data-renderer-base';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedPlotSourceChannel } from '../data-pipeline/types/processed-plot-source-channel';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';
import { SourceSplitInspector } from '../channel-data-inspectors/source-split-inspector';

export class DataRenderer extends MultiPlotDataRendererBase {

  public static create(interpolator: LineViewerGetInterpolatedChannelValueAtDomainValue) {
    return new DataRenderer(
      new DataRendererInner('line-plot', interpolator, new SourceSplitInspector()),
      interpolator);
  }

  private constructor(
    private linePlotInner: DataRendererInner,
    private lineViewerGetInterpolatedChannelValueAtDomainValue: LineViewerGetInterpolatedChannelValueAtDomainValue) {
    super(linePlotInner);
  }

  public curve(value: d3.CurveFactory): this {
    this.linePlotInner.curve = value;
    this.lineViewerGetInterpolatedChannelValueAtDomainValue.curve = value;
    return this;
  }
}

export class DataRendererInner extends MultiPlotDataRendererInnerBase {

  constructor(
    cssClassPrefix: string,
    getInterpolatedChannelValueAtDomainValue: GetInterpolatedChannelValueAtDomainValue,
    private readonly sourceSplitInspector: SourceSplitInspector) {
    super(cssClassPrefix, getInterpolatedChannelValueAtDomainValue);
  }

  public curve: d3.CurveFactory = d3.curveLinear;

  protected drawChannelData(context: CanvasRenderingContext2D, plot: ProcessedPlot, channel: ProcessedPlotSourceChannel) {
    const settings = this.definedSettings;
    let sourceIndex = channel.sourceIndex;
    let yChannel = channel.yChannel;
    let xCoordinates = channel.xCoordinates;
    let yCoordinates = channel.yCoordinates;

    let line = d3.line<number>()
      .curve(this.curve)
      .defined((d: number) => !isNaN(d))
      .x((d: number, i: number) => xCoordinates[i])
      .y((d: number) => d);

    context.lineWidth = 1;
    context.strokeStyle = settings.getChannelColor ? settings.getChannelColor(yChannel.channelIndex, sourceIndex) : 'green';

    line.context(context);
    context.beginPath();
    line(yCoordinates);
    context.stroke();
  }

  protected drawPlotFeatures(context: CanvasRenderingContext2D, plot: ProcessedPlot) {
    if (!this.sourceData) {
      return;
    }

    const settings = this.definedSettings;

    let activeDomainChannel = plot.column.processed.channels.find(v => v.isVisible && v.hasData);
    if (!activeDomainChannel || !activeDomainChannel.isMonotonic) {
      return;
    }

    let sourcesVisibility = this.sourceData.map(v => v.isVisible);
    let yMinimum = plot.row.processed.getVisibleMinimum(sourcesVisibility);
    let yMaximum = plot.row.processed.getVisibleMaximum(sourcesVisibility);

    for (let sourceIndex = 0; sourceIndex < this.sourceData.length; ++sourceIndex) {
      let source = this.sourceData[sourceIndex];
      if (!source.isVisible) {
        continue;
      }

      let splitChannel = source.featureChannels.splitChannel;
      if (!this.sourceSplitInspector.hasSplitIndices(splitChannel)) {
        continue;
      }

      let columnScale = plot.column.processed.scale;
      let rowScale = plot.row.processed.scale;

      context.save();
      try {
        context.lineWidth = 0.5;
        context.strokeStyle = settings.getChannelColor ? settings.getChannelColor(0, sourceIndex) : 'black';
        context.setLineDash([5, 5]);
        context.beginPath();

        let domainData = activeDomainChannel.sources[sourceIndex].data;
        if (domainData) {
          for (let index of splitChannel.dataChangeIndices) {
            let columnValue = columnScale(domainData[index]) - 0.5;
            context.moveTo(
              columnValue,
              rowScale(yMinimum));
            context.lineTo(
              columnValue,
              rowScale(yMaximum));
          }
        }

        context.stroke();
      } finally {
        context.restore();
      }
    }
  }
}

export class LineViewerGetInterpolatedChannelValueAtDomainValue extends GetInterpolatedChannelValueAtDomainValue {
  public curve?: d3.CurveFactory;

  protected executeAtIndex(channelData: ReadonlyArray<number>, domainValue: number, domainData: ReadonlyArray<number>, index: number) {
    if (this.curve === d3.curveStepBefore) {
      if (index === -1) {
        return channelData[channelData.length - 1];
      }
      return channelData[index];
    } else {
      return super.executeAtIndex(channelData, domainValue, domainData, index);
    }
  }
}
