import { Component } from '@angular/core';
import {WorksheetDock} from './worksheet-dock.service';
import {ResizeEvent} from 'angular-resizable-element';

@Component({
  selector: 'cs-worksheet-dock',
  templateUrl: './worksheet-dock.component.html',
  styleUrls: ['./worksheet-dock.component.scss']
})
export class WorksheetDockComponent {

  constructor(
    public readonly dock: WorksheetDock) {
  }

  handleResize(event: ResizeEvent): void {
    this.dock.height = event.rectangle.height;
  }


  handleResizeEnd(event: ResizeEvent): void {
    this.dock.height = event.rectangle.height;
  }
}
