import { ParallelCoordinatesDataRenderer } from './parallel-coordinates-data-renderer';
import { LineMouseEvent, ParallelCoordinatesData } from './parallel-coordinates-types';
import { DomainNewsEvent, SharedState } from '../shared-state';
import { INDEX_DOMAIN_NAME } from '../../constants';
import { SVGSelection } from '../../untyped-selection';
import { StudySourceLoader, UnderlyingStudyData } from '../channel-data-loaders/study-source-loader';
import { SiteHooks } from '../../site-hooks';
import { IErrorHandler } from './error-handler';
import { ParallelCoordinatesViewerSettings } from './parallel-coordinates-viewer-settings';

export const AVERAGE_PIXELS_PER_CHARACTER = 5.2;

export class ParallelCoordinatesDataEventHandler {

  private data?: ParallelCoordinatesData;
  private tooltips: ToolTip[] = [];

  constructor(
    private readonly errorHandler: IErrorHandler,
    private readonly svg: SVGSelection,
    private readonly sharedState: SharedState,
    private readonly siteHooks: SiteHooks,
    private readonly settings: ParallelCoordinatesViewerSettings,
    private readonly dataRenderer: ParallelCoordinatesDataRenderer) {

    this.dataRenderer.on('mouseover', (currentEvent: any) => this.handleMouseOver(currentEvent))
      .on('mouseout', () => this.handleMouseOut())
      .on('click', (currentEvent: any) => this.handleClick(currentEvent));
  }

  public setData(data: ParallelCoordinatesData) {
    this.data = data;
  }

  private handleMouseOver(lineEvent: LineMouseEvent) {
    if (!this.data || this.data.dimensionList.some(v => v.isBrushing)) {
      return;
    }

    let studyData = this.getStudyData();
    if (!studyData) {
      return;
    }

    // now add a tooltip showing the name of the selected experiment

    if (this.sharedState) {
      this.sharedState.getDomainNews(INDEX_DOMAIN_NAME).raise(
        new DomainNewsEvent(
          this.sharedState.sourceLoaderSet.sources.map(v => lineEvent.lineIndex)));
    }

    let event = <MouseEvent>lineEvent.sourceEvent;
    let xsvg = event.offsetX; // get the mouse position
    let ysvg = event.offsetY;
    let dataLabel = studyData.data.explorationData.explorationMap.jobs[lineEvent.lineIndex].name;

    if (dataLabel) {
      this.tooltips.push(new ToolTip(xsvg, ysvg, dataLabel));
    }

    this.render();
  }

  private render() {
    let tooltipContainerUpdate = this.svg.selectAll<SVGGElement, ToolTip>('.job-tooltip').data(this.tooltips);
    tooltipContainerUpdate.exit().remove();
    let tooltipContainerEnter = tooltipContainerUpdate.enter().append('g')
      .attr('id', 'job-tooltip')
      .attr('class', 'job-tooltip');
    let tooltipContainer = tooltipContainerUpdate.merge(tooltipContainerEnter);

    tooltipContainer
      .attr('transform', d => 'translate(' + (d.x + 10) + ',' + (d.y - 20) + ')');

    tooltipContainerEnter.append('rect')
      .attr('class', 'tooltip-box')
      .attr('height', 15)
      .attr('rx', 5)
      .attr('ry', 5);
    tooltipContainer.select('.tooltip-box')
      .attr('width', (d) => d.label.length * AVERAGE_PIXELS_PER_CHARACTER);

    tooltipContainerEnter.append('text')
      .attr('x', 2)
      .attr('y', 11)
      .attr('text-anchor', 'start');
    tooltipContainer.select('text')
      .text(d => d.label);

    let helperUpdate = this.svg.selectAll<SVGGElement, unknown>('.mouse-click-helper').data([null]);
    let helperEnter = helperUpdate.enter().append<SVGGElement>('g')
      .attr('class', 'mouse-click-helper');
    let helper = helperUpdate.merge(helperEnter);
    helperEnter
      .attr('transform', `translate(${this.settings.svgSize.width - 4}, 11)`)
      .append('text')
      .attr('text-anchor', 'end')
      .style('opacity', 0)
      .text('Click to view job, Control+Click to add to Compare Results area or Alt+Click to remove data from interpolation.');
    helper.select('.mouse-click-helper text')
      .transition().duration(this.tooltips.length ? 0 : 200)
      .style('opacity', this.tooltips.length);
  }

  private handleMouseOut() {
    this.tooltips.length = 0;
    this.render();
  }

  private handleClick(currentEvent: any) {
    let lineEvent = <LineMouseEvent>currentEvent;
    this.showStudyJob(lineEvent.lineIndex, currentEvent);
  }

  private getStudyData(): UnderlyingStudyData | undefined {
    if (this.sharedState.sourceLoaderSet.sources.length === 0) {
      return undefined;
    }

    let sourceLoader = this.sharedState.sourceLoaderSet.sources[0].inner;
    if (!(sourceLoader instanceof StudySourceLoader)) {
      return undefined;
    }

    return sourceLoader.underlyingStudyData;
  }

  private async showStudyJob(lineIndex: number, currentEvent?: LineMouseEvent) {
    try {
      let studyData = this.getStudyData();
      if (!studyData) {
        return;
      }

      let jobIndex = studyData.data.explorationData.scalarData.lineIndexToJobIndexMap[lineIndex];
      await this.siteHooks.showStudyJob(studyData.studyId, jobIndex, currentEvent);
    } catch (error) {
      this.errorHandler.setError(this.siteHooks.getFriendlyErrorAndLog(error));
    }
  }
}

class ToolTip {
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly label: string) {
  }
}
