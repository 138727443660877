import * as d3 from '../../d3-bundle';
import { SVGGSelection, SVGSelection } from '../../untyped-selection';

/**
 * The orientation of the slider.
 */
enum SliderOrientation {

  /**
   * The slider is oriented horizontally, with the handle at the bottom.
   */
  bottom,

  /**
   * The slider is oriented vertically, with the handle on the left.
   */
  left
}

/**
 * The slider has been implemented in the traditional style of a D3 component, as a function
 * that is also an object. Other components are implemented as simple classes, but this one
 * was written first and has not been refactored.
 * This interface defines the type of the function object.
 */
export interface ISlider/* extends d3.Selection<SVGElement, any, SVGGElement, any>*/ {
  /**
   * Create a new slider.
   */
  (selection: SVGGSelection): void;

  /**
   * Set or get the track length.
   * @param _ The new track length.
   */
  trackLength(_: number): this;
  trackLength(): number;

  /**
   * Set or get the position of the slider handle.
   * @param _ The new position.
   */
  position(_: number): this;
  position(): number;

  /**
   * Resize the slider.
   * @param newTrackLength The new track length.
   */
  resize(newTrackLength: number): this;

  /**
   * Reposition the slider handle.
   * @param selection The selection containing the slider.
   * @param newPosition The new position.
   * @param transitionTime The time for the transition.
   */
  reposition(selection: SVGSelection, newPosition: number, transitionTime: number): void;

  /**
   * Set an event listener.
   * @param typenames The event type.
   * @param listener The event listener.
   */
  on(typenames: string, listener: d3.ValueFn<d3.BaseType, any, void>, capture?: boolean): this;
  on(typenames: string): d3.ValueFn<d3.BaseType, any, void> | undefined;
  on(typenames: string, listener: null): this;
}

/**
 * A slider event.
 */
export class SliderEvent {

  /**
   * Create a new slider event.
   * @param type The event type.
   * @param position The position of the handle.
   * @param sourceEvent The source event.
   */
  constructor(
    public type: string,
    public position: number,
    public sourceEvent?: any) {
  }
}

// Structure references:
//    https://bost.ocks.org/mike/chart/time-series-chart.js
//    https://github.com/d3/d3-axis/blob/master/src/axis.js
//
// Slider references:
//    https://bl.ocks.org/mbostock/6452972
function slider(orientation: SliderOrientation) {
  let trackLength: number = 100;
  let position: number = 0;
  let listeners = d3.dispatch('start', 'drag', 'end', 'moved');
  let reverse: boolean = false;

  let getHandlePosition = function(x: number): number {
    if (x < 0) {
      return 0;
    }
    if (x > trackLength) {
      return trackLength;
    }
    return reverse ? trackLength - x : x;
  };

  let getUnboundHandlePosition = function(x: number): number {
    return reverse ? trackLength - x : x;
  };

  let getHandleTransform = function(x: number): string {
    return 'translate(' + getHandlePosition(x) + ', 0)';
  };

  let getUnboundHandleTransform = function(x: number): string {
    return 'translate(' + getUnboundHandlePosition(x) + ', 0)';
  };

  let dispatchEvent = function(this: any, type: string, x: number, currentEvent: any) {
    // Example: https://github.com/d3/d3-drag/blob/master/src/drag.js
    let event = new SliderEvent(type, x, currentEvent);
    listeners.call(type, this, event);
  };

  function create(selection: SVGGSelection) {
    let fontSize = 20;
    let fontSizeText = `${fontSize}px`;

    let gUpdate = selection.selectAll<SVGGElement, unknown>('.canopy-slider').data([null]);
    let gEnter = gUpdate.enter().append<SVGGElement>('g').attr('class', 'canopy-slider');
    let g = gEnter.merge(gUpdate);

    gEnter.append<SVGGElement>('g')
      .attr('class', 'handle')
      .append<SVGTextElement>('text')
      .attr('font-size', fontSizeText)
      .attr('transform', 'rotate(180) translate(-5.5, -3)')
      .text('\uf041');
    let handle = g.select('.handle');

    // We use the unbound position here because, e.g. in the PC plot when zoomed
    // another chart can cause the handle to move beyond the bounds, and we want
    // to render this even if it can't be dragged beyond the bounds in the PC plot.
    handle.attr('transform', getUnboundHandleTransform(position));

    let overlayOverflow = 5; // Allow us to grip the handle beyond the drag bounds.
    gEnter.append<SVGLineElement>('line')
      .attr('class', 'track-overlay')
      .attr('stroke', 'transparent')
      .attr('stroke-width', fontSizeText)
      .attr('transform', 'translate(0, ' + fontSize / 2 + ')')
      .on('click mousemove', currentEvent => {
        // This stops, for example, the "view job" action being invoked when the user just wanted
        // to move the slider.
        currentEvent.stopPropagation();
      })
      .call(d3.drag<SVGLineElement, null>()
        .on('start drag end', currentEvent => {
          handle.interrupt();
          currentEvent.sourceEvent.stopPropagation();
          position = getHandlePosition(currentEvent.x);
          dispatchEvent(currentEvent.type, position, currentEvent);
          dispatchEvent('moved', position, currentEvent);
          handle.attr('transform', getHandleTransform(currentEvent.x));
        }));
    g.select('.track-overlay')
      .attr('x1', -overlayOverflow)
      .attr('x2', trackLength + overlayOverflow);

    switch (orientation) {
      case SliderOrientation.bottom:
        g.attr('transform', null);
        break;

      case SliderOrientation.left:
        g.attr('transform', 'rotate(90)');

        break;
    }
  }

  let slider = <ISlider>create;
  let s = <any>slider;

  s.trackLength = function(_: number): number | ISlider {
    if (!arguments.length) {
      return trackLength;
    }
    trackLength = _;
    return slider;
  };

  s.position = function(_: number): number | ISlider {
    if (!arguments.length) {
      return position;
    }
    position = _;
    return slider;
  };

  s.resize = function(newTrackLength: number) {
    let proportion = trackLength ? position / trackLength : 0;
    position = proportion * newTrackLength;
    trackLength = newTrackLength;
    return slider;
  };

  s.on = function(typenames: string, callback: (this: object, ...args: any[]) => void) {
    let value = listeners.on(typenames, callback);
    return value === listeners ? slider : value;
  };

  s.reposition = function(selection: SVGSelection, newPosition: number, transitionTime: number) {
    selection.select('.handle')
      .transition().duration(transitionTime).attr('transform', getUnboundHandleTransform(newPosition))
      .on('end', function() {
        position = newPosition;
      });
  };

  return slider;
}

export function sliderLeft(): ISlider {
  return slider(SliderOrientation.left);
}

export function sliderBottom(): ISlider {
  return slider(SliderOrientation.bottom);
}
