import {CreateWorksheetRowFromStudy, CreateWorksheetRowFromStudyOptions} from './create-worksheet-row-from-study';
import {DocumentCustomPropertyData, WorksheetStub} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {IWorksheetParent, WorksheetViewModelFactory} from './worksheet-view-model';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import { AuthenticationService } from '../identity/state/authentication.service';

export const SUPPORT_SESSION_TYPE_CUSTOM_PROPERTY_KEY = 'support-session-type';
export const SUPPORT_SESSION_ID_CUSTOM_PROPERTY_KEY = 'support-session-id';
export const SUPPORT_SESSION_TENANT_ID_CUSTOM_PROPERTY_KEY = 'support-session-tenant-id';

@Injectable()
export class CreateWorksheetFromStudy implements IWorksheetParent {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly worksheetStub: WorksheetStub,
    private readonly createWorksheetRowFromStudy: CreateWorksheetRowFromStudy,
    private readonly worksheetViewModelFactory: WorksheetViewModelFactory,
    private readonly loadingDialog: LoadingDialog){
  }

  public async execute(tenantId: string, studyId: string, name: string, fromSupportSession: boolean, options: CreateWorksheetRowFromStudyOptions): Promise<WorksheetResult>{
    const userData = this.authenticationService.userDataSnapshot;

    const properties: DocumentCustomPropertyData[] = [];
    if(fromSupportSession){
      properties.push({
        name: SUPPORT_SESSION_TYPE_CUSTOM_PROPERTY_KEY,
        value: 'study',
      });
      properties.push({
        name: SUPPORT_SESSION_ID_CUSTOM_PROPERTY_KEY,
        value: studyId,
      });
      properties.push({
        name: SUPPORT_SESSION_TENANT_ID_CUSTOM_PROPERTY_KEY,
        value: tenantId,
      });
    }

    const worksheetResult = await this.loadingDialog.showUntilFinished(
      this.worksheetStub.postWorksheet(
        userData.tenant,
        {
          name,
          properties
        }),
      'Creating worksheet...');
    const worksheetId = worksheetResult.worksheet.worksheetId;
    const viewModel = await this.worksheetViewModelFactory.createFromWorksheetResult(
      this,
      worksheetResult);

    const worksheetContent = await this.createWorksheetRowFromStudy.execute(tenantId, studyId, worksheetId, options);

    await viewModel.pasteRowsAsync(
      viewModel.rows[0],
      worksheetContent.clipboardContent,
      false,
      false);

    await this.loadingDialog.showUntilFinished(
      this.worksheetStub.putWorksheet(
        userData.tenant,
        worksheetId,
        {
          name,
          properties,
          outline: viewModel.getOutline(),
        }),
      'Saving data to worksheet...');

    return new WorksheetResult(userData.tenant, worksheetId);
  }

  public update(generateColumns: boolean): void {
  }

  public waitForUpdate(): Promise<void> {
    return Promise.resolve();
  }

  redo(): Promise<void> {
    return Promise.resolve();
  }

  undo(): Promise<void> {
    return Promise.resolve();
  }
}

export class WorksheetResult {
  constructor(
    public readonly tenantId: string,
    public readonly worksheetId: string) {
  }
}
