import {
  ConfigReference,
  ConfigResolvedReference,
  DocumentSubType, ResolvedLabel, SimType,
  StudyResolvedReference,
  UserInformation,
  WorksheetConfig,
} from '../../generated/api-stubs';
import {WorksheetUnderlyingData} from './worksheet-underlying-data';
import {UNKNOWN_USER} from './worksheet-constants';
import {RowViewModel} from './row-view-model';
import {PopulatedStudyViewModel, StudyInputNecessity} from './study-view-model';
import {HashValidity} from './hash-validity';
import {RowItemViewModel} from './row-item-view-model';
import {referenceAsStudy} from './worksheet-types';
import {IReadonlyWorksheetReferenceMetadata} from './worksheet-references-metadata';

export class ConfigViewModel extends RowItemViewModel {

  public populated: PopulatedConfigViewModel | undefined;
  public readonly unpopulated: UnpopulatedConfigViewModel;

  public isSelected: boolean;
  public matchesSelectedConfig: boolean = false;

  constructor(
    parent: RowViewModel,
    configType: DocumentSubType,
    config: WorksheetConfig,
    private underlyingData: WorksheetUnderlyingData) {
    super(parent);
    if (config && config.reference) {
      this.populated = new PopulatedConfigViewModel(this, configType, config);
    }

    this.unpopulated = new UnpopulatedConfigViewModel(configType, underlyingData);

    this.update(underlyingData);
  }

  public get referenceMetadata(): IReadonlyWorksheetReferenceMetadata {
    return this.populated
      ? this.underlyingData.referencesMetadata.get(this.populated.reference)
      : undefined;
  }

  public get configType(): DocumentSubType {
    return this.unpopulated.configType;
  }

  public get isPopulated(): boolean {
    return !!this.populated;
  }

  public get isStudyPopulated(): boolean {
    return !!this.row.study.populated;
  }

  public get isTelemetry(): boolean {
    return this.configType === DocumentSubType.telemetry;
  }

  public isReadOnly(userId: string): boolean {
    return !!(this.populated
      && this.populated.resolvedReference
      && this.populated.resolvedReference.data
      && this.populated.resolvedReference.data.userId !== userId);
  }

  public canWrite(userId: string): boolean{
    return !this.isReadOnly(userId);
  }

  public get hashValidity(): HashValidity {
    if(this.row.study.isResolved && this.row.study.populated.resolvedReference.data.inputHashes.length){

      if (this.studyInputNecessity === StudyInputNecessity.invalid){
        return HashValidity.none;
      }

      const studyHashes = this.row.study.populated.resolvedReference.data.inputHashes.find(
        v => v.configType === this.configType);
      if (studyHashes){
        if(this.isResolved){
          if(this.populated.configResolvedReference.data.hashes.some(
            c => studyHashes.hashes.some(s => c.hash === s.hash))){
            return HashValidity.valid;
          } else{
            return HashValidity.invalid;
          }
        } else{
          return HashValidity.invalid;
        }
      } else {
        if (this.isResolved) {
          return HashValidity.invalid;
        }
      }
    }

    return HashValidity.none;
  }

  public update(underlyingData: WorksheetUnderlyingData) {
    this.underlyingData = underlyingData;
    if(this.populated){
      this.populated.update(this.underlyingData);
    }

    this.unpopulated.update(this.underlyingData);
  }

  public replaceAllConfigs(targetReference: ConfigReference) {
    if(!this.populated){
      return;
    }

    return this.row.worksheet.setAllConfigs(this.populated.reference, targetReference);
  }

  public replaceSpecificConfigs(sourceReference: ConfigReference, targetReference: ConfigReference) {
    return this.row.worksheet.setAllConfigs(sourceReference, targetReference);
  }

  public setConfig(reference: ConfigReference) {
    if(this.populated){
      this.populated.destroy(this.underlyingData);
    }

    if(reference){
      this.populated = new PopulatedConfigViewModel(
        this,
        this.configType,
        {
          configType: this.unpopulated.configType,
          reference,
          inheritReference: false,
        });
      this.populated.update(this.underlyingData);
    } else {
      this.populated = undefined;
      this.matchesSelectedConfig = false;
    }
  }

  public get reference(): ConfigReference | undefined {
    if(this.populated) {
      return this.populated.reference;
    }

    return undefined;
  }

  public get resolvedReference(): ConfigResolvedReference | StudyResolvedReference | undefined {
    if(this.populated) {
      return this.populated.resolvedReference;
    }

    return undefined;
  }

  public getOutline(): WorksheetConfig {
    if(!this.populated) {
      throw new Error('Cannot create outline of unpopulated config.');
    }

    return {
      configType: this.unpopulated.configType,
      reference: this.populated.reference,
      inheritReference: this.populated.inheritReference,
    };
  }

  public get isResolved(): boolean {
    return !!(this.populated && this.populated.resolvedReference && this.populated.resolvedReference.data);
  }

  public get isErrored(): boolean {
    return !!(this.populated && this.populated.resolvedReference && this.populated.resolvedReference.error);
  }

  public get studyInputNecessity(): StudyInputNecessity {
    return this.row.study.getStudyInputNecessity(this.unpopulated.configType);
  }

  public setItemsMatching() {
    this.row.worksheet.setItemsMatching(this.reference);
  }

  public clearItemsMatching() {
    this.row.worksheet.clearItemsMatching();
  }
}

export class PopulatedConfigViewModel {
  public resolvedReference: ConfigResolvedReference | StudyResolvedReference | undefined;
  public configResolvedReference: ConfigResolvedReference | undefined;
  public resolvedLabels: ReadonlyArray<ResolvedLabel> = [];
  public owner: UserInformation | undefined;
  public ownerName: string;
  public populatedStudy: PopulatedStudyViewModel;

  constructor(
    private readonly parent: ConfigViewModel,
    private readonly configType: DocumentSubType,
    private readonly config: WorksheetConfig) {
  }

  public destroy(underlyingData: WorksheetUnderlyingData){
    underlyingData.referencesMetadata.decrementConfig(this.parent);
  }

  public update(underlyingData: WorksheetUnderlyingData) {
    underlyingData.referencesMetadata.incrementConfig(this.parent);

    this.resolvedLabels = [];
    switch(this.configType){
      case DocumentSubType.telemetry:
        this.resolvedReference = underlyingData.studyResolvedReferences.get(this.config.reference);
        this.configResolvedReference = underlyingData.configResolvedReferences.get(this.config.reference);
        const studyResolvedLabels = underlyingData.studyResolvedLabels.get(this.config.reference);
        if(studyResolvedLabels){
          const simulationResolvedLabels = studyResolvedLabels.simulationLabels.find(v => v.simType === SimType.Telemetry);
          if(simulationResolvedLabels){
            this.resolvedLabels = simulationResolvedLabels.resolvedLabels;
          }
        }
        this.populatedStudy = new PopulatedStudyViewModel(undefined, referenceAsStudy(this.config.reference));
        this.populatedStudy.update(underlyingData);
        break;

      default:
        this.resolvedReference =
          this.configResolvedReference = underlyingData.configResolvedReferences.get(this.config.reference);
        const configResolvedLabels = underlyingData.configResolvedLabels.get(this.config.reference);
        if(configResolvedLabels){
          this.resolvedLabels = configResolvedLabels.resolvedLabels;
        }
        break;
    }

    this.ownerName = UNKNOWN_USER;
    if (this.resolvedReference && this.resolvedReference.data) {
      this.owner = underlyingData.users[this.resolvedReference.data.userId];
      if (this.owner) {
        this.ownerName = this.owner.username;
      }
    }
  }

  public get reference(): ConfigReference {
    return this.config.reference;
  }

  public get inheritReference(): boolean {
    return this.config.inheritReference;
  }
}

export class UnpopulatedConfigViewModel {

  public configTypeName: string;

  public configTypePluralKey: string;

  constructor(
    public readonly configType: DocumentSubType,
    private underlyingData: WorksheetUnderlyingData){

    this.configTypeName = this.underlyingData.getConfigTypeName(this.configType);
    this.configTypePluralKey = this.underlyingData.getConfigTypePluralKey(this.configType);
  }

  public update(underlyingData: WorksheetUnderlyingData) {
    this.underlyingData = underlyingData;
  }
}
