import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SavedConfigResolver extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.resolvers.unshift(function(schema: any) {
      if (schema.type === 'string') {
        if (schema.savedConfig) {
          return 'savedConfig';
        }
      }

      return undefined;
    });
  }
}
