import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { WorksheetViewModel } from '../worksheet-view-model';
import { CanopyPusher, StudiesProgress, StudyBuildProgress } from '../../common/canopy-pusher.service';
import { GetFriendlyErrorAndLog } from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { Subscription } from 'rxjs';
import { ColumnViewModel } from '../column-view-model';
import { CellElementToViewModelLookup } from '../cell-element-to-view-model-lookup';
import { RowItemViewModel } from '../row-item-view-model';
import { SimulationViewModel } from '../simulation-view-model';
import { RowMetadataViewModel } from '../row-metadata-view-model';
import { ConfigViewModel } from '../config-view-model';
import { StudyViewModel } from '../study-view-model';
import { ContextMenu } from '../../context-menu/context-menu.service';
import { ButtonMenuItem, KeyboardAction, MenuDefinition } from '../../context-menu/context-menu-types';
import { Position } from '../../visualizations/viewers/position';
import { CommandResult, WorksheetContext } from '../worksheet-commands/worksheet-command';
import { ActivatedRoute } from '@angular/router';

// For resizing look at https://github.com/mattlewis92/angular-resizable-element/

@Component({
  selector: 'cs-worksheet-table',
  templateUrl: './worksheet-table.component.html',
  styleUrls: ['./worksheet-table.component.scss']
})
export class WorksheetTableComponent implements OnInit, OnDestroy {

  @Input() public worksheet: WorksheetViewModel;
  @Input() public isWorksheetInDock: boolean = false;

  public readonly KeyboardAction = KeyboardAction;
  public readonly NavigateAction = NavigateAction;

  public errorMessage: string;

  public studiesProgressSubscription: Subscription;
  public studyBuildProgressSubscription: Subscription;

  //private previousHoveredViewModel: RowItemViewModel | undefined;
  private rangeSelectionStart: MouseTarget | undefined;
  private ignoreFocus: boolean = false;

  constructor(
    private readonly canopyPusher: CanopyPusher,
    private readonly cellElementToViewModelLookup: CellElementToViewModelLookup,
    private readonly contextMenu: ContextMenu,
    private readonly elementRef: ElementRef,
    private readonly route: ActivatedRoute,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  ngOnInit() {
    this.studiesProgressSubscription = this.canopyPusher.studiesProgress.subscribe((data: StudiesProgress) => this.onStudiesProgress(data));
    this.studyBuildProgressSubscription = this.canopyPusher.studyBuildProgress.subscribe((data: StudyBuildProgress) => this.onStudyBuildProgress(data));
  }

  public ngOnDestroy() {
    this.studiesProgressSubscription.unsubscribe();
    this.studyBuildProgressSubscription.unsubscribe();
  }

  public async onStudiesProgress(data: StudiesProgress) {
    try {

      let shouldUpdate = false;
      for (let item of data.items) {
        for (let row of this.worksheet.rows) {
          const studies = row.getPopulatedStudies();
          for (let populatedStudy of studies) {
            if (populatedStudy.resolvedReference
                && populatedStudy.resolvedReference.data
                && populatedStudy.resolvedReference.data.studyDocument) {

              const targetStudyId = populatedStudy.reference.targetId;
              const study = populatedStudy.resolvedReference.data.studyDocument;
              const isStudyComplete = !!item.jobCount && item.jobCount === item.completedJobCount;

              if (targetStudyId === item.studyId) {
                study.executionTimeSeconds = item.executionTimeMs / 1000;
                study.jobCount = item.jobCount;
                study.completedJobCount = item.completedJobCount;
                study.succeededJobCount = item.succeededJobCount;
                study.succeededComputeCredits = item.succeededComputeCredits;
                study.succeededStorageCredits = item.succeededStorageCredits;

                shouldUpdate = shouldUpdate || isStudyComplete;
              } else if (item.resultsStudyIds && item.resultsStudyIds.indexOf(targetStudyId) !== -1) {
                shouldUpdate = shouldUpdate || isStudyComplete;
              }
            }
          }
        }
      }

      if (shouldUpdate) {
        // We need to request an update to resolve labels.
        this.worksheet.requestUpdate(true, false);
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onStudyBuildProgress(data: StudyBuildProgress) {
    try {
      for (let row of this.worksheet.rows) {
        const studies = row.getPopulatedStudies();
        for (let populatedStudy of studies) {
          if (populatedStudy.reference.targetId === data.studyId
            && populatedStudy.resolvedReference
            && populatedStudy.resolvedReference.data
            && populatedStudy.resolvedReference.data.studyDocument) {

            const study = populatedStudy.resolvedReference.data.studyDocument;

            if (study.dispatchedJobCount <= data.dispatchedJobCount) {
              study.errorMessages = data.errorMessages;
              study.jobCount = data.jobCount;
              study.dispatchedJobCount = data.dispatchedJobCount;
            }
          }
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public columnTrackById(index: number, item: ColumnViewModel) {
    return item.trackingId;
  }
  /*
    public onTableMouseMove(event: MouseEvent){
      const target = this.getMouseTargetViewModel(event);
      if(!target) {
        return;
      }

      const viewModel = target.viewModel;
      if(viewModel !== this.previousHoveredViewModel){
        if(this.previousHoveredViewModel){
          this.onCellMouseLeave(this.previousHoveredViewModel);
        }

        this.previousHoveredViewModel = viewModel;

        if(viewModel){
          this.onCellMouseEnter(viewModel);
        }
      }
    }

    public onCellMouseEnter(viewModel: RowItemViewModel){
      viewModel.setItemsMatching();
    }

    public onCellMouseLeave(viewModel: RowItemViewModel){
      viewModel.clearItemsMatching();
    }
  */
  public onTableContextMenu(event: MouseEvent) {
    if (event.altKey) {
      return;
    }

    this.verifyRangeSelectionSet(event);

    const target = this.getMouseTargetViewModel(event);
    if (!target) {
      return;
    }

    event.preventDefault(); // Stop the browser context menu from showing.

    if (!target.viewModel.isSelected) {
      this.performTableClick(event);
    }

    const menuItemsDefinition = target.viewModel.generateContextMenu(
      new WorksheetContext(this.isWorksheetInDock, this.route));

    let menuPosition: Position;
    if (event.clientX || event.clientY) {
      menuPosition = new Position(event.clientX, event.clientY);
    } else {
      // This occurs when using a button to open the context menu.
      const el = (event.target || event.srcElement) as Element;
      const boundingRect = el.getBoundingClientRect();
      menuPosition = new Position(boundingRect.left, boundingRect.top);
    }

    this.contextMenu.content = new MenuDefinition(
      menuPosition,
      menuItemsDefinition.items,
      menuItemsDefinition.wrapper);
  }

  public onTableNavigateKey(event: KeyboardEvent, action: NavigateAction) {
    this.verifyRangeSelectionSet(event);

    let target = this.getElementViewModel(document.activeElement);
    if (!target) {
      return;
    }

    const row = target.viewModel.row;
    let columnIndex = row.columns.findIndex(v => v.value === target.viewModel);
    if (columnIndex === -1) {
      return;
    }

    event.preventDefault(); // Prevent page scrolling.

    if (action === NavigateAction.down || action === NavigateAction.up) {
      const rowIndex = this.worksheet.rows.indexOf(row);
      if (rowIndex === -1) {
        return;
      }

      if (action === NavigateAction.up && rowIndex === 0) {
        return;
      }
      if (action === NavigateAction.down && rowIndex === this.worksheet.rows.length - 1) {
        return;
      }

      const newRow = action === NavigateAction.up ? this.worksheet.rows[rowIndex - 1] : this.worksheet.rows[rowIndex + 1];
      this.clearSelection();
      this.findElementAndFocus(this.elementRef.nativeElement, newRow.columns[columnIndex].value);
    } else {
      const studyColumnIndex = row.columns.findIndex(v => v.value instanceof StudyViewModel);
      if (studyColumnIndex === -1) {
        return;
      }

      if (action === NavigateAction.left && columnIndex === 0) {
        return;
      }
      if (action === NavigateAction.right && columnIndex >= studyColumnIndex) {
        return;
      }

      if (columnIndex >= studyColumnIndex) {
        columnIndex = studyColumnIndex;
      }

      const newColumnIndex = action === NavigateAction.left ? columnIndex - 1 : columnIndex + 1;
      this.clearSelection();
      this.findElementAndFocus(this.elementRef.nativeElement, row.columns[newColumnIndex].value);
    }
  }

  public onClipboardKey(event: KeyboardEvent) {
    this.verifyRangeSelectionSet(event);
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }

    if(event.altKey && event.key === 'v'){
      this.onTableKey(KeyboardAction.pasteDuplicate);
      return;
    }
    switch (event.key.toLocaleLowerCase()) {
      case 'x': this.onTableKey(KeyboardAction.cut); break;
      case 'c': this.onTableKey(KeyboardAction.copy); break;
      case 'v': this.onTableKey(KeyboardAction.paste); break;
    }
  }

  public onUndoOrRedo(isUndo: boolean) {
    let target = this.getElementViewModel(document.activeElement);
    if (!target) {
      return;
    }

    let viewModel = target.viewModel;
    if (viewModel instanceof SimulationViewModel) {
      viewModel = viewModel.row.study;
    }

    const contextMenu = viewModel.generateContextMenu(
      new WorksheetContext(this.isWorksheetInDock, this.route));

    contextMenu.wrapper.executeMenuItemAction({} as MouseEvent, async (e) => {
      if (isUndo) {
        await this.worksheet.undo();
      } else {
        await this.worksheet.redo();
      }
      return CommandResult.NoUpdate;
    });
  }

  public onTableDoubleClick(event: MouseEvent) {
    this.verifyRangeSelectionSet(event);
    this.onTableKey(KeyboardAction.enter);
  }

  public verifyRangeSelectionSet(event: { shiftKey: boolean }) {
    if (event.shiftKey) {
      if (!this.rangeSelectionStart) {
        this.onRangeSelectionStart();
      }
    } else {
      this.onRangeSelectionEnd();
    }
  }

  public onRangeSelectionStart() {
    if (this.rangeSelectionStart) {
      return;
    }

    this.rangeSelectionStart = this.getElementViewModel(document.activeElement);
  }

  public onRangeSelectionEnd() {
    this.rangeSelectionStart = undefined;
  }

  public onTableClicked(event: MouseEvent) {
    if (event.button) {
      return;
    }
    this.performTableClick(event);
  }

  private performTableClick(event: MouseEvent) {
    this.verifyRangeSelectionSet(event);

    const target = this.getMouseTargetViewModel(event);
    if (!target) {
      return;
    }

    event.preventDefault(); // Prevent separate focus event firing.
    this.setFocusWithoutRaisingEvent(target.element);

    const isControlKeyPressed = event.ctrlKey || event.metaKey;
    this.onViewModelActivated(target.viewModel, isControlKeyPressed, isControlKeyPressed);
  }

  public onTableFocus(event: FocusEvent) {
    if (this.ignoreFocus) {
      return;
    }

    const target = this.getElementViewModel(document.activeElement);
    if (!target) {
      return;
    }

    this.onViewModelActivated(target.viewModel, target.viewModel.isSelected, false);
  }

  private onViewModelActivated(viewModel: RowItemViewModel, maintainSelection: boolean, toggleViewModelSelection: boolean) {
    if (this.rangeSelectionStart) {
      this.clearSelection();

      let startViewModel = this.rangeSelectionStart.viewModel;
      let endViewModel = viewModel;
      let startRowIndex = this.worksheet.rows.indexOf(startViewModel.row);
      let endRowIndex = this.worksheet.rows.indexOf(endViewModel.row);

      if (startRowIndex > endRowIndex) {
        let tempIndex = startRowIndex;
        let tempViewModel = startViewModel;
        startRowIndex = endRowIndex;
        startViewModel = endViewModel;
        endRowIndex = tempIndex;
        endViewModel = tempViewModel;
      }

      let startColumnIndex = startViewModel.row.columns.findIndex(v => v.value === startViewModel);
      let endColumnIndex = endViewModel.row.columns.findIndex(v => v.value === endViewModel);

      if (startColumnIndex > endColumnIndex) {
        let tempIndex = startColumnIndex;
        startColumnIndex = endColumnIndex;
        endColumnIndex = tempIndex;
      }

      for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; ++rowIndex) {
        let currentRow = this.worksheet.rows[rowIndex];
        for (let columnIndex = startColumnIndex; columnIndex <= endColumnIndex; ++columnIndex) {
          let currentColumn = currentRow.columns[columnIndex];
          this.setViewModelSelected(currentColumn.value, false);
        }
      }
    } else {
      if (!maintainSelection) {
        this.clearSelection();
      }

      this.setViewModelSelected(viewModel, toggleViewModelSelection);
    }
  }

  private setViewModelSelected(viewModel: RowItemViewModel, toggleSelection: boolean) {
    if (viewModel instanceof RowMetadataViewModel) {
      for (let config of viewModel.row.configs) {
        config.isSelected = this.evaluateIsSelected(config.isSelected, toggleSelection);
      }
      viewModel.row.study.isSelected = this.evaluateIsSelected(viewModel.row.study.isSelected, toggleSelection);
    } else if (viewModel instanceof SimulationViewModel) {
      viewModel.row.study.isSelected = this.evaluateIsSelected(viewModel.row.study.isSelected, toggleSelection);
    } else if (viewModel instanceof ConfigViewModel || viewModel instanceof StudyViewModel) {
      viewModel.isSelected = this.evaluateIsSelected(viewModel.isSelected, toggleSelection);
    }
  }

  private clearSelection() {
    for (let row of this.worksheet.rows) {
      row.study.isSelected = false;
      for (let config of row.configs) {
        config.isSelected = false;
      }
    }
  }

  private onTableKey(action: KeyboardAction) {
    let target = this.getElementViewModel(document.activeElement);
    if (!target) {
      return;
    }

    let viewModel = target.viewModel;
    if (viewModel instanceof SimulationViewModel) {
      viewModel = viewModel.row.study;
    }

    const contextMenu = viewModel.generateContextMenu(
      new WorksheetContext(this.isWorksheetInDock, this.route));

    const button = contextMenu.items
      .filter((v): v is ButtonMenuItem<CommandResult> => v instanceof ButtonMenuItem)
      .find(v => v.keyboardAction === action);
    if (!button) {
      return;
    }

    contextMenu.wrapper.executeMenuItemAction({} as MouseEvent, (e) => button.action(e));
  }

  private setFocusWithoutRaisingEvent(element: Element) {
    this.ignoreFocus = true;
    try {
      let htmlElement = element as HTMLElement;
      if (htmlElement.focus) {
        htmlElement.focus();
      }
    } finally {
      this.ignoreFocus = false;
    }
  }

  private evaluateIsSelected(previousIsSelectedState: boolean, toggleSelection: boolean) {
    return toggleSelection ? !previousIsSelectedState : true;
  }

  private getMouseTargetViewModelElement(event: MouseEvent): Element | undefined {
    return (event.target || event.srcElement) as Element;
  }

  private getMouseTargetViewModel(event: MouseEvent): MouseTarget | undefined {
    let target = this.getMouseTargetViewModelElement(event);
    return this.getElementViewModel(target);
  }

  private getElementViewModel(target: Element): MouseTarget | undefined {
    let viewModel: RowItemViewModel | undefined;
    while (!viewModel && target && (!window.event || target !== window.event.currentTarget)) {
      viewModel = this.cellElementToViewModelLookup.get(target);
      if (viewModel) {
        return new MouseTarget(viewModel, target);
      }

      target = target.parentElement;
    }

    return undefined;
  }

  private findElementAndFocus(root: Element, viewModel: RowItemViewModel) {
    const element = this.findElementForViewModel(root, viewModel);
    if (element) {
      element.focus();
    }
  }

  private findElementForViewModel(root: Element, viewModel: RowItemViewModel): HTMLTableDataCellElement {
    const cells = root.getElementsByTagName('td');
    for (let i = 0; i < cells.length; ++i) {
      const cell = cells.item(i);
      if (this.cellElementToViewModelLookup.get(cell) === viewModel) {
        return cell;
      }
    }

    return undefined;
  }
}

class MouseTarget {
  constructor(
    public readonly viewModel: RowItemViewModel,
    public readonly element: Element) {
  }
}

export enum NavigateAction {
  up,
  down,
  left,
  right,
}

