import {
  ConfigReference,
  DocumentCustomPropertyData,
  DocumentSubType, GetSupportSessionQueryResult,
  GetWorksheetQueryResult,
  LabelDefinitions,
  SimType,
  StudyReference,
  StudyType,
  TenantInformation,
  UserInformation,
  WorksheetOutline,
  WorksheetRow,
} from '../../generated/api-stubs';
import {ColumnType} from './column-type';
import {ColumnViewModel} from './column-view-model';
import {WorksheetUnderlyingData, WorksheetUnderlyingDataFactory} from './worksheet-underlying-data';
import {RowViewModel} from './row-view-model';
import {MINIMUM_ROWS} from './worksheet-constants';
import {CanopyJson} from '../common/canopy-json.service';
import {Injectable} from '@angular/core';
import {isDefined} from '../visualizations/is-defined';
import {ClipboardContent} from './worksheet-clipboard.service';
import {DuplicateClipboardContentIfRequired} from './duplicate-clipboard-content-if-required';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {CustomPropertyUtilities} from '../simulations/custom-properties/custom-property-utilities';
import {IReference, referenceEquals} from './worksheet-types';
import {CreateWorksheetRowFromStudy, CreateWorksheetRowFromStudyOptions} from './create-worksheet-row-from-study';
import {RowItemViewModel} from './row-item-view-model';
import {UndoHistoryTracker} from './undo-history-tracker.service';

// https://codeburst.io/display-a-table-using-components-with-angular-4-f13f0971666d
// https://medium.com/@rohit22173/creating-re-sizable-columns-in-angular2-d22fbcbe39c9

export const MAXIMUM_ROW_COUNT = 250;

export interface IWorksheetParent {
  update(generateColumns: boolean, updateHistory: boolean): void;
  waitForUpdate(): Promise<void>;
  undo(): Promise<void>;
  redo(): Promise<void>;
}

@Injectable()
export class WorksheetViewModelFactory {
  constructor(
    private readonly json: CanopyJson,
    private readonly duplicateClipboardContentIfRequired: DuplicateClipboardContentIfRequired,
    private readonly loadingDialog: LoadingDialog,
    private readonly createWorksheetRowFromStudy: CreateWorksheetRowFromStudy,
    private readonly worksheetUnderlyingDataFactory: WorksheetUnderlyingDataFactory){
  }

  public create(
    parent: IWorksheetParent,
    underlyingData: WorksheetUnderlyingData,
    defaultStudyType: StudyType): WorksheetViewModel {

    return new WorksheetViewModel(
      this.json,
      this.duplicateClipboardContentIfRequired,
      this.loadingDialog,
      this.createWorksheetRowFromStudy,
      parent,
      underlyingData,
      defaultStudyType);
  }

  public async createFromWorksheetResult(
    parent: IWorksheetParent,
    worksheetResult: GetWorksheetQueryResult): Promise<WorksheetViewModel> {

    const underlyingData = await this.worksheetUnderlyingDataFactory.create(worksheetResult);
    const defaultStudyType = underlyingData.studyTypesList[0];
    const result = this.create(parent, underlyingData, defaultStudyType.studyType);
    await result.generateColumns();
    return result;
  }
}

export const WORKSHEET_HISTORY_SIZE = 11;

export class WorksheetViewModel {

  public readonly tenant: TenantInformation;
  public readonly user: UserInformation;
  public readonly worksheetId: string;
  public name: string;
  public properties: DocumentCustomPropertyData[] | undefined;
  public notes: string | undefined;

  public supportSession?: GetSupportSessionQueryResult;

  public readonly history: UndoHistoryTracker<ReadonlyArray<WorksheetRow>> = new UndoHistoryTracker<ReadonlyArray<WorksheetRow>>(WORKSHEET_HISTORY_SIZE);

  private readonly _rows: RowViewModel[];
  public get rows(): ReadonlyArray<RowViewModel> {
    return this._rows;
  }

  public columns: ReadonlyArray<ColumnViewModel>;

  public worksheetLabelDefinitions: LabelDefinitions;

  private previousMatchingReference: IReference | undefined;

  constructor(
    private readonly json: CanopyJson,
    private readonly duplicateClipboardContentIfRequired: DuplicateClipboardContentIfRequired,
    private readonly loadingDialog: LoadingDialog,
    private readonly createWorksheetRowFromStudy: CreateWorksheetRowFromStudy,
    private parent: IWorksheetParent,
    private underlyingData: WorksheetUnderlyingData,
    private defaultStudyType: StudyType) {

    const worksheetResult = this.underlyingData.worksheetResult;
    this.tenant = this.underlyingData.tenants[worksheetResult.worksheet.tenantId];
    this.user = this.underlyingData.users[worksheetResult.worksheet.userId];
    this.worksheetId = worksheetResult.worksheet.worksheetId;
    this.name = worksheetResult.worksheet.name;
    this.properties = CustomPropertyUtilities.objectToList(worksheetResult.worksheet.properties);
    this.notes = worksheetResult.worksheet.notes;
    this.worksheetLabelDefinitions = worksheetResult.worksheet.outline.labelDefinitions;

    this.supportSession = {
      session: worksheetResult.worksheet.supportSession,
      userInformation: worksheetResult.userInformation
    };

    const rows = worksheetResult.worksheet.outline.rows.map(
      v => {
        const result = new RowViewModel(this, v, this.defaultStudyType, this.underlyingData);
        this.defaultStudyType = result.study.unpopulated.studyType || this.defaultStudyType;
        return result;
      });

    if(rows.length === 0 || rows.every(v => !!(v.study.reference === undefined && v.configs.length === 0))) {
      while(rows.length < MINIMUM_ROWS){
        rows.push(this.createEmptyRow());
      }
    }

    this._rows = rows;
  }

  public get tenantId(): string {
    return this.tenant.tenantId;
  }

  public get userId(): string {
    return this.user.userId;
  }

  public get maximumRowsReached(): boolean {
    return this._rows.length >= MAXIMUM_ROW_COUNT;
  }

  public getCurrentUnderlyingData(): WorksheetUnderlyingData {
    return this.underlyingData;
  }

  public isReadOnly(userId: string): boolean{
    return this.userId !== userId;
  }

  public canWrite(userId: string): boolean{
    return !this.isReadOnly(userId);
  }

  public waitForUpdate(){
    return this.parent.waitForUpdate();
  }

  public updateParent(parent: IWorksheetParent) {
    this.parent = parent;
  }

  public update(underlyingData: WorksheetUnderlyingData){
    this.underlyingData = underlyingData;
    const worksheetResult = this.underlyingData.worksheetResult;
    this.supportSession = {
      session: worksheetResult.worksheet.supportSession,
      userInformation: worksheetResult.userInformation
    };
    this.worksheetLabelDefinitions = worksheetResult.worksheet.outline.labelDefinitions;

    for(let row of this._rows){
      row.update(underlyingData);
    }
  }

  public requestUpdate(generateColumns: boolean = false, updateHistory: boolean = true) {
    this.parent.update(generateColumns, updateHistory);
  }

  public undo(): Promise<void> {
    return this.parent.undo();
  }

  public redo(): Promise<void> {
    return this.parent.redo();
  }

  public generateColumns() {
    const allConfigTypes = new Set<DocumentSubType>();
    const allSimTypes = new Set<SimType>();

    for (const row of this.rows) {
      for (const config of row.configs.filter(v => !!v.populated)) {
        allConfigTypes.add(config.unpopulated.configType);
      }
      for (const configType of row.study.inputConfigTypes) {
        allConfigTypes.add(configType);
      }
      for (const simulation of row.simulations) {
        allSimTypes.add(simulation.unpopulated.simType);
      }
    }

    const sortedConfigTypes = Array.from(allConfigTypes).sort();
    const sortedSimulationTypes = Array.from(allSimTypes).sort();

    this.columns = [
      new ColumnViewModel(ColumnType.rowMetadata, undefined, 'Row'),
      ...sortedConfigTypes.map(v => new ColumnViewModel(
        ColumnType.config, v, this.underlyingData.getConfigTypeName((v)))),
      new ColumnViewModel(ColumnType.study, undefined, 'Study'),
      ...sortedSimulationTypes.map(v => new ColumnViewModel(
        ColumnType.simulation, v, this.underlyingData.getSimTypeName(v)))
    ];

    for (const row of this.rows) {
      row.generateColumns(this.columns);
    }
  }

  public getOutline(): WorksheetOutline {
    // NOTE: If this is ever used for history / undo, it needs to be
    // either immutable or a deep copy.
    return {
      labelDefinitions: this.worksheetLabelDefinitions,
      rows: this.rows.map(row => row.getOutline()),
    };
  }

  public setItemsMatching(reference: IReference | undefined) {
    let setMatchesSelectedConfig = (reference: IReference, value: boolean) => {
      if(reference){
        const referenceMetadata = this.underlyingData.referencesMetadata.get(reference);
        referenceMetadata.configs.forEach(v => {
          v.matchesSelectedConfig = value;
        });
        referenceMetadata.studies.forEach(v => {
          v.matchesSelectedConfig = value;
        });
      }
    };

    setMatchesSelectedConfig(this.previousMatchingReference, false);
    this.previousMatchingReference = reference;
    setMatchesSelectedConfig(this.previousMatchingReference, true);
  }

  public clearItemsMatching() {
    this.setItemsMatching(undefined);
  }

  public countStudyReferences(reference: StudyReference){
    if(!reference){
      return 0;
    }

    let result = 0;
    for(let row of this._rows){
      if(referenceEquals(reference, row.study.reference)){
        ++result;
      }

      const telemetryConfig = row.getConfig(DocumentSubType.telemetry);
      if(telemetryConfig && referenceEquals(reference, telemetryConfig.reference)){
        ++result;
      }
    }

    return result;
  }

  public setAllConfigs(sourceReference: ConfigReference, targetReference: ConfigReference) {
    if(!sourceReference){
      return;
    }

    let sourceReferenceMetadata = this.underlyingData.referencesMetadata.get(sourceReference);
    for(let config of Array.from(sourceReferenceMetadata.configs)){
      config.setConfig(targetReference);
    }
  }

  public setAllStudies(sourceReference: StudyReference, targetReference: StudyReference) {
    if(!sourceReference){
      return;
    }

    let sourceReferenceMetadata = this.underlyingData.referencesMetadata.get(sourceReference);
    for(let study of Array.from(sourceReferenceMetadata.studies)){
      study.setStudy(targetReference);
    }
  }

  public insertRows(row: RowViewModel, count: number) {
    let rowIndex = this._rows.indexOf(row);
    if(rowIndex === -1) {
      rowIndex = this._rows.length;
    }

    this.insertRowsAt(
      rowIndex,
      count,
      () => this.createEmptyRow(row.study.studyType));
  }

  public appendRows(row: RowViewModel, count: number) {
    let rowIndex = this._rows.indexOf(row);
    if(rowIndex === -1) {
      rowIndex = this._rows.length - 1;
    }

    this.insertRowsAt(
      rowIndex + 1,
      count,
      () => this.createEmptyRow(row.study.studyType));
  }

  public copyDownRow(row: RowViewModel, count: number) {
    let rowIndex = this._rows.indexOf(row);
    if(rowIndex === -1) {
      rowIndex = this._rows.length - 1;
    }

    this.insertRowsAt(
      rowIndex + 1,
      count,
      () => new RowViewModel(
        this,
        this.json.clone(row.getOutline()),
        row.study.studyType,
        this.underlyingData));
  }

  public removeRow(row: RowViewModel) {
    let rowIndex = this._rows.indexOf(row);
    if(rowIndex === -1) {
      return;
    }

    this._rows.splice(rowIndex, 1);
  }

  public clearRow(row: RowViewModel) {
    this.pasteRowsSync(
      row,
      new ClipboardContent(this.tenantId, this.worksheetId, [this.createEmptyRowData()]),
      true);
  }

  public async extractStudyInputs(row: RowViewModel) {
    if (!row.study.reference) {
      return;
    }

    const rowContent = await this.createWorksheetRowFromStudy.execute(
      row.study.reference.tenantId,
      row.study.reference.targetId,
      this.worksheetId,
      CreateWorksheetRowFromStudyOptions.default());

    this.pasteRowsSync(row, rowContent.clipboardContent, false);
  }

  public getSelectedOutline(): WorksheetRow[] {
    let result: WorksheetRow[] = [];
    let pending: WorksheetRow[] = [];
    for(let row of this.rows){
      if(row.anySelectedAndPopulated){
        result.push(...pending);
        pending.length = 0;
        result.push(row.getRowSelectedOutline());
      } else if(result.length){
        pending.push(row.getRowSelectedOutline());
      }
    }
    return result;
  }

  public getSelectedViewModels(): RowItemViewModel[] {
    let result: RowItemViewModel[] = [];
    for(let row of this.rows){
      if(row.isSelected){
        result.push(row.rowMetadata);
      } else{
        result.push(...row.configs.filter(v => v.isSelected));
        if(row.study.isSelected){
          result.push(row.study);
        }
      }
    }
    return result;
  }

  public getSelectedRows(): RowViewModel[] {
    let result: RowViewModel[] = [];
    for(let row of this.rows){
      if(row.anySelected){
        result.push(row);
      }
    }
    return result;
  }

  public clearSelected() {
    for(let row of this.rows){
      if(row.isSelected){
        this.clearRow(row);
      } else {
        if(row.study.populated && row.study.isSelected){
          row.study.setStudy(undefined);
        }

        for(let config of row.configs){
          if(config.populated && config.isSelected){
            config.setConfig(undefined);
          }
        }
      }
    }
  }

  public pasteRowsSync(targetRow: RowViewModel, content: ClipboardContent, removeExisting: boolean) {
    if(!this.verifyClipboardContentExists(content)) {
      return;
    }

    if(content.sourceWorksheetId !== this.worksheetId) {
      throw new Error('Synchronous paste rows cannot be used for content not from this worksheet.');
    }

    this.pasteRowsInner(targetRow, content, removeExisting);
  }

  public applyRows(rows: ReadonlyArray<WorksheetRow>) {
    if(!rows || rows.length === 0 || this._rows.length === 0) {
      return;
    }

    this.applyRowsInner(
      this._rows[0],
      new ClipboardContent(
        this.tenantId,
        this.worksheetId,
        rows),
      true);

    this._rows.length = rows.length;
  }

  public insertRowsAt(index: number, count: number, rowFactory: () => RowViewModel): boolean {
    if(this._rows.length === MAXIMUM_ROW_COUNT){
      return false;
    }

    let result = true;
    if((this._rows.length + count) > MAXIMUM_ROW_COUNT){
      count = MAXIMUM_ROW_COUNT - this._rows.length;
      result = false;
    }

    this._rows.splice(
      index,
      0,
      ...Array.from({length: count}, rowFactory));

    return result;
  }

  public createEmptyRow(studyType?: StudyType): RowViewModel {
    return new RowViewModel(
      this,
      this.createEmptyRowData(),
      studyType === undefined ? this.defaultStudyType : studyType,
      this.underlyingData);
  }

  public async pasteRowsAsync(targetRow: RowViewModel, content: ClipboardContent, forceDuplication: boolean, removeExisting: boolean): Promise<void> {
    if(!this.verifyClipboardContentExists(content)) {
      return;
    }

    const duplicationTask = this.duplicateClipboardContentIfRequired.execute(this.tenantId, this.worksheetId, content, forceDuplication);
    content = await this.loadingDialog.showUntilFinished(duplicationTask, 'Copying...');

    this.pasteRowsInner(targetRow, content, removeExisting);
  }

  private pasteRowsInner(targetRow: RowViewModel, content: ClipboardContent, removeExisting: boolean) {
    if(!this.verifyClipboardContentExists(content)) {
      return;
    }

    this.applyRowsInner(targetRow, content, removeExisting);
  }

  private applyRowsInner(targetRow: RowViewModel, content: ClipboardContent, removeExisting: boolean) {
    const targetRowIndex = this._rows.indexOf(targetRow);
    if(targetRowIndex === -1) {
      return;
    }

    for(let inputRowIndex = 0; inputRowIndex < content.rows.length; ++inputRowIndex){
      const outputRowIndex = targetRowIndex + inputRowIndex;

      if(outputRowIndex === this._rows.length) {
        const insertResult = this.insertRowsAt(
          outputRowIndex,
          1,
          () => this.createEmptyRow(targetRow.study.studyType));

        if(!insertResult){
          // Maximum rows have been reached, so stop pasting.
          break;
        }
      }

      const rowData = content.rows[inputRowIndex];
      const row = this._rows[outputRowIndex];
      if(isDefined(rowData.name)){
        row.name = rowData.name;
      }

      if(removeExisting) {
        row.setAllConfigsFromData(rowData.configs);
      } else if(rowData.configs) {
        for (let config of rowData.configs){
          row.setConfigFromData(config);
        }
      }

      if(rowData.study) {
        row.setStudyFromData(rowData.study);
      } else if(removeExisting){
        row.study.setStudy(undefined);
      }
    }
  }

  private verifyClipboardContentExists(content: ClipboardContent): boolean {
    return !!(content && content.rows && content.rows.length);
  }

  private createEmptyRowData(): WorksheetRow {
    return {
      name: '',
      configs: [],
      study: { reference: undefined },
    };
  }
}

