import {
  ConfigReference,
  DocumentSubType,
} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {LoadConfigFromReference} from './load-config-from-reference';
import {
  CompareConfigDialog,
} from '../simulations/configs/comparing/compare-config-dialog/compare-config-dialog.service';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {SaveToConfigReferenceHandlerFactory} from '../simulations/configs/comparing/save-output-config-handlers/save-to-config-reference-handler';
import {ConfigOrConfigLoader} from '../simulations/configs/comparing/config-or-config-loader';

@Injectable()
export class CompareSelectedConfigs {
  constructor(
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly loadingDialog: LoadingDialog,
    private readonly loadConfigFromReference: LoadConfigFromReference,
    private readonly saveToConfigReferenceHandlerFactory: SaveToConfigReferenceHandlerFactory){
  }

  public async execute(configType: DocumentSubType, primaryConfigReference: ConfigReference, otherConfigReference: ConfigReference){
    const config = await this.loadingDialog.showUntilFinished(
      this.loadConfigFromReference.execute(configType, primaryConfigReference),
      'Loading config 1...');
    const config2 = await this.loadingDialog.showUntilFinished(
      this.loadConfigFromReference.execute(configType, otherConfigReference),
      'Loading config 2...');
    await this.compareConfigDialog.compare(
      configType,
      [
        new ConfigOrConfigLoader('actioned config', config, undefined),
        new ConfigOrConfigLoader('other config', config2, undefined),
      ],
      this.saveToConfigReferenceHandlerFactory.create(primaryConfigReference));
  }
}
