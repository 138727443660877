import {EventEmitter, Injectable} from '@angular/core';
import {WorksheetViewModel} from '../worksheet-view-model';
import {Timer} from '../../common/timer.service';

const MIN_HEIGHT = 50;

@Injectable()
export class WorksheetDock {

  public readonly undockRequested: EventEmitter<void> = new EventEmitter();

  private _height: number = 300;

  private _worksheet: DockedWorksheet | undefined;

  private transferringViewModel: WorksheetViewModel | undefined;

  constructor(private readonly timer: Timer) {
  }

  public onIdentityChanged(){
    this.setWorksheet(undefined);
  }

  public get height(): number {
    return this._worksheet ? this._height : 0;
  }
  public set height(value: number) {
    if(value < MIN_HEIGHT){
      this._height = MIN_HEIGHT;
    } else {
      this._height = value;
    }
  }

  public get worksheet(): DockedWorksheet | undefined {
    return this._worksheet;
  }

  public async setWorksheet(value: DockedWorksheet | undefined) {
    if(this._worksheet && value){
      this._worksheet = undefined;
      await this.timer.yield();
    }

    this._worksheet = value;
  }

  public setTransferringViewModel(viewModel: WorksheetViewModel) {
    this.transferringViewModel = viewModel;
  }

  public popTransferringViewModel(): WorksheetViewModel {
    const result = this.transferringViewModel;
    this.transferringViewModel = undefined;
    return result;
  }

  public requestUndock() {
    this.undockRequested.emit();
  }
}

export class DockedWorksheet {
  constructor(
    public readonly tenantId: string,
    public readonly worksheetId: string){
  }
}
