import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem, KeyboardAction,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {WorksheetClipboard} from '../worksheet-clipboard.service';

@Injectable()
export class CopyCommand extends WorksheetCommand {

  constructor(
    private readonly clipboard: WorksheetClipboard) {
    super();
  }

  public canExecute(context: CommandContext): boolean {
    let canCopy = true;
    if(context.isConfigOrStudy()){
      canCopy = context.target.isPopulated;
    }

    return canCopy;
  }

  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    this.clipboard.copy(
      context.worksheet.tenantId,
      context.worksheet.worksheetId,
      context.worksheet.getSelectedOutline());

    return CommandResult.NoUpdate;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      'Copy' + (context.isRowMetadata() ? ' Row' : ''),
      `copy-button`,
      () => this.execute(context),
      'file-o',
      KeyboardAction.copy));
  }
}
