import { Injectable } from '@angular/core';
import { isArray } from './is-array';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SplitItemsResolver extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.resolvers.unshift(function(schema: any) {
      if (isArray(schema)) {
        if (schema.items.type === 'string') {
          if (schema.savedConfigs) {
            // Saved configs should stay as individual items.
          } else {
            return 'splitStrings';
          }
        } else if (schema.items.type === 'number') {
          return 'splitNumbers';
        } else if (schema.items.type === 'integer') {
          return 'splitIntegers';
        } else if (schema.items.type === 'boolean') {
          return 'splitBooleans';
        } else if (schema.items.anyOf
          && schema.items.anyOf.length === 2
          && schema.items.anyOf.every((v: any) => v && (v.type === 'number' || v.type === 'null'))) {

          // This is handling data where NaNs become null during serialization, for example:
          // 'data': {
          //   'type': 'array',
          //   'minItems': 2,
          //   'title': 'Channel Data',
          //   'items': {
          //     'anyOf': [
          //       {'type': 'number'},
          //       {'type': 'null'}
          //     ]
          //   }
          // }
          return 'splitNumbers';
        }
      }

      return undefined;
    });
  }
}
