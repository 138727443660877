import * as d3 from '../../d3-bundle';
import { IMultiPlotLayout } from '../data-pipeline/types/i-multi-plot-layout';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedMultiPlotChannel } from '../data-pipeline/types/processed-multi-plot-channel';
import { DisplayableError } from '../../displayable-error';
import { cssSanitize } from '../../css-sanitize';
import { IMinMax } from '../min-max';
import { ExplorationData, ChannelType } from '../channel-data-loaders/study-source-loader';
import { isUndefined } from '../../is-defined';

export interface DimensionLookup {
  [name: string]: Dimension;
}

export interface ReadonlyDimensionLookup {
  readonly [name: string]: Dimension;
}

export class Dimension {
  public readonly channel: ProcessedMultiPlotChannel;

  public readonly scale: d3.ScaleLinear<number, number>;
  private _calculatedPosition: number = 0;
  public dragOffset: number = 0;

  public readonly id: string;

  constructor(
    public readonly allDimensions: ReadonlyArray<Dimension>,
    public readonly plot: ProcessedPlot,
    outputDimensionIndex: number) {

    if (isUndefined(this.outputDimensionIndex)) {
      this.outputDimensionIndex = outputDimensionIndex;
    }

    const channel = plot.column.processed.channels.find(v => v.isVisible);
    if (!channel) {
      throw new DisplayableError('No visible channels found for dimension.');
    }

    this.channel = channel;

    this.id = cssSanitize(this.channel.name);

    // Our scale is going to be the domain of the plot X axis with the range of the plot Y axis,
    // because we're flipping the X axis on it's side.
    this.scale = d3.scaleLinear<number, number>();
    this.zoomToDomain(this.zoomDomain);

    this.updatePlotSizes();
  }

  public updatePlotSizes() {
    let column = this.plot.column.processed;
    let row = this.plot.row.processed;

    // Our axis position is going to be half way along the plot.
    this._calculatedPosition = column.offset + (column.size / 2);

    // Copy the range from the plot, but use the row height the axes are rotated 90 degrees.
    // We map from the column scale range so that we put the zero and maximum in the same order
    // (it varies depending on if the axis is reversed), and then reverse it as we're flipping it 90 degrees.
    this.scale.range(column.scale.range().map(v => v === column.plotSize ? row.plotSize : v).reverse());
  }

  public get isZoomedToFilter(): boolean {
    const domain = this.scale.domain();
    if (this.filter) {
      return d3.min(domain) === this.filter.minimum
        && d3.max(domain) === this.filter.maximum;
    } else {
      let columnDomain = this.plot.column.processed.scale.domain();
      return domain[0] === columnDomain[0] && domain[1] === columnDomain[1];
    }
  }

  public zoomToFilter() {
    if (this.filter && !this.isZoomedToFilter) {
      let domain: [number, number] = [this.filter.minimum, this.filter.maximum];
      this.zoomDomain = domain;
      this.zoomToDomain(domain);
    } else {
      this.zoomDomain = undefined;
      this.resetZoom();
    }
  }

  private zoomToDomain(domain: [number, number] | undefined) {
    if (domain) {
      this.scale.domain(domain);
    } else {
      this.resetZoom();
    }
  }

  private resetZoom() {
    this.scale.domain(this.dataDomain);
  }

  public get dataDomain(): [number, number] {
    return this.plot.column.processed.scale.domain() as [number, number];
  }

  public get explorationData(): ExplorationData {
    return this.channel.sources[0].getSourceData<ExplorationData>();
  }

  public get isInputDimension(): boolean {
    return this.explorationData.channelType === ChannelType.input;
  }

  public get calculatedPosition(): number {
    return this._calculatedPosition;
  }

  public get renderPosition(): number {
    if (this.dragOffset) {
      return this._calculatedPosition + this.dragOffset;
    }

    let offsetDimension = this.allDimensions.find(v => !!v.dragOffset);
    if (offsetDimension) {
      // There is a dimension being offset by dragging. Check if we need to shuffle up or down.
      let offsetDimensionPosition = offsetDimension.renderPosition;
      if (offsetDimension.plot.plotIndex < this.plot.plotIndex && offsetDimensionPosition >= this.plot.absoluteRenderArea.x) {
        // It has been dragged passed our axis, so shuffle down.
        return this.allDimensions[this.plot.plotIndex - 1].calculatedPosition;
      } else if (offsetDimension.plot.plotIndex > this.plot.plotIndex && offsetDimensionPosition <= (this.plot.absoluteRenderArea.x + this.plot.absoluteRenderArea.width)) {
        // It has been dragged passed our axis, so shuffle up.
        return this.allDimensions[this.plot.plotIndex + 1].calculatedPosition;
      }
    }

    return this._calculatedPosition;
  }

  // This is used to flag when the axis is being flipped, so that we can transition
  // the brush without rendering the filtered data as the brush moves.
  public get isAxisReversing(): boolean {
    return this.channel.unprocessed.transient.flags['isAxisReversing'];
  }
  public set isAxisReversing(value: boolean) {
    this.channel.unprocessed.transient.flags['isAxisReversing'] = value;
  }

  // This is used to track when we are brushing, so we don't recursively
  // move the brush during a render while we are brushing.
  public get isBrushing(): boolean {
    return this.channel.unprocessed.transient.flags['isBrushing'];
  }
  public set isBrushing(value: boolean) {
    this.channel.unprocessed.transient.flags['isBrushing'] = value;
  }

  // This is used to flag when the axis is being zoomed, so that we can transition
  // the brush without rendering the filtered data as the brush moves.
  public get isAxisZooming(): boolean {
    return this.channel.unprocessed.transient.flags['isAxisZooming'];
  }
  public set isAxisZooming(value: boolean) {
    this.channel.unprocessed.transient.flags['isAxisZooming'] = value;
  }

  // This is used to track when we are brushing, so we don't recursively
  // move the brush during a render while we are brushing.
  public get zoomDomain(): [number, number] | undefined {
    return this.plot.column.transient.custom['zoomDomain'];
  }
  public set zoomDomain(value: [number, number] | undefined) {
    this.plot.column.transient.custom['zoomDomain'] = value;
  }

  // This is used to track when we are brushing, so we don't recursively
  // move the brush during a render while we are brushing.
  public get outputDimensionIndex(): number {
    return this.plot.column.transient.custom['outputDimensionIndex'];
  }
  public set outputDimensionIndex(value: number) {
    this.plot.column.transient.custom['outputDimensionIndex'] = value;
  }

  public get filter(): IMinMax | undefined {
    return this.channel.unprocessed.transient.filter;
  }
  public set filter(value: IMinMax | undefined) {
    this.channel.unprocessed.transient.filter = value;
  }
}

export class LineMouseEvent {
  constructor(
    public type: string,
    public lineIndex: number,
    public sourceEvent?: any) {
  }
}

export type PointsInDimensions = ReadonlyArray<number>;

export class ParallelCoordinatesData {
  constructor(
    public readonly layout: IMultiPlotLayout,
    public readonly dimensionList: ReadonlyArray<Dimension>,
    public readonly dimensionsByName: ReadonlyDimensionLookup,
    public readonly lines: ReadonlyArray<PointsInDimensions>,
    public readonly colorScale: d3.ScaleContinuousNumeric<string, string>,
    public readonly colorDimensionIndex: number) {
  }
}
