import {WorksheetViewModel} from '../worksheet-view-model';
import {RowItemViewModel} from '../row-item-view-model';
import {MenuItem} from '../../context-menu/context-menu-types';
import {RowMetadataViewModel} from '../row-metadata-view-model';
import {ConfigViewModel} from '../config-view-model';
import {StudyViewModel} from '../study-view-model';
import {SimulationViewModel} from '../simulation-view-model';
import {RowViewModel} from '../row-view-model';
import {ColumnType} from '../column-type';
import {ActivatedRoute} from '@angular/router';

export abstract class WorksheetCommand{
  protected constructor(){
  }

  public abstract createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void;
}

export class WorksheetContext {
  constructor(
    public readonly isDocked: boolean,
    public readonly route: ActivatedRoute) {
  }
}

export interface ICommandContext<T extends RowItemViewModel> {
  readonly worksheet: WorksheetViewModel;
  readonly target: T;
  readonly selectedRows: ReadonlyArray<RowViewModel>;
  readonly isMultiRow: boolean;
  readonly isSingleRow: boolean;
  readonly worksheetContext: WorksheetContext;

  isRowMetadata(): this is ICommandContext<RowMetadataViewModel>;
  isConfig(): this is ICommandContext<ConfigViewModel>;
  isStudy(): this is ICommandContext<StudyViewModel>;
  isSimulation(): this is ICommandContext<SimulationViewModel>;
  isConfigOrStudy(): this is ICommandContext<ConfigViewModel> | ICommandContext<StudyViewModel>;
}

// It is possible for the user to make non-sensical or complex selections when performing certain commands.
// We do a best attempt at handling these sensibly, sometimes simplifying to save code complexity.
// Often this means paying attention to the target cell, and the selected rows, and assuming
// the user wanted to select the target cell type in all rows. This is why these are the two things
// exposed by default. However you can get the exact selection if if makes sense for the command (see copy command).
export class CommandContext implements ICommandContext<RowItemViewModel> {
  public readonly selectedRows: ReadonlyArray<RowViewModel>;
  public readonly isMultiRow: boolean;
  public readonly isSingleRow: boolean;

  constructor(
    public readonly target: RowItemViewModel,
    public readonly worksheetContext: WorksheetContext){
    this.selectedRows = this.worksheet.getSelectedRows();
    this.isMultiRow = this.selectedRows.length > 1;
    this.isSingleRow = this.selectedRows.length === 1;
  }

  public get worksheet(): WorksheetViewModel {
    return this.target.row.worksheet;
  }

  public isRowMetadata(): this is ICommandContext<RowMetadataViewModel> {
    return this.target instanceof RowMetadataViewModel;
  }

  public isConfig(): this is ICommandContext<ConfigViewModel> {
    return this.target instanceof ConfigViewModel;
  }

  public isStudy(): this is ICommandContext<StudyViewModel> {
    return this.target instanceof StudyViewModel;
  }

  public isSimulation(): this is ICommandContext<SimulationViewModel> {
    return this.target instanceof SimulationViewModel;
  }

  public isConfigOrStudy(): this is ICommandContext<ConfigViewModel> | ICommandContext<StudyViewModel> {
    return this.target instanceof ConfigViewModel || this.target instanceof StudyViewModel;
  }
}

export enum UpdateType {
  none,
  update,
  updateAndGenerateColumns,
}

export class CommandResult {
  public static readonly NoUpdate: CommandResult = new CommandResult(false, UpdateType.none);
  public static readonly UpdateOnly: CommandResult = new CommandResult(false, UpdateType.update);
  public static readonly UpdateAndGenerateColumns: CommandResult = new CommandResult(true, UpdateType.updateAndGenerateColumns);
  public static readonly GenerateColumnsOnly: CommandResult = new CommandResult(true, UpdateType.none);

  constructor(
    public readonly generateColumnsImmediately: boolean,
    public readonly refreshWorksheet: UpdateType){
  }
}

export class MockCommandContext<T extends RowItemViewModel> implements ICommandContext<T> {
  constructor(
    public readonly target: any,
    public readonly worksheet: any,
    public readonly selectedRows: ReadonlyArray<any>,
    private readonly columnType: ColumnType,
    worksheetContext?: WorksheetContext){
      this.worksheetContext = worksheetContext || {} as any;
  }

  public readonly worksheetContext: WorksheetContext;

  public get isMultiRow(): boolean {
 return this.selectedRows.length > 1; 
}
  public get isSingleRow(): boolean {
 return this.selectedRows.length === 1; 
}

  isConfig(): this is ICommandContext<ConfigViewModel> {
    return this.columnType === ColumnType.config;
  }

  isConfigOrStudy(): this is ICommandContext<ConfigViewModel> | ICommandContext<StudyViewModel> {
    return this.columnType === ColumnType.config || this.columnType === ColumnType.study;
  }

  isRowMetadata(): this is ICommandContext<RowMetadataViewModel> {
    return this.columnType === ColumnType.rowMetadata;
  }

  isSimulation(): this is ICommandContext<SimulationViewModel> {
    return this.columnType === ColumnType.simulation;
  }

  isStudy(): this is ICommandContext<StudyViewModel> {
    return this.columnType === ColumnType.study;
  }
}
