import { Injectable } from '@angular/core';
import { CommandContext, CommandResult, ICommandContext, WorksheetCommand } from './worksheet-command';
import { ButtonMenuItem, MenuItem, SubMenuItem } from '../../context-menu/context-menu-types';
import { ConfigViewModel } from '../config-view-model';
import { ExportConfigService } from '../../simulations/configs/export-config.service';
import { ConfigReferenceLoaderFactory } from '../../simulations/configs/comparing/config-loaders/config-reference-loader';
import { ConfigOrConfigLoader } from '../../simulations/configs/comparing/config-or-config-loader';

@Injectable()
export class DownloadConfigCommandService extends WorksheetCommand {

  constructor(
    private readonly exportConfig: ExportConfigService,
    private readonly loadConfigFromReference: ConfigReferenceLoaderFactory){
    super();
  }

  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    return context.isConfig()
      && context.isSingleRow
      && context.target.isPopulated;
  }

  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }
    let configLoader = this.loadConfigFromReference.create(context.target.configType, context.target.reference, undefined);
    let downloadTypes: MenuItem<CommandResult>[] = [];
    for(let downloadType of this.exportConfig.downloadConfigTypes(() => new ConfigOrConfigLoader('', undefined, configLoader))){
      downloadTypes.push(new ButtonMenuItem<CommandResult>(
        downloadType.displayName,
        '',
        async () => {
          await downloadType.execute();
          return CommandResult.NoUpdate;
        },
        'download'));
    }
    result.push(new SubMenuItem<CommandResult>(
      'Download',
      '',
      downloadTypes,
      true));
  }
}
