import { Injectable } from '@angular/core';
import { stringToBoolean } from './string-to-boolean';
import { JsonEditorCustomization } from './json-editor-customization';

export declare let JSONEditor: any;

@Injectable()
export class SplitBooleans2DEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitBooleans2D = JSONEditor.defaults.editors.splitStrings2D.extend({
      getValue() {
        return (<any>this)._super().map(function(line: string[]) {
 return line.map(function(str) {
 return stringToBoolean(str); 
}); 
});
      }
    });
  }
}
