import {Component, OnInit, Input} from '@angular/core';
import {CanopyJsonDiffPatch} from '../../../../common/canopy-json-diff-patch.service';
import {Timer} from '../../../../common/timer.service';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

@Component({
  selector: 'cs-compare-config-deprecated',
  templateUrl: './compare-config-deprecated.component.html',
  styleUrls: ['./compare-config-deprecated.component.scss']
})
export class CompareConfigDeprecatedComponent implements OnInit {
  @Input() public firstTitle: string;
  @Input() public first: any;
  @Input() public secondTitle: string;
  @Input() public second: any;
  @Input() public elementId: string;

  public errorMessage: string;

  constructor(
    private readonly canopyJsonDiffPatch: CanopyJsonDiffPatch,
    private readonly timer: Timer,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){

    if(!this.elementId){
      this.elementId = 'diff';
    }
  }

  public ngOnInit(): void {
    this.load();
  }

  public async load(){
    try {
      await this.timer.yield();
      this.canopyJsonDiffPatch.diff(this.first, this.second, this.elementId);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}
